import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class AuctionRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.auctionApi;
  }

  getAuctionsToken(params: any) {
    const url = '/public/auctions';
    return this.get(url, params);
  }

  createAuction(params: any) {
    const url = `/my/auctions`;
    return this.post(url, params);
  }

  getSwapList(params: {
    network: string;
    poolId: string;
    sortBy?: string;
    sortType?: string;
    page?: number;
    limit?: number;
  }) {
    const url = '/public/swaps';
    return this.get(url, params);
  }

  getAuctionDetail(id: string) {
    const url = `/public/auctions/${id}`;
    return this.get(url);
  }

  getMyLBPs(params: any) {
    const url = `/my/auctions`;
    return this.get(url, params);
  }

  getInfoJoinExitHistoryPool(id: string) {
    const url = `/public/pools/${id}/join-exits`;
    return this.get(url);
  }

  getPriceSnapshotOfPool(poolId: string, mainTokenAddress: string) {
    const url = `/public/pools/${poolId}/price-snapshots`;
    return this.get(url, {
      asset: mainTokenAddress,
    });
  }
}
