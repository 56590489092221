import {
  DiscordIcon,
  MediumIcon,
  SocialIcon,
  TelegramIcon,
  TwitterIcon,
  WebIcon,
  FacebookGrayIcon,
  YoutubeGrayIcon,
  TiktokIcon,
} from 'src/assets/icons';
import { MediaType } from 'src/utils/project';
import { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import styles from 'src/styles/components/SocialMenu.module.scss';

type TYPE_SHOW = 'list' | 'menu';

type PropsType = {
  media: MediaType;
  type?: TYPE_SHOW;
  iconColor?: string;
};

const _renderSocialLinksTypeList = (media: MediaType, iconColor?: string) => {
  return (
    <>
      {media.mediumUrl && (
        <a
          href={media.mediumUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <MediumIcon color={iconColor} />
        </a>
      )}

      {media.telegramUrl && (
        <a
          href={media.telegramUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <TelegramIcon color={iconColor} />
        </a>
      )}

      {media.twitterUrl && (
        <a
          href={media.twitterUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <TwitterIcon color={iconColor} />
        </a>
      )}

      {media.discordUrl && (
        <a
          href={media.discordUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <DiscordIcon color={iconColor} />
        </a>
      )}

      {media.facebookUrl && (
        <a
          href={media.facebookUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <FacebookGrayIcon color={iconColor} />
        </a>
      )}

      {media.tiktokUrl && (
        <a
          href={media.tiktokUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <TiktokIcon color={iconColor} />
        </a>
      )}

      {media.youtubeUrl && (
        <a
          href={media.youtubeUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <YoutubeGrayIcon color={iconColor} />
        </a>
      )}

      {media.websiteUrl && (
        <a
          href={media.websiteUrl}
          target="_blank"
          rel="noreferrer"
          className={styles['link-icon']}
        >
          <WebIcon color={iconColor} />
        </a>
      )}
    </>
  );
};

const _renderSocialLinkTypeMenu = (media: MediaType, iconColor?: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickSocialIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div onClick={onClickSocialIcon}>
        <SocialIcon />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: styles['social-menu-paper'],
          list: styles['social-menu-list'],
        }}
      >
        <MenuItem>
          <div className={styles['social-menu-list']}>
            {_renderSocialLinksTypeList(media, iconColor)}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

const SocialLinks = ({ media, type = 'list', iconColor }: PropsType) => {
  if (type === 'menu') return _renderSocialLinkTypeMenu(media, iconColor);
  return _renderSocialLinksTypeList(media, iconColor);
};

export default SocialLinks;
