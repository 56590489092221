import axios from 'axios';
import config from 'src/config';
import { JsonRpcProvider } from '@ethersproject/providers';
import jwt from 'jsonwebtoken';
import Storage from 'src/utils/storage';
import _ from 'lodash';

export const setAuthorizationToRequest = (
  accessToken: string,
  chainId: string,
) => {
  const network = _.find(
    config.networks,
    (network) => Number(network.chainId) === Number(chainId),
  );
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  axios.defaults.headers.common['x-app-domain'] = config.auth.domain;
  axios.defaults.headers.common['x-app-network'] = network?.id || '';
  axios.defaults.headers.common['x-app-version'] = 2;
  axios.defaults.headers.common['x-app-message'] = config.auth.message;
};

export const switchNetwork = async (
  network: string,
  provider: JsonRpcProvider | null | undefined,
) => {
  if (!provider) {
    throw new Error('[SwitchNetwork] throw exception: Not found provider');
  }
  const chainId = config.networks[network].chainId;
  try {
    await provider.send('wallet_switchEthereumChain', [
      {
        chainId: `0x${chainId.toString(16)}`,
      },
    ]);
  } catch (error: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    // TODO: change 4902 to constant variable
    if (error.code === 4902) {
      return addNewNetwork(network, provider);
    }
    // 4001: User rejected to switch network
    return new Promise((resolve, reject) => reject(error.message));
  }
};

const addNewNetwork = (network: string, provider: JsonRpcProvider) => {
  try {
    const { chainId, name, nativeCurrency, rpcUrls, blockExplorer } =
      config.networks[network];
    return provider.send('wallet_addEthereumChain', [
      {
        chainId: `0x${chainId.toString(16)}`,
        chainName: name,
        nativeCurrency,
        rpcUrls,
        blockExplorerUrls: [blockExplorer.url],
      },
    ]);
  } catch (error: any) {
    console.error(`[AddNewNetwork] throw exception: ${error.message}`, error);
    throw error;
  }
};

export const generateJwtToken = async (
  userAddress: string,
  signature: string,
): Promise<string> => {
  const date = new Date();
  const payload = {
    address: userAddress,
    signature: signature,
    network: Storage.getNetwork(),
    signType: 'signTypedData',
    iat: date.getTime() / 1000,
    exp: date.getTime() / 1000 + 365 * 24 * 3600, //  temporary 1 year
  };
  return jwt.sign(payload, config.auth.secretKey, {
    algorithm: 'HS256',
  });
};
