import { useDispatch, useSelector } from 'react-redux';
import config, { Connector } from 'src/config';
import { RootState } from 'src/store';
import { map } from 'lodash';
import { setNetwork } from 'src/store/authentication';
import { switchNetwork } from 'src/utils/utils-auth';
import Storage from 'src/utils/storage';
import styles from 'src/styles/modals/ModalConnectWallet.module.scss';
import { toggleConnectWalletModal } from 'src/store/authentication';
import { Web3Provider } from '@ethersproject/providers';
import useAuth from '../hooks/useAuth';
import { METAMASK_WALLET, TRUST_WALLET } from 'src/connectors';
import { toastError } from 'src/utils/utils-notify';
import { getErrorMessage } from 'src/utils/utils-helpers';
import BaseModal from './BaseModal';

const ModalConnectWallet = () => {
  const dispatch = useDispatch();
  const { connectWallet } = useAuth();

  const { network, address, provider, openConnectWalletModal } = useSelector(
    (state: RootState) => state.authentication,
  );
  const networkList = Object.keys(config.networks)
    .filter((key) => config.networks[key].support.some((s) => s === 'signIn'))
    .map((key) => config.networks[key]);
  const connectorsByNetwork = config.networks[network]?.connectors;

  const handleClose = () => {
    dispatch(toggleConnectWalletModal(false));
  };

  const handleNetworkChange = async (newNetwork: string) => {
    if (network !== newNetwork) {
      const currentConnectorId = Storage.getConnectorId();
      if (provider && address && currentConnectorId) {
        // if user has already logged in
        await switchNetwork(newNetwork, new Web3Provider(provider)); // change network
      }
      // else => only store in redux and local storage
      else dispatch(setNetwork(newNetwork));
    }
  };

  const handleWalletChange = async (wallet: Connector) => {
    if (!wallet) {
      return;
    }
    try {
      if ([METAMASK_WALLET, TRUST_WALLET].includes(wallet.id)) {
        const provider = (window as any).ethereum;
        await switchNetwork(network, provider);
      }
      await connectWallet(wallet.id, network);
      handleClose && handleClose();
    } catch (error: any) {
      toastError({ message: getErrorMessage(error) });
    }
  };

  return (
    <BaseModal
      open={openConnectWalletModal}
      onClose={handleClose}
      fullScreenMobile
      aria-labelledby="modal-connect-wallet-title"
      aria-describedby="modal-connect-wallet-description"
      title={'Connect to a wallet'}
    >
      <div className={styles['container-wallet']}>
        <div className={styles['section']}>
          <p className={styles['title']}>1. Choose Network</p>
          <div className={styles['grid']}>
            {networkList.map((item, index) => (
              <div
                className={`${styles['grid-item']} ${styles['grid-item-6']} ${styles['grid-item-md-4']}`}
                key={index}
              >
                <div
                  className={`${styles['choose-item']} ${
                    network === item.id ? styles['selected'] : ''
                  }`}
                  onClick={() => handleNetworkChange(item.id)}
                >
                  <img
                    className={styles['img-thumb']}
                    src={item.icon ? item.icon : '/images/loading.png'}
                    alt={item.name ? item.name : 'loading img'}
                  />
                  {item.name && <p className={styles['name']}>{item.name}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['section']}>
          <p className={styles['title']}>2. Choose Wallet</p>
          <div className={styles['grid']}>
            {map(connectorsByNetwork, (wallet: any) => (
              <div
                className={`${styles['grid-item']} ${styles['grid-item-6']} ${styles['grid-item-md-4']}`}
                key={wallet.id}
              >
                <div
                  className={styles['choose-item']}
                  onClick={() => handleWalletChange(wallet)}
                >
                  <img
                    className={styles['img-thumb']}
                    src={wallet.icon ? wallet.icon : '/images/loading.png'}
                    alt=""
                  />
                  {wallet.name && (
                    <p className={styles['name']}>{wallet.name}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalConnectWallet;
