import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import styles from 'src/styles/modals/ModalMyAssets.module.scss';
import { formatWeiNumber } from 'src/utils/utils-formats';
import BaseModal from './BaseModal';
import NoResult from 'src/assets/icons/NoResult';
import AppDropdown from 'src/components/AppDropdown';
import { getNetworkConfigs, getNetworkProvider } from 'src/utils/utils-network';
import config from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { multicall } from 'src/utils/utils-multicall';
import abi from 'src/abi';
import LoadingIcon from 'src/assets/icons/LoadingIcon';

const ADDRESS_CURRENCY_NATIVE = '0x00';

interface ModalMyAssetsProps {
  open: boolean;
  onClose: () => void;
}

const INDEX_TAB_MY_ASSET = {
  token: 0,
  nft: 1,
};

const ModalMyAssets: FC<ModalMyAssetsProps> = ({ open, onClose, ...props }) => {
  const {
    authentication: { network },
  } = useSelector((state: RootState) => state);

  const networks = getNetworkConfigs();
  const { user } = useAuth();
  const networksAccept = networks.filter(
    (item) => item.name.toLowerCase() !== 'solana',
  );
  const NETWORK_OPTIONS = networksAccept.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const [tabAsset, setTabAsset] = useState<number>(INDEX_TAB_MY_ASSET.token);
  const [dropdownNetwork, setDropdownNetwork] = useState<string>(network || '');

  const [dataToken, setDataToken] = useState<any[]>([]);
  const [dataNFT, setDataNFT] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getBalances = async () => {
    if (!user) {
      return;
    }

    setLoading(true);
    try {
      const provider = getNetworkProvider(dropdownNetwork);
      const tokens = config.networks[dropdownNetwork].currencies;
      const tokensBase = Object.values(tokens).filter(
        (item: any) => item.address !== ADDRESS_CURRENCY_NATIVE,
      );
      const calls: any[] = [];

      tokensBase.forEach((token: any) => {
        calls.push({
          address: token.address,
          name: 'balanceOf',
          params: [user.getAddress()],
        });
      });

      const balances = await multicall(abi['Erc20'], calls, dropdownNetwork);
      const balanceTokenNative = await provider.getBalance(user.getAddress());

      const data = Object.values(tokens).map((token: any, index: number) => {
        return {
          ...token,
          balance:
            token.address === ADDRESS_CURRENCY_NATIVE
              ? balanceTokenNative.toString()
              : balances[index - 1]?.toString(),
        };
      });

      setDataToken(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getNameNetwork = (networkId: string) => {
    return networksAccept.find((item) => item.id === networkId)?.name || '';
  };

  useEffect(() => {
    getBalances();
  }, [dropdownNetwork, user]);

  const _renderTabToken = () => {
    const _renderDataTokens = () => {
      if (!dataToken.length) {
        return (
          <tr>
            <td className={styles['col-nofound']} colSpan={4}>
              <div className={styles['nft-nofound']}>
                <NoResult />
              </div>
            </td>
          </tr>
        );
      }

      return (
        <>
          {dataToken.map((item: any, index) => (
            <tr key={index}>
              <td className={styles['col-asset']}>
                <img src={item.icon} alt="" />
                {item?.name}
              </td>
              <td className={styles['col-symbol']}>{item?.symbol}</td>
              <td className={styles['col-network']}>
                {getNameNetwork(dropdownNetwork)}
              </td>
              <td className={styles['col-value']}>
                <div className={styles['cell-value']}>
                  <span className={styles['value-token']}></span>
                  <span className={styles['value-currency']}>
                    {formatWeiNumber(item.balance, item.decimals)}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </>
      );
    };

    return (
      <div className={styles['table-scroll']}>
        <table className={styles['table-asset-token']}>
          <thead>
            <tr>
              <th className={styles['col-asset']}>Assets</th>
              <th className={styles['col-symbol']}>Symbol</th>
              <th className={styles['col-network']}>Network</th>
              <th className={styles['col-value']}>Value</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className={styles['col-nofound']} colSpan={4}>
                  <div className={styles['nft-nofound']}>
                    <LoadingIcon width={100} height={100} />
                  </div>
                </td>
              </tr>
            ) : (
              _renderDataTokens()
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const _renderTabNFT = () => {
    const FieldItem = (propsItem: any) => {
      const { label = '', value = '' } = propsItem;
      return (
        <div className={styles['field-text']}>
          <div className={styles['aside']}>
            <span className={styles['label']}>{label}</span>
          </div>
          <div className={`${styles['aside']} ${styles['grow']}`}>
            <div className={styles['value-ellipsis']}>
              <span className={styles['value']}>{value}</span>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={styles['tab-nft']}>
        <div className={styles['grid-my-nft']}>
          {dataNFT.length ? (
            dataNFT.map((item) => (
              <div
                key={item}
                className={`${styles['grid-item']} ${styles['grid-item-md-6']} ${styles['grid-item-lg-3']}`}
              >
                <div className={styles['card-nft']}>
                  <div className={styles['card-header']}>
                    <img
                      className={styles['thumb-card']}
                      src="/images/thumb-staking.png"
                      alt=""
                    />
                    <div className={styles['title-card']}>
                      {' '}
                      Depseabu (ID: 515){' '}
                    </div>
                  </div>
                  <div className={styles['card-body']}>
                    <FieldItem label={'Item name'} value={'Depseabu'} />
                    <FieldItem label={'Owner'} value={'0xa0F8...E51E'} />
                    <FieldItem label={'ID'} value={'1632'} />
                    <FieldItem label={'Collection'} value={'Bunicorn'} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles['nft-nofound']}>
              <NoResult />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="My Assets"
      maxWidth={'lg'}
      fullScreenMobile
      {...props}
    >
      <div className={styles['tab-bar']}>
        <div className={styles['tab-asset']}>
          <button
            type="button"
            className={`${styles['btn-tab']} ${
              tabAsset === INDEX_TAB_MY_ASSET.token ? styles['active'] : ''
            }`}
            onClick={() => setTabAsset(INDEX_TAB_MY_ASSET.token)}
          >
            My Tokens
          </button>
          <button
            type="button"
            className={`${styles['btn-tab']} ${
              tabAsset === INDEX_TAB_MY_ASSET.nft ? styles['active'] : ''
            }`}
            onClick={() => setTabAsset(INDEX_TAB_MY_ASSET.nft)}
          >
            My NFT
          </button>
        </div>
        <div className={styles['dropdown-network']}>
          <AppDropdown
            variant="outlined"
            value={dropdownNetwork}
            onChange={(e: any) => setDropdownNetwork(e)}
            options={NETWORK_OPTIONS}
          />
        </div>
      </div>
      <div className={styles['content-tab']}>
        {tabAsset === INDEX_TAB_MY_ASSET.token && _renderTabToken()}
        {tabAsset === INDEX_TAB_MY_ASSET.nft && _renderTabNFT()}
      </div>
    </BaseModal>
  );
};

export default ModalMyAssets;
