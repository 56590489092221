import { useEffect, useMemo, useState } from 'react';
import styles from 'src/styles/components/MyPool.module.scss';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ArrowCircleDownIcon, CalendarIcon, ClockIcon } from 'src/assets/icons';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import {
  formatTimestamp,
  formatNumber,
  convertWeiToDec,
} from 'src/utils/utils-formats';
import { Link } from 'react-router-dom';
import { INOTimeline, WhitelistPhaseType } from 'src/utils/timelines';
import { Collapse, Tooltip } from '@material-ui/core';
import {
  PHASES,
  getPoolDetailLink,
  getIDOPhaseClassName,
} from 'src/utils/utils-pool';
import { isMobile } from 'react-device-detect';
import useAuth from 'src/hooks/useAuth';
import { INOPool, PoolResponseType } from 'src/utils/pool';
import BigNumber from 'bignumber.js';
import { getNetworkProvider } from 'src/utils/utils-network';
import { inoPoolContract } from 'src/utils/utils-contract';

interface IMyINOPoolType {
  pool: PoolResponseType;
}

const MyINOPool = (props: IMyINOPoolType) => {
  const { pool } = props;
  const { user } = useAuth();

  const timeline = useMemo(() => new INOTimeline(pool), [pool]);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [userInvest, setUserInvest] = useState<string | BigNumber>('0');
  const inoPool = useMemo(() => new INOPool(pool), [pool]);

  /** start:: state, variable on mobile */
  const [openCollapse, setOpenCollapse] = useState(false);
  /** end:: state, variable on mobile */

  const getUserInvest = async (contractAddress: string, network: string) => {
    if (!user || !contractAddress) {
      return;
    }
    if (inoPool.isNetworkSolana()) {
      //
    } else {
      const networkProvider = getNetworkProvider(network);
      const contract = inoPoolContract(contractAddress, networkProvider);
      const result = await contract.userInvested(user.getAddress());
      setUserInvest(result.toString());
    }
  };

  useEffect(() => {
    getUserInvest(inoPool.getContractAddress(), inoPool.getNetwork());
  }, [user?.getAddress(), inoPool.getContractAddress()]);

  const _renderExpandedItem = (
    phase: typeof PHASES[keyof typeof PHASES],
    title: string,
    time: number,
  ) => {
    const HOUR_FORMAT = 'HH:mm';
    const DATE_FORMAT = 'YYYY/MM/DD';
    return (
      <div className={styles['card-mypool-item']}>
        <div className={styles['flex-title-item']}>
          <span
            className={`${styles['after-status']} ${
              styles[getIDOPhaseClassName(timeline, phase)]
            } `}
          />
          <label className={styles['label']}>{title}</label>
        </div>
        <div className={styles['detail']}>
          <div className={styles['row']}>
            <span className={styles['label-icon']}>
              <ClockIcon />
            </span>
            <span className={styles['text']}>
              {formatTimestamp(time, HOUR_FORMAT)}
            </span>
          </div>
          <div className={styles['row']}>
            <span className={styles['label-icon']}>
              <CalendarIcon />
            </span>
            <span className={styles['text']}>
              {formatTimestamp(time, DATE_FORMAT)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const _renderForMobile = () => {
    const whitelistPhase: WhitelistPhaseType = timeline.getWhitelistPhase();
    const { startTime: swapStartTime, endTime: swapEndTime } =
      timeline.getSwapTime();
    return (
      <>
        <div
          onClick={() => setOpenCollapse((prevState) => !prevState)}
          className={styles['card-mypool-header']}
        >
          <div className={styles['flex-header']}>
            <Link to={getPoolDetailLink(pool)} className={styles['link-pool']}>
              <span className={styles['icon']}>
                <img src={inoPool.getSwapTokenLogoUrl()} alt="" />
              </span>
              <Tooltip title={inoPool.getName()} placement="top-start">
                <h5 className={styles['title']}>{inoPool.getName()}</h5>
              </Tooltip>
            </Link>
            <span
              className={`${styles['icon-collapse']} ${
                !openCollapse ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['card-mypool-subheader']}>
          <label className={styles['label']}>Invest</label>
          <h6 className={styles['text']}>
            {formatNumber(userInvest)}{' '}
            {Number(userInvest) > 0 && inoPool.getCollateralCurrencySymbol()}
          </h6>
        </div>
        <Collapse
          in={openCollapse}
          timeout="auto"
          unmountOnExit
          style={{ borderTop: '1px solid #1F2646', marginTop: '16px' }}
        >
          <div className={styles['card-mypool-body']}>
            {_renderExpandedItem(
              PHASES.WHITE_LIST,
              'Whitelist',
              timeline.beforeWhitelistPhase()
                ? whitelistPhase.startTime
                : whitelistPhase.endTime,
            )}
            {_renderExpandedItem(
              PHASES.TOKEN_SALE,
              'Swap',
              timeline.isSwapPhase() || timeline.afterSwapPhase()
                ? swapEndTime
                : swapStartTime,
            )}
          </div>
        </Collapse>
      </>
    );
  };

  const _renderForDesktop = () => {
    const handleExpandAccordion = () => {
      setIsExpanded((preState) => !preState);
    };
    const whitelistPhase: WhitelistPhaseType = timeline.getWhitelistPhase();
    const { startTime: swapStartTime, endTime: swapEndTime } =
      timeline.getSwapTime();
    const TIME_FORMAT = 'HH:mm - YYYY/MM/DD';
    return (
      <Accordion
        className={styles['my-pool-item']}
        expanded={isExpanded}
        onChange={handleExpandAccordion}
      >
        <AccordionSummary
          expandIcon={<ArrowCircleDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={`${styles['content-pool']} row`}>
            <div className={`${styles['pool-name']} col-md-3`}>
              <Link to={getPoolDetailLink(pool)} className="link-detail">
                <img src={inoPool.getSwapTokenLogoUrl()} alt="" />
                <Tooltip title={inoPool.getName()} placement="top-start">
                  <div className={styles['title']}>{inoPool.getName()}</div>
                </Tooltip>
              </Link>
            </div>
            <div className={`${styles['pool-step']} col-md-5`}>
              <div
                className={`${styles['step']} ${
                  styles[getIDOPhaseClassName(timeline, PHASES.WHITE_LIST)]
                }`}
              ></div>
              <div
                className={`${styles['step']} ${
                  styles[getIDOPhaseClassName(timeline, PHASES.TOKEN_SALE)]
                }`}
              ></div>
            </div>
            <div className={`${styles['value-invest']} col-md-4`}>
              {formatNumber(
                convertWeiToDec(
                  userInvest,
                  inoPool.getCollateralCurrencyDecimals(),
                ),
              )}{' '}
              {Number(userInvest) > 0 && inoPool.getCollateralCurrencySymbol()}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="pool--list-content">
          <div className={`${styles['content-pool']} row`}>
            <div className="col-md-3" />
            <div className={`${styles['time-step']} col-md-5`}>
              <div className={styles['content-step']}>
                {timeline.beforeWhitelistPhase() ? (
                  <>
                    Start in
                    <div>
                      {formatTimestamp(whitelistPhase.startTime, TIME_FORMAT)}
                    </div>
                  </>
                ) : (
                  <>
                    End in
                    <div>
                      {formatTimestamp(whitelistPhase.endTime, TIME_FORMAT)}
                    </div>
                  </>
                )}
              </div>
              <div className={styles['content-step']}>
                {timeline.isSwapPhase() || timeline.afterSwapPhase() ? (
                  <>
                    End in
                    <div>{formatTimestamp(swapEndTime, TIME_FORMAT)}</div>
                  </>
                ) : (
                  <>
                    Start in
                    <div>{formatTimestamp(swapStartTime, TIME_FORMAT)}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div
      className={`${isMobile ? styles['card-mypool'] : styles['pool-item']} ${
        styles['ino']
      }`}
    >
      {isMobile ? _renderForMobile() : _renderForDesktop()}
    </div>
  );
};

export default MyINOPool;
