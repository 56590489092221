import { FC } from 'react';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/modals/ModalApplyWhitelist.module.scss';
import useAuth from 'src/hooks/useAuth';
import { imgApplyWhitelist } from 'src/assets/images';
import {
  DiscordIcon,
  FacebookIcon,
  MediumIcon,
  ReferenceIcon,
  TelegramIcon,
  TwitterIcon,
  WebIcon,
} from 'src/assets/icons';
import BaseModal from './BaseModal';

interface ModalApplyWhitelistProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  poolId: string;
  socialList?: any;
}

const ModalApplyWhitelist: FC<ModalApplyWhitelistProps> = ({
  open,
  onClose,
  onSuccess,
  poolId,
  socialList,
}) => {
  const { user } = useAuth();

  const applyWhitelist = async () => {
    if (!user) {
      return;
    }
    await rf.getRequest('PoolRequest').applyWhitelist(poolId);
    onSuccess && onSuccess();
    onClose();
  };

  const socialItems = socialList
    ? Object.keys(socialList).map((key) => ({
        name: key.charAt(0).toUpperCase() + key.slice(1).replace('Url', ''),
        url: socialList[key],
      }))
    : [];

  return (
    <BaseModal
      open={open}
      title="Apply Whitelist"
      onClose={onClose}
      onActionLeft={onClose}
      onActionRight={applyWhitelist}
      fullScreenMobile
    >
      <div className={styles['thumb-detail']}>
        <img
          className={styles['img-apply-whitelist']}
          src={imgApplyWhitelist}
        />
      </div>
      <ul className={styles['reference-list']}>
        {socialItems?.map((item, index) => (
          <li className={styles['modal-list-item']} key={index}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles['item-link']}
            >
              <span className={styles['item-icon']}>
                {item.name.includes('Discord') && <DiscordIcon />}
                {item.name.includes('Facebook') && <FacebookIcon />}
                {item.name.includes('Medium') && <MediumIcon />}
                {item.name.includes('Telegram') && <TelegramIcon />}
                {item.name.includes('Twitter') && <TwitterIcon />}
                {item.name.includes('Website') && <WebIcon />}
              </span>

              <span className={styles['item-name']}>{item.name}</span>
              <span className={styles['item-ref']}>
                <ReferenceIcon />
              </span>
            </a>
          </li>
        ))}
      </ul>
    </BaseModal>
  );
};

export default ModalApplyWhitelist;
