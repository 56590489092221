import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class TransactionSolanaRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.swapSolanaApi;
  }

  getBuyTransaction(data: {
    poolId: string;
    userAddress: string;
    inAmount: string;
  }) {
    const url = '/transactions/buy';
    return this.post(url, data);
  }

  getClaimTransaction(data: { poolId: string; userAddress: string }) {
    const url = '/transactions/claim';
    return this.post(url, data);
  }
}
