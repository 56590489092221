import { FC } from 'react';
import BaseModal, { BaseModalProps } from './BaseModal';
import styles from 'src/styles/modals/ModalComingSoon.module.scss';
import AppButton from 'src/components/AppButton';

const ModalComingSoon: FC<BaseModalProps> = ({ ...props }) => {
  return (
    <BaseModal {...props} title={''}>
      <div className={styles['modal-coming-soon']}>
        <span className={styles['content']}>Coming Soon...</span>
      </div>
    </BaseModal>
  );
};

export default ModalComingSoon;
