import { FC, useEffect, useState } from 'react';
import PartAuctionPriceChart from 'src/pages/PageAuctionCreate/parts/PartAuctionPriceChart';
import styles from 'src/styles/pages/LBPDetails/LBPChart.module.scss';
import {
  AuctionPriceChartData,
  calcAuctionChartData,
  calcSpotPrice,
  getCurrentAuctionTime,
  getNormalizedWeight,
  IAuctionResponseType,
  IAuctionSnapshotResponseType,
  ITokenBase,
  ITokenMain,
} from 'src/utils/utils-auction';
import rf from 'src/requests/RequestFactory';
import moment from 'moment';
import { convertWeiToDec } from 'src/utils/utils-formats';
import BigNumber from 'bignumber.js';

interface PartLBPChartProps {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  balanceStart: string[];
  balanceCurrent: string[];
  tokenWeights: string[];
  swapFee: string;
}

const PartLBPChart: FC<PartLBPChartProps> = ({
  auction,
  mainToken,
  baseToken,
  mainTokenIndex,
  baseTokenIndex,
  balanceStart,
  balanceCurrent,
  tokenWeights,
  swapFee,
}) => {
  const [chartData, setChartData] = useState<AuctionPriceChartData[]>([]);

  const getCurrentAuctionData = (): AuctionPriceChartData => {
    const currentTime = getCurrentAuctionTime().valueOf();
    const currentPrice =
      calcSpotPrice(
        +balanceCurrent[baseTokenIndex],
        +convertWeiToDec(tokenWeights[baseTokenIndex]?.toString()),
        +balanceCurrent[mainTokenIndex],
        +convertWeiToDec(tokenWeights[mainTokenIndex]?.toString()),
        swapFee,
      ) || '0';

    return {
      time: currentTime,
      value: currentPrice,
    };
  };

  const generateAuctionChartData = (
    historyData: IAuctionSnapshotResponseType[] | undefined,
  ): AuctionPriceChartData[] => {
    if (!historyData || !historyData.length) {
      console.log(
        'generateAuctionChartData generateAuctionChartDataWithoutSwap',
      );
      return generateAuctionChartDataWithoutSwap();
    }
    console.log(
      'generateAuctionChartData generateAuctionChartDataWithSwap',
      historyData,
    );
    return generateAuctionChartDataWithSwap(historyData);
  };

  const generateAuctionChartDataWithoutSwap = (): AuctionPriceChartData[] => {
    const currentAuctionData = getCurrentAuctionData();
    const predictedPriceWithoutSwapData = calcAuctionChartData(
      +balanceStart[baseTokenIndex],
      +balanceStart[mainTokenIndex],
      +convertWeiToDec(auction.pool.startWeights[mainTokenIndex].toString()),
      +convertWeiToDec(auction.pool.endWeights[mainTokenIndex].toString()),
      new Date(auction.pool.startTime * 1000),
      new Date(auction.pool.endTime * 1000),
      Number(auction.pool.swapFee),
    );

    const result: AuctionPriceChartData[] = [...predictedPriceWithoutSwapData];
    if (currentAuctionData.time < predictedPriceWithoutSwapData[0].time) {
      // if currentTime is before startTime => replace currentPrice to the first value of chart => synchronize to price in Overview
      result[0] = currentAuctionData;
      return result;
    }
    result.push(currentAuctionData);
    result.sort(
      (a: AuctionPriceChartData, b: AuctionPriceChartData) => +a.time - +b.time,
    );
    return result;
  };

  const generateAuctionChartDataBeforeSwap = (
    historyData: AuctionPriceChartData[],
  ): AuctionPriceChartData[] => {
    const firstHistoryData = historyData[0];
    const firstHistoryWeight = getNormalizedWeight(
      +firstHistoryData.time / 1000,
      {
        startTime: auction.pool.createTime,
        endTime: auction.pool.endTime,
        startWeight: new BigNumber(
          auction.pool.startWeights[mainTokenIndex],
        ).toString(),
        endWeight: new BigNumber(
          auction.pool.endWeights[mainTokenIndex],
        ).toString(),
      },
    );
    const beforeSwapPriceData = calcAuctionChartData(
      +balanceStart[baseTokenIndex],
      +balanceStart[mainTokenIndex],
      +convertWeiToDec(auction.pool.startWeights[mainTokenIndex].toString()),
      +convertWeiToDec(firstHistoryWeight.toString()),
      new Date(
        moment(auction.pool.startTime * 1000)
          .startOf('hour')
          .valueOf(),
      ),
      new Date(moment(firstHistoryData.time).startOf('hour').valueOf()),
      +swapFee,
    );
    beforeSwapPriceData.pop(); // remove last data, instead first data of history

    return beforeSwapPriceData;
  };

  const generateAuctionChartDataPredicted = (
    historyData: AuctionPriceChartData[],
  ): AuctionPriceChartData[] => {
    const currentAuctionData = getCurrentAuctionData();

    const lastHistoryData = historyData[historyData.length - 1];
    const lastHistoryWeight = getNormalizedWeight(
      Number(lastHistoryData.time) / 1000,
      {
        startTime: auction.pool.createTime,
        endTime: auction.pool.endTime,
        startWeight: new BigNumber(
          auction.pool.startWeights[mainTokenIndex],
        ).toString(),
        endWeight: new BigNumber(
          auction.pool.endWeights[mainTokenIndex],
        ).toString(),
      },
    );
    const predictedPriceData = calcAuctionChartData(
      +balanceCurrent[baseTokenIndex],
      +balanceCurrent[mainTokenIndex],
      +convertWeiToDec(lastHistoryWeight.toString()),
      +convertWeiToDec(auction.pool.endWeights[mainTokenIndex].toString()),
      new Date(moment(lastHistoryData.time).startOf('hour').valueOf()),
      new Date(auction.pool.endTime * 1000),
      +swapFee,
    );
    predictedPriceData.shift(); // remove first data, instead last data of history

    predictedPriceData.push(currentAuctionData);
    predictedPriceData.sort(
      (a: AuctionPriceChartData, b: AuctionPriceChartData) => +a.time - +b.time,
    );

    return predictedPriceData;
  };

  const generateAuctionChartDataWithSwap = (
    historyData: IAuctionSnapshotResponseType[],
  ): AuctionPriceChartData[] => {
    const historyAuctionData: AuctionPriceChartData[] = historyData.map(
      (item: IAuctionSnapshotResponseType) => ({
        time: item.timestamp * 1000,
        value: +item.avgPrice,
      }),
    );

    const beforeSwapPriceData =
      generateAuctionChartDataBeforeSwap(historyAuctionData);
    const predictedPriceData =
      generateAuctionChartDataPredicted(historyAuctionData);

    console.log('beforeSwapPriceData', beforeSwapPriceData);
    console.log('predictedPriceData', predictedPriceData);

    return [
      ...beforeSwapPriceData,
      ...historyAuctionData,
      ...predictedPriceData,
    ];
  };

  const fetchData = async () => {
    if (
      !auction ||
      balanceCurrent.length <= 0 ||
      tokenWeights.length <= 0 ||
      !swapFee
    ) {
      return;
    }

    const historySnapshots = await rf
      .getRequest('AuctionsRequest')
      .getPriceSnapshotOfPool(auction.pool.id, mainToken.address);

    const chartData = generateAuctionChartData(historySnapshots.docs);
    setChartData(chartData);
  };

  useEffect(() => {
    fetchData();
  }, [auction, balanceCurrent, tokenWeights, swapFee]);

  return (
    <div className={styles['lbp-detail-chart']}>
      <PartAuctionPriceChart
        id="historySwap"
        customChartClassname={styles['history-swap-chart']}
        priceData={chartData}
        symbol={{
          main: mainToken.symbol,
          base: baseToken.symbol,
        }}
      />
    </div>
  );
};

export default PartLBPChart;
