import prod from './prod.json';
import dev from './dev.json';

const env = process.env.REACT_APP_ENV || 'prod';
const configs: any = { prod, dev };

export interface Connector {
  id: string;
  name: string;
  icon: string;
  disableIcon: string;
  description: string;
  href: string;
  mobile: boolean;
  deepLink: string;
  options: any;
}

export interface BlockExplorer {
  name: string;
  icon: string;
  url: string;
}

export interface Network {
  id: string;
  support: Array<string>;
  coingeckoId: string;
  name: string;
  networkFamily?: string;
  chainId: number;
  rpcUrls: string[];
  blockExplorer: BlockExplorer;
  addresses?: any;
  connectors: Connector[];
  icon?: string;
  disableIcon?: string;
  currency?: string;
  nativeCurrency?: any;
  currencies?: any;
}

export interface Project {
  contractAddress: string;
  tokenAddressBuy: string;
}
export interface Config {
  auth: {
    domain: string;
    message: string;
    secretKey: string;
  };
  api: {
    poolApi: string;
    userApi: string;
    swapApi: string;
    swapSolanaApi: string;
    auctionApi: string;
  };
  apiCoingecko: string;
  kycUrl: string;
  subgraphUrl: string;
  apiGamer: string;
  metamaskDeepLink: string;
  buyMoreBuniUrl: string;
  defaultNetwork: string;
  supportStakingNetwork: string;
  networks: { [key: string]: Network };
  projects: {
    [key: string]: Project;
  };
  addresses: {
    buni: string;
  };
  socialLinks: {
    medium: string;
    telegram: string;
    twitter: string;
    discord: string;
    globe: string;
    announcements: string;
  };
  minBaseTokenInAuction: number;
}

const config: Config = configs[env];

export default config;
