import { KYC_STATUS } from 'src/constants';
import { formatTierAsString } from './utils-formats';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ILinkedAccount, ILinkedProvider, LinkedProviderType } from './common';

export interface UserInterface {
  address: string;
  tier: string;
  kycStatus: string;
  balance: string;
  provider: null | JsonRpcProvider;
  linkedProviders: ILinkedProvider[];
  linkedAccounts: ILinkedAccount[];

  setTier: (tier: string) => void;
  setKYCStatus: (kycStatus: string) => void;
  setBalance: (balance: string) => void;
  setNetwork: (network: string) => void;
  setProvider: (provider: JsonRpcProvider) => void;
  setLinkedProviders: (providers: ILinkedProvider[]) => void;
  setLinkedAccounts: (linkedAccounts: ILinkedAccount[]) => void;
  getAddress: () => string;
  getTier: () => string;
  getKYCStatus: () => string;
  getBalance: () => string;
  getTierAsString: () => string;
  getProvider: () => JsonRpcProvider | null;
  getLinkedProviders: () => ILinkedProvider[];
  getNetwork: () => string;
  getLinkedAccounts: () => ILinkedAccount[];
  getLinkedAccountByNetworkFamily: (
    networkFamily: string,
  ) => ILinkedAccount | undefined;
  getLinkedProviderByNetworkFamily: (
    networkFamily: string,
  ) => LinkedProviderType | null;
  isKyced: () => boolean;
}

export class AuthUser implements UserInterface {
  public address = '';
  public tier = '';
  public kycStatus = '';
  public balance = '';
  public network = '';
  public provider: JsonRpcProvider | null = null;
  public linkedProviders: ILinkedProvider[] = [];
  public linkedAccounts: ILinkedAccount[] = [];

  constructor(address: string) {
    this.address = address;
  }

  setTier(tier: string): void {
    this.tier = tier;
  }

  setKYCStatus(kycStatus: string): void {
    this.kycStatus = kycStatus;
  }

  setBalance(balance: string): void {
    this.balance = balance;
  }

  setNetwork(network: string): void {
    this.network = network;
  }

  setProvider(provider: JsonRpcProvider) {
    this.provider = provider;
  }

  setLinkedProviders(providers: ILinkedProvider[] = []) {
    this.linkedProviders = providers;
  }

  setLinkedAccounts(linkedAccounts: ILinkedAccount[] = []): void {
    this.linkedAccounts = linkedAccounts;
  }

  getAddress(): string {
    return this.address;
  }

  getTier(): string {
    return this.tier;
  }

  getKYCStatus(): string {
    return this.kycStatus;
  }

  getBalance(): string {
    return this.balance;
  }

  // apply this to all
  getTierAsString(): string {
    return formatTierAsString(this.getTier());
  }

  getProvider(): JsonRpcProvider | null {
    return this.provider;
  }

  getLinkedProviders(): ILinkedProvider[] {
    return this.linkedProviders;
  }

  getNetwork(): string {
    return this.network;
  }

  getLinkedAccounts(): ILinkedAccount[] {
    return this.linkedAccounts;
  }

  getLinkedAccountByNetworkFamily(
    networkFamily: string,
  ): ILinkedAccount | undefined {
    return this.linkedAccounts.find(
      (account: ILinkedAccount) =>
        account.networkFamily === networkFamily &&
        account.userAddress === this.address,
    );
  }

  getLinkedProviderByNetworkFamily(
    networkFamily: string,
  ): LinkedProviderType | null {
    const linkedProvider = this.linkedProviders.find(
      (item: ILinkedProvider) =>
        item.networkFamily === networkFamily &&
        item.userAddress === this.getAddress(),
    );

    return linkedProvider ? linkedProvider.provider : null;
  }

  isKyced(): boolean {
    return this.kycStatus === KYC_STATUS.APPROVED;
  }
}
