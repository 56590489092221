import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
  FC,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { defaultAbiCoder, Interface } from '@ethersproject/abi';
import BigNumber from 'bignumber.js';
import { AuctionType, RefStep, TIME_FORMAT } from 'src/utils/utils-auction';
import {
  convertDecToWei,
  formatNumber,
  formatShortAddress,
  formatTimestamp,
  formatToPercent,
} from 'src/utils/utils-formats';
import abi from 'src/abi';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import config from 'src/config';
import rf from 'src/requests/RequestFactory';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/pages/LPB/steps/StepCreateLBP.module.scss';
import {
  getAllowance,
  areTokensApproved,
  makeApproveParams,
} from 'src/utils/utils-token';
import { copyToClipboard } from 'src/utils/utils-helpers';
import { isMobile } from 'react-device-detect';
import ModalStuck from 'src/modals/ModalStuck';
import {
  CopyIcon,
  CreatedTransactionIcon,
  NoTransactionIcon,
  CheckedCircleIcon,
} from 'src/assets/icons';
import moment from 'moment';
import { toastError } from 'src/utils/utils-notify';
import { BeatLoader } from 'react-spinners';
import Storage from 'src/utils/storage';

interface CreateLbpProps {
  auction: AuctionType;
  onClickBack: () => void;
  onSuccess: () => void;
}

interface IButtonApprove {
  token: any;
  network: string;
  fetchAllowance: () => void;
}

interface DataCompare {
  address: string;
  amount: string;
  startWeight: string;
  endWeight: string;
}

export const ButtonApprove: FC<IButtonApprove> = ({
  token,
  network,
  fetchAllowance,
}) => {
  const [isTokenApproved, setIsTokenApproved] = useState<boolean>(false);
  const [isTokenApproving, setIsTokenApproving] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const onClickApproveToken = async () => {
    if (!token?.address) {
      return;
    }
    const params = makeApproveParams(
      token?.address,
      config.networks[network].addresses.auctionProxy,
    );
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    checkTokenAllowance().then();
    fetchAllowance();
  };

  const checkTokenAllowance = async () => {
    if (!network || !token.address || !user) return false;
    try {
      setIsTokenApproving(true);
      const tokenAllowance = await getAllowance(
        network,
        token.address,
        user?.getAddress(),
        config.networks[network].addresses.auctionProxy,
      );
      setIsTokenApproving(false);
      return setIsTokenApproved(new BigNumber(tokenAllowance).gt(0));
    } catch (error) {
      setIsTokenApproving(false);
      return false;
    }
  };

  useEffect(() => {
    checkTokenAllowance();
  }, [token, user?.getAddress()]);

  return (
    <>
      <AppButton
        variant="primary"
        className={styles['btn']}
        onClick={onClickApproveToken}
        isDisable={isTokenApproved || network !== user?.getNetwork()}
      >
        {isTokenApproving ? (
          <div style={{ paddingTop: '5px' }}>
            <BeatLoader color="white" size={8} />
          </div>
        ) : (
          `Approve ${token.symbol.toUpperCase()}`
        )}
      </AppButton>
    </>
  );
};

const StepCreateLbp = forwardRef((props: CreateLbpProps, ref: Ref<RefStep>) => {
  const { auction, onClickBack, onSuccess } = props;
  const [isLbpCreated, setIsLbpCreated] = useState<boolean>(false);
  const [isLbpCreating, setIsLbpCreating] = useState<boolean>(false);
  const [createdAuctionId, setCreatedAuctionId] = useState('');
  const [LBPNetworkAddress, setLBPNetworkAddress] = useState('');
  const [areAllTokensApproved, setAreAllTokensApproved] =
    useState<boolean>(false);
  const [isOpenModalStuck, setIsOpenModalStuck] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();

  const {
    network,
    token,
    collateralToken,
    depositToken,
    weights,
    swapFee,
    duration,
    media,
    description,
  } = auction;

  const dispatch = useDispatch();
  const { user } = useAuth();

  const isNetworkCorrect = network === user?.getNetwork();

  const validate = () => {
    return;
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  const _renderButtonTitle = () => {
    if (isLbpCreated) {
      return 'View Auction';
    }

    if (isLbpCreating) {
      return (
        <div style={{ paddingTop: '5px' }}>
          <BeatLoader color="white" size={8} />
        </div>
      );
    }
    return `Schedule ${token.symbol.toUpperCase()} Auction`;
  };

  const createTransaction = async () => {
    const nowDate = moment(moment.utc().format('yyyy-MM-DD HH:mm:ss')).toDate();

    if (nowDate > duration.endDate) {
      toastError({ message: 'The start time must be after the current time.' });
      return;
    }

    if (nowDate > duration.endDate) {
      toastError({ message: 'The end time must be after the current time.' });
      return;
    }

    const data = [
      {
        address: token.address,
        amount: convertDecToWei(depositToken.launch.toString(), token.decimals),
        startWeight: convertDecToWei(weights.startWeight.toString()),
        endWeight: convertDecToWei(weights.endWeight.toString()),
      },
      {
        address: collateralToken.address,
        amount: convertDecToWei(
          depositToken.collateral.toString(),
          collateralToken.decimals,
        ),
        startWeight: convertDecToWei(
          new BigNumber(1).minus(weights.startWeight).toString(),
        ),
        endWeight: convertDecToWei(
          new BigNumber(1).minus(weights.endWeight).toString(),
        ),
      },
    ];
    data.sort((a: DataCompare, b: DataCompare) => {
      if (a.address.toLowerCase() < b.address.toLowerCase()) {
        return -1;
      }
      return 1;
    });
    const [token1, token2] = data;
    const amounts = [token1.amount, token2.amount];
    const userData = defaultAbiCoder.encode(
      ['uint256', 'uint256[]'],
      [0, amounts],
    );
    const isCorrectOrder =
      token1.address.toLowerCase() === collateralToken.address.toLowerCase();
    const paramsSend = [
      `${token.symbol} Token Launch Auction`,
      `${token.symbol}_TLA`,
      [token1.address, token2.address],
      [token1.amount, token2.amount],
      [token1.startWeight, token2.startWeight],
      [token1.endWeight, token2.endWeight],
      isCorrectOrder,
      Math.floor(+convertDecToWei(swapFee.toString())).toString(),
      userData,
      Math.floor(
        moment
          .utc(moment(duration.startDate).format('yyyy-MM-DD HH:mm:ss'))
          .valueOf() / 1000,
      ),
      Math.floor(
        moment
          .utc(moment(duration.endDate).format('yyyy-MM-DD HH:mm:ss'))
          .valueOf() / 1000,
      ),
    ];

    const params = [
      abi['LBPProxy'],
      config.networks[network].addresses.auctionProxy,
      'createAuction',
      [paramsSend],
      {},
    ];
    const transaction: any = await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    const provider = user?.getProvider();
    if (provider && transaction) {
      const transactionHash = transaction.payload.hash;
      const receipt = await provider.getTransactionReceipt(transactionHash);
      const itf = new Interface(abi['LBPProxy']);
      receipt.logs.forEach((log) => {
        try {
          const decodeLog = itf.parseLog(log);
          if (decodeLog && decodeLog.name === 'PoolCreated') {
            setLBPNetworkAddress(decodeLog.args[0]);
          }
        } catch (e) {}
      });
      return transaction;
    }
    return transaction;
  };

  const createAuction = async (txHash: string) => {
    const socialLinks = _.pickBy(
      {
        website: media.website,
        telegram: media.telegram,
        twitter: media.twitter,
        discord: media.discord,
        medium: media.medium,
      },
      (item) => !!item,
    );
    const createAuctionParams = {
      network,
      creationTx: txHash,
      logoUrl: token.logo,
      description,
      draftInfo: {
        tokens: [
          {
            address: token.address,
            name: token.name,
            symbol: token.symbol,
            decimals: token.decimals,
          },
          {
            address: collateralToken.address,
            name: collateralToken.name,
            symbol: collateralToken.symbol,
            decimals: collateralToken.decimals,
          },
        ],
        amounts: [
          convertDecToWei(depositToken.launch.toString(), token.decimals),
          convertDecToWei(
            depositToken.collateral.toString(),
            collateralToken.decimals,
          ),
        ],
        startTime: Math.floor(
          moment
            .utc(moment(duration.startDate).format('yyyy-MM-DD HH:mm:ss'))
            .valueOf() / 1000,
        ),
        endTime: Math.floor(
          moment
            .utc(moment(duration.endDate).format('yyyy-MM-DD HH:mm:ss'))
            .valueOf() / 1000,
        ),
        startWeights: [
          convertDecToWei(weights.startWeight.toString()),
          convertDecToWei(
            new BigNumber(1).minus(weights.startWeight).toString(),
          ),
        ],
        endWeights: [
          convertDecToWei(weights.endWeight.toString()),
          convertDecToWei(new BigNumber(1).minus(weights.endWeight).toString()),
        ],
        swapFee: swapFee,
      },
      socialLinks,
    };
    try {
      const res = await rf
        .getRequest('AuctionsRequest')
        .createAuction(_.pickBy(createAuctionParams, (item) => !!item));
      setCreatedAuctionId(res.id);
      setIsLbpCreating(false);
      setIsLbpCreated(true);
      onSuccess();
      Storage.clearAuction();
      setIsSuccess(true);
      setTxHash('');
    } catch (error: any) {
      setIsLbpCreating(false);
      setIsError(true);
      toastError(error);
    }
  };

  const onClickCreateLbp = async () => {
    setIsLbpCreating(true);
    const transaction = await createTransaction();
    const txHash = transaction?.payload?.hash;
    if (!txHash) {
      setIsLbpCreating(false);
      return;
    } else {
      await createAuction(txHash);
    }
  };

  const onHandleBack = async () => {
    onClickBack();
  };

  const onClickViewLbp = () => {
    history.push(`/auction/${createdAuctionId}`);
  };

  const onHandleCreateLbp = () => {
    if (isLbpCreated) {
      onClickViewLbp();
    } else {
      onClickCreateLbp();
    }
  };

  const checkAllTokenApproved = async () => {
    const isAllTokenApproved = await areTokensApproved(
      auction.network,
      auction.token.address,
      auction.collateralToken.address,
      user?.getAddress(),
    );
    setAreAllTokensApproved(isAllTokenApproved);
  };

  const handleCloseModalStuck = () => {
    setIsOpenModalStuck(false);
    setIsSuccess(false);
    setIsError(false);
    setTxHash('');
  };

  useEffect(() => {
    checkAllTokenApproved();
  }, [auction, user]);

  const isDisabledBackStep = isLbpCreated || isLbpCreating;

  const _renderButtonsApprove = () => {
    return (
      <div className={styles['row']}>
        <div className={styles['group-button-left']}>
          <ButtonApprove
            token={collateralToken}
            network={network}
            fetchAllowance={checkAllTokenApproved}
          />
        </div>
        <div className={styles['group-button-right']}>
          <ButtonApprove
            token={token}
            network={network}
            fetchAllowance={checkAllTokenApproved}
          />
        </div>
      </div>
    );
  };

  const _renderButtonsCreateLBP = () => {
    return (
      <div className={styles['row']}>
        {!isLbpCreated && (
          <div className={styles['group-button-left']}>
            <AppButton
              variant="secondary"
              onClick={() => setIsOpenModalStuck(true)}
              isDisable={!areAllTokensApproved || !isNetworkCorrect}
              className={styles['btn']}
            >
              Stuck?
            </AppButton>
          </div>
        )}
        <div className={styles['group-button-right']}>
          <AppButton
            variant="primary"
            onClick={onHandleCreateLbp}
            isDisable={
              isLbpCreating || !areAllTokensApproved || !isNetworkCorrect
            }
            className={styles['btn']}
          >
            {_renderButtonTitle()}
          </AppButton>
        </div>
      </div>
    );
  };

  const _renderAuctionInfo = () => {
    return (
      <div className={styles['wrap-item']}>
        <div className={styles['card']}>
          <div className={styles['card-body']}>
            {isMobile && (
              <div
                className={`${styles['status-approve']} ${
                  areAllTokensApproved ? styles['done'] : styles['process']
                }`}
              >
                Approve interactions with main and base tokens{' '}
                {areAllTokensApproved && <CheckedCircleIcon />}
              </div>
            )}
            <div className={`${styles['title-body']}`}>Schedule Auction</div>
          </div>

          <div className={styles['section-content']}>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>
                    Auction network address:{' '}
                  </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  {LBPNetworkAddress ? (
                    <span className={styles['label']}>
                      {formatShortAddress(LBPNetworkAddress)}
                      <span
                        className={styles['icon']}
                        onClick={() => copyToClipboard(LBPNetworkAddress)}
                      >
                        &nbsp;
                        <CopyIcon />
                      </span>
                    </span>
                  ) : (
                    <span className={styles['label']}>Not yet created</span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Main token amount: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatNumber(depositToken.launch)}{' '}
                    {token.symbol.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Base token amount: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatNumber(depositToken.collateral)}{' '}
                    {collateralToken.symbol.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Swap fee:</span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatToPercent(swapFee)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Platform access fee: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>2%</span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Start time: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatTimestamp(duration.startDate.getTime(), TIME_FORMAT)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>End time: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatTimestamp(duration.endDate.getTime(), TIME_FORMAT)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className={styles['group-btn']}>{_renderButtonsApprove()}</div>
          )}
        </div>
        <div className={styles['card']}>
          <div className={styles['card-body']}>
            <div className={styles['foot']}>
              {LBPNetworkAddress ? (
                <CreatedTransactionIcon />
              ) : (
                <NoTransactionIcon />
              )}
              <span className={styles['caption-img']}>
                {LBPNetworkAddress ? (
                  <>
                    <span>{collateralToken.symbol}</span> -{' '}
                    <span>{token.symbol}</span> Auction Created
                  </>
                ) : (
                  'No transactions in progress'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className={styles['card-body']}>
          <div
            className={`${styles['status-approve']} ${
              areAllTokensApproved ? styles['done'] : styles['process']
            }`}
          >
            Approve interactions with main and base tokens{' '}
            {areAllTokensApproved && <CheckedCircleIcon />}
          </div>
        </div>
      )}
      {_renderAuctionInfo()}
      {!areAllTokensApproved && !isMobile && (
        <div className={styles['message-warning']}>
          You must approve interactions with main and base tokens to complete
          the last step of Auction creation.
        </div>
      )}

      <div className={`${styles['wrap-footer']}`}>
        <div className={styles['footer-fixed']}>
          <div className={styles['row']}>
            {!isMobile && (
              <div className={styles['group-button-left']}>
                <AppButton
                  variant="outline"
                  onClick={onHandleBack}
                  isDisable={isDisabledBackStep}
                  className={styles['btn']}
                >
                  Back
                </AppButton>
              </div>
            )}

            <div className={styles['group-button-right']}>
              <div className={styles['group-footer-full']}>
                {!areAllTokensApproved && isMobile
                  ? _renderButtonsApprove()
                  : _renderButtonsCreateLBP()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalStuck
        open={isOpenModalStuck}
        onClose={handleCloseModalStuck}
        onSubmit={(txHash: string) => createAuction(txHash)}
        isSuccess={isSuccess}
        txHash={txHash}
        setTxHash={setTxHash}
        isError={isError}
      />
    </>
  );
});

export default StepCreateLbp;
