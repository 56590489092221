import React, { FC, useEffect, useMemo, useState, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { createValidator } from 'src/utils/utils-validator';
import { INOPool, PackageType } from 'src/utils/pool';
import {
  convertWeiToDec,
  formatInoBoxes,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import styles from 'src/styles/modals/ModalBuyINO.module.scss';
import { InputAdornment, InputLabel } from '@material-ui/core';
import AppInputCurrency from 'src/components/AppInputCurrency';
import BaseModal, { BaseModalProps } from './BaseModal';
import clsx from 'clsx';
import { usePhaseInfo, WinnerType } from '../hooks/usePhaseInfo';
import useINOPool from '../hooks/useINOPool';
import useAuth from 'src/hooks/useAuth';
import { UserInterface } from 'src/utils/user';
import {
  getBoxesUserPurchased,
  getCollateralBalance,
} from 'src/utils/utils-pool';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { RELOAD_SOLD_BOXES_AFTER_BUYING } from 'src/pages/PageINOPoolDetail/part/INOOverview';
import { RELOAD_PURCHASED_BOXES_AFTER_BUYING } from 'src/pages/PageINOPoolDetail/part/PurchaseInfo';
import { toastError } from '../utils/utils-notify';
import AppInput from 'src/components/AppInput';
import { RELOAD_INO_DETAILS_AFTER_BUYING } from 'src/pages/PageINOPoolDetail';
import rf from '../requests/RequestFactory';

interface ModalBuyINOProps extends BaseModalProps {
  packageItem?: PackageType;
  inoPool: INOPool;
  type?: 'public' | 'private';
  winner: WinnerType | null;
  onSuccess?: () => void;
  remainingBoxes?: number;
}

const ModalBuyINO = (props: Omit<ModalBuyINOProps, 'children'>) => {
  const {
    packageItem,
    title = 'Buying Mystery Boxes',
    inoPool,
    onClose,
    onSuccess,
    remainingBoxes = 0,
    ...rest
  } = props;
  const price = convertWeiToDec(
    packageItem?.price || '0',
    inoPool.getCollateralCurrencyDecimals(),
  );
  const [quantity, setQuantity] = useState<string>('');
  const [collateralBalance, setCollateralBalance] = useState<string>('0');
  const [boxesUserPurchased, setBoxesUserPurchased] = useState<string>('0');

  const { userAllocation } = usePhaseInfo(inoPool);
  const { onBuyNFT } = useINOPool(inoPool);
  const { user } = useAuth();

  const validator = useRef(
    createValidator({
      element: (message: string) => (
        <div className={styles['error']}>{message}</div>
      ),
    }),
  );

  const [deposit, setDeposit] = useState<string>('0');

  const getMaxCanBuy = async () => {
    return rf.getRequest('PoolRequest').getUserDepositAmount(inoPool.getId());
  };

  useEffect(() => {
    getMaxCanBuy()
      .then((res) => setDeposit(res.maxBuyAmount))
      .catch((error) => console.log(error));
  }, [user]);

  const maximumBoxesUserCanBuy = useMemo(() => {
    if (!userAllocation) {
      return 0;
    }
    if (!boxesUserPurchased) {
      return userAllocation;
    }
    return Number(deposit) - Number(boxesUserPurchased);
  }, [userAllocation, boxesUserPurchased]);

  const totalPrice = useMemo(
    () => new BigNumber(quantity).multipliedBy(new BigNumber(price)),
    [quantity],
  );

  const isOverBalance = useMemo(
    () =>
      new BigNumber(totalPrice).isGreaterThan(
        new BigNumber(
          convertWeiToDec(
            collateralBalance,
            inoPool.getCollateralCurrencyDecimals(),
          ),
        ),
      ),
    [quantity],
  );
  const isOverBox = useMemo(
    () => maximumBoxesUserCanBuy < +quantity,
    [quantity],
  );

  const isDisabledBuy = useMemo(
    () =>
      +quantity <= 0 ||
      isOverBalance ||
      isOverBox ||
      remainingBoxes < +quantity,
    [quantity],
  );

  const fetchCollateralBalance = async (
    pool: INOPool,
    account: UserInterface | null,
  ) => {
    if (!pool || !account) {
      return;
    }
    const balance = await getCollateralBalance(pool, account);
    setCollateralBalance(balance);
  };

  const getBoxesPurchased = async () => {
    if (!user) return;
    const boxes = await getBoxesUserPurchased(
      user?.getAddress(),
      inoPool.getContractAddress(),
      inoPool.getNetwork(),
    );
    setBoxesUserPurchased(boxes);
  };

  useEffect(() => {
    fetchCollateralBalance(inoPool, user);
  }, []);

  useEffect(() => {
    getBoxesPurchased().catch((error) => {
      toastError(error.message);
    });
  }, []);

  const onBuy = async () => {
    if (!packageItem?.packageId) {
      return;
    }
    await onBuyNFT(totalPrice.toString(), packageItem?.packageId, onSuccess);
    AppBroadcast.dispatch(RELOAD_SOLD_BOXES_AFTER_BUYING);
    AppBroadcast.dispatch(RELOAD_PURCHASED_BOXES_AFTER_BUYING);
    AppBroadcast.dispatch(RELOAD_INO_DETAILS_AFTER_BUYING);
    onClose && onClose();
  };

  const onChangeBoxesValue = (value: string) => {
    if (+value < 0) {
      return;
    }
    const newValue = value.replace(/[^\d]/, '');
    setQuantity(newValue);
  };

  return (
    <BaseModal
      {...rest}
      title={title}
      onClose={onClose}
      isDisabledActionRight={isDisabledBuy}
      onActionRight={onBuy}
      onActionLeft={onClose}
      keepMounted={false}
      fullScreenMobile
    >
      <>
        {packageItem ? (
          <>
            <ul className={styles['list-item']}>
              <ModalListItem name="Box type" value={packageItem.name} />
              <ModalListItem
                name="Allocation"
                value={formatInoBoxes(userAllocation || 0)}
              />
              <ModalListItem
                name="Price"
                value={`${price} ${inoPool.getCollateralCurrencySymbol() || 0}`}
              />
            </ul>
            <div className={styles['input-container']}>
              <InputLabel className={styles['label']}>Quantity</InputLabel>
              <AppInput
                type="number"
                hideArrow={false}
                validate={{
                  name: 'inoBoxes',
                  validator: validator.current,
                  rule: [
                    'numeric',
                    'isPositive',
                    `maxValue:${maximumBoxesUserCanBuy}`,
                  ],
                  options: {
                    messages: {
                      maxValue:
                        'The number you entered exceeds the maximum amount.',
                    },
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={styles['inputAdornedEnd']}
                  >
                    boxes
                  </InputAdornment>
                }
                value={quantity}
                handleChange={(value) => onChangeBoxesValue(value)}
              />
            </div>
            <div className={styles['input-container']}>
              <InputLabel className={`${styles['label']} ${styles['balance']}`}>
                <span>Your Wallet Balance</span>
                <span>{`${formatWeiNumber(
                  collateralBalance,
                  inoPool.getCollateralCurrencyDecimals(),
                )} ${inoPool.getCollateralCurrencySymbol()}`}</span>
              </InputLabel>
              <AppInputCurrency
                disabled
                value={totalPrice.toString()}
                error={isOverBalance}
                msgError="Your balance is not enough."
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={styles['inputAdornedEnd']}
                  >
                    <div className={styles['brand']}>
                      <span className={styles['logo-brand']}>
                        <img src={inoPool.getCollateralCurrencyIcon()} alt="" />
                      </span>
                      <span className={styles['symbol']}>
                        {inoPool.getCollateralCurrencySymbol()}
                      </span>
                    </div>
                  </InputAdornment>
                }
              />
            </div>
          </>
        ) : null}
      </>
    </BaseModal>
  );
};

interface ModalListItemProps {
  name?: string | React.ReactNode;
  value?: string | number | React.ReactNode;
  secondaryColor?: boolean;
  className?: string;
  valueClassName?: string;
}

export const ModalListItem: FC<ModalListItemProps> = ({
  name,
  value,
  secondaryColor,
  className,
  valueClassName,
}) => {
  return (
    <li className={clsx(styles['modal-list-item'], className)}>
      <div className={styles['modal-list-item__left']}>
        <span
          className={clsx(
            styles['dot'],
            secondaryColor && styles['dot__secondary'],
          )}
        />
        <p className={styles['modal-list-item__key']}>{name}</p>
      </div>
      <p className={clsx(styles['modal-list-item__value'], valueClassName)}>
        {value}
      </p>
    </li>
  );
};

export default ModalBuyINO;
