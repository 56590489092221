import { withWidth } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import BasePage from 'src/pages/BasePage';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/pages/ProjectDetail.module.scss';
import { Project, ProjectInterface } from 'src/utils/project';
import PartBanner from './parts/PartBanner';
import PartPoolList from './parts/PartRelatedPool';
import PartProjectInfo from './parts/PartProjectInfo';

const ProjectDetail = () => {
  const { id: projectId } = useParams() as any;
  const [response, setResponse] = useState<any>(null);
  const [project, setProject] = useState<ProjectInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchProjectDetail = useCallback(async () => {
    setLoading(true);
    try {
      const responseProject = (await rf
        .getRequest('ProjectRequest')
        .getProjectDetail(projectId)) as any;
      responseProject && setProject(new Project(responseProject));
      responseProject && setResponse(responseProject);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProjectDetail();
  }, [projectId]);
  return (
    <BasePage>
      <div className={`${styles['project-detail']}`}>
        {loading ? (
          <div className={styles['loading']}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {project && !!Object.keys(project).length && (
              <>
                <PartBanner project={response} />
                <PartProjectInfo project={project} />
                <PartPoolList projectId={project.getId()} />
              </>
            )}
          </>
        )}
      </div>
    </BasePage>
  );
};

export default withWidth()(withRouter(ProjectDetail));
