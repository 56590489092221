import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import BigNumber from 'bignumber.js';
import {
  getStakeUnitPriceUsd,
  getStakingPool,
  getUserStakingPool,
} from 'src/utils/utils-farm';
import { isAddress } from 'ethers/lib/utils';
import useAuth from 'src/hooks/useAuth';

type ReturnType = {
  getStakingPools: () => Promise<any>;
  getUserStakingPools: () => Promise<any>;
};

const useFarm = (): ReturnType => {
  const { metadataStakingPools } = useSelector(
    (state: RootState) => state.metadata,
  );
  const { user } = useAuth();

  const getStakingPools = async () => {
    const stakingPools = [];
    for (let index = 0; index < metadataStakingPools.length; index++) {
      const metadataPool = metadataStakingPools[index];
      const detailPool = await getStakingPool(metadataPool.poolAddress);
      const lpPrice = await getStakeUnitPriceUsd(metadataPool);

      stakingPools.push({
        ...metadataPool,
        ...detailPool,
        lpPrice,
        totalLiquidity: new BigNumber(lpPrice)
          .times(detailPool?.stakedTokenSupply || 0)
          .toString(),
      });
    }
    return stakingPools;
  };

  const getUserStakingPools = async () => {
    const stakingPools: any = [];

    for (const pool of metadataStakingPools) {
      const poolAddress = pool.poolAddress;
      if (!isAddress(poolAddress) || !user) {
        continue;
      }
      const stakingPool = await getUserStakingPool(
        user?.getAddress(),
        poolAddress,
      );
      stakingPools.push(stakingPool);
    }
    return stakingPools;
  };

  return {
    getStakingPools,
    getUserStakingPools,
  };
};

export default useFarm;
