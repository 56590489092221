import animationData from 'src/assets/icons/loading.json';
import Lottie from 'react-lottie';

const LoadingIcon = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height || 150}
      width={props.width || 150}
    />
  );
};

export default LoadingIcon;
