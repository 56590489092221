import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class PoolRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.poolApi;
  }

  getPools(params: any) {
    const url = '/public/pools';
    return this.get(url, params);
  }

  getHighlightPools() {
    const url = '/public/pools/hightlight';
    return this.get(url, {});
  }

  getFeaturedPools() {
    const url = '/public/pools/featured';
    return this.get(url, {});
  }

  applyWhitelist(poolId: any) {
    const url = `/my/participants`;
    return this.post(url, { poolId });
  }

  getPoolDetail(poolId: any) {
    const url = `/public/pools/${poolId}`;
    return this.get(url);
  }

  getInoDetail(poolId: string) {
    const url = `/public/pools/ino-pool/${poolId}`;
    return this.get(url);
  }

  getWhiteListParticipants(poolId: string) {
    const url = `/my/participants?poolId=${poolId}`;
    return this.get(url);
  }

  getWinners(poolId: string) {
    const url = `/my/winners?poolId=${poolId}`;
    return this.get(url);
  }

  getUserClaimableAmount(poolId: string) {
    const url = `/my/user/claimable`;
    return this.get(url, { poolId });
  }

  getUserDepositAmount(poolId: string, packageId?: string | number) {
    const url = `/my/users/deposit`;
    return this.get(url, { poolId, packageId });
  }

  getMyPools(params: any) {
    const url = '/my/participants';
    return this.get(url, params);
  }
}
