import { FC, forwardRef, Ref } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { CalendarIcon } from 'src/assets/icons';
import styles from 'src/styles/components/AppDatePicker.module.scss';

enum SizePickerEnum {
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface AppDatePicketProps extends ReactDatePickerProps {
  size?: SizePickerEnum;
  placeholder?: string;
}

const AppDatePicker: FC<AppDatePicketProps> = ({
  size = SizePickerEnum.LARGE,
  placeholder,
  ...props
}) => {
  const PicketInput = forwardRef(
    ({ value, onClick }: any, ref: Ref<HTMLInputElement>) => {
      return (
        <div className={`${styles['input-picker']} ${styles[size]}`}>
          <div className={styles['input-control']}>
            <input
              type="text"
              readOnly
              placeholder={placeholder}
              onClick={onClick}
              value={value}
              ref={ref}
            />
          </div>
          <div className={styles['adornment-end']} onClick={onClick}>
            <CalendarIcon />
          </div>
        </div>
      );
    },
  );

  return <ReactDatePicker {...props} customInput={<PicketInput />} />;
};

export default AppDatePicker;
