import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AppButton from 'src/components/AppButton';
import PoolCard from 'src/components/PoolCard';
import { VisibilityGridPoolsEnum } from 'src/constants';
import landingStyle from 'src/styles/pages/Homepage.module.scss';
import { PoolResponseType } from 'src/utils/pool';

interface IPartListProject {
  featuredPools: PoolResponseType[];
}

const PartListProject: FC<IPartListProject> = ({ featuredPools }) => {
  const history = useHistory();
  return (
    <div>
      <div className={landingStyle['list-project']}>
        <div className={'row'}>
          {featuredPools.map((featuredPool: PoolResponseType, index) => (
            <PoolCard
              pool={featuredPool}
              key={index}
              type={VisibilityGridPoolsEnum.COLUMN}
            />
          ))}
        </div>
        <div className={landingStyle['btn-explore']}>
          <AppButton
            variant="tertiary"
            sizes="big"
            onClick={() => history.push('/pools')}
          >
            Explore All Pools
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default PartListProject;
