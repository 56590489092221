import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from '@material-ui/core';
import moment from 'moment';
import { ArrowLeftIcon, UnverifiedIcon, VerifiedIcon } from 'src/assets/icons';
import AppBadge from 'src/components/AppBadge';
import AppButton from 'src/components/AppButton';
import SocialLinks from 'src/components/SocialLinks';
import { ModalListItem } from 'src/modals/ModalBuyINO';
import styles from 'src/styles/pages/INODetail/INOOverview.module.scss';
import clsx from 'clsx';
import CardTimeLine from 'src/pages/PageIDOPoolDetail/parts/CardTimeLine';
import { INOPool, PackageType, PoolResponseType } from 'src/utils/pool';
import { formatNumber, formatTimestamp } from 'src/utils/utils-formats';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  getBoxesSold,
  getCountdownTimestamp,
  getTitleCurrentPhase,
} from 'src/utils/utils-pool';
import { TimelineInterface } from 'src/utils/timelines';
import ModalApplyWhitelist from 'src/modals/ModalApplyWhitelist';
import ModalBuyINO from 'src/modals/ModalBuyINO';
import { Link } from 'react-router-dom';
import { toggleConnectWalletModal } from 'src/store/authentication';
import ImagePackageDetail from './ImagePackageDetail';
import { usePhaseInfo } from 'src/hooks/usePhaseInfo';
import AppCountdown from '../../../components/AppCountdown';
import PurchaseInfo from './PurchaseInfo';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import AppAlertWarning from 'src/components/AppAlertWarning';

interface INOOverviewProps {
  pool: PoolResponseType;
}

export const RELOAD_SOLD_BOXES_AFTER_BUYING = 'RELOAD_SOLD_BOXES_AFTER_BUYING';

const INOOverview = ({ pool }: INOOverviewProps) => {
  const TIME_FORMAT = 'HH:mm YYYY/MM/DD';
  const inoPool = useMemo(() => new INOPool(pool), [pool]);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [showTimeline, setShowTimeline] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<PackageType>(
    {} as PackageType,
  );
  const [boxesSoldQuantity, setBoxesSoldQuantity] = useState<string>('0');
  const [openApplyWhitelistModal, setOpenApplyWhitelistModal] =
    useState<boolean>(false);
  const [openBuyBoxModal, setOpenBuyBoxModal] = useState<boolean>(false);

  const shouldRequireKyc = !user?.isKyced();
  const timeline = inoPool?.getTimeline();

  const {
    getMessage,
    isWhitelisted,
    winner,
    loading: isLoadingPhaseInfo,
  } = usePhaseInfo(inoPool);

  const getPhaseName = () => {
    if (timeline?.beforeWhitelistPhase()) return 'Upcoming';
    if (timeline?.isWhitelistPhase() || timeline?.beforeSwapPhase())
      return 'Whitelist';
    if (timeline?.isSwapPhase() || timeline?.afterSwapPhase()) return 'Swap';
  };

  const getSoldBoxes = async (boxId: string | number) => {
    if (!inoPool.getContractAddress() || !boxId) {
      return;
    }
    try {
      setBoxesSoldQuantity('0'); // reset to 0 at first
      const soldBoxes = await getBoxesSold(
        boxId,
        inoPool.getContractAddress(),
        inoPool.getNetwork(),
      );
      setBoxesSoldQuantity(soldBoxes.toString());
    } catch (error: any) {
      console.log(error);
    }
  };

  //Get package sold
  useEffect(() => {
    getSoldBoxes(selectedPackage.packageId);
    AppBroadcast.on(RELOAD_SOLD_BOXES_AFTER_BUYING, () =>
      getSoldBoxes(selectedPackage.packageId),
    );
    return () => {
      AppBroadcast.remove(RELOAD_SOLD_BOXES_AFTER_BUYING);
    };
  }, [inoPool.getContractAddress(), selectedPackage.packageId]);

  const _renderNetworkAlert = () => {
    if (!user || inoPool.isNetworkCorrect(user)) {
      return null;
    }
    return (
      <AppAlertWarning acceptedNetwork={inoPool.getNetwork()}>
        <div className={styles['message-alert']}>
          Please switch to the required network to continue.
        </div>
      </AppAlertWarning>
    );
  };

  const handleClickConnectWallet = () => {
    dispatch(toggleConnectWalletModal(true));
  };

  const renderUserInfo = () => {
    return (
      <div className={clsx(styles['user'])}>
        {user ? (
          <>
            <div className={styles['user__info']}>
              <h6 className={styles['user__info--rank']}>
                {user.getTierAsString()} User
              </h6>
              {shouldRequireKyc ? (
                <AppBadge color="orange">
                  <UnverifiedIcon />
                  <span>Unverified</span>
                </AppBadge>
              ) : (
                <AppBadge color="green">
                  <VerifiedIcon />
                  <span>Verified</span>
                </AppBadge>
              )}
            </div>
            <div className={styles['user__action']}>
              {shouldRequireKyc && (
                <AppButton sizes="small">
                  <a href="/account" target="blank">
                    KYC Now
                  </a>
                </AppButton>
              )}
              <AppButton sizes="small" variant="secondary">
                <a href="/staking" target="blank">
                  Stake
                </a>
              </AppButton>
            </div>
          </>
        ) : (
          <>
            <p>Connect your wallet to continue</p>
            <AppButton sizes="medium" onClick={handleClickConnectWallet}>
              Connect Wallet
            </AppButton>
          </>
        )}
      </div>
    );
  };

  const renderCountdown = (countDown: number) => {
    const timeline = inoPool?.getTimeline() as TimelineInterface;

    const days = Math.floor(countDown / (3600 * 24));
    const hours = moment.utc(countDown * 1000).format('HH');
    const minutes = moment.utc(countDown * 1000).format('mm');
    const seconds = moment.utc(countDown * 1000).format('s');

    return (
      <>
        <p>{getTitleCurrentPhase(timeline)}</p>
        <div>
          {days > 0 && (
            <>
              <span className={styles['countdown-text']}>{days}</span>
              <span>Days : </span>
            </>
          )}
          <span className={styles['countdown-text']}>{hours}</span>
          <span>Hours : </span>
          <span className={styles['countdown-text']}>{minutes}</span>
          <span>Minutes : </span>
          <span className={styles['countdown-text']}>{seconds}</span>
          <span>Seconds</span>
        </div>
      </>
    );
  };
  const onSelectPackage = (packageId: string | number) => {
    if (inoPool?.getPackages() === undefined) return;
    for (let i = 0; i < inoPool?.getPackages().length; i++) {
      if (inoPool.getPackages()[i].packageId === packageId) {
        setSelectedPackage(inoPool.getPackages()[i]);
        return;
      }
    }
  };

  useEffect(() => {
    if (!inoPool?.getPackages()) return;
    setSelectedPackage(inoPool?.getPackages()[0]);
  }, [inoPool]);

  const onToggleApplyWhitelistModal = () =>
    setOpenApplyWhitelistModal((prevState) => !prevState);

  const onToggleBuyBoxModal = () =>
    setOpenBuyBoxModal((prevState) => !prevState);

  const _renderButtonBuyBox = () => {
    const checkUserCondition =
      user && inoPool.isKYCValid(user) && inoPool.isNetworkCorrect(user);
    if (
      checkUserCondition &&
      !isWhitelisted &&
      timeline?.isWhitelistPhase() &&
      inoPool.isTierEligible(user)
    ) {
      return (
        <div className={styles['whitelist-info__button']}>
          <AppButton
            variant="white"
            sizes="medium"
            onClick={onToggleApplyWhitelistModal}
          >
            Apply Whitelist
          </AppButton>
        </div>
      );
    }
    const totalBoxes =
      inoPool.getPackages() &&
      inoPool
        .getPackages()
        .reduce((pre, curr) => pre + Number(curr.quantity), 0);
    const totalBoxesSold =
      inoPool.getPackages() &&
      inoPool.getPackages().reduce((pre, curr) => pre + Number(curr.sold), 0);

    if (totalBoxes === totalBoxesSold) {
      return (
        <div className={styles['whitelist-info__button']}>
          <AppButton variant="warning" sizes="medium" isDisable>
            Sold Out
          </AppButton>
        </div>
      );
    }
    if (
      checkUserCondition &&
      ((isWhitelisted && timeline?.isPublicSwapPhase()) ||
        (timeline?.isPrivateSwapPhase() &&
          winner &&
          !inoPool.isTierUnstaked(user, winner)))
    ) {
      const disabled = selectedPackage.sold === selectedPackage.quantity;
      return (
        <div className={styles['whitelist-info__button']}>
          <AppButton
            onClick={onToggleBuyBoxModal}
            sizes="medium"
            isDisable={disabled}
          >
            Buy now
          </AppButton>
        </div>
      );
    }
  };

  return (
    <div className={styles['overview']}>
      {_renderNetworkAlert()}
      <div className={styles['navigation']}>
        <ArrowLeftIcon />
        <Link to="/pools">Back</Link>
      </div>
      <div className={styles['overview__top']}>
        <div
          className={clsx(
            styles['overview__content--header'],
            styles['hide-desktop'],
          )}
        >
          <img
            src={inoPool?.getSwapTokenLogoUrl()}
            alt=""
            className={styles['logo']}
          />
          <h3 className="">{inoPool?.getName()}</h3>
        </div>
        <PackageSwitch
          className={styles['hide-desktop']}
          packages={inoPool?.getPackages() as PackageType[]}
          onSelectedTab={(packageId: string | number) =>
            onSelectPackage(packageId)
          }
        />
        <div className={styles['overview__container']}>
          {inoPool && (
            <ImagePackageDetail
              media={inoPool.getProject()?.getMedia() || {}}
              imageUrl={selectedPackage.logoUrl}
              loading={isLoadingPhaseInfo}
              phaseNotice={{
                iconType: getMessage()?.iconType,
                message: getMessage()?.message,
              }}
            />
          )}

          <div className={styles['overview__content']}>
            <div
              className={clsx(
                styles['overview__content--header'],
                styles['hide-mobile'],
              )}
            >
              <img
                src={inoPool?.getSwapTokenLogoUrl()}
                alt=""
                className={styles['logo']}
              />
              <h3 className="">{inoPool?.getName()}</h3>
            </div>
            <div
              className={clsx(
                styles['overview__content--tool'],
                styles['hide-mobile'],
              )}
            >
              <PackageSwitch
                packages={inoPool?.getPackages() as PackageType[]}
                onSelectedTab={(packageId: string | number) =>
                  onSelectPackage(packageId)
                }
              />
              <div className={styles['overview__content--socials']}>
                <SocialLinks
                  media={inoPool?.getProject()?.getMedia() || {}}
                  iconColor="#CACCD1"
                />
              </div>
            </div>

            <div className={styles['whitelist-info']}>
              <div className={styles['whitelist-info__header']}>
                <div className={styles['whitelist-info__price']}>
                  <span>Price: </span>
                  <h4>
                    {selectedPackage.price}{' '}
                    {inoPool?.getCollateralCurrencySymbol()}
                  </h4>
                </div>
                {_renderButtonBuyBox()}
              </div>
              <hr className={styles['separator']} />
              {timeline?.afterSwapPhase() ? (
                <p>Pool ended</p>
              ) : (
                <div className={styles['whitelist-info__countdown']}>
                  <AppCountdown
                    endDate={
                      timeline
                        ? Math.floor(getCountdownTimestamp(timeline) / 1000)
                        : 0
                    }
                    render={renderCountdown}
                  />
                </div>
              )}
            </div>
            <ul className={styles['whitelist-info__info']}>
              <ModalListItem
                name="Total Boxes"
                value={formatNumber(selectedPackage?.quantity || 0)}
                className={styles['list-item']}
              />
              <ModalListItem
                name="Boxes Sold"
                value={formatNumber(boxesSoldQuantity)}
                className={styles['list-item']}
              />
              <ModalListItem
                name="Remaining"
                value={formatNumber(
                  selectedPackage?.quantity - Number(boxesSoldQuantity),
                )}
                className={styles['list-item']}
              />
            </ul>
            <div className={styles['user-container']}>
              <div className={styles['hide-mobile']}>{renderUserInfo()}</div>
              <div
                className={clsx(
                  styles['user__collapse-header'],
                  styles['hide-desktop'],
                )}
                onClick={() => setShowTimeline(!showTimeline)}
              >
                <div className={styles['user__collapse-header--left']}>
                  <span className={styles['point']} />
                  <p>{getPhaseName()}</p>
                </div>
                <span
                  className={`${
                    styles['user__collapse-header--icon-collapse']
                  } ${
                    styles[
                      !showTimeline ? 'user__collapse-header--collapsed' : ''
                    ]
                  }`}
                />
              </div>
              <Collapse
                in={showTimeline}
                className={clsx(styles['hide-desktop'])}
                classes={{
                  root: styles['collapse'],
                }}
              >
                {renderUserInfo()}
                <div className={styles['timeline-mobile']}>
                  {inoPool && (
                    <CardTimeLine
                      timeline={inoPool?.getTimeline()}
                      typePool="INO"
                      className={styles['timeline-element']}
                    />
                  )}
                </div>
              </Collapse>
            </div>
            <div
              className={clsx(
                styles['timeline-desktop'],
                styles['hide-mobile'],
              )}
            >
              {inoPool && (
                <CardTimeLine
                  timeline={inoPool?.getTimeline()}
                  className={styles['timeline-element']}
                  typePool="INO"
                />
              )}
            </div>
          </div>
        </div>
        {inoPool.getId() && <PurchaseInfo inoPool={inoPool} />}
        {(timeline?.afterSwapPhase() || timeline?.isSwapPhase()) && (
          <div className={styles['phase-info__timer']}>
            <ModalListItem
              secondaryColor
              className={clsx(
                styles['phase-timer'],
                styles['phase-info__item'],
                styles['list-item'],
              )}
              name="Time exclusive sale"
              valueClassName={styles['phase-info__timer--item']}
              value={
                <div className={styles['phase-timer__item']}>
                  <div>
                    <span>From</span>
                    <span
                      className={clsx(
                        styles['time'],
                        timeline?.isPrivateSwapPhase() && styles['highlight'],
                      )}
                    >
                      {timeline &&
                        formatTimestamp(
                          timeline.getPrivateSwapPhase().startTime,
                          TIME_FORMAT,
                        )}
                    </span>
                  </div>
                  <div>
                    <span>To</span>
                    <span
                      className={clsx(
                        styles['time'],
                        timeline?.isPrivateSwapPhase() && styles['highlight'],
                      )}
                    >
                      {timeline &&
                        formatTimestamp(
                          timeline.getPrivateSwapPhase().endTime,
                          TIME_FORMAT,
                        )}
                    </span>
                  </div>
                </div>
              }
            />
            {timeline?.getPublicSwapPhase() ? (
              <ModalListItem
                secondaryColor
                className={clsx(
                  styles['phase-timer'],
                  styles['phase-info__item'],
                )}
                name="Time for FCFS Sale"
                valueClassName={styles['phase-info__timer--item']}
                value={
                  <div className={styles['phase-timer__item']}>
                    <div>
                      <span>From</span>
                      <span
                        className={clsx(
                          styles['time'],
                          timeline?.isPublicSwapPhase() && styles['highlight'],
                        )}
                      >
                        {formatTimestamp(
                          timeline?.getPublicSwapPhase()?.startTime || 0,
                          TIME_FORMAT,
                        )}
                      </span>
                    </div>
                    <div>
                      <span>To</span>
                      <span
                        className={clsx(
                          styles['time'],
                          timeline?.isPublicSwapPhase() && styles['highlight'],
                        )}
                      >
                        {formatTimestamp(
                          timeline?.getPublicSwapPhase()?.endTime || 0,
                          TIME_FORMAT,
                        )}
                      </span>
                    </div>
                  </div>
                }
              />
            ) : (
              <span className={`${styles['text']} ${styles['text--warning']}`}>
                This pool does not have FCFS Sale.
              </span>
            )}
          </div>
        )}
      </div>
      <ModalApplyWhitelist
        open={openApplyWhitelistModal}
        onClose={onToggleApplyWhitelistModal}
        poolId={pool.id}
        onSuccess={() => {
          window.location.reload();
        }}
        socialList={inoPool?.getProject()?.getMedia()}
      />
      {openBuyBoxModal && (
        <ModalBuyINO
          inoPool={inoPool}
          open={openBuyBoxModal}
          winner={winner}
          packageItem={selectedPackage}
          onClose={onToggleBuyBoxModal}
          remainingBoxes={selectedPackage?.quantity - Number(boxesSoldQuantity)}
        />
      )}
    </div>
  );
};

export default INOOverview;

interface PackageSwitchProps {
  onSelectedTab: (packageId: string | number) => void;
  packages: PackageType[];
  className?: string;
}

const PackageSwitch = ({
  onSelectedTab,
  className,
  packages,
}: PackageSwitchProps) => {
  const [packageActive, setPackageActive] = useState<string | number | null>(
    null,
  );

  useEffect(() => {
    if (!packages) return;
    setPackageActive(packages[0].packageId);
  }, [packages]);

  const onSelectTab = (value: string | number) => {
    setPackageActive(value);
    onSelectedTab(value);
  };

  return (
    <ul className={clsx(styles['button-tabs'], className)}>
      {packageActive !== null
        ? packages.map((item: PackageType) => {
            return (
              <li
                key={item.packageId}
                className={
                  packageActive === item.packageId ? styles['active'] : ''
                }
                onClick={() => onSelectTab(item.packageId)}
              >
                <span>{item.name}</span>
              </li>
            );
          })
        : null}
    </ul>
  );
};
