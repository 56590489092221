import {
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  ChangeEvent,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { debounce } from 'lodash';
import styles from 'src/styles/components/AppDataTable.module.scss';
import AppPagination from './AppPagination';
import LoadingIcon from '../assets/icons/LoadingIcon';
import NoResult from '../assets/icons/NoResult';
import AppButton from './AppButton';

// For more params, please define them below with ? mark
export interface RequestParams {
  searchKey?: string;
  network?: string;
  type?: string;
  status?: string | number;
  poolId?: string;
  poolType?: string;
}

interface DataTableProps {
  requestParams?: RequestParams; // if requestParams are not passed, only fetchs API in didMount
  limit?: number;
  wrapperClassName?: string;
  fetchData: (requestParams: RequestParams) => Promise<IResponseType>;
  renderBody: (tableData: any[]) => ReactNode;
  renderHeader?: () => ReactNode;
  renderNoData?: () => ReactNode;
}

export interface DataTableRef {
  tableData: any[];
}

interface Pagination {
  limit: number; // the limit item of page
  page: number; // the current page
  sortBy?: string;
  sortType?: 'asc' | 'desc'; // Available values : asc, desc
}

interface IResponseType {
  totalDocs: number;
  totalPages: number;
  page: number;
  limit: number;
  docs: any[];
}

const AppDataTable = forwardRef(
  (props: DataTableProps, ref: Ref<DataTableRef>) => {
    const DEFAULT_LIMIT = 20;
    const DEBOUNCE_TIME = 1000;
    const CONSTANT = 'CONSTANT';

    const useStyles = makeStyles(() => ({
      root: {
        '& .Mui-selected': {
          border: '1px solid #B53EFA !important',
        },
      },
    }));

    const higherOrderClasses = useStyles();
    // make requestParams not change => call at the first load
    const defaultRequestParams = useMemo(() => ({}), [CONSTANT]);

    const {
      limit = DEFAULT_LIMIT,
      requestParams = defaultRequestParams,
      wrapperClassName,
      fetchData,
      renderBody,
      renderHeader,
      renderNoData,
    } = props;

    const initialPagination: Pagination = { limit, page: 1 };

    const [tableData, setTableData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pagination, setPagination] = useState<Pagination>(initialPagination);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      tableData,
    }));

    const fetchTableData = async (
      params: RequestParams,
      tablePagination: Pagination,
      isLoadMore = false,
    ) => {
      const setLoading = isLoadMore ? setIsLoadingMore : setIsLoading;
      setLoading(true);
      const response: IResponseType = await fetchData({
        ...params,
        ...tablePagination,
      });
      if (response && response.docs) {
        setTableData((prevState) =>
          isLoadMore ? [...prevState, ...response.docs] : response.docs,
        );
        setPagination(tablePagination);
        setTotalPages(response.totalPages);
      }
      setLoading(false);
    };

    const debounceFetchTablaData = useCallback(
      debounce(fetchTableData, DEBOUNCE_TIME),
      [requestParams],
    );

    useEffect(() => {
      debounceFetchTablaData(requestParams, { ...pagination, page: 1 });
      return () => {
        debounceFetchTablaData.cancel();
      };
    }, [debounceFetchTablaData]);

    const onChangePagination = (_event: ChangeEvent<unknown>, page: number) => {
      fetchTableData(requestParams, { ...pagination, page });
    };

    const onLoadMore = () => {
      const nextPage = pagination.page + 1;
      fetchTableData(
        requestParams,
        { ...pagination, page: nextPage },
        isMobile,
      );
    };

    const _renderLoading = () => {
      const width = isMobile ? 100 : 150;
      return (
        <div>
          <LoadingIcon width={width} height={width} />
        </div>
      );
    };

    const _renderLoadMore = () => {
      return (
        <div className={styles['load-more']}>
          <AppButton
            sizes="small"
            variant="outline"
            className={styles['btn-load-more']}
            onClick={onLoadMore}
            isDisable={isLoadingMore}
          >
            {isLoadingMore ? <BeatLoader color="white" size={8} /> : 'See more'}
          </AppButton>
        </div>
      );
    };

    const _renderPagination = () => {
      return (
        <div className={styles['pagination']}>
          <AppPagination
            className={higherOrderClasses.root}
            count={totalPages}
            page={pagination.page}
            onChange={onChangePagination}
          />
        </div>
      );
    };

    const _renderFooter = () => {
      if (totalPages <= 1 || isLoading) {
        return null;
      }
      return isMobile ? _renderLoadMore() : _renderPagination();
    };

    const _renderBody = () => {
      if (isLoading) {
        return _renderLoading();
      }
      if (!tableData.length) {
        return renderNoData ? renderNoData() : <NoResult />;
      }
      return (
        <>
          {renderBody(tableData)}
          {_renderFooter()}
        </>
      );
    };

    const _renderTable = () => {
      return (
        <>
          {renderHeader && renderHeader()}
          {_renderBody()}
        </>
      );
    };

    return (
      <div className={`${styles['wrapper']} ${wrapperClassName}`}>
        {_renderTable()}
      </div>
    );
  },
);

export default AppDataTable;
