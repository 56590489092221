import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import myAccount from './myAccount';
import metadata from './metadata';
import authentication from './authentication';
import transactions from './transactions';

const rootReducer = combineReducers({
  authentication,
  myAccount,
  metadata,
  transactions,
});

const initialState = {};

const middlewares = [thunk];

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default () => {
  return { store };
};
