import BaseRequest from './BaseRequest';
import config from 'src/config';

const baseUrl: string = config.apiGamer;

export default class GameRequest extends BaseRequest {
  getUrlPrefix() {
    return '';
  }

  getGameElo(connectedAccount: any) {
    const url = baseUrl.replace('API_ADDRESS', connectedAccount);
    return this.get(url);
  }
}
