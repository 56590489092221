import BigNumber from 'bignumber.js';
import { multicall } from './utils-multicall';
import abi from 'src/abi';
import { erc20Contract } from './utils-contract';
import { getNetworkProvider } from 'src/utils/utils-network';
import { isAddress } from 'ethers/lib/utils';
import { MaxUint256 } from '@ethersproject/constants';
import config from 'src/config';

export type TokenType = {
  decimals: number;
  symbol: string;
  name: string;
  address: string;
};

export const getTokenInfo = async (
  contractAddress: string,
  network: string,
) => {
  try {
    const calls = [
      {
        address: contractAddress,
        name: 'symbol',
        params: [],
      },
      {
        address: contractAddress,
        name: 'decimals',
        params: [],
      },
      {
        address: contractAddress,
        name: 'name',
        params: [],
      },
      {
        address: contractAddress,
        name: 'totalSupply',
        params: [],
      },
    ].filter(Boolean);

    const [[symbol], decimals, [name], totalSupply] = await multicall(
      abi['Erc20'],
      calls,
      network,
    );

    return {
      symbol,
      name,
      decimals: Number(decimals),
      contractAddress,
      totalSupply: totalSupply.toString(),
    };
  } catch (error) {
    return null;
  }
};

export const getAllowance = async (
  network: string,
  contractAddress: string,
  userAddress: string,
  spenderAddress: string,
) => {
  if (
    !isAddress(contractAddress) ||
    !isAddress(userAddress) ||
    !isAddress(spenderAddress)
  ) {
    return null;
  }
  const provider = getNetworkProvider(network);
  const contract = erc20Contract(contractAddress, provider);
  const allowance = await contract.allowance(userAddress, spenderAddress);
  return allowance.toString();
};

export const makeApproveParams = (
  tokenContractAddress: string,
  spenderAddress: string,
) => {
  return [
    abi['Erc20'],
    tokenContractAddress,
    'approve',
    [spenderAddress, MaxUint256.toString()],
    {},
  ];
};

export const getTokenBalance = async (
  network: string,
  tokenAddress: string,
  userAddress: string,
) => {
  const contract = erc20Contract(tokenAddress, getNetworkProvider(network));
  const balance = await contract.balanceOf(userAddress);
  return balance.toString();
};

export const getToken = (network: string, addressToken: string): any => {
  const currenciesByNetwork = config.networks[network]?.currencies;
  if (!currenciesByNetwork) {
    return;
  }

  return Object.values(currenciesByNetwork).find(
    (item: any) => item.address === addressToken,
  );
};

export const getTokenDecimals = (
  network: string,
  collateralCurrency: string,
  defaultCurrency = 'usdc',
): number => {
  const currency = config.networks[network].currencies[collateralCurrency];
  if (currency) {
    return currency.decimals;
  }
  return config.networks[network].currencies[defaultCurrency].decimals;
};

export const areTokensApproved = async (
  network: string,
  tokenAddress: string,
  collateralTokenAddress: string,
  userAddress: string | undefined,
) => {
  if (!network || !tokenAddress || !collateralTokenAddress || !userAddress) {
    return false;
  }
  try {
    const launchTokenAllowance = await getAllowance(
      network,
      tokenAddress,
      userAddress,
      config.networks[network].addresses.auctionProxy,
    );
    const collateralTokenAllowance = await getAllowance(
      network,
      collateralTokenAddress,
      userAddress,
      config.networks[network].addresses.auctionProxy,
    );
    return (
      new BigNumber(collateralTokenAllowance).gt(0) &&
      new BigNumber(launchTokenAllowance).gt(0)
    );
  } catch (error) {
    return false;
  }
};

export const checkIsTokenBase = (addressToken: string, network: string) => {
  const currenciesByNetwork = config.networks[network]?.currencies;
  if (!currenciesByNetwork) {
    return false;
  }
  return Object.values(currenciesByNetwork).find(
    (item: any) => item.address === addressToken,
  );
};

export const getSymbolBaseToken = (symbol: string) => {
  if (symbol?.includes('bnb')) {
    return 'bnb';
  }

  if (symbol?.includes('eth')) {
    return 'eth';
  }

  if (symbol?.includes('avax')) {
    return 'avax';
  }

  if (symbol?.includes('matic')) {
    return 'matic';
  }

  return symbol;
};
