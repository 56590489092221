import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { ArrowCircleDownIcon } from 'src/assets/icons';
import styles from 'src/styles/components/CardClaimProgress.module.scss';
import { formatTimestamp, formatWeiNumber } from 'src/utils/utils-formats';
import AppButton from 'src/components/AppButton';
import {
  getMaxClaimableTokensByTime,
  isDisableClaim,
} from 'src/utils/utils-pool';
import useAuth from 'src/hooks/useAuth';
import { isMobile } from 'react-device-detect';
import useIDOPool from 'src/hooks/useIDOPool';
import { ClaimScheduleType, IDOPool } from 'src/utils/pool';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { RELOAD_USER_CLAIM_INFO, UserClaimInfoType } from '..';

interface CardClaimProgressProps extends RouteComponentProps {
  idoPool: IDOPool;
  purchasedTokens: number;
  claimInfo: UserClaimInfoType;
}

const CardClaimProgress = (props: CardClaimProgressProps) => {
  const { user } = useAuth();
  const { claimToken } = useIDOPool();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const { idoPool, purchasedTokens, claimInfo } = props;

  const claimSchedules = idoPool.getClaimSchedules();

  if (!claimSchedules.length) {
    return null;
  }

  const generateDataClaimSchedules = () => {
    return claimSchedules.map((item: ClaimScheduleType, index: number) => {
      return {
        percentage:
          index === 0
            ? item.maxClaimablePercentage
            : claimSchedules[index]?.maxClaimablePercentage -
              claimSchedules[index - 1]?.maxClaimablePercentage,
        startTime: item.startTime,
        totalClaim: (+purchasedTokens * item.maxClaimablePercentage) / 100,
        valueClaim:
          index === 0
            ? (+purchasedTokens * item.maxClaimablePercentage) / 100
            : (+purchasedTokens *
                (claimSchedules[index]?.maxClaimablePercentage -
                  claimSchedules[index - 1]?.maxClaimablePercentage)) /
              100,
      };
    });
  };

  const maxClaimableTokensByTime = getMaxClaimableTokensByTime(
    claimSchedules,
    purchasedTokens,
  );

  const isDisableClaimButton = isDisableClaim(
    idoPool,
    user,
    claimInfo?.claimedToken,
    maxClaimableTokensByTime,
  );

  const onClickClaim = async () => {
    await claimToken(idoPool, user);
    AppBroadcast.dispatch(RELOAD_USER_CLAIM_INFO);
  };

  const _renderCardHeader = () => {
    return (
      <>
        <div className={styles['card-title']}>
          <div className={styles['left']}>Claim Progress</div>
          <div className={styles['right']}>
            {isMobile && (
              <button
                className={`${styles['btn-collapse']} ${
                  !openCollapse ? styles['collapsed'] : ''
                }`}
                type="button"
                onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
              >
                <i className={styles['icon']} />
              </button>
            )}
            <AppButton
              sizes="small"
              isDisable={isDisableClaimButton}
              onClick={onClickClaim}
            >
              Claim Tokens
            </AppButton>
          </div>
        </div>
        {isMobile && (
          <div
            className={`${styles['sub-header']} ${
              openCollapse ? styles['hidden'] : ''
            }`}
          >
            <div className={styles['col']}>
              <div className={styles['row']}>
                <div className={styles['left']}>
                  <i className={styles['icon']} />
                  <span className={styles['label']}>Time</span>
                </div>
                <div className={styles['right']}>
                  <span className={styles['value']}>
                    {formatTimestamp(
                      claimSchedules[0].startTime * 1000,
                      'HH:mm:ss_DD/MM/yyyy',
                    )}
                  </span>
                </div>
              </div>
              <div className={styles['row']}>
                <div className={styles['left']}>
                  <i className={styles['icon']} />
                  <span className={styles['label']}>Amount</span>
                </div>
                <div className={styles['right']}>
                  <span className={styles['value']}>
                    {formatWeiNumber(
                      purchasedTokens.toString(),
                      idoPool.getSwapTokenDecimals(),
                    )}{' '}
                    {idoPool.getSwapTokenSymbol()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const _renderClaimStatus = (item: any) => {
    if (moment().unix() >= item.startTime) {
      if (
        item.totalClaim <= +claimInfo?.claimedToken &&
        +claimInfo?.claimedToken > 0
      ) {
        return <div className={styles['claimed']}>Claimed</div>;
      }
      return <div className={styles['claimable']}>Claimable</div>;
    }

    return <div>Vesting</div>;
  };

  const _renderCardBodyMobile = () => {
    return (
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <div className={styles['timeline']}>
          {generateDataClaimSchedules().map((item: any, index: number) => {
            return (
              <div className={styles['item-mobile']}>
                <div
                  className={`${styles['row']} ${
                    moment().unix() >= item.startTime ? styles['done'] : ''
                  }`}
                  key={index}
                >
                  <div className={styles['header-claim']}>
                    <div className={styles['percent']}>{item.percentage}%</div>

                    <div className={styles['claim-status']}>
                      {_renderClaimStatus(item)}
                    </div>
                  </div>

                  <div className={styles['date']}>
                    {formatTimestamp(
                      item.startTime * 1000,
                      'HH:mm:ss_DD/MM/yyyy',
                    )}
                  </div>
                  <div className={styles['amount']}>
                    {formatWeiNumber(
                      item.valueClaim,
                      idoPool.getSwapTokenDecimals(),
                    )}{' '}
                    {idoPool.getSwapTokenSymbol()}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Collapse>
    );
  };

  const _renderCardBodyDesktop = () => {
    return (
      <div className={styles['timeline']}>
        {!openCollapse && (
          <div className={styles['header-collapse']}>
            <div className={`${styles['row']}`}>
              <div className={styles['date']}>
                {formatTimestamp(
                  claimSchedules[0].startTime * 1000,
                  'HH:mm:ss_DD/MM/yyyy',
                )}
              </div>
              <div className={styles['amount']}>
                {formatWeiNumber(
                  purchasedTokens.toString(),
                  idoPool.getSwapTokenDecimals(),
                )}{' '}
                {idoPool.getSwapTokenSymbol()}
              </div>
            </div>
            <button
              type="button"
              className={styles['btn-collapse']}
              onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
            >
              <ArrowCircleDownIcon />
            </button>
          </div>
        )}
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          {generateDataClaimSchedules().map((item: any, index: number) => {
            return (
              <div
                className={`${styles['row']} ${
                  moment().unix() >= item.startTime ? styles['done'] : ''
                }`}
                key={index}
              >
                <div className={styles['percent']}>{item.percentage}%</div>
                <div className={styles['date']}>
                  {formatTimestamp(
                    item.startTime * 1000,
                    'HH:mm:ss_DD/MM/yyyy',
                  )}
                </div>
                <div className={styles['amount']}>
                  {formatWeiNumber(
                    item.valueClaim,
                    idoPool.getSwapTokenDecimals(),
                  )}{' '}
                  {idoPool.getSwapTokenSymbol()}
                </div>

                <div
                  className={`${styles['claim-status']} ${styles['claim-status-desktop']}`}
                >
                  {_renderClaimStatus(item)}
                </div>

                {index === 0 && (
                  <button
                    type="button"
                    className={styles['btn-collapse']}
                    onClick={() =>
                      setOpenCollapse((openCollapse) => !openCollapse)
                    }
                  >
                    <ArrowCircleDownIcon />
                  </button>
                )}
              </div>
            );
          })}
        </Collapse>
      </div>
    );
  };

  return (
    <div className={styles['card']}>
      <div className={styles['card-header']}>
        <div className={styles['card-header']}>
          <>{_renderCardHeader()}</>
        </div>
      </div>
      <div className={styles['card-body']}>
        {isMobile ? (
          <>{_renderCardBodyMobile()}</>
        ) : (
          <>{_renderCardBodyDesktop()}</>
        )}
      </div>
    </div>
  );
};

export default withRouter(CardClaimProgress);
