import styles from 'src/styles/pages/LBPDetails/LBPOverview.module.scss';
import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import {
  ArrowLeftIcon,
  CalendarIcon,
  ClockIcon,
  SettingIcon,
  UnverifiedIcon,
  VerifiedIcon,
} from 'src/assets/icons';
import { formatNumber, formatTimestamp } from 'src/utils/utils-formats';
import AppCountdown from 'src/components/AppCountdown';
import ModalSettingLBP from 'src/modals/ModalSettingLBP';
import { Link } from 'react-router-dom';
import {
  IAuctionResponseType,
  ITokenMain,
  ITokenBase,
} from 'src/utils/utils-auction';
import useAuth from 'src/hooks/useAuth';
import { Auction } from 'src/utils/auction';
import { roundNumber } from 'src/utils/utils-helpers';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getSymbolBaseToken } from 'src/utils/utils-token';

interface IPartLBPOverview {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  baseTokenAccrued: number;
  mainTokenReleased: number;
  balanceStart: string[];
  balanceCurrent: string[];
  priceTokenMain: string;
  isEnabledSwap: boolean;
  fetchStatusEnableSwap: () => void;
  fetchBalancesPool: () => void;
}

interface IHeader {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  baseTokenAccrued: number;
  balanceCurrent: string[];
  isEnabledSwap: boolean;
  fetchStatusEnableSwap: () => void;
  fetchBalancesPool: () => void;
}

const Header: FC<IHeader> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  baseTokenAccrued,
  balanceCurrent,
  isEnabledSwap,
  fetchStatusEnableSwap,
  fetchBalancesPool,
}) => {
  const [isOpenModalSetting, setIsOpenModalSetting] = useState<boolean>(false);
  const { user } = useAuth();
  const lbpAuction = useMemo(() => new Auction(auction), [auction]);

  return (
    <>
      <div className={styles['detail-back']}>
        <div className={styles['content-left']}>
          <Link to={`/auctions`}>
            <div className={styles['icon']}>
              <ArrowLeftIcon />
            </div>
            <div className={styles['name']}>All Auctions</div>
          </Link>
        </div>
        {user?.getAddress() === auction?.ownerAddress &&
          !lbpAuction.getIsDraft() && (
            <div className={styles['settings']}>
              Settings{' '}
              <SettingIcon onClick={() => setIsOpenModalSetting(true)} />
            </div>
          )}
      </div>
      {!lbpAuction.getIsDraft() && (
        <ModalSettingLBP
          open={isOpenModalSetting}
          onClose={() => setIsOpenModalSetting(false)}
          auction={auction}
          baseToken={baseToken}
          baseTokenIndex={baseTokenIndex}
          mainTokenIndex={mainTokenIndex}
          baseTokenAccrued={baseTokenAccrued}
          balanceCurrent={balanceCurrent}
          isEnabledSwap={isEnabledSwap}
          fetchStatusEnableSwap={fetchStatusEnableSwap}
          fetchBalancesPool={fetchBalancesPool}
        />
      )}
    </>
  );
};

const PartLBPOverview: FC<IPartLBPOverview> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  baseTokenAccrued,
  mainTokenReleased,
  balanceStart,
  balanceCurrent,
  priceTokenMain,
  isEnabledSwap,
  fetchStatusEnableSwap,
  fetchBalancesPool,
}) => {
  const { pool } = auction;

  const lbpAuction = useMemo(() => new Auction(auction), [auction]);

  const percentMainTokensReleased = useMemo(() => {
    if (+mainTokenReleased < 0) {
      return 0;
    }

    return new BigNumber(+mainTokenReleased)
      .dividedBy(+balanceStart[mainTokenIndex])
      .multipliedBy(100)
      .toFixed(2);
  }, [mainTokenReleased, balanceStart, mainTokenIndex]);

  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);

  const priceBaseToken = useMemo(() => {
    return tokensUsdPrice[getSymbolBaseToken(baseToken?.symbol?.toLowerCase())];
  }, [tokensUsdPrice, baseToken]);

  const _renderLBPDuration = () => {
    const startTime = lbpAuction.getStartTime();
    const endTime = lbpAuction.getEndTime();
    const durationDay = Math.round(
      moment(endTime * 1000).diff(moment(startTime * 1000), 'hours') / 24,
    );
    const now = moment().unix();
    const isBeforeStart = now < startTime;
    const isDuring = now >= startTime && now <= endTime;
    const isEnded = now > endTime;

    const getLabelTimeLine = () => {
      if (isBeforeStart) {
        return 'Start in';
      }

      if (isDuring) {
        return 'Ends in';
      }

      return 'Ended';
    };

    const getCountdownEndTime = () => {
      if (isBeforeStart) {
        return startTime * 1000;
      }

      if (isDuring) {
        return endTime * 1000;
      }

      return 0;
    };

    const renderCountdown = (durationAuction: number) => {
      return (
        <>
          {Math.floor(durationAuction / (3600 * 24)) > 0 && (
            <>
              <span>{Math.floor(durationAuction / (3600 * 24))}</span>
              {' : '}
            </>
          )}
          <span>{moment.utc(durationAuction * 1000).format('HH')}</span>
          {' : '}
          <span>{moment.utc(durationAuction * 1000).format('mm')}</span>
          {' : '}
          <span>{moment.utc(durationAuction * 1000).format('ss')}</span>
        </>
      );
    };

    return (
      <div className={styles['lbp-duration']}>
        <div className={styles['card-duration']}>
          <div className={styles['title']}>
            {lbpAuction.getName()}
            {auction.isVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
          </div>
          <div className={styles['content']}>
            <div className={styles['detail']}>
              <div className={`${styles['item']} ${styles['icon']}`}>
                <CalendarIcon />
              </div>
              <div className={`${styles['item']} ${styles['label']}`}>
                Duration
              </div>
              <div className={`${styles['item']} ${styles['value']}`}>
                {durationDay} {durationDay > 1 ? 'days' : 'day'}
              </div>
            </div>
            <div className={styles['detail']}>
              <div className={`${styles['item']} ${styles['icon']}`}>
                <ClockIcon />
              </div>
              <div className={`${styles['item']} ${styles['label']}`}>
                {getLabelTimeLine()}
              </div>
              <div className={`${styles['item']} ${styles['value']}`}>
                {isEnded ? (
                  <>{formatTimestamp(endTime * 1000, 'DD/MM/YYYY')} </>
                ) : (
                  <>
                    <AppCountdown
                      endDate={getCountdownEndTime()}
                      render={renderCountdown}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderLBPOverview = () => {
    if (!pool) {
      return;
    }

    const { totalLiquidity, totalSwapVolume } = pool;
    return (
      <div className={styles['lbp-overview']}>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Total Volume</span>
            </div>
            <div className={styles['value']}>
              <div className={styles['text']}>
                {formatNumber(Number(totalSwapVolume).toFixed(6))}{' '}
                {+totalSwapVolume > 0 && <span>{baseToken.symbol}</span>}
              </div>
              {+totalSwapVolume > 0 && (
                <div className={styles['price']}>
                  ~$
                  {formatNumber(
                    new BigNumber(+totalSwapVolume)
                      .multipliedBy(+priceBaseToken)
                      .toFixed(6, BigNumber.ROUND_DOWN),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Liquidity</span>
            </div>
            <div className={styles['value']}>
              <div className={styles['text']}>
                {formatNumber(Number(totalLiquidity).toFixed(6))}{' '}
                {+totalLiquidity > 0 && <span>{baseToken.symbol}</span>}
              </div>
              {+totalLiquidity > 0 && (
                <div className={styles['price']}>
                  ~$
                  {formatNumber(
                    new BigNumber(+totalLiquidity)
                      .multipliedBy(+priceBaseToken)
                      .toFixed(6, BigNumber.ROUND_DOWN),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Price</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(
                  roundNumber(
                    priceTokenMain,
                    BigNumber.ROUND_DOWN,
                    mainToken?.decimals,
                  ),
                )}{' '}
                {baseToken.symbol}
              </span>
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Main Tokens Released</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(percentMainTokensReleased)}%
              </span>
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Base Token Accrued</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(
                  roundNumber(
                    baseTokenAccrued,
                    BigNumber.ROUND_DOWN,
                    baseToken?.decimals,
                  ),
                )}
              </span>
              <span className={styles['note']}>{baseToken?.symbol}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header
        auction={auction}
        mainToken={mainToken}
        baseToken={baseToken}
        baseTokenIndex={baseTokenIndex}
        mainTokenIndex={mainTokenIndex}
        baseTokenAccrued={baseTokenAccrued}
        balanceCurrent={balanceCurrent}
        isEnabledSwap={isEnabledSwap}
        fetchStatusEnableSwap={fetchStatusEnableSwap}
        fetchBalancesPool={fetchBalancesPool}
      />
      <div className={styles['content-item']}>{_renderLBPDuration()}</div>
      <div className={styles['content-item']}>{_renderLBPOverview()}</div>
    </>
  );
};

export default PartLBPOverview;
