const requireFile = require.context('./', true, /[\w-]+\.json$/);

export default Object.fromEntries(
  requireFile
    .keys()
    .map((fileName: string) => [
      fileName.replace('./', '').replace('.json', ''),
      requireFile(fileName).abi,
    ]),
);
