import styles from 'src/styles/pages/ProjectDetail.module.scss';
import { useEffect, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import PoolCard from 'src/components/PoolCard';
import { VisibilityGridPoolsEnum } from 'src/constants';
import { isWidthUp, withWidth } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AppDataTable from 'src/components/AppDataTable';

const RelatedPools = (props: any) => {
  const { projectId } = props;

  const getPools = async (params: any) => {
    try {
      const response = (await rf
        .getRequest('ProjectRequest')
        .getPoolsInProject(projectId, {
          ...params,
          sortBy: 'type',
          sortType: 'asc',
        })) as any;
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const _renderBodyTable = (listPool: any) => {
    return (
      <div className={styles['pool-list']}>
        {listPool && !!listPool.length && (
          <>
            <div className={styles['title']}>Pool List</div>
            <div
              className={!isWidthUp('lg', props.width) ? ' row' : ''}
              style={{ justifyContent: 'center' }}
            >
              {listPool.map((pool: any) => (
                <PoolCard
                  pool={pool}
                  key={pool.id}
                  type={
                    isWidthUp('lg', props.width)
                      ? VisibilityGridPoolsEnum.ROW
                      : VisibilityGridPoolsEnum.COLUMN
                  }
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <AppDataTable
      limit={10}
      renderBody={_renderBodyTable}
      fetchData={getPools}
    />
  );
};

export default withWidth()(withRouter(RelatedPools));
