import { Collapse } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  FilterIcon,
  GridColumnIcon,
  GridRowIcon,
  SearchIcon,
} from 'src/assets/icons';
import AppDataTable from 'src/components/AppDataTable';
import AppDropdown, { DropdownItem } from 'src/components/AppDropdown';
import AppInput from 'src/components/AppInput';
import PoolCard from 'src/components/PoolCard';
import { VisibilityGridPoolsEnum } from 'src/constants';
import BasePage from 'src/pages/BasePage';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/pages/Pools.module.scss';
import { PoolStatusEnum, PoolType } from 'src/utils/common';
import { PoolResponseType } from 'src/utils/pool';
import { getNetworkConfigs } from 'src/utils/utils-network';
import AppButton from 'src/components/AppButton';

interface IRequestPoolParam {
  searchKey?: string;
  type?: PoolType;
  network?: string;
  currencyId?: string;
  status?: string | number;
  recommended?: string;
  page?: string;
  limit?: number;
}

const LIMIT_PER_PAGE = 12;

const VISIBILITY_OPTIONS = [
  {
    value: VisibilityGridPoolsEnum.COLUMN,
    label: 'Columns',
    icon: <GridColumnIcon />,
  },
  {
    value: VisibilityGridPoolsEnum.ROW,
    label: 'List',
    icon: <GridRowIcon />,
  },
];

const STATUS_OPTIONS = [
  {
    value: '',
    label: 'All Status',
  },
  {
    value: PoolStatusEnum.UPCOMING,
    label: 'Upcoming',
  },
  {
    value: PoolStatusEnum.WHITELIST,
    label: 'Whitelist',
  },
  {
    value: PoolStatusEnum.TOKEN_SALE,
    label: 'Swap',
  },
  {
    value: PoolStatusEnum.CLAIMABLE,
    label: 'Reward Claim (IDO)',
  },
];

const TYPE_OPTIONS = [
  {
    value: PoolType.ALL_TYPE,
    label: 'All Type',
  },
  {
    value: PoolType.IDO,
    label: PoolType.IDO,
  },
  {
    value: PoolType.INO,
    label: PoolType.INO,
  },
];

const PagePoolList = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<VisibilityGridPoolsEnum>(
    VisibilityGridPoolsEnum.COLUMN,
  );

  const [param, setParam] = useState<IRequestPoolParam>({
    searchKey: '',
    status: '',
    network: '',
    type: PoolType.ALL_TYPE,
  });
  const [countFilter, setCountFilter] = useState<number>(0);
  const networks = getNetworkConfigs();

  const NETWORK_OPTIONS = networks.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  NETWORK_OPTIONS.unshift({
    value: '',
    label: 'All networks',
  });

  useEffect(() => {
    setCountFilter(
      Object.keys(param).filter((value) => (param as any)[value].length).length,
    );
  }, [param]);

  const handleSearch = (value: string) => {
    setParam((param) => ({
      ...param,
      searchKey: value,
    }));
  };

  const _renderSelectVisibilityDesktop = () => {
    return (
      <AppDropdown
        className={styles['dropdown-visibility']}
        value={visibility}
        onChange={setVisibility}
        options={VISIBILITY_OPTIONS}
        renderOptions={(item, index) => (
          <DropdownItem value={item.value} key={index}>
            <span className={styles['adornment-start']}>{item.icon}</span>
            <span className={styles['value-type-visibility']}>
              {item.label}
            </span>
          </DropdownItem>
        )}
      />
    );
  };

  const _renderSelectStatus = () => {
    return (
      <div className={styles['filter-contain']}>
        <div className={styles['filter-header']}>
          <div className={styles['label-filter']}>Status</div>
          <div
            className={styles['end-label-filter']}
            onClick={() => setParam((param) => ({ ...param, status: '' }))}
          >
            Clear
          </div>
        </div>
        <AppDropdown
          className={styles['select-filter']}
          variant="outlined"
          value={param.status}
          onChange={(e: any) =>
            setParam((param) => ({ ...param, status: e.toString() }))
          }
          options={STATUS_OPTIONS}
        />
      </div>
    );
  };

  const _renderSelectType = () => {
    return (
      <div className={styles['filter-contain']}>
        <div className={styles['filter-header']}>
          <div className={styles['label-filter']}>Type</div>
          <div
            className={styles['end-label-filter']}
            onClick={() =>
              setParam((param) => ({ ...param, type: PoolType.ALL_TYPE }))
            }
          >
            Clear
          </div>
        </div>
        <AppDropdown
          className={styles['select-filter']}
          variant="outlined"
          value={param.type}
          onChange={(e: any) =>
            setParam((param) => ({ ...param, type: e.toString() }))
          }
          options={TYPE_OPTIONS}
        />
      </div>
    );
  };

  const _renderSelectNetwork = () => {
    return (
      <div className={styles['filter-contain']}>
        <div className={styles['filter-header']}>
          <div className={styles['label-filter']}>Network</div>
          <div
            className={styles['end-label-filter']}
            onClick={() => setParam((param) => ({ ...param, network: '' }))}
          >
            Clear
          </div>
        </div>
        <AppDropdown
          className={styles['select-filter']}
          variant="outlined"
          value={param.network}
          onChange={(e: any) =>
            setParam((param) => ({ ...param, network: e.toString() }))
          }
          options={NETWORK_OPTIONS}
        />
      </div>
    );
  };

  return (
    <BasePage>
      <div className={`${styles['min-h-page']}`}>
        <div className={`${styles['pools']}`}>
          <div className={`${styles['pool-header']}`}>
            <div className={`${styles['title']}`}>
              <div className={`${styles['left']}`}>Pool List</div>
              <div className={`${styles['right']}`}>
                {!isMobile && (
                  <div className={styles['select-visibility']}>
                    {_renderSelectVisibilityDesktop()}
                  </div>
                )}
                <div className={`${styles['select-filter']}`}>
                  <AppButton
                    variant="quaternary"
                    sizes="big"
                    className={`${styles['btn-filter']} ${
                      openFilter ? styles['active'] : ''
                    }`}
                    onClick={() => setOpenFilter((openFilter) => !openFilter)}
                  >
                    {countFilter ? (
                      <span className={styles['badge-filter']}>
                        {countFilter}
                      </span>
                    ) : (
                      <FilterIcon />
                    )}
                    <span>Filters</span>
                  </AppButton>
                </div>
              </div>
            </div>
            <Collapse in={openFilter} timeout="auto" unmountOnExit>
              <div className={styles['collapse-filter']}>
                <div className={`${styles['filter-box']}`}>
                  <div className={`${styles['type-search']}`}>
                    <label className={`${styles['label']}`}>
                      <AppInput
                        placeholder="Search by Pool name"
                        handleChange={handleSearch}
                        value={param.searchKey}
                        startAdornment={<SearchIcon />}
                      />
                    </label>
                  </div>
                  <div className={`${styles['type-select']}`}>
                    {_renderSelectStatus()}
                  </div>
                  <div className={`${styles['type-select']}`}>
                    {_renderSelectType()}
                  </div>
                  <div className={`${styles['type-select']}`}>
                    {_renderSelectNetwork()}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <div className={`${styles['pool-body']}`}>
            <AppDataTable
              wrapperClassName={
                visibility === VisibilityGridPoolsEnum.COLUMN
                  ? `${styles['grid-col']} row`
                  : ''
              }
              requestParams={param}
              limit={LIMIT_PER_PAGE}
              fetchData={async (payload) => {
                try {
                  return await rf.getRequest('PoolRequest').getPools(payload);
                } catch (error: any) {
                  return { ...error, docs: [] };
                }
              }}
              renderBody={(data: PoolResponseType[]) =>
                data.map((pool: PoolResponseType) => (
                  <PoolCard
                    pool={pool}
                    key={pool.id}
                    type={
                      isMobile ? VisibilityGridPoolsEnum.COLUMN : visibility
                    }
                  />
                ))
              }
            />
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default PagePoolList;
