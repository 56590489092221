import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store';
import DropdownNetwork from '../components/DropdownNetwork';
import DropdownMyAccount from '../components/DropdownMyAccount';
import { Drawer, withWidth } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  BackIcon,
  DiscordIcon,
  MediumIcon,
  TelegramIcon,
  TwitterIcon,
  WebIcon,
  MenuLeftIcon,
} from 'src/assets/icons';
import styles from 'src/styles/layout/Header.module.scss';
import { withRouter } from 'react-router';
import config from 'src/config';
import AppButton from 'src/components/AppButton';
import { toggleConnectWalletModal } from 'src/store/authentication';
import { switchNetwork } from 'src/utils/utils-auth';
import useAuth from 'src/hooks/useAuth';
import Storage from 'src/utils/storage';
import { getErrorMessage } from 'src/utils/utils-helpers';
import { toastError } from 'src/utils/utils-notify';

interface menuInterface {
  link: string;
  text: string;
  authenticate: boolean;
}

const menuLinks: menuInterface[] = [
  {
    link: '/pools',
    text: 'Pools',
    authenticate: false,
  },
  {
    link: '/auctions',
    text: 'Auctions',
    authenticate: false,
  },
  {
    link: '/staking',
    text: 'Staking',
    authenticate: false,
  },
];

const TheHeader = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const {
    authentication: { address: currentAccount },
  } = useSelector((state: RootState) => state);

  const connectorId = Storage.getConnectorId();
  const { connectWallet } = useAuth();

  useEffect(() => {
    if (connectorId) {
      // auto-connect to wallet if wallet has been logged in after reloading page
      connectWallet(connectorId, network).catch((error) =>
        toastError({ message: getErrorMessage(error) }),
      );
    }
  }, [connectorId]);

  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [visibleBgMenu, setVisibleBgMenu] = useState<boolean>(
    window.scrollY > 32,
  );

  const location = useLocation();

  const toggleMenu = () => {
    isShowMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
    setIsShowMenu((isShowMenu) => !isShowMenu);
  };

  useEffect(() => {
    const onScrollPage = () => {
      setVisibleBgMenu(window.scrollY > 32);
    };
    document.addEventListener('scroll', onScrollPage);
    return () => {
      document.removeEventListener('scroll', onScrollPage);
    };
  }, [visibleBgMenu]);

  const _renderMenuOnMobile = () => {
    return (
      <Drawer
        anchor={'left'}
        open={isShowMenu}
        onClose={() => setIsShowMenu(false)}
      >
        <ul className={styles['left-menu']}>
          <div className={styles['header-sidebar']}>
            <BackIcon onClick={() => setIsShowMenu(false)} />
            <Link to={'/'} className={styles['logo']}>
              <img src={`/images/header/img-logo.svg`} alt="" />
            </Link>
          </div>
          {menuLinks.map((item, index) => (
            <li
              key={index}
              className={`${styles['sidebar-link']} ${
                (location.pathname === item.link ||
                  location.pathname.includes(item.link)) &&
                styles['active']
              }`}
              onClick={() => setIsShowMenu(false)}
            >
              <Link to={item.link}>
                {(location.pathname === item.link ||
                  location.pathname.includes(item.link)) && <span>•</span>}{' '}
                {item.text}
              </Link>
            </li>
          ))}

          <div className={styles['social-icons']}>
            <a
              href={config.socialLinks.medium}
              target="_blank"
              rel="noreferrer"
            >
              <MediumIcon />
            </a>
            <a
              href={config.socialLinks.telegram}
              target="_blank"
              rel="noreferrer"
            >
              <TelegramIcon />
            </a>
            <a
              href={config.socialLinks.twitter}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href={config.socialLinks.discord}
              target="_blank"
              rel="noreferrer"
            >
              <DiscordIcon />
            </a>
            <a href={config.socialLinks.globe} target="_blank" rel="noreferrer">
              <WebIcon />
            </a>
          </div>
        </ul>
      </Drawer>
    );
  };

  const { network } = useSelector((state: RootState) => state.authentication);

  const onChangeNetwork = (newNetwork: string) => {
    if (network !== newNetwork) {
      switchNetwork(newNetwork, user?.getProvider()).then(); // change network
    }
  };

  const _renderAccountConnect = () => {
    const handleClickConnectWallet = () => {
      dispatch(toggleConnectWalletModal(true));
    };
    return currentAccount ? (
      <>
        <DropdownNetwork
          value={network}
          onChange={onChangeNetwork}
          type={'header'}
          size={'small'}
          support={'signIn'}
          hiddenTextOnMobile
        />
        <div className={styles['the-account']}>
          <DropdownMyAccount />
        </div>
      </>
    ) : (
      <div className={styles['contain-connect']}>
        <AppButton
          sizes="medium"
          onClick={handleClickConnectWallet}
          type={'submit'}
        >
          Connect Wallet
        </AppButton>
      </div>
    );
  };

  return (
    <>
      <header
        className={`${styles['header-v2']} ${
          visibleBgMenu ? styles['visible-background'] : ''
        }`}
      >
        <nav className={styles['navbar-v2']}>
          <div className={styles['navbar-wrapper']}>
            <button
              type="button"
              className={styles['btn-toggle-menu']}
              onClick={toggleMenu}
            >
              <MenuLeftIcon />
            </button>

            {isShowMenu && _renderMenuOnMobile()}

            <div className={styles['header-logo']}>
              <Link to={'/'} className={styles['logo']}>
                <img src={`/images/header/img-logo.svg`} alt="" />
              </Link>
            </div>
            <div className={styles['header-right']}>
              <ul className={styles['navbar-menu']}>
                {menuLinks.map((item, index) => (
                  <li
                    key={index}
                    className={`${styles['navbar-link']} ${
                      location.pathname === item.link && styles['active']
                    }`}
                  >
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>
              <div className={styles['navbar-account']}>
                {_renderAccountConnect()}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default withWidth()(withRouter(TheHeader));
