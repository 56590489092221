import { getNetworkConfig } from './utils-network';
import React from 'react';

export type MediaType = {
  discordUrl?: string;
  facebookUrl?: string;
  mediumUrl?: string;
  telegramUrl?: string;
  twitterUrl?: string;
  websiteUrl?: string;
  youtubeUrl?: string;
  tiktokUrl?: string;
};

export type AdvisorType = {
  avatar: string;
  fullName: string;
  intro: string;
};

export type BackerType = {
  logoUrl: string;
  name: string;
};

export type RoadMapType = {
  title: string;
  description: string;
  startTime: number;
  endTime: number;
};

export type TeamType = {
  avatar: string;
  fullName: string;
  intro: string;
  position: string;
};

export type KeyMetricsType = {
  ticker?: string;
  totalSupply?: string;
  publicRaise?: string;
  networks?: [];
  valuation?: string;
  initialMarketCap?: string;
};

export type TokenomicType = {
  title: string;
  description: string;
  totalAllocation: string;
  price?: string;
};

export type NetworkLink = {
  networkFamily: string;
  connected: boolean;
  icon: React.ReactNode;
  domain: string;
  network: string;
  walletAddress: string;
  onDisconnect?: () => Promise<any>;
};

export interface ProjectInterface {
  id: string;
  name: string;
  overview: string;
  media: MediaType;

  setId(id: string): void;
  getId(): string;
  setName(name: string): void;
  getName(): string;
  setOverview(overview: string): void;
  getOverview(): string;
  setMedia(media: MediaType): void;
  getMedia(): MediaType;
  setAdvisors(advisors: AdvisorType[]): void;
  getAdvisors(): AdvisorType[];
  setBackers(backers: BackerType[]): void;
  getBackers(): BackerType[];
  setRoadMaps(roadMaps: RoadMapType[]): void;
  getRoadMaps(): RoadMapType[];
  setTeams(teams: TeamType[]): void;
  getTeams(): TeamType[];
  setKeyMetrics(keyMetrics: KeyMetricsType): void;
  getKeyMetrics(): KeyMetricsType;
  getNetworkAsString(): string;
  setTokenomics(tokenomics: TokenomicType[]): void;
  getTokenomics(): TokenomicType[];
}

export class Project implements ProjectInterface {
  public id = '';
  public name = '';
  public overview = '';
  public media: MediaType = {};
  public advisors: AdvisorType[] = [];
  public backers: BackerType[] = [];
  public roadMaps: RoadMapType[] = [];
  public teams: TeamType[] = [];
  public keyMetrics: KeyMetricsType = {};
  public tokenomics: TokenomicType[] = [];

  constructor(project: any) {
    if (!project) {
      return;
    }
    this.setId(project.id);
    this.setName(project.name);
    this.setMedia(project.media);
    this.setOverview(project.info.overview);
    this.setAdvisors(project.info.advisors);
    this.setBackers(project.info.backers);
    this.setRoadMaps(project.info.roadmap);
    this.setTeams(project.info.team);
    this.setKeyMetrics(project.info.keyMetrics);
    this.setTokenomics(project.info.tokenomics);
  }

  setId(id: string) {
    this.id = id;
  }
  getId(): string {
    return this.id;
  }
  setName(name: string) {
    this.name = name;
  }
  getName(): string {
    return this.name;
  }
  setOverview(overview: string) {
    this.overview = overview;
  }
  getOverview(): string {
    return this.overview;
  }
  setMedia(media: MediaType) {
    this.media = media;
  }
  getMedia(): MediaType {
    return this.media;
  }
  setTokenomics(tokenomics: TokenomicType[]) {
    this.tokenomics = tokenomics;
  }
  getTokenomics(): TokenomicType[] {
    return this.tokenomics;
  }
  setKeyMetrics(keyMetrics: KeyMetricsType) {
    this.keyMetrics = keyMetrics;
  }
  getKeyMetrics(): KeyMetricsType {
    return this.keyMetrics;
  }
  getNetworkAsString(): string {
    const networks = this.keyMetrics.networks;
    if (!networks || !networks.length) {
      return '--';
    }
    const networkNames =
      networks.map((network) => {
        const networkConfig = getNetworkConfig(network);
        return networkConfig?.name;
      }) || [];
    return networkNames.join('|');
  }
  setAdvisors(advisors: AdvisorType[]) {
    this.advisors = advisors;
  }
  getAdvisors(): AdvisorType[] {
    return this.advisors;
  }
  setBackers(backers: BackerType[]) {
    return (this.backers = backers);
  }
  getBackers(): BackerType[] {
    return this.backers;
  }
  setRoadMaps(roadMaps: RoadMapType[]) {
    return (this.roadMaps = roadMaps);
  }
  getRoadMaps(): RoadMapType[] {
    return this.roadMaps;
  }
  setTeams(teams: TeamType[]) {
    return (this.teams = teams);
  }
  getTeams(): TeamType[] {
    return this.teams;
  }
}
