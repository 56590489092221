import { InjectedConnector } from '@web3-react/injected-connector';
import BaseConnector from './BaseConnector';

declare global {
  interface Window {
    ethereum: any;
  }
}

const isInstalled = () => {
  return !!window.ethereum;
};

class MetamaskConnector extends BaseConnector {
  getName() {
    return 'MetaMask';
  }

  getId(): string {
    return 'metamask-connector';
  }

  /**
   * connect wallet
   * @returns provider
   */
  async connect() {
    if (!isInstalled()) {
      return null;
    }
    const connector = new InjectedConnector(this.options);
    await connector.activate();
    const provider = await connector.getProvider();
    this.connector = connector;
    this.provider = provider;
    return provider;
  }
}

export default MetamaskConnector;
