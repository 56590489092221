import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import styles from 'src/styles/components/CardStakingUser.module.scss';
import {
  formatShortAddress,
  formatTimestamp,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import { getValueStaked } from '../pages/PageStaking/parts/PartDiamondUsers';

const StakingCardUser = (props: any) => {
  const { index, user } = props;
  const [open, setOpen] = useState(false);
  const { stakedBpt, stakedBuni } = getValueStaked(user);

  return (
    <div className={styles['card-staking']}>
      <div
        onClick={() => setOpen((open) => !open)}
        className={styles['card-staking-header']}
      >
        <div className={styles['flex-header']}>
          <span className={styles['icon-position']}>{index + 1}</span>
          <h5 className={styles['title']}>
            {formatShortAddress(user.userAddress)}
          </h5>
          <span
            className={`${styles['icon-collapse']} ${
              !open ? styles['collapsed'] : ''
            }`}
          />
        </div>
      </div>
      <div className={styles['card-staking-subheader']}>
        <label className={styles['label']}>Total BUNI Value</label>
        <h6 className={styles['text']}>
          {formatWeiNumber(user.amountStaked)} BUNI
        </h6>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={styles['card-staking-body']}>
          <div className={styles['card-staking-item']}>
            <label className={styles['label']}>BPT Staked</label>
            <h6 className={styles['text']}>
              {formatWeiNumber(stakedBpt)} BPT
            </h6>
          </div>
          <div className={styles['card-staking-item']}>
            <label className={styles['label']}>BUNI Staked</label>
            <h6 className={styles['text']}>
              {formatWeiNumber(stakedBuni)} BUNI
            </h6>
          </div>
          <div className={styles['card-staking-item']}>
            <label className={styles['label']}>Last Updated</label>
            <h6 className={styles['text']}>
              {formatTimestamp(user.lastTimeStaked, 'hh:mm - YYYY/MM/DD')}
            </h6>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default StakingCardUser;
