import styles from 'src/styles/components/PoolList.module.scss';
import { formatWeiNumber } from 'src/utils/utils-formats';
import { BuniLogo, BNBLogo, ArrowNextIcon } from 'src/assets/icons';
import { withRouter } from 'react-router';
import { withWidth } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import BigNumber from 'bignumber.js';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import useFarm from 'src/hooks/useFarm';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const PartStakingPoolList = () => {
  const { metadataStakingPools } = useSelector(
    (state: RootState) => state.metadata,
  );
  const { user } = useAuth();
  const { getStakingPools, getUserStakingPools } = useFarm();
  const [stakingPools, setStakingPools] = useState([]) as any;
  const [selectedPool, setSelectedPool] = useState([]) as any;
  const [userStakingPools, setUserStakingPools] = useState([]) as any;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  useEffect(() => {
    if (!metadataStakingPools?.length) {
      return;
    }
    getStakingPools().then((payload) => {
      setStakingPools(payload);
      setIsLoadingData(false);
    });
  }, [metadataStakingPools?.length]);

  useEffect(() => {
    fetchUserStakingPools();

    AppBroadcast.on('LOAD_USER_INFORMATION', () => {
      fetchUserStakingPools();
    });
    return () => {
      AppBroadcast.remove('LOAD_USER_INFORMATION');
    };
  }, [user?.getAddress()]);

  const filterUserStakingPool = (stakingPools: any, poolAddress: string) => {
    if (!poolAddress || !userStakingPools) {
      return null;
    }
    return stakingPools.find(
      (stakingPool: any) =>
        stakingPool?.poolAddress.toLowerCase() === poolAddress.toLowerCase(),
    );
  };

  const fetchUserStakingPools = () => {
    if (!user?.getAddress()) {
      return;
    }
    getUserStakingPools().then((payload) => {
      setUserStakingPools(payload);
    });
  };

  const calcMyLiquidityInPool = (stakingPool: any, userStakingPool: any) => {
    if (!userStakingPool) {
      return '0';
    }
    return new BigNumber(stakingPool.lpPrice)
      .times(userStakingPool.stakedAmount || '0')
      .toString();
  };

  const selectStakingPool = (pool: any) => {
    setSelectedPool(pool);
    AppBroadcast.dispatch(`OPEN_DETAIL_STAKING_POOL`, pool);
  };

  const _renderPoolForDesktop = (pool: any) => {
    const userStakedPool = filterUserStakingPool(
      userStakingPools,
      pool.poolAddress,
    );
    return (
      <div
        key={pool?.poolAddress}
        onClick={() => {
          selectStakingPool(pool);
        }}
        className={
          styles['pool-item'] +
          ' row ' +
          styles[selectedPool.poolAddress === pool.poolAddress ? 'active' : '']
        }
      >
        <div className={styles['cell'] + ' col-3'}>
          <BuniLogo /> {pool?.lpSymbol?.includes('BNB') && <BNBLogo />}
          <span style={{ marginLeft: '20px' }}>{pool?.lpSymbol}</span>
        </div>
        <div className={styles['cell'] + ' col-3'}>
          {formatWeiNumber(userStakedPool?.pendingReward)}
        </div>
        <div className={styles['cell'] + ' col-3'}>
          <>{formatWeiNumber(userStakedPool?.stakedAmount)}</>{' '}
          {pool?.lpSymbol?.includes('BNB') ? 'BPT' : pool?.lpSymbol}
          <div className={styles['price']}>
            ${formatWeiNumber(calcMyLiquidityInPool(pool, userStakedPool))}
          </div>
        </div>
        <div className={styles['cell'] + ' col-3'}>
          ${formatWeiNumber(pool.totalLiquidity)}
        </div>
      </div>
    );
  };

  const _renderPoolForMobile = (pool: any) => {
    const userStakedPool = filterUserStakingPool(
      userStakingPools,
      pool.poolAddress,
    );
    return (
      <div key={pool?.poolAddress} className={styles['pool-item']}>
        <div className={styles['pool-contain']}>
          <div
            className={styles['pool-header']}
            onClick={() => selectStakingPool(pool)}
          >
            <div className={styles['pool-title']}>
              <div className={styles['pool-intro']}>
                <span className={styles['pool-icon']}>
                  <BuniLogo />
                </span>
                {pool?.lpSymbol?.includes('BNB') && (
                  <span className={styles['pool-icon']}>
                    <BNBLogo />
                  </span>
                )}

                <h3 className={styles['pool-name']}>{pool?.lpSymbol}</h3>
              </div>
              <button className={styles['arrow-detail']}>
                <ArrowNextIcon />
              </button>
            </div>
          </div>
          <div className={styles['pool-body']}>
            <div className={styles['pool-row']}>
              <div className={styles['pool-col']}>
                <label className={styles['pool-label']}>Earned</label>
              </div>
              <div className={styles['pool-col']}>
                <div className={styles['pool-value']}>
                  {formatWeiNumber(userStakedPool?.pendingReward)}
                </div>
              </div>
            </div>
            <div className={styles['pool-row']}>
              <div className={styles['pool-col']}>
                <label className={styles['pool-label']}>My Stake</label>
              </div>
              <div className={styles['pool-col']}>
                <div className={styles['pool-value']}>
                  <>{formatWeiNumber(userStakedPool?.stakedAmount)}</>{' '}
                  {pool?.lpSymbol?.includes('BNB') ? 'BPT' : pool?.lpSymbol}
                </div>
                <div className={styles['pool-value-currency']}>
                  $
                  {formatWeiNumber(calcMyLiquidityInPool(pool, userStakedPool))}
                </div>
              </div>
            </div>
            <div className={styles['pool-row']}>
              <div className={styles['pool-col']}>
                <label className={styles['pool-label']}>Liquidity</label>
              </div>
              <div className={styles['pool-col']}>
                <div className={styles['pool-value']}>
                  ${formatWeiNumber(pool.totalLiquidity)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderForDesktop = () => {
    return (
      <div className={styles['pool-list']}>
        <div className={styles['header']}>Pool List</div>
        <div className={styles['content']}>
          <div className={styles['table']}>
            <div className={styles['thead'] + ' row'}>
              <div className={styles['cell'] + ' col-3'}>Pools</div>
              <div className={styles['cell'] + ' col-3'}>Earned</div>
              <div className={styles['cell'] + ' col-3'}>My Stake</div>
              <div className={styles['cell'] + ' col-3'}>Liquidity</div>
            </div>
            <div>
              {isLoadingData && _renderShowLoadingIcon()}
              {!isLoadingData &&
                stakingPools.map((stakingPool: any) => {
                  return _renderPoolForDesktop(stakingPool);
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderForMobile = () => {
    return (
      <section className={styles['pool-list-mobile']}>
        <div className={styles['pool-list-contain']}>
          {isLoadingData && _renderShowLoadingIcon()}
          {!isLoadingData &&
            stakingPools.map((stakingPool: any) => {
              return _renderPoolForMobile(stakingPool);
            })}
        </div>
      </section>
    );
  };

  const _renderShowLoadingIcon = () => {
    return (
      <div className={styles['loading']}>
        <LoadingIcon width={100} height={100} />
      </div>
    );
  };

  return isMobile ? _renderForMobile() : _renderForDesktop();
};

export default withWidth()(withRouter(PartStakingPoolList));
