import { FC } from 'react';
import PartOverview from 'src/pages/PageStaking/parts/PartOverview';
import BaseModal, { BaseModalProps } from './BaseModal';

interface ModalUserStakingOverviewProps extends BaseModalProps {
  onClose?: () => void;
}

const ModalUserStakingOverview: FC<ModalUserStakingOverviewProps> = ({
  onClose,
  ...props
}) => {
  return (
    <BaseModal {...props} title={'Overview'} onClose={onClose} fullScreenMobile>
      <PartOverview />
    </BaseModal>
  );
};

export default ModalUserStakingOverview;
