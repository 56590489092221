import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import styles from 'src/styles/modals/ModalLinkAccount.module.scss';
import config, { Connector } from 'src/config';
import { RootState } from 'src/store';
import { addLinkedProvider } from 'src/store/myAccount';
import ConnectorFactory, { ConnectorType } from 'src/connectors';
import { ILinkedAccount } from 'src/utils/common';
import { ButtonConnectWallet } from './ModalLinkAccount';
import CopyableWalletAddress from 'src/components/CopyableWalletAddress';
import { SolanaIcon } from 'src/assets/icons';
import { toast } from 'react-toastify';
import { toastInfo } from 'src/utils/utils-notify';
import useAuth from 'src/hooks/useAuth';
import BaseModal, { BaseModalProps } from './BaseModal';

interface ModalLinkAccountProps extends BaseModalProps {
  network: string;
  onClose: () => void;
}

const ModalConnectSolana: FC<ModalLinkAccountProps> = (props) => {
  const { network, onClose, ...rest } = props;
  const { user } = useAuth();

  const connectorsByNetwork = network
    ? config.networks[network]?.connectors
    : [];

  const { network: networkStore } = useSelector(
    (state: RootState) => state.authentication,
  );

  const dispatch = useDispatch();

  const linkAccountToSolana = async (connector: ConnectorType) => {
    const linkedAddress = await connector.getAccount();
    if (
      user &&
      !user
        .getLinkedAccounts()
        .some(
          (item: ILinkedAccount) =>
            item.networkFamily === config.networks[network]?.networkFamily &&
            item.networkAddress === linkedAddress,
        )
    ) {
      // if user hasn't linked to Solana yet
      toastInfo({ message: 'Please link Solana account in My Profile' });
      return;
    }
    dispatch(
      addLinkedProvider({
        userAddress: user?.getAddress(),
        networkAddress: linkedAddress,
        networkFamily: config.networks[network].networkFamily,
        provider: connector,
      }),
    );
  };

  const onConnectWallet = async (wallet: Connector) => {
    if (!network) {
      return;
    }
    try {
      const connector = ConnectorFactory.getConnector(
        wallet.id,
        config.networks[network].id,
      );
      await connector.connect();
      await linkAccountToSolana(connector);
      onClose();
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  return (
    <BaseModal title="Connect wallet" onClose={onClose} {...rest}>
      <div className={styles['wallet-connector']}>
        <div className={styles['icon-brand']}>
          <SolanaIcon />
        </div>
        <div className={styles['wallet-address']}>
          <span className={styles['copy-text']}>
            {networkStore.toLowerCase()}:
          </span>
          <CopyableWalletAddress
            walletAddress={user?.getAddress() || ''}
            bold
          />
        </div>
        <div className={styles['choose-wallet']}>
          <p className={styles['choose-wallet-title']}>Choose a Wallet</p>
          <div className={styles['group-btn-wallet']}>
            {map(connectorsByNetwork, (wallet: Connector) => (
              <ButtonConnectWallet
                wallet={wallet}
                onConnectWallet={() => {
                  onConnectWallet(wallet);
                }}
                key={wallet.id}
              />
            ))}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalConnectSolana;
