import { RouteComponentProps, withRouter } from 'react-router';
import { PoolStatus } from 'src/constants';
import styles from 'src/styles/components/CardTimeLine.module.scss';
import { formatTimestamp } from 'src/utils/utils-formats';
import {
  ClaimPhaseType,
  TimelineInterface,
  WhitelistPhaseType,
} from 'src/utils/timelines';
import { PHASES, getIDOPhaseClassName } from 'src/utils/utils-pool';
import clsx from 'clsx';

interface CardTimeLineProps extends RouteComponentProps {
  timeline: TimelineInterface | null;
  typePool?: 'IDO' | 'INO';
  className?: string;
}

const CardTimeLine = (props: CardTimeLineProps) => {
  const { timeline, typePool = 'IDO', className } = props;
  const TIME_FORMAT = 'HH:mm YYYY/MM/DD';

  if (!timeline) {
    return null;
  }

  const whitelistPhase: WhitelistPhaseType = timeline.getWhitelistPhase();
  const { startTime: swapStartTime, endTime: swapEndTime } =
    timeline.getSwapTime();
  const claimPhase: ClaimPhaseType = timeline.getClaimPhase();

  return (
    <div className={clsx(styles['card'], className)}>
      <div className={styles['next-time']}>
        <div
          className={`${styles['step']} ${
            styles[getIDOPhaseClassName(timeline, PHASES.UPCOMING)]
          }`}
        >
          <span className={styles['check-step-circle']} />
          <div className={styles['label']}>
            <span>{PoolStatus.Upcoming}</span>
          </div>
        </div>
        <div
          className={`${styles['step']} ${
            styles[getIDOPhaseClassName(timeline, PHASES.WHITE_LIST)]
          }`}
        >
          <span className={styles['check-step-circle']} />
          <div className={styles['label']}>
            <span>{PoolStatus.Whitelist}</span>
          </div>
          <div className={styles['content']}>
            <div className={styles['start']}>
              Start from:{' '}
              {formatTimestamp(whitelistPhase.startTime, TIME_FORMAT)}
            </div>
            <div className={styles['end']}>
              End in: {formatTimestamp(whitelistPhase.endTime, TIME_FORMAT)}
            </div>
          </div>
        </div>
        <div
          className={`${styles['step']} ${
            styles[getIDOPhaseClassName(timeline, PHASES.TOKEN_SALE)]
          }`}
        >
          <span className={styles['check-step-circle']} />
          <div className={styles['label']}>
            <span>{PoolStatus.Swap}</span>
          </div>
          <div className={styles['content']}>
            <div className={styles['start']}>
              Start from: {formatTimestamp(swapStartTime, TIME_FORMAT)}
            </div>
            <div className={styles['end']}>
              End in: {formatTimestamp(swapEndTime, TIME_FORMAT)}
            </div>
          </div>
        </div>
        <div
          className={`${styles['step']} ${
            styles[getIDOPhaseClassName(timeline, PHASES.CLAIM)]
          }`}
        >
          <span className={styles['check-step-circle']} />
          <div className={styles['label']}>
            <span>
              {typePool === 'IDO' ? PoolStatus.Claim : PoolStatus.End}
            </span>
          </div>
          <div className={styles['content']}>
            <div className={styles['start']}>
              {typePool === 'IDO'
                ? `Start from: ${formatTimestamp(
                    claimPhase.startTime,
                    TIME_FORMAT,
                  )}`
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(CardTimeLine);
