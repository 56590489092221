import styles from 'src/styles/modals/ModalTransaction.module.scss';
import { toggleTransactionModal } from 'src/store/transactions';
import { TransactionIcon } from 'src/assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import config from 'src/config';
import BaseModal from './BaseModal';
import { RootState } from 'src/store';

const ModalViewSubmittedTx = () => {
  const dispatch = useDispatch();
  const { openModalTransaction, transaction } = useSelector(
    (state: RootState) => state.transactions,
  );
  const { network } = useSelector((state: RootState) => state.authentication);

  const getTxUrl = (txHash: string) => {
    const blockExplorerUrl = config.networks[network]?.blockExplorer.url;
    return `${blockExplorerUrl}tx/${txHash}`;
  };

  const blockExplorerName = config.networks[network]?.blockExplorer.name;

  return (
    <BaseModal
      open={openModalTransaction}
      onClose={() => dispatch(toggleTransactionModal(false))}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      title={'Transaction submitted'}
    >
      <div className={styles['main']}>
        <TransactionIcon />
        <div className={styles['link']}>
          <a href={getTxUrl(transaction.hash)} target="_blank">
            View on <span>{blockExplorerName}</span>
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalViewSubmittedTx;
