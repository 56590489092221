import { Interface } from '@ethersproject/abi';
import config from 'src/config';
import { getNetworkProvider } from 'src/utils/utils-network';
import { multicallContract } from 'src/utils/utils-contract';

export async function multicall(
  abi: any,
  calls: any,
  network = config.supportStakingNetwork,
) {
  const MULTICALL_ADDRESS = config.networks[network].addresses.multicall;
  const provider = getNetworkProvider(network);
  try {
    const multi = multicallContract(MULTICALL_ADDRESS, provider);
    const itf = new Interface(abi);
    const calldata = calls.map((call: any) => {
      return [
        call.address.toLowerCase(),
        itf.encodeFunctionData(call.name, call.params),
      ];
    });
    //@ts-ignore
    const { returnData } = await multi.aggregate(calldata);
    const res = returnData.map((call: any, i: any) =>
      itf.decodeFunctionResult(calls[i].name, call),
    );

    return res;
  } catch (e) {
    console.error('[multicall]', e);
  }
}
