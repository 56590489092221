import React, { useEffect, useMemo, useState } from 'react';
import { IDOPool, INOPool } from 'src/utils/pool';
import useAuth from './useAuth';
import rf from 'src/requests/RequestFactory';
import BigNumber from 'bignumber.js';
import { formatTimestamp } from 'src/utils/utils-formats';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { PhaseType } from '../utils/timelines';

export type WinnerType = {
  id: string;
  poolId: string;
  type: string;
  candidate: string;
  userAddress: string;
  userTier: string;
  ticket: number;
  allocationSize: string;
};

export type IconType = 'info' | 'warning';

export const usePhaseInfo = (pool: INOPool | IDOPool) => {
  const timeline = pool.getTimeline();
  const { token: jwtToken } = useSelector(
    (state: RootState) => state.authentication,
  );
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
  const [winner, setWinner] = useState<WinnerType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  const userAllocation = useMemo(() => {
    let allocation = 0;
    if (!user || !isWhitelisted) {
      return allocation;
    }
    if (winner) {
      allocation = Number(winner?.allocationSize);
    }
    if (timeline?.isPublicSwapPhase()) {
      allocation = pool?.getFreeBuyAllocation();
    }
    return allocation;
  }, [user?.getAddress(), pool?.getId(), winner]);

  useEffect(() => {
    if (!pool.getId() || !user || !jwtToken) return;
    const onCheckCondition = async () => {
      try {
        setLoading(true);
        const resWinner = (await rf
          .getRequest('PoolRequest')
          .getWinners(pool.getId())) as any;
        const winnerUser = resWinner.docs.find(
          (item: WinnerType) => item.userAddress === user.getAddress(),
        );
        const resWhitelist = (await rf
          .getRequest('PoolRequest')
          .getWhiteListParticipants(pool.getId())) as any;
        setIsWhitelisted(
          resWhitelist.docs.some(
            (item: any) => item.userAddress === user.getAddress(),
          ),
        );
        setWinner(winnerUser);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    onCheckCondition();
  }, [user, pool, jwtToken]);
  const getMessage = (): {
    iconType: IconType;
    message: string;
  } => {
    if (!user) {
      return {
        iconType: 'warning',
        message: 'Connect your wallet to apply whitelist and join swap.',
      };
    }
    if (!pool?.isKYCValid(user)) {
      return {
        iconType: 'warning',
        message: 'Complete KYC to join token sale.',
      };
    }
    if (!pool.isNetworkCorrect(user)) {
      return {
        iconType: 'warning',
        message:
          'Please switch to the required network to apply whitelist, purchase and claim boxes',
      };
    }
    if (!pool.isTierEligible(user) && timeline?.isWhitelistPhase()) {
      return {
        iconType: 'warning',
        message:
          'Your current tier does not meet pool’s requirements. View your profile and upgrade your Tier to apply whitelist of this project.',
      };
    }

    if (timeline?.beforeWhitelistPhase()) {
      return {
        iconType: 'info',
        message: 'Please wait until Whitelist time to apply to join',
      };
    }

    // phase whitelist
    if (timeline?.isWhitelistPhase()) {
      const privateSwapPhase: PhaseType = timeline.getPrivateSwapPhase();
      if (isWhitelisted) {
        return {
          iconType: 'info',
          message: `You applied whitelist. Please wait for the winner list and allocation to
        be announced on 
        ${formatTimestamp(privateSwapPhase.startTime, 'DD/MM/YYYY')}`,
        };
      } else {
        return {
          iconType: 'info',
          message: 'You are qualified to apply whitelist',
        };
      }
    }
    if (
      ((timeline?.afterWhitelistPhase() && timeline?.beforeSwapPhase()) ||
        timeline?.isSwapPhase()) &&
      !pool.isPoolDeployed()
    ) {
      const privateSwapPhase: PhaseType = timeline.getPrivateSwapPhase();
      if (isWhitelisted) {
        return {
          iconType: 'info',
          message: `You applied whitelist. Please wait for the winner list and allocation to
        be announced on
        ${formatTimestamp(privateSwapPhase.startTime, 'DD/MM/YYYY')}`,
        };
      } else {
        return {
          iconType: 'warning',
          message:
            'You are not a participant of this project; \nStay tuned and follow other projects to take part in another sales',
        };
      }
    }

    if (
      timeline?.afterWhitelistPhase() &&
      timeline?.beforeSwapPhase() &&
      pool.isPoolDeployed()
    ) {
      if (isWhitelisted) {
        return {
          iconType: 'info',
          message: 'Please wait for Sale start time',
        };
      } else if (!isWhitelisted) {
        return {
          iconType: 'warning',
          message:
            'You are not a participant of this project; \nStay tuned and follow other projects to take part in another sales',
        };
      }
    }

    if (timeline?.isPrivateSwapPhase()) {
      if (pool.isTierUnstaked(user, winner) && winner) {
        return {
          iconType: 'warning',
          message: `Your staking tier has been reduced before the Sale ends. Thus, you cannot purchase the boxes allocated to you.\n  You can still claim the boxes purchased prior to tier reduction and join FCFS sale after Sales for allocation slots end`,
        };
      }
      if (winner) {
        return {
          iconType: 'info',
          message: 'You can purchase the boxes',
        };
      } else if (!winner && isWhitelisted) {
        return {
          iconType: 'info',
          message:
            'Sorry, you are not in the buyer list of this project; you can join FCFS sale after Sales for allocation slots end',
        };
      } else if (!isWhitelisted) {
        return {
          iconType: 'warning',
          message:
            'You are not a participant of this project;\r Stay tuned and follow other projects to take part in another sales',
        };
      }
    }

    if (timeline?.isPublicSwapPhase()) {
      if (!isWhitelisted) {
        return {
          iconType: 'warning',
          message:
            'You are not a participant of this project;\n Stay tuned and follow other projects to take part in another sales',
        };
      }
      if (isWhitelisted) {
        return {
          iconType: 'info',
          message: 'You can purchase the boxes',
        };
      }
    }

    if (timeline?.afterSwapPhase()) {
      return {
        iconType: 'info',
        message: 'This INO pool has ended.',
      };
    }

    //end

    return {
      iconType: 'info',
      message: '',
    };
  };

  const isShowUserAllocation = (): boolean => {
    if (!user || !timeline?.isSwapPhase()) {
      return false;
    }
    if (!isWhitelisted) {
      return false;
    }
    if (timeline?.isPrivateSwapPhase()) {
      return !!winner;
    }
    return true;
  };

  return {
    getMessage,
    isShowUserAllocation,
    winner,
    userAllocation,
    isWhitelisted,
    loading,
  };
};
