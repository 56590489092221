import styles from 'src/styles/modals/ModalStuck.module.scss';
import { FC, useRef } from 'react';
import AppButton from 'src/components/AppButton';
import AppInput from 'src/components/AppInput';
import BaseModal from './BaseModal';
import { createValidator } from '../utils/utils-validator';

interface ModalStuckProps {
  open: boolean;
  isSuccess: boolean;
  isError: boolean;
  onClose: () => void;
  onSubmit: (txHash: string) => void;
  setTxHash: (value: string) => void;
  txHash: string;
}

const ModalStuck: FC<ModalStuckProps> = ({
  open,
  onClose,
  onSubmit,
  isSuccess,
  isError,
  setTxHash,
  txHash,
}) => {
  const onConfirm = () => {
    onSubmit(txHash);
  };

  const validator = useRef(
    createValidator({
      element: (message: string) => (
        <div className={styles['helper-text']}>{message}</div>
      ),
    }),
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <BaseModal title={'Stuck?'} open={open} onClose={handleClose}>
      <>
        <div className={styles['main']}>
          <div className={styles['box-message']}>
            Please reset with the latest tx hash from your wallet to continue.
          </div>

          <div className={styles['label']}>
            Stuck? Please paste the latest tx hash from your wallet once it
            completes.
          </div>
          <AppInput
            type="text"
            value={txHash}
            handleChange={(value: string) => setTxHash(value.trim())}
            placeholder="0x122..."
            validate={{
              name: 'txHash',
              validator: validator.current,
              rule: 'required',
            }}
            endAdornment={
              <div className={styles['adornment-end']}>
                <div className={styles['slot']}>
                  <AppButton
                    sizes={'small'}
                    onClick={() => onConfirm()}
                    isDisable={!txHash}
                  >
                    Reset
                  </AppButton>
                </div>
              </div>
            }
          />
          <div className={styles['text-waring']}>
            {isSuccess && 'Successfully reset!'}
            {txHash && isError && 'Reset unsuccessfully! Please try again.'}
          </div>
        </div>
      </>
    </BaseModal>
  );
};

export default ModalStuck;
