import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import styles from 'src/styles/pages/PageStaking/PartOverview.module.scss';
import { formatWeiNumber, formatNumber } from 'src/utils/utils-formats';
import { withRouter } from 'react-router';
import { withWidth } from '@material-ui/core';
import { getTokenBuniPrice } from 'src/utils/utils-farm';
import { useEffect, useState } from 'react';
import { NOT_AVAILABLE_TEXT } from 'src/utils/common';
import BigNumber from 'bignumber.js';
import { isMobile } from 'react-device-detect';
import useAuth from '../../../hooks/useAuth';

const PartOverview = () => {
  const {
    stakedInfo,
    userInfo: { buniToNextTier },
  } = useSelector((state: RootState) => state.myAccount);

  const { user } = useAuth();
  const { lpRate: buniLPRatio } = useSelector(
    (state: RootState) => state.metadata,
  );

  const { buniStake, lpStake } = stakedInfo;
  const [tokenPrice, setTokenPrice] = useState<string>('0');
  const [totalBuniValue, setTotalBuniValue] = useState<string>('0');
  const [myLiquidity, setMyLiquidity] = useState<string>('0');

  useEffect(() => {
    setTotalBuniValue(getTotalBuniValue(lpStake, buniStake));
  }, [lpStake, buniStake, buniLPRatio]);

  useEffect(() => {
    setMyLiquidity(getMyLiquidity(lpStake, buniStake));
  }, [lpStake, buniStake, buniLPRatio, tokenPrice]);

  const getTotalBuniValue = (lpStake: string, buniStake: string) => {
    return new BigNumber(lpStake)
      .multipliedBy(buniLPRatio || 0)
      .plus(buniStake)
      .toString();
  };

  const getMyLiquidity = (lpStake: string, buniStake: string) => {
    const totalBuniValue = getTotalBuniValue(lpStake, buniStake);
    return new BigNumber(totalBuniValue).multipliedBy(tokenPrice).toString();
  };

  useEffect(() => {
    getTokenBuniPrice().then((buniPrice) => {
      setTokenPrice(buniPrice.price);
    });
  }, []);

  const _renderForDesktop = () => {
    return (
      <>
        <div className={styles['header']}>
          <div className={styles['title']}>Overview</div>

          <div className={styles['conversation-rate']}>
            (Conversation Rate: 1 BPT = {formatNumber(buniLPRatio)} BUNI)
          </div>
        </div>
        <div className={`${styles['content']} row`}>
          <div className="col-md-3">
            <div className={styles['info']}>
              <div className={styles['title']}>
                <span className={styles['dot']} />
                <span> Current Tier</span>
              </div>

              <div className={styles['value']}>
                {user ? user.getTierAsString() : NOT_AVAILABLE_TEXT}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className={styles['info']}>
              <div className={styles['title']}>
                <span className={styles['dot']} />
                <span>BUNI to Next Tier</span>
              </div>

              <div className={styles['value']}>
                {formatWeiNumber(buniToNextTier?.toString() || '')} BUNI
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className={styles['info']}>
              <div className={styles['title']}>
                <span className={styles['dot']} />
                <span>Total Buni Value</span>
              </div>

              <div className={styles['value']}>
                {formatWeiNumber(totalBuniValue)} BUNI
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className={styles['info']}>
              <div className={styles['title']}>
                <span className={styles['dot']} />
                <span>My Liquidity</span>
              </div>

              <div className={styles['value']}>
                $ {formatWeiNumber(myLiquidity)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const _renderForMobile = () => {
    return (
      <ul className={styles['list-content']}>
        <li className={styles['item']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>Current Tier</span>
          </div>
          <div className={styles['value']}>
            <span className={styles['text']}>{user?.getTierAsString()}</span>
          </div>
        </li>
        <li className={styles['item']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>BUNI to Next Tier</span>
          </div>
          <div className={styles['value']}>
            <span className={styles['text']}>
              {formatWeiNumber(buniToNextTier?.toString() || '')} BUNI
            </span>
          </div>
        </li>
        <li className={styles['item']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>Total Buni Value</span>
          </div>
          <div className={styles['value']}>
            <span className={styles['text']}>
              {formatWeiNumber(totalBuniValue)} BUNI
            </span>
          </div>
        </li>
        <li className={styles['item']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>My Liquidity</span>
          </div>
          <div className={styles['value']}>
            <span className={styles['text']}>
              $ {formatWeiNumber(myLiquidity)}
            </span>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <>
      {isMobile ? (
        <div>{_renderForMobile()}</div>
      ) : (
        <div className={styles['overview']}>{_renderForDesktop()}</div>
      )}
    </>
  );
};

export default withWidth()(withRouter(PartOverview));
