import { FC } from 'react';
import styles from 'src/styles/components/DropdownNetwork.module.scss';
import config from 'src/config';
import AppDropdown, { AppDropdownProps, DropdownItem } from './AppDropdown';
import { isMobile } from 'react-device-detect';

interface DropdownNetworkProps extends Omit<AppDropdownProps, 'options'> {
  hiddenTextOnMobile?: boolean;
  type?: 'header' | 'body';
  support?: 'signIn' | 'auction';
}

const DropdownNetwork: FC<DropdownNetworkProps> = ({
  hiddenTextOnMobile,
  type = 'body',
  support,
  ...props
}) => {
  const networkList = Object.keys(config.networks).map(
    (key) => config.networks[key],
  );

  return (
    <AppDropdown
      {...props}
      options={
        support
          ? networkList.filter((f) => f.support.some((s) => s === support))
          : networkList
      }
      renderValue={
        hiddenTextOnMobile && isMobile
          ? (value: any) => (
              <span
                className={`${styles['adornment-network-' + type]} ${
                  styles['adornment-primary']
                }`}
              >
                <img
                  src={networkList.find((f) => f.id === value)?.icon}
                  alt=""
                />
              </span>
            )
          : undefined
      }
      renderOptions={(item, index) => (
        <DropdownItem value={item.id} key={index}>
          <span className={styles['adornment-network-' + type]}>
            <img src={item.icon} alt={item.name} />
          </span>
          <span className={`${styles['value-network-' + type]}`}>
            {item.name}
          </span>
        </DropdownItem>
      )}
    />
  );
};

export default DropdownNetwork;
