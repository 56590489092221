import animationData from 'src/assets/icons/no-result-found.json';
import Lottie from 'react-lottie';
import styles from 'src/styles/components/NoResult.module.scss';
import { BgNoResultIcon } from 'src/assets/icons/index';

const NoResultIcon = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height || 'auto'}
      width={props.width || 'auto'}
    />
  );
};

const NoResult = () => {
  return (
    <div className={styles['no-result']}>
      <div className={styles['bg-img']}>
        <BgNoResultIcon className={styles['bg']} />
        <div className={styles['context-result']}>
          <NoResultIcon styles={styles['icon-anim']} />
          <div className={styles['text-view']}>
            <span className={styles['title']}>No results found</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResult;
