import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTransactionSubmittingModal } from 'src/store/transactions';
import BaseModal from './BaseModal';

const ModalSubmittingTx = () => {
  const dispatch = useDispatch();
  const { openModalTransactionSubmitting } = useSelector(
    (state: any) => state.transactions,
  );

  return (
    <BaseModal
      open={openModalTransactionSubmitting}
      onClose={() => dispatch(toggleTransactionSubmittingModal(false))}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      title={'Transaction Submitting'}
      isCloseIcon={false}
    >
      <div
        style={{
          margin: '50px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
      </div>
    </BaseModal>
  );
};

export default ModalSubmittingTx;
