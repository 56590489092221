import { createSlice } from '@reduxjs/toolkit';
import Storage from 'src/utils/storage';
import config from 'src/config';
import BaseConnector from 'src/connectors/BaseConnector';
import { ExternalProvider } from '@ethersproject/providers';

interface AuthenticationState {
  network: string;
  connector: BaseConnector | null;
  provider: ExternalProvider | null;
  address: string;
  chainId: string;
  token: string;
  isLoadingWallet: boolean;
  openSignatureRequiredModal: boolean;
  openConnectWalletModal: boolean;
}

const initialState = {
  network: '',
  chainId: '',
  address: '',
  isLoadingWallet: false,
  openSignatureRequiredModal: false,
  openConnectWalletModal: false,
} as AuthenticationState;

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload;
      Storage.setNetwork(state.network);
      Storage.setChainId(config.networks[state.network]?.chainId || '');
    },
    setConnector: (state, action) => {
      state.connector = action.payload;
      state.connector && Storage.setConnectorId(state.connector.getId());
    },
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    setLoadingWallet: (state, action) => {
      state.isLoadingWallet = action.payload;
    },
    setDisConnectAuthentication: (state) => {
      state.connector = null;
      state.address = '';
      state.chainId = '0';
      state.isLoadingWallet = false;
    },
    clearAuth: (state) => {
      state.connector = null;
      state.address = '';
      state.chainId = '0';
      state.isLoadingWallet = false;
    },
    setChainId: (state, action) => {
      state.chainId = String(action.payload);
      Storage.setChainId(state.chainId);
    },
    setAddress: (state, action) => {
      state.address = action.payload;
      Storage.setAddress(state.address);
    },
    setToken: (state, action) => {
      state.token = action.payload;
      Storage.setAccessToken(state.token);
    },
    toggleSignatureRequiredModal: (state, action) => {
      state.openSignatureRequiredModal = action.payload;
    },
    toggleConnectWalletModal: (state, action) => {
      state.openConnectWalletModal = action.payload;
    },
  },
});

export const {
  setNetwork,
  setConnector,
  setProvider,
  clearAuth,
  setAddress,
  setChainId,
  setLoadingWallet,
  setToken,
  toggleSignatureRequiredModal,
  toggleConnectWalletModal,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
