import { FC, useEffect, useState } from 'react';
import AppButton from './AppButton';
import useAuth from 'src/hooks/useAuth';
import config from 'src/config';
import { getTokenBalance } from 'src/utils/utils-token';
import { convertWeiToDec } from 'src/utils/utils-formats';
import AppInputCurrency, { AppInputCurrencyProps } from './AppInputCurrency';
import styles from 'src/styles/components/AppInputTokenMax.module.scss';

interface AppInputTokenMaxProps extends AppInputCurrencyProps {
  token?: string;
  value?: string;
  maxValue?: string;
  tokenDetails?: {
    symbol: string;
    contractAddress?: string;
  };
  icon?: React.ReactNode;
  handleChange: (value: string) => void;
  onlyBtnMax?: boolean;
}

const AppInputTokenMax: FC<AppInputTokenMaxProps> = ({
  icon,
  tokenDetails,
  value,
  maxValue,
  readOnly,
  onlyBtnMax,
  handleChange,
  ...props
}) => {
  const { user } = useAuth();

  const [tokenBalance, setTokenBalance] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(false);
  const fetchTokenBalance = (tokenAddress: string | undefined) => {
    if (!tokenAddress || !user) {
      return 0;
    }
    setLoading(true);

    getTokenBalance(
      config.supportStakingNetwork,
      tokenAddress,
      user.getAddress(),
    )
      .then((balance) => {
        setTokenBalance(balance);
      })
      .finally(() => setLoading(false));
  };

  const onSetMaxValue = () => {
    if (readOnly) return;
    handleChange(maxValue ? maxValue : convertWeiToDec(tokenBalance));
  };

  useEffect(() => {
    if (maxValue) return;
    fetchTokenBalance(tokenDetails?.contractAddress);
  }, [tokenDetails?.contractAddress, user?.getAddress(), value]);
  return (
    <AppInputCurrency
      handleChange={handleChange}
      value={value}
      readOnly={readOnly}
      endAdornment={
        <div className={styles['adornment-end']}>
          {!onlyBtnMax && (
            <div className={styles['slot']}>
              <div className={styles['brand']}>
                {icon && <span className={styles['logo-brand']}>{icon}</span>}
                <span className={styles['symbol']}>{tokenDetails?.symbol}</span>
              </div>
            </div>
          )}
          <div className={styles['slot']}>
            <AppButton
              sizes="small"
              onClick={onSetMaxValue}
              isDisable={maxValue ? false : loading}
            >
              MAX
            </AppButton>
          </div>
        </div>
      }
      decimalsLimit={8}
      {...props}
    />
  );
};

export default AppInputTokenMax;
