import styles from 'src/styles/pages/StakingPools.module.scss';
import StakingTable from 'src/components/StakingTable';
import { Collapse } from '@material-ui/core';
import { useState } from 'react';

const PartStakingTier = () => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${styles['collapse-tier']} ${
        open ? styles['collapse-tier-show'] : ''
      }`}
    >
      <div
        className={styles['header']}
        onClick={() => setOpen((open) => !open)}
      >
        <div className={styles['title']}>Staking Tier</div>
        <div>
          <span
            className={`${styles['icon-collapse']} ${
              !open ? styles['collapsed'] : ''
            }`}
          />
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StakingTable customClass="staking-table-profile" />
      </Collapse>
    </div>
  );
};

export default PartStakingTier;
