import { SolanaConnector } from './BaseConnector';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';

class SolflareConnector extends SolanaConnector {
  network = {
    chainId: 'solflare-chainId', // temporarily chainId
  };

  getName() {
    return 'Solflare';
  }

  /**
   * connect wallet
   * @returns provider
   */

  async connect() {
    const adapter = new SolflareWalletAdapter();
    this.provider = adapter;
    return adapter.connect();
  }
}

export default SolflareConnector;
