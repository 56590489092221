import { Collapse, withWidth } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from 'src/store';
import styles from 'src/styles/components/StakingTable.module.scss';
import { formatTierAsString } from 'src/utils/utils-formats';
import { isMobile } from 'react-device-detect';

const StakingTable = (props: any) => {
  const [selected, setSelected] = useState<any>(null);
  const { tiers } = useSelector((state: RootState) => state.metadata);
  const _formatWithdrawDelay = (withdrawDelay: string) => {
    if (!withdrawDelay) {
      return '--';
    }
    return `${+withdrawDelay / 86400} days`;
  };
  const _formatExclusivePools = (exclusivePools: string) => {
    if (!exclusivePools) {
      return 'No';
    }
    return 'Yes';
  };
  const _renderForDesktop = () => {
    if (!tiers) {
      return null;
    }
    return (
      <table
        className={`${styles['staking-table']} ${styles[props.customClass]}`}
      >
        <tbody>
          <tr>
            <td></td>
            {tiers.map((tier: any) => (
              <td key={`icon${tier.id}`}>
                {formatTierAsString(tier?.tier)}
                <img className={styles['icon-head']} src={tier.icon} alt="" />
              </td>
            ))}
          </tr>

          <tr>
            <td>Requirement</td>
            {tiers.map((tier: any) => (
              <td key={`requirement${tier.id}`}>{tier?.requirement || '--'}</td>
            ))}
          </tr>
          <tr>
            <td>Method</td>
            {tiers.map((tier: any) => (
              <td key={`method${tier.id}`}>{tier?.method || '--'}</td>
            ))}
          </tr>
          <tr>
            <td>Max Alloc</td>
            {tiers.map((tier: any) => (
              <td key={`maxAllocation${tier.id}`}>
                {tier?.maxAllocation || '--'}
              </td>
            ))}
          </tr>
          <tr>
            <td>Withdraw Delay</td>
            {tiers.map((tier: any) => (
              <td key={`withdrawDelay${tier.id}`}>
                {_formatWithdrawDelay(tier?.withdrawDelay)}
              </td>
            ))}
          </tr>
          <tr>
            <td>Exclusive Pools</td>
            {tiers.map((tier: any) => (
              <td key={`exclusivePools${tier.id}`}>
                {_formatExclusivePools(tier?.exclusivePools)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const _renderTierForMobile = (tier: any) => {
    const _renderCard = (label: string, value: string) => (
      <div className={styles['card-staking-item']}>
        <label className={styles['label']}>{label}</label>
        <h6 className={styles['text']}>{value}</h6>
      </div>
    );
    return (
      <div className={styles['card-staking']} key={tier.id}>
        <div
          onClick={() => setSelected(tier)}
          className={styles['card-staking-header']}
        >
          <div className={styles['flex-header']}>
            <img src={tier.icon} className={styles['icon']} />
            <h5 className={styles['title']}>{formatTierAsString(tier.tier)}</h5>
            <span
              className={`${styles['icon-collapse']} ${
                selected?.id !== tier?.id ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['card-staking-subheader']}>
          <label className={styles['label']}>Requirement</label>
          <h6 className={styles['text']}>{tier.requirement}</h6>
        </div>
        <Collapse in={selected?.id === tier?.id} timeout="auto" unmountOnExit>
          <div className={styles['card-staking-body']}>
            {_renderCard('Requirement', tier?.requirement || '--')}
            {_renderCard('Method', tier?.method || '--')}
            {_renderCard('Max Alloc', tier?.maxAllocation || '--')}
            {_renderCard(
              'Withdraw Delay',
              _formatWithdrawDelay(tier?.withdrawDelay),
            )}
            {_renderCard(
              'Exclusive Pools',
              _formatExclusivePools(tier?.exclusivePools),
            )}
          </div>
        </Collapse>
      </div>
    );
  };

  const _renderForMobile = () => {
    return (
      <section className={styles['staking-table-mobile']}>
        {tiers.map((tier) => {
          return _renderTierForMobile(tier);
        })}
      </section>
    );
  };

  return isMobile ? _renderForMobile() : _renderForDesktop();
};
export default withWidth()(withRouter(StakingTable));
