import BigNumber from 'bignumber.js';
import config from 'src/config';
import moment from 'moment';
import { Idl } from '@project-serum/anchor/src/idl';
import abi from 'src/abi';
import { UserInterface } from 'src/utils/user';
import {
  ClaimScheduleType,
  IDOPool,
  INOPool,
  PoolResponseType,
} from 'src/utils/pool';
import rf from 'src/requests/RequestFactory';
import { multicall } from 'src/utils/utils-multicall';
import {
  getAllowance,
  getTokenBalance,
  makeApproveParams,
} from 'src/utils/utils-token';
import { convertWeiToDec, formatNumber } from 'src/utils/utils-formats';
import { TimelineInterface } from 'src/utils/timelines';
import { getNetworkConfig, getNetworkProvider } from './utils-network';
import { inoPoolContract, preSalePoolContract } from './utils-contract';
import { Program, Provider } from '@project-serum/anchor';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import PreSalePoolWrapper from 'src/utils/preSalePool';
import { toastError, toastInfo, toastSuccess } from './utils-notify';
import { processTransaction } from '../store/transactions';
import { Dispatch } from 'react';
import { PoolType } from './common';

const PRESALE_POOL_PROGRAM_ID = 'EGUmJfqFRUp25N4dHkH5Kn3yTvVUFGLfxRb8j5AfUzYa';

export interface IWinnerResponseType {
  id: string;
  type: string;
  candidate: string;
  userAddress: string;
  userTier: string;
  ticket: number;
  allocationSize: string;
}

type ClaimedTokenInfoType = {
  claimedToken: string;
  purchasedToken: string;
  remainingToken: string;
};

const _getSolanaAccount = (pool: IDOPool, user: UserInterface) => {
  if (!user) {
    return null;
  }
  const solanaNetworkFamily =
    config.networks[pool.getNetwork()].networkFamily || '';
  return user.getLinkedAccountByNetworkFamily(solanaNetworkFamily);
};

export const isSolanaAccountMatched = (
  pool: IDOPool,
  user: UserInterface,
  solanaAddress: string,
): boolean => {
  return solanaAddress === _getSolanaAccount(pool, user)?.networkAddress;
};

export const getSolanaProvider = (
  pool: IDOPool,
  user: UserInterface | null,
) => {
  if (!pool || !user) {
    return null;
  }
  const solanaNetworkFamily =
    config.networks[pool.getNetwork()].networkFamily || '';
  return user.getLinkedProviderByNetworkFamily(solanaNetworkFamily);
};

const _getSolanaConnection = (pool: IDOPool): Connection => {
  return new Connection(
    config.networks[pool.getNetwork()].rpcUrls[0],
    'confirmed',
  );
};

const _getPreSalePoolProgram = (
  pool: IDOPool,
  user: UserInterface,
): Program<Idl> => {
  const connection = _getSolanaConnection(pool);
  const solanaProvider = getSolanaProvider(pool, user) as any;
  const provider = new Provider(connection, solanaProvider, {
    preflightCommitment: 'confirmed',
    commitment: 'confirmed',
  });
  return new Program(
    abi['PreSalePoolSolana'],
    new PublicKey(PRESALE_POOL_PROGRAM_ID),
    provider,
  );
};

const _getPreSalePoolInfo = (pool: IDOPool, user: UserInterface) => {
  if (!pool || !user) {
    return null;
  }
  const program = _getPreSalePoolProgram(pool, user);
  const poolAddress = new PublicKey(pool.getContractAddress());
  return program.account.presalePoolAccount.fetch(poolAddress);
};

const _getPreSalePoolMemberInfo = async (
  pool: IDOPool,
  user: UserInterface,
  solanaUserAddress: string | undefined,
) => {
  if (!pool || !user || !solanaUserAddress) {
    return null;
  }
  const program = _getPreSalePoolProgram(pool, user);
  const wrapper = new PreSalePoolWrapper(program);
  const [memberAddress] = await wrapper.getUserAccount(
    new PublicKey(pool.getContractAddress()),
    new PublicKey(solanaUserAddress),
  );
  // return null if user has not purchased any time
  return program.account.userAccount.fetchNullable(memberAddress);
};

export const getClaimedTokenUserInfo = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<ClaimedTokenInfoType> => {
  if (pool.isNetworkSolana()) {
    const solanaAccount = _getSolanaAccount(pool, user);
    const poolMember = await _getPreSalePoolMemberInfo(
      pool,
      user,
      solanaAccount?.networkAddress,
    );
    if (!poolMember) {
      return {
        claimedToken: '0',
        purchasedToken: '0',
        remainingToken: '0',
      };
    }
    const remainingToken = new BigNumber(poolMember.purchased.toString())
      .minus(poolMember.claimed.toString())
      .toString();
    return {
      claimedToken: poolMember.claimed.toString(),
      purchasedToken: poolMember.purchased.toString(),
      remainingToken: remainingToken,
    };
  } else {
    const calls = [
      {
        address: pool.getContractAddress(),
        name: 'userPurchased',
        params: [user.getAddress()],
      },
      {
        address: pool.getContractAddress(),
        name: 'userClaimed',
        params: [user.getAddress()],
      },
    ];

    const [purchasedToken, claimedToken] = await multicall(
      abi['PreSalePool'],
      calls,
      pool.getNetwork(),
    );
    const remainingToken = new BigNumber(purchasedToken.toString())
      .minus(claimedToken.toString())
      .toString();
    return {
      claimedToken: claimedToken.toString(),
      purchasedToken: purchasedToken.toString(),
      remainingToken: remainingToken.toString(),
    };
  }
};

const _makeClaimIdoTokenParams = (
  poolAddress: string,
  [claimToken, amount, deadline, signature]: [string, string, string, string],
): string[] => {
  return [
    abi['PreSalePool'],
    poolAddress,
    'claimTokens',
    [claimToken, amount, deadline, signature],
    {},
  ];
};

export const getClaimIdoTokenParams = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<string[]> => {
  const claimParams = {
    poolId: pool.getId(),
    userAddress: user.getAddress(),
  };
  const res = await rf
    .getRequest('TransactionRequest')
    .getClaimTransaction(claimParams);
  const { claimToken, amount, deadline, signature } = res;
  const contractAddress = pool?.getContractAddress();
  const { claimedToken } = await getClaimedTokenUserInfo(pool, user);
  if (new BigNumber(amount).comparedTo(claimedToken) === 0) {
    throw new Error(
      'You have claimed all available tokens. Comeback at the next schedule.',
    );
  }
  return _makeClaimIdoTokenParams(contractAddress, [
    claimToken,
    amount,
    deadline,
    signature,
  ]);
};

export const getUserPurchased = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<number> => {
  if (pool.isNetworkSolana()) {
    const solanaAccount = _getSolanaAccount(pool, user);
    const poolMember = await _getPreSalePoolMemberInfo(
      pool,
      user,
      solanaAccount?.networkAddress,
    );
    return poolMember ? +poolMember.purchased : 0;
  } else {
    const networkProvider = getNetworkProvider(pool.getNetwork());
    const contract = preSalePoolContract(
      pool.getContractAddress(),
      networkProvider,
    );
    try {
      const userPurchased = await contract.userPurchased(user.getAddress());
      return +userPurchased;
    } catch (error) {
      return 0;
    }
  }
};

export const getSoldTokens = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<number> => {
  if (pool.isNetworkSolana()) {
    const poolInfo = await _getPreSalePoolInfo(pool, user);
    return poolInfo ? +poolInfo.tokenSold : 0;
  } else {
    const networkProvider = getNetworkProvider(pool.getNetwork());
    const contract = preSalePoolContract(
      pool.getContractAddress(),
      networkProvider,
    );
    try {
      const soldTokens = await contract.tokenSold();
      return +soldTokens;
    } catch (error) {
      return 0;
    }
  }
};

export const getTitleCurrentPhase = (timeline: TimelineInterface): string => {
  if (
    !!timeline.getWhitelistPhase().startTime &&
    timeline.beforeWhitelistPhase()
  ) {
    return 'Whitelist starts in:';
  }
  if (!!timeline.getWhitelistPhase().startTime && timeline.isWhitelistPhase()) {
    return 'Whitelist ends in:';
  }
  if (!!timeline.getSwapTime().startTime && timeline.beforeSwapPhase()) {
    return 'Swap starts in:';
  }
  if (
    !!timeline.getPrivateSwapPhase().endTime &&
    timeline.isPrivateSwapPhase()
  ) {
    return 'Exclusive sale ends in:';
  }
  if (
    !!timeline.getPublicSwapPhase()?.endTime &&
    timeline.isPublicSwapPhase()
  ) {
    return 'FCFS Sale ends in:';
  }
  return '';
};

export const getCountdownTimestamp = (timeline: TimelineInterface): number => {
  if (timeline.beforeWhitelistPhase()) {
    const { startTime } = timeline.getWhitelistPhase();
    return startTime;
  }
  if (timeline.isWhitelistPhase()) {
    const { endTime } = timeline.getWhitelistPhase();
    return endTime;
  }
  if (timeline.beforeSwapPhase()) {
    const { startTime } = timeline.getPrivateSwapPhase();
    return startTime;
  }
  if (timeline.isPrivateSwapPhase()) {
    const { endTime } = timeline.getPrivateSwapPhase();
    return endTime;
  }
  if (timeline.isPublicSwapPhase()) {
    const publicSwapPhase = timeline.getPublicSwapPhase();
    return publicSwapPhase ? publicSwapPhase.endTime : 0;
  }
  return 0;
};

export const getMaxClaimableTokensByTime = (
  claimSchedules: ClaimScheduleType[],
  purchasedTokens: number,
): number => {
  const currentTime = moment().unix();
  let maxClaimableTokensByTime = 0;
  for (let index = 0; index < claimSchedules.length; index++) {
    if (index === claimSchedules.length - 1) {
      maxClaimableTokensByTime = +purchasedTokens;
      break;
    } else {
      if (
        claimSchedules[index].startTime <= currentTime &&
        currentTime < claimSchedules[index + 1].startTime
      ) {
        maxClaimableTokensByTime =
          (+purchasedTokens * claimSchedules[index].maxClaimablePercentage) /
          100;
        break;
      }
    }
  }
  return maxClaimableTokensByTime;
};

export const getPublicRaiseByUSD = (
  tokenAmount: string,
  tokenPrice: string,
  tokenDecimals: number,
  currencyDecimals: number,
  currencyByUSD: number,
): string => {
  const publicRaise =
    +convertWeiToDec(tokenAmount, tokenDecimals) *
    +convertWeiToDec(tokenPrice, currencyDecimals);
  const publicRaiseByUSD = publicRaise * currencyByUSD;
  return formatNumber(publicRaiseByUSD.toFixed(2));
};

export const getAllocationTicketByUSD = (
  tokenPerTicket: string,
  tokenPrice: string,
  tokenDecimals: number,
  currencyDecimals: number,
  currencyByUSD: number,
): string => {
  const price =
    +convertWeiToDec(tokenPerTicket, tokenDecimals) *
    +convertWeiToDec(tokenPrice, currencyDecimals) *
    currencyByUSD;
  return formatNumber(price.toFixed(2));
};

export const makePurchaseIDOWithNativeTokenParams = (
  poolAddress: string,
  methodParams: Pick<any, string>,
  amountInput: string,
): string[] => {
  methodParams = Object.values(methodParams) || [];
  return [
    abi['PreSalePool'],
    poolAddress,
    'buyTokenByEtherWithPermission',
    methodParams,
    {
      value: amountInput,
    },
  ];
};

export const makePurchaseIDOWithTokenParams = (
  poolAddress: string,
  methodParams: Pick<any, string>,
): string[] => {
  methodParams = Object.values(methodParams) || [];
  return [
    abi['PreSalePool'],
    poolAddress,
    'buyTokenByTokenWithPermission',
    methodParams,
    {},
  ];
};

export const getPurchaseIDOSignature = async (
  user: UserInterface,
  pool: IDOPool,
  inAmount: string,
): Promise<string[] | null> => {
  if (!user || !pool) {
    return null;
  }
  return await rf.getRequest('TransactionRequest').getBuyTransaction({
    poolId: pool.getId(),
    userAddress: user.getAddress(),
    inAmount,
  });
};

export const isPurchaseWithNativeToken = (
  network: string,
  symbol: string,
): boolean => {
  if (!symbol) {
    return false;
  }
  const networkConfig = getNetworkConfig(network);
  return (
    symbol.toUpperCase() === networkConfig?.nativeCurrency?.symbol.toUpperCase()
  );
};

const _getSolanaTokenBalance = async (
  pool: IDOPool,
  user: UserInterface,
  tokenAddress: string,
  solanaUserAddress: string,
): Promise<string> => {
  if (!pool || !user || !tokenAddress || !solanaUserAddress) {
    return '0';
  }
  const program = _getPreSalePoolProgram(pool, user);
  const wrapper = new PreSalePoolWrapper(program);
  const tokenAmount = await wrapper.getTokenBalance(
    new PublicKey(tokenAddress),
    new PublicKey(solanaUserAddress),
  );
  return tokenAmount.amount;
};

export const getCollateralBalance = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<string> => {
  const network = pool.getNetwork();
  const collateralSymbol = pool.getCollateralCurrencySymbol();
  if (!user || !network) {
    return '0';
  }
  if (pool.isNetworkSolana()) {
    const solanaAccount = _getSolanaAccount(pool, user);
    if (!solanaAccount) {
      return '0';
    }
    if (isPurchaseWithNativeToken(network, collateralSymbol)) {
      const connection = _getSolanaConnection(pool);
      const balance = await connection.getBalance(
        new PublicKey(solanaAccount.networkAddress),
        'confirmed',
      );
      return balance.toString();
    } else {
      const collateralCurrency =
        config.networks[network].currencies[collateralSymbol.toLowerCase()];
      return _getSolanaTokenBalance(
        pool,
        user,
        collateralCurrency.address,
        solanaAccount.networkAddress,
      );
    }
  } else {
    const provider = getNetworkProvider(network);
    if (isPurchaseWithNativeToken(network, collateralSymbol)) {
      const balance = await provider.getBalance(user.getAddress());
      return balance.toString();
    }
    const collateralCurrency =
      config.networks[network].currencies[collateralSymbol.toLowerCase()];
    if (!collateralCurrency) {
      return '0';
    }
    return getTokenBalance(
      network,
      collateralCurrency.address,
      user.getAddress(),
    );
  }
};

export enum PHASES {
  UPCOMING,
  WHITE_LIST,
  TOKEN_SALE,
  CLAIM,
}

export const shouldHighlightIDOPhase = (
  timeline: TimelineInterface,
  phase: number,
): boolean => {
  if (phase === PHASES.UPCOMING) {
    return timeline.shouldHighlightUpcomingPhase();
  }
  if (phase === PHASES.WHITE_LIST) {
    return timeline.shouldHighlightWhitelistPhase();
  }
  if (phase === PHASES.TOKEN_SALE) {
    return timeline.shouldHighlightSwapPhase();
  }
  if (phase === PHASES.CLAIM) {
    return timeline.shouldHighlightClaimPhase();
  }
  return false;
};

export const isBeforeIDOPhase = (
  timeline: TimelineInterface,
  phase: number,
): boolean => {
  if (phase === PHASES.WHITE_LIST) {
    return timeline.beforeWhitelistPhase();
  }
  if (phase === PHASES.TOKEN_SALE) {
    return timeline.beforeSwapPhase();
  }
  if (phase === PHASES.CLAIM) {
    return timeline.beforeClaimPhase();
  }
  return false;
};

export const getIDOPhaseClassName = (
  idoTimeline: TimelineInterface,
  phase: number,
): string => {
  if (shouldHighlightIDOPhase(idoTimeline, phase)) {
    return 'happening';
  }
  if (!isBeforeIDOPhase(idoTimeline, phase)) {
    return 'done';
  }
  return '';
};

export const isTokenApproved = async (
  pool: INOPool | IDOPool,
  user: UserInterface,
) => {
  if (
    isPurchaseWithNativeToken(
      pool.getNetwork(),
      pool.getCollateralCurrencySymbol(),
    )
  ) {
    return true;
  }
  const allowance = await getAllowance(
    pool.getNetwork(),
    pool.getCollateralCurrencyAddress(),
    user.getAddress(),
    pool.getContractAddress(),
  );

  return new BigNumber(allowance).gt(0);
};
export const approveToken = async (
  pool: IDOPool | INOPool,
  user: UserInterface,
  dispatch: Dispatch<any>,
) => {
  const isApproved = await isTokenApproved(pool, user);
  if (isApproved) {
    return;
  }
  toastInfo({
    message: `You need to give permission to access your ${pool.getCollateralCurrencySymbol()}`,
  });
  const params = makeApproveParams(
    pool.getCollateralCurrencyAddress(),
    pool.getContractAddress(),
  );
  await dispatch(processTransaction({ provider: user?.getProvider(), params }));
};

export const isDisableClaim = (
  pool: IDOPool,
  user: UserInterface | null,
  claimedToken: string,
  maxClaimableTokensByTime: number,
) => {
  if (!pool || !user) {
    return true;
  }
  const timeline = pool.getTimeline();

  if (
    !timeline ||
    timeline.beforeClaimPhase() ||
    !pool.canClaimToken(user) ||
    maxClaimableTokensByTime === 0
  ) {
    return true;
  }

  return +claimedToken > 0 && +claimedToken === maxClaimableTokensByTime;
};

export const getBoxesSold = async (
  packageId: string | number,
  contractAddress: string,
  network: string,
) => {
  const networkProvider = getNetworkProvider(network);
  const contract = inoPoolContract(contractAddress, networkProvider);
  return contract.packageSold(packageId);
};

export const getBoxesUserPurchased = async (
  userAddress: string,
  contractAddress: string,
  network: string,
) => {
  const networkProvider = getNetworkProvider(network);
  const contract = inoPoolContract(contractAddress, networkProvider);
  return contract.userPurchased(userAddress);
};

export const _getPurchaseIDOSolanaSignature = (
  pool: IDOPool,
  user: UserInterface,
  inAmount: string,
) => {
  if (!pool || !user) {
    return null;
  }
  const solanaAccount = _getSolanaAccount(pool, user);
  return rf.getRequest('TransactionSolanaRequest').getBuyTransaction({
    poolId: pool.getId(),
    userAddress: solanaAccount?.networkAddress,
    inAmount,
  });
};

const _getClaimIDOSolanaSignature = (pool: IDOPool, user: UserInterface) => {
  if (!pool || !user) {
    return null;
  }
  const solanaAccount = _getSolanaAccount(pool, user);
  return rf.getRequest('TransactionSolanaRequest').getClaimTransaction({
    poolId: pool.getId(),
    userAddress: solanaAccount?.networkAddress,
  });
};

const _makeTransactionInSolana = async (
  pool: IDOPool,
  user: UserInterface,
  transaction: string,
) => {
  const solanaProvider = getSolanaProvider(pool, user);
  if (!pool || !solanaProvider || !transaction) {
    return;
  }
  const recoverTx = Transaction.from(Buffer.from(transaction, 'base64'));
  const signed = await solanaProvider.signTransaction(recoverTx);
  const connection = _getSolanaConnection(pool);
  const signature = await connection.sendRawTransaction(signed.serialize());
  await connection.confirmTransaction(signature);
};

export const purchaseSolanaIDO = async (
  pool: IDOPool,
  user: UserInterface,
  buyAmount: string,
): Promise<void> => {
  try {
    const { transaction } = await _getPurchaseIDOSolanaSignature(
      pool,
      user,
      buyAmount,
    );
    await _makeTransactionInSolana(pool, user, transaction);
    toastSuccess({ message: 'Purchase successfully!' });
  } catch (error: any) {
    toastError({ message: error.toString() });
  }
};

export const claimSolanaIDO = async (
  pool: IDOPool,
  user: UserInterface,
): Promise<void> => {
  try {
    const { transaction } = await _getClaimIDOSolanaSignature(pool, user);
    await _makeTransactionInSolana(pool, user, transaction);
    toastSuccess({ message: 'Claim successfully!' });
  } catch (error: any) {
    toastError({ message: error.toString() });
  }
};

export const getPoolDetailLink = (pool: PoolResponseType) => {
  if (pool.type === PoolType.INO) {
    return `/pool-ino/${pool.id}`;
  }
  return `/pool-ido/${pool.id}`;
};
