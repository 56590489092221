import React from 'react';
import BasePage from './BasePage';
import styles from 'src/styles/pages/TermsOfUse.module.scss';

const PageTermOfUse = () => {
  return (
    <BasePage>
      <div className={styles['terms-of-use-wrap']}>
        <div className={styles['text-center']}>
          <h4 className={styles['padding-section']}>Terms of Use</h4>
          <div
            className={`${styles['text-content']} ${styles['padding-section']}`}
          >
            Last Updated: 07/04/2022
          </div>
        </div>
        <div className={styles['text-notice']}>
          Please do not access this site where such access is prohibited by
          applicable law. Please carefully read these terms of use before using
          the site. These terms apply to any person or entity accessing the site
          and by using the site you agree to be bound by them. The terms of use
          contain a mandatory individual arbitration and class action/jury trial
          waiver provision that requires the use of arbitration on an individual
          basis to resolve disputes, rather than jury trials or class actions.
          If you do not want to be bound by these terms of use, you should not
          access the site. By using the site in any capacity, you agree that you
          have read, understood, and agree to be subject to these terms of use.
        </div>
        <h5 className={styles['title']}>1. Overview</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          This Metaverse Starter Platform Terms of Use agreement (“Terms” or
          “agreement”) (“Metaverse Starter Platform”, “we” and “us” refers to
          Metaverse Starter Platform) covers the website, Metaverse Starter
          Platform user-interface and application (collectively “the Site”) we
          own and administer, at times in conjunction with others, which
          provides the ability to access the decentralized Metaverse Starter
          Platform Protocol. Additionally, you can access the Metaverse Starter
          Platform through third-party web or mobile interfaces. These Terms
          apply to you (“You” or “you”) as a user of our Site including all the
          products, services, tools and information, without limitation, made
          available on the Site.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You must be able to form a legally binding contract online either as
          an individual or on behalf of a legal entity. You represent that, if
          you are agreeing to these Terms on behalf of a legal entity, you have
          the legal authority to bind that entity to these Terms and you are not
          indirectly or directly included on any sanctions list and at least 18
          years old or the age of majority where you reside, (whichever is
          older) can form a legally binding contract online, and have the full,
          right, power and authority to enter into and to comply with the
          obligations under these Terms.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You are advised to periodically review these Terms so you understand
          any changes to the Terms. Metaverse Starter Platform in its sole
          discretion, reserves the right to make changes to our Terms. Changes
          are binding on users of the Site and will take effect immediately upon
          posting. As a user, you agree to be bound by any changes, variations,
          or modifications to our Terms and your continued use of the Site shall
          constitute acceptance of any such changes, revisions, variations, or
          modifications. When we make changes, we will make the updated Terms
          available on the interface and update the “Last Updated” date at the
          beginning of the Terms accordingly.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You accept such changes, by continuing to use the Site and by doing so
          you agree that we have provided you with sufficient notice of such
          change. Our Privacy Policy and Cookies Policy also apply to your
          access and use of the Site.
        </div>
        <h5 className={styles['title']}>2. Site</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          As part of the Site, Metaverse Starter Platform provides access to a
          decentralized finance application (“Application” or “Metaverse Starter
          Platform dapp”) on the Ethereum, Polygon, BSC, Near and Solana
          blockchains, that allows users of blockchain assets (“Cryptocurrency
          Assets”) to transact using smart contracts (“Smart Contracts”). Use of
          the Metaverse Starter Platform may require that you pay a fee, such as
          gas charges on the Ethereum network to perform a transaction. You
          acknowledge and agree that Metaverse Starter Platform has no control
          over any activities, transactions, the method of payment of any
          transactions, or any actual processing of payments of transactions.
          You must ensure that you have a sufficient balance to complete any
          transaction on the Metaverse Starter Platform before initiating such
          transaction.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You acknowledge and agree that the Metaverse Starter Platform has no
          control over any transactions conducted through the Metaverse Starter
          Platform, the method of payment of any transactions or any actual
          payments of transactions including use of any third-party services
          such as Metamask, or other wallet services. Likewise, you must ensure
          that you have a sufficient balance of the applicable cryptocurrency
          tokens stored at your Metaverse Starter Platform-compatible wallet
          address (“Cryptocurrency Wallet”) to complete any transaction on the
          Metaverse Starter Platform or the Ethereum network before initiating
          such a transaction.
        </div>
        <h5 className={styles['title']}>3. Platform Access Fee</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Metaverse Starter applies a 2% platform access fee on the quantity
          accrued to the base token balance at the conclusion of a Token Launch
          Auction (Auction). Conclusion is defined by the owner's withdrawal of
          liquidity of main and base token balances from the Auction. The
          quantity accrued is inclusive of accrual to the base tokens due to
          swaps and due to swap fees.
        </div>
        <h5 className={styles['title']}>
          4. Access / Disclaimer of Warranties
        </h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          ACCESS TO THIS SITE AND THE PRODUCTS HEREIN ARE PROVIDED ON AN 'AS IS'
          AND 'AS AVAILABLE' BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. NO WARRANTY IS PROVIDED THAT THE SITE OR ANY PRODUCT
          WILL BE FREE FROM DEFECTS OR VIRUSES OR THAT OPERATION OF THE PRODUCT
          WILL BE UNINTERRUPTED. YOUR USE OF THE SITE AND ANY PRODUCT AND ANY
          MATERIAL OR SERVICES OBTAINED OR ACCESSED VIA THE SITE IS AT YOUR OWN
          DISCRETION AND RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE
          RESULTING FROM THEIR USE. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY
          NOT APPLY TO YOU.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We do not guarantee or promise that the Site, or any content on it,
          will always be available, functional, usable or uninterrupted. From
          time to time, access may be interrupted, suspended or restricted,
          including because of a fault, error or unforeseen circumstances or
          because we are carrying out planned maintenance or changes.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We reserve the right to limit the availability of the site to any
          person, geographic area or jurisdiction in our sole discretion and/or
          to terminate your access to and use of the site, at any time and in
          our sole discretion. We may suspend or disable your access to the Site
          for any reason and in our sole discretion, including for any
          intentional or unintentional breaches of these Terms. We may remove or
          amend the content of the Site at any time. Some of the content may be
          out of date at any given time and we are under no obligation to update
          or revise it. We do not promise or guarantee that the Site, or any
          content on it, will be free from errors or omissions.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We will not be liable to you for any issue, loss or damage you may or
          have suffered as a result of the Site being unavailable at any time
          for any reason. You will comply with all applicable domestic and
          international laws, statutes, ordinances, rules and regulations
          applicable to your use of the site (“Applicable Laws”).
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          As a condition to accessing or using the Site, you agree and represent
          that you will:
          <br />
          • Only use the Services and the Site for lawful purposes and in
          adherence with these Terms; <br /> • Ensure that all information that
          you provide on the Site is current, complete, and accurate; <br />•
          Maintain the security, privacy and confidentiality of access to your
          cryptocurrency wallet address.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          As a condition to accessing or using the Site or the Services, you
          will not:
          <br />
          • Violate any Applicable Law, including, without limitation, any
          relevant and applicable anti-money laundering and anti-terrorist
          financing and sanctions laws and any relevant and applicable privacy,
          secrecy and data protection laws.
          <br />
          • Use the Site for any purpose or conduct that is directly or
          indirectly unlawful;
          <br />
          • Export, reexport, or transfer, directly or indirectly, any Metaverse
          Starter Platform technology in violation of applicable export laws or
          regulations;
          <br />
          • Infringe on or misappropriate any contract, intellectual property or
          other third-party right, or commit a tort while using the Site;
          <br />
          • Misrepresent, with omission or otherwise, the truthfulness, sourcing
          or reliability of any content on the Site;
          <br />
          • Use the Site in any manner that could interfere with, disrupt,
          negatively affect, redirect or inhibit other users from fully enjoying
          the Site or the Metaverse Starter Platform, or that could damage,
          disable, overburden, or impair the functioning of the Site or the
          Metaverse Starter Platform in any manner;
          <br />
          • Attempt to circumvent or disable any content filtering techniques or
          security measures that Metaverse Starter Platform employs on the Site,
          or attempt to access any service or area of the Site that you are not
          authorized to access;
          <br />
          • Use any robot, spider, crawler, scraper, or other automated means or
          interface not provided by us, to access the Site to extract data;
          <br />
          • Introduce or use any malware, virus, Trojan horse, worm, logic bomb,
          drop-dead device, backdoor, shutdown mechanism or other harmful
          material into the Site;
          <br />
          • Post content or communications on the Site that are, in our sole
          discretion, libelous, defamatory, profane, obscene, pornographic,
          sexually explicit, indecent, lewd, vulgar, suggestive, harassing,
          hateful, threatening, offensive, discriminatory, bigoted, abusive,
          inflammatory, fraudulent, deceptive or otherwise objectionable;
          <br />
          • To the extent applicable, post content on the Site containing
          unsolicited promotions, commercial messages or any chain messages or
          user content designed to deceive, induce or trick the user of the
          Site; <br />• Encourage or induce any third party to engage in any of
          the activities prohibited under these Terms.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          4 (a). You acknowledge that the Site and your use of the Site present
          certain risks, including without limitation the following risks:
          <br />
          • Losses while digital assets are being supplied to the Metaverse
          Starter Platform and losses due to the fluctuation of prices of tokens
          in a transaction pair or liquidity pool. Prices of digital assets
          fluctuate day by day or even minute by minute. The value of your
          available balance could surge or drop suddenly. Please note that there
          is a possibility that the price of tokens could decrease to zero.
          Prices of tokens are prone to significant fluctuations, for example,
          due to announced proposed legislative acts, governmental restrictions,
          news related to cyber crimes or other factors causing potentially
          excessive market enthusiasm, disproportionate loss in confidence, or
          manipulation by others in the market.
          <br />
          • Risks associated with accessing the Metaverse Starter Platform
          through third party web or mobile interfaces. You are responsible for
          doing your own diligence on those interfaces to understand and accept
          the risks that use entails. You are also responsible for doing your
          own diligence on those interfaces to understand and accept any fees
          that those interfaces may charge.
          <br />
          • Risks associated with any Smart Contracts with which you interact.
          <br />
          • Although Metaverse Starter Platform does not have access to your
          assets, you are reminded and acknowledge that at any time, your access
          to your Cryptocurrency Assets through third party wallet services,
          unrelated to Metaverse Starter Platform, may be suspended or
          terminated or there may be a delay in your access or use of your
          Cryptocurrency Assets, which may result in the Cryptocurrency Assets
          diminishing in value or you being unable to complete a Smart Contract.
          <br />
          • You are reminded of the inherent risks with digital assets and
          decentralized finance including the fact that tokens are not legal
          tender and are not backed by any government. Unlike fiat currencies,
          which are regulated and backed by local governments and central banks,
          tokens are based only on technology and user consensus, which means
          that in cases of manipulations or market panic, central governments
          will not take any corrective actions or measures to achieve stability,
          maintain liquidity or protect their value. There is a possibility that
          certain transactions cannot be settled or may be difficult to settle,
          or can be completed only at significantly adverse prices depending on
          the market situation and/or market volume. Transactions may be
          irreversible, and, accordingly, potential losses due to fraudulent or
          accidental transactions are not recoverable. Some blockchain
          transactions are deemed to be completed when recorded on a public
          ledger, which is not necessarily the date or time when you or another
          party initiated the transaction.
          <br />
          • The regulatory frameworks applicable to blockchain transactions in
          connection with tokens are still developing and evolving. It is
          possible that your transactions or funds are, or may be in the future,
          subject to various reporting, tax or other liabilities and
          obligations. Legislative and regulatory changes or actions at the
          country or international level may materially and adversely affect the
          use, transfer, exchange, and value of your tokens.
          <br />
          • The site and/or application may be wholly or partially suspended or
          terminated for any or no reason, which may limit your access to your
          Cryptocurrency Assets.
          <br />
          • You are solely responsible for understanding and complying with any
          and all Applicable Laws in connection with your acceptance of these
          Terms and your use of any part of the Site, including but not limited
          to those related to taxes as well as reporting and disclosure
          obligations.
          <br />• This list of risk factors is non-exhaustive, and other risks,
          arising either now or in the future, could additionally be relevant
          and applicable to you in making an informed judgment to accept, or
          continue to accept, these Terms and/or use, or continue to use the
          Site.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          4 (b). You acknowledge that the creation and use of Token Launch
          Auction (Auctions) through the Site and your use of the Site present
          certain risks, including without limitation the following risks:
          <br />
          • Anyone can utilize the Metaverse Starter Platform to create a Token
          Launch Auction (Auction) subject to the laws applicable to them in
          their local jurisdiction. Metaverse Starter does not have any
          involvement with the projects that are visible on its platform and
          each Auction is independently managed by the Auction owner. Metaverse
          Starter has developed a user interface and smart contracts that run on
          top of the Balancer Protocol and the Ethereum, Polygon, and Arbitrum
          blockchains
          <br />
          • The Auction that you create must not be used to support any illegal
          activity of any kind according to the jurisdiction that you reside in.
          This illegal activity may include but is not limited to: Financing
          terrorism, committing fraud, theft of funds, human smuggling or human
          trafficking, cyber crime, illegal drugs, destruction of property,
          sexual crimes, financial crimes such as embezzlement, tax evasion or
          blackmail and any other applicable crimes as per the Auction host's
          local jurisdiction.
          <br />
          • By agreeing to these terms, you acknowledge that the Auction you
          will create will be available to users of all jurisdictions to
          participate in except for sanctioned countries under the Office of
          Foreign Assets Control - Sanctions Programs. If you do not wish for
          your Auction to be available for users from certain jurisdictions
          please contact the Metaverse Starter Platform team who might be able
          to assist you with your request. The Metaverse Starter Platform team
          reserves the right to accommodate such requests at their discretion,
          and if we don’t manage to accommodate your request you take
          responsibility for the jurisdictions that the Auction you’ve created
          will be available in.
          <br />
          • By creating the Auction on Metaverse Starter you acknowledge that
          you own all legal rights to name, logos, copy used when uploading
          details regarding your Auction and confirm you are not infringing any
          copyright and rights of use laws.
          <br />
          • If your Auction is found to violate any of these terms, the
          Metaverse Starter Platform can remove your Auction from the Metaverse
          Starter Platform user interface.
          <br />
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Accordingly, you expressly agree that:
          <br />
          1. you assume all risk in connection with the specific risks
          identified above in 3(a) and 3(b);
          <br />
          2. you assume all risk in connection with your access to and use of
          the Site, the Application and the Smart Contracts;
          <br />
          3. that you expressly waive and release Metaverse Starter Platform
          from any and all liability, claims, causes of action, responsibility
          or damages arising from or in any way related to your use of the Site,
          the Application or the Smart Contracts; and
          <br />
          4. No developer or entity involved in creating the Metaverse Starter
          Platform will be liable for any claims or damages whatsoever
          associated with your use, inability to use, or your interaction with
          other users of, the Metaverse Starter Platform, including any direct,
          indirect, incidental, special, exemplary, punitive or consequential
          damages, or loss of profits, cryptocurrencies, tokens, or anything
          else of value.
        </div>
        <h5 className={styles['title']}>
          5. Know your Customer ("KYC") Policy
        </h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Metaverse Starter is a permissionless, fully decentralized platform
          for token sales, swaps, and decentralized exchange. As a software
          development company, Metaverse Starter has no role in enforcing KYC by
          default, however, we do provide such tools for fundraising entities
          using Metaverse Starter to enforce on their users, if the entities
          choose to do so. The use of KYC tool on Metaverse Starter by
          fundraising entities using the Service is completely at the discretion
          of said entities.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Although Metaverse Starter makes no warranty as to the merit,
          legality, or juridical nature of any token (including whether or not
          it is considered a security or financial instrument under any
          applicable Securities Laws), token sale or fundraiser on top of
          Metaverse Starter, we nonetheless understand the need of some token
          projects to require KYC on their token sale participants. Therefore,
          and at the sole behest of fundraising entities and/or competent
          regulatory authorities, Metaverse Starter reserves the right at any
          time:
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          <div className={` ${styles['text-dot']}`}>
            To ask for any KYC documentation it deems necessary to determine the
            identity and location of a User.
          </div>
          <div className={` ${styles['text-dot']}`}>
            To restrict service and payment until identity is sufficiently
            determined.
          </div>
          <div className={` ${styles['text-dot']}`}>
            To share submitted KYC information and documentation to 3rd parties
            to verify the authenticity of submitted information, and the
            end-user (you) agrees to this by using the Service
          </div>
        </div>
        <h5 className={styles['title']}>6. Third-Party Content</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          The Site may contain hyperlinks or references to third-party websites
          or content. Any such hyperlinks or references are provided for your
          information and convenience only. We have no control over third-party
          websites and accept no legal responsibility for any content, material
          or information contained in them. The display of any hyperlink and
          reference to any third-party website does not mean that we endorse
          that third party's website, products or services or opine on the
          accuracy or reliability of such information. Your use of a third-party
          site may be governed by the terms and conditions of that third-party
          site.
        </div>
        <h5 className={styles['title']}>7. Jurisdiction and Governing Law</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          The laws of Vietnam (with the exclusion of any rules that might lead
          to the use of any other law which is not the law of Vietnam) will
          govern the validity and construction of this Agreement, any separate
          agreement whereby we provide you any services and any dispute arising
          out of or in relation to this Agreement or such separate agreement.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Citizens and residents of and persons located in the United States of
          America, China, Hong Kong, and all sanctioned OFAC countries (DR
          Congo, Iran, Myanmar, Sudan, Iraq, Ivory Coast, North Korea, Syria,
          Zimbabwe, Cuba, Belarus, Liberia) (the ”Prohibited Jurisdictions”) are
          not permitted to make use of the Service.
        </div>
        <h5 className={styles['title']}>
          8. Our Privacy Policy and Cookie Policy
        </h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Certain areas of our website may record and collect information about
          you. You can find more information about how we will process your
          personal information in our Privacy Policy.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          When you use the Site, we may collect information about your computer
          and your interaction with the Site. See our Cookie Policy for more
          information.
        </div>
        <h5 className={styles['title']}>9. Intellectual Property Rights</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Metaverse Starter Platform is the owner of all intellectual property
          rights in the Site and the material published on them. To the extent
          practical, these works are protected by copyright laws and all such
          rights are reserved. www.Metaverse Starterlaunch.com is the uniform
          resource locator (‘URL’) of Metaverse Starter Platform. You will not
          make use of this URL (or any other URL owned by us) on another website
          or digital platform without our prior written consent. Any
          unauthorized use or reproduction may be prosecuted. You will retain
          ownership of all copyright in data you upload or submit by, through or
          to the Site. You grant us a worldwide, royalty-free, irrevocable
          license to use, copy, distribute or publish and send this data in any
          manner.
        </div>
        <h5 className={styles['title']}>10. Breach</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Without prejudice to any other rights, if a User breaches in whole or
          in part any provision contained herein, Metaverse Starter reserves the
          right to take such action as it sees fit, including terminating this
          Agreement or any other agreement in place with the User and/or taking
          legal action against such User.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You agree to indemnify and hold harmless Metaverse Starter, its
          affiliates, subsidiaries, licensors, and their respective directors,
          officers, members, managers, employees, and agents from and against
          any and all claims and expenses arising out of your use of the
          Services, a breach of any provision of these Terms by you or any
          person using the Services on your behalf, a breach by you of any
          applicable laws, or any third-party claim to the extent arising from
          or connected with an allegation that your use of the Services in
          accordance with these Terms infringes any rights of a third party
        </div>
        <h5 className={styles['title']}>11. Limitation of Liability</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Under no circumstances shall we or any of our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries be liable
          to you for any indirect, punitive, incidental, special, consequential,
          or exemplary damages, including (but not limited to) damages for loss
          of profits, goodwill, use, data, or other intangible property, arising
          out of or relating to any access or use of the Site including the
          user-interface, nor will we be responsible for any damage, loss, or
          injury resulting from hacking, tampering, or other unauthorized access
          or use of the Site including the user-interface or the information
          contained within it.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We assume no liability or responsibility for any: (a) errors,
          mistakes, or inaccuracies of content; (b) personal injury or property
          damage, of any nature whatsoever, resulting from any access or use of
          the Site including the user-interface; (c) unauthorized access or use
          of any secure server or database in our control, or the use of any
          information or data stored therein; (d) interruption or cessation of
          function related to the Site; (e) bugs, viruses, trojan horses, or the
          like that may be transmitted to or through the Site; (f) errors or
          omissions in, or loss or damage incurred as a result of the use of,
          any content made available through the Site; and (g) the defamatory,
          offensive, or illegal conduct of any third party.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Under no circumstances shall we or any of our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries be liable
          to you for any claims, proceedings, liabilities, obligations, damages,
          losses, or costs in an amount exceeding $100.00. This limitation of
          liability applies regardless of whether the alleged liability is based
          on contract, tort, negligence, strict liability, or any other basis,
          and even if we have been advised of the possibility of such liability.
          Some jurisdictions do not allow the exclusion of certain warranties or
          the limitation or exclusion of certain liabilities, but your
          acceptance of these Terms constitutes an agreement to limit the
          liability of Metaverse Starter Platform and our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries to the
          maximum extent possible under any applicable laws.
        </div>
        <h5 className={styles['title']}>12. Disclaimers</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We do not guarantee that the Site will be secure or free from bugs or
          viruses.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You are responsible for configuring your information technology,
          computer programs and/or platform in order to access the Site. You
          should use and deploy your own virus protection and security software.
          We cannot promise that the use of the Site, or any content taken from
          the Site, will not infringe the rights of any third party.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          The content and materials available on the Site are for informational
          purposes only and are not intended to address your particular
          requirements or needs. In particular, the content and materials
          available on the Site do not constitute any form of advice, referral
          or recommendation by us, should not be regarded as an offer,
          solicitation, invitation or recommendation to buy or sell tokens or
          any other financial services and is not intended to be relied upon by
          you in making any specific decision to buy or sell a token. We
          recommend that you seek independent advice from financial, legal and
          tax advisors before making any such decision particularly in light of
          the risks associated with digital assets.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Nothing included in the site constitutes an offer or solicitation to
          sell, or distribution of, investments and related services to anyone
          in any jurisdiction.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          From time to time, reference may be made to data we have gathered.
          These references may be selective or, may be partial. As markets
          change continuously, previously published information and data may not
          be current and should not be relied upon.
        </div>
        <h5 className={styles['title']}>13. Indemnification</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          You agree to indemnify and hold Metaverse Starter Platform and our
          officers, directors, employees, contractors, agents, affiliates, or
          subsidiaries harmless from any claim or demand, including attorneys’
          fees and costs, made by any third-party due to or arising out of 1)
          your use of the site or 2) this agreement.
        </div>
        <h5 className={styles['title']}>14. General</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          We may perform any of our obligations, and exercise any of the rights
          granted to us under these Terms, through an affiliated or unaffiliated
          third-party. We may assign any or all our rights and obligations under
          these Terms to any third-party.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          If any clause or part of any clause of these Terms is found to be
          void, unenforceable or invalid, then it will be severed from these
          Terms, leaving the remainder in full force and effect, provided that
          the severance has not altered the basic nature of these Terms.
        </div>

        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          No single or partial exercise, or failure or delay in exercising any
          right, power or remedy by us shall constitute a waiver by us of, or
          impair or preclude any further exercise of, that or any right, power
          or remedy arising under these terms and conditions or otherwise. If
          any of the provisions in these Terms are found to be illegal, invalid
          or unenforceable by any court of competent jurisdiction, the remainder
          shall continue in full force and effect.
        </div>

        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          All disclaimers, indemnities and exclusions in these Terms shall
          survive termination of the Terms and shall continue to apply during
          any suspension or any period during which the Site is not available
          for you to use for any reason whatsoever.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          Any dispute, controversy, or claim arising out of or in relation to
          these Terms, including the validity, invalidity, breach or termination
          thereof, shall be settled by arbitration in accordance with the Swiss
          Rules of International Arbitration of the Swiss Chambers of Commerce
          in force on the date when the Notice of Arbitration is submitted in
          accordance with these Rules. The number of arbitrators shall be one or
          three; the seat of the arbitration shall be determined by the
          arbitrator(s); the arbitral proceedings shall be conducted in English.
          The applicable law shall be Estonian law or another choice of law
          determined in Metaverse Starter Platform’s sole discretion.
        </div>

        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          With respect to all persons and entities, regardless of whether they
          have obtained or used the site for personal, commercial or other
          purposes, all disputes, controversies or claims must be brought in the
          parties’ individual capacity, and not as a plaintiff or class member
          in any purported class action, collective action or other
          representative proceeding. This waiver applies to class arbitration,
          and, unless we agree otherwise, the arbitrator may not consolidate
          more than one person’s claims. You agree that, by entering into this
          agreement, you and Metaverse Starter Platform are each waiving the
          right to a trial by jury or to participate in a class action,
          collective action, or other representative proceeding of any kind.
        </div>
        <h5 className={styles['title']}>15. Force Majeure</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          There is a risk that transactions effected through the Site may be
          affected by system delays, failures resulting from adverse events,
          natural disasters, pandemics and other emergencies, as well as
          unforeseen significant changes in the external environment. With
          regard to opportunity loss (e.g., loss of opportunity to place a
          payment instruction, resulting in loss of profits which could have
          been obtained) due to occurrences such as emergency situations and
          force majeure events, Metaverse Starter Platform is under no
          obligation to take any corrective action or measure and shall no under
          circumstances be liable for any lost profits or other trading losses.
        </div>
        <h5 className={styles['title']}>16. Miscellaneous</h5>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          <span className={styles['text_underline']}>Severability</span>: If a
          provision of this Agreement is or becomes illegal, invalid, or
          unenforceable in any jurisdiction, that shall not affect the validity
          or enforceability in that jurisdiction of any other provision hereof
          or the validity or enforceability in other jurisdictions of that or
          any other provision hereof.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          <span className={styles['text_underline']}>Assignment</span>:
          Metaverse Starter reserves the right to assign this Agreement, in
          whole or in part, at any time without notice. The User may not assign
          any of his/her rights or obligations under this Agreement.
        </div>
        <div
          className={`${styles['text-content']} ${styles['padding-section']}`}
        >
          <span className={styles['text_underline']}>Third-Party Rights</span>:
          Unless expressly provided to the contrary in this Agreement, a person
          who is not a party to this Agreement has no right to enforce or to
          enjoy the benefit of any term of this Agreement. Notwithstanding any
          term of this Agreement, no consent of any party who is not a party to
          this Agreement shall be required for the waiver, variation, or
          termination of any part of this Agreement.
        </div>
        <h5 className={styles['title']}>17. Contact Us</h5>
        <div>
          Please contact us if you have any questions about these Terms or other
          topics, at
          <a href="https://t.me/bunicornswap_en">
            https://t.me/bunicornswap_en
          </a>{' '}
          or{' '}
          <a href="https://twitter.com/bunicornswap?lang=en">
            https://twitter.com/bunicornswap?lang=en
          </a>
        </div>
      </div>
    </BasePage>
  );
};

export default PageTermOfUse;
