import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import BasePage from '../BasePage';
import styles from 'src/styles/pages/LPB/Lbp.module.scss';
import {
  Overview,
  PoolSetup,
  PoolInfo,
  AuctionPreview,
  CreateLbp,
} from './steps';
import { LeftSide } from './parts';
import Storage from 'src/utils/storage';
import {
  AuctionType,
  DEFAULT_CURRENCY,
  DEFAULT_SWAP_FEE,
  RefStep,
} from 'src/utils/utils-auction';
import { ArrowNextIcon } from 'src/assets/icons';
import AppAlertWarning from 'src/components/AppAlertWarning';
import useAuth from 'src/hooks/useAuth';

export enum STEPS {
  CONFIG_OVERVIEW,
  POOL_SETUP,
  POOL_INFO,
  POOL_PREVIEW,
  CREATE_LBP,
  CREATE_SUCCESS,
}

const LbpPage = () => {
  const { network: networkStore } = useSelector(
    (state: RootState) => state.authentication,
  );

  const defaultStartDate = moment(
    moment.utc().add(15, 'm').startOf('m').format('yyyy-MM-DD HH:mm:ss'),
  );

  const initialLbpState: AuctionType = {
    network: networkStore,
    token: {
      address: '',
      name: '',
      symbol: '',
      decimals: 0,
      totalSupply: '',
      logo: '',
    },
    collateralToken: {
      address: '',
      name: '',
      symbol: DEFAULT_CURRENCY,
      decimals: 0,
    },
    depositToken: {
      launch: '',
      collateral: '',
    },
    duration: {
      startDate: defaultStartDate.toDate(),
      endDate: defaultStartDate.add(3, 'days').toDate(),
    },
    weights: {
      startWeight: 0.9,
      endWeight: 0.1,
    },
    description: '',
    media: {
      website: '',
      telegram: '',
      twitter: '',
      discord: '',
      medium: '',
    },
    countries: [],
    swapFee: DEFAULT_SWAP_FEE,
    permissions: {
      pauseTrading: true,
      pullLiquidity: true,
    },
    step: STEPS.CONFIG_OVERVIEW,
  };

  const [activeStep, setActiveStep] = useState<
    typeof STEPS[keyof typeof STEPS]
  >(STEPS.CONFIG_OVERVIEW);
  const [auction, setAuction] = useState<AuctionType>(initialLbpState);
  const [auctionNetwork, setAuctionNetwork] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const childRef = useRef<RefStep>(null);
  const { user } = useAuth();

  useEffect(() => {
    // scroll to top of view
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    if (!auction || !auction.token.address) {
      return;
    }
    Storage.setAuction(auction);
  }, [auction]);

  useEffect(() => {
    const storageAuction = Storage.getAuction();
    if (storageAuction) {
      setAuction({
        ...storageAuction,
        duration: {
          startDate: new Date(storageAuction.duration.startDate),
          endDate: new Date(storageAuction.duration.endDate),
        },
      });

      setActiveStep(storageAuction.step);
    }
  }, []);

  useEffect(() => {
    const storageAuction = Storage.getAuction();
    if (storageAuction) {
      setAuctionNetwork(storageAuction.network);
    }
  }, [auction]);

  useEffect(() => {
    const storageAuction = Storage.getAuction();
    if (!storageAuction) {
      setActiveStep(STEPS.CONFIG_OVERVIEW);
      setAuction(initialLbpState);
    }
  }, [user]);

  const goToNextStep = async (step: typeof STEPS[keyof typeof STEPS]) => {
    try {
      if (childRef && childRef.current) {
        childRef.current.validate && (await childRef.current.validate());
        if (childRef.current.auction) {
          setAuction({ ...childRef.current.auction, step: step + 1 });
        } else {
          setAuction({ ...auction, step: step + 1 });
        }
      }
      setActiveStep(step + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const goToPreviousStep = (step: typeof STEPS[keyof typeof STEPS]) =>
    setActiveStep(step - 1);

  const _renderStepWrapper = ({ title, subTitle, renderContent }: any) => {
    return (
      <>
        <div className={styles['wrap-item']}>
          <div className={styles['card']}>
            <div className={styles['card-header']}>
              <div className={styles['title']}>
                {activeStep !== STEPS.CONFIG_OVERVIEW && (
                  <div className={styles['adornment-start']}>
                    <ArrowNextIcon
                      onClick={() => {
                        if (isSuccess) return;
                        setActiveStep(+activeStep - 1);
                      }}
                    />
                  </div>
                )}

                <span className={styles['text']}>{title}</span>
              </div>
              <div className={styles['sub-title']}>{subTitle}</div>
            </div>
          </div>
        </div>
        <>{renderContent ? renderContent : null}</>
      </>
    );
  };

  const stepConfigs = [
    {
      step: STEPS.CONFIG_OVERVIEW,
      title: 'Main token Information',
      subTitle:
        "Select the blockchain you would like to create an Token Launch Auction on and enter the main token's details.",
      content: (
        <Overview
          ref={childRef}
          auction={auction}
          onClickNext={() => goToNextStep(STEPS.CONFIG_OVERVIEW)}
          onChangeNetworkAuction={(value) => setAuctionNetwork(value)}
        />
      ),
    },
    {
      step: STEPS.POOL_SETUP,
      title: 'Detail Configuration',
      subTitle: 'Fill out Configuration details.',
      content: (
        <PoolSetup
          ref={childRef}
          auction={auction}
          onClickNext={() => goToNextStep(STEPS.POOL_SETUP)}
          onClickBack={() => goToPreviousStep(STEPS.POOL_SETUP)}
        />
      ),
    },
    {
      step: STEPS.POOL_INFO,
      title: 'Detail Configuration',
      subTitle: 'Fill out Configuration details.',
      content: (
        <PoolInfo
          ref={childRef}
          auction={auction}
          onClickNext={() => goToNextStep(STEPS.POOL_INFO)}
          onClickBack={() => goToPreviousStep(STEPS.POOL_INFO)}
        />
      ),
    },
    {
      step: STEPS.POOL_PREVIEW,
      title: 'Detail Configuration',
      subTitle:
        'Please double check everything before creating the Token Launch Auction.',
      content: (
        <AuctionPreview
          ref={childRef}
          auction={auction}
          onClickNext={() => goToNextStep(STEPS.POOL_PREVIEW)}
          onClickBack={() => goToPreviousStep(STEPS.POOL_PREVIEW)}
        />
      ),
    },
    {
      step: STEPS.CREATE_LBP,
      title: 'Sign Creation Transactions',
      subTitle:
        'Now that your auction configuration is ready you need to deploy it to the blockchain network. To do so you must sign 3 transactions using your wallet',
      content: (
        <CreateLbp
          ref={childRef}
          auction={auction}
          onClickBack={() => goToPreviousStep(STEPS.CREATE_LBP)}
          onSuccess={() => setIsSuccess(true)}
        />
      ),
    },
  ];

  const _renderCurrentStep = (step: typeof STEPS[keyof typeof STEPS]) => {
    const stepConfig = stepConfigs.find((item) => item.step === step);
    if (!stepConfig) {
      return null;
    }
    return _renderStepWrapper({
      title: stepConfig.title,
      subTitle: stepConfig.subTitle,
      renderContent: stepConfig.content,
    });
  };

  const _renderWrongNetwork = () => {
    if (!auctionNetwork) return;
    return (
      <AppAlertWarning acceptedNetwork={auctionNetwork}>
        Connected wallet does not match target network of Token Launch Auction
      </AppAlertWarning>
    );
  };

  return (
    <BasePage>
      <div className={styles['create-lbp-page']}>
        {_renderWrongNetwork()}
        <div className={styles['dashboard']}>
          <LeftSide activeStep={activeStep} isSuccess={isSuccess} />
          <div className={styles['detail']}>
            <div className={styles['main-content']}>
              <div className={styles['container']}>
                {_renderCurrentStep(activeStep)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default withRouter(LbpPage);
