import React, { ChangeEvent, useState } from 'react';
import { Tabs, Tab, TabProps } from '@material-ui/core';
import styles from 'src/styles/pages/INODetail/INODescription.module.scss';
import { Project, ProjectInterface } from 'src/utils/project';
import { InoDetailsType } from 'src/utils/pool';
import ProjectContentView from 'src/components/ProjectContentView';

interface INODescriptionProps {
  project: ProjectInterface;
  inoDetails: InoDetailsType;
}
const INODescription = ({ project, inoDetails }: INODescriptionProps) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const projectInfo = new Project(project);

  const onChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <div className={styles['description']}>
      <div className={styles['content']}>
        <Tabs
          value={tabValue}
          onChange={onChange}
          classes={{
            root: styles['tabs'],
            indicator: styles['indicator'],
          }}
        >
          <CustomTab label="Description" />
          <CustomTab label="Series Content" />
        </Tabs>
      </div>
      <TabPanel value={tabValue} index={0}>
        <ProjectContentView
          project={projectInfo}
          classNameInfo={styles['project-info']}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div
          dangerouslySetInnerHTML={{ __html: inoDetails.serialContents || '' }}
          className={styles['table']}
        ></div>
      </TabPanel>
    </div>
  );
};

export default INODescription;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return <div {...rest}>{value === index && children}</div>;
};

const CustomTab = (props: TabProps) => {
  const { classes, ...rest } = props;
  return (
    <Tab
      classes={{
        root: styles['custom-tab'],
        selected: styles['selected'],
        ...classes,
      }}
      {...rest}
    />
  );
};
