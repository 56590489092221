import Pagination, {
  PaginationProps,
  PaginationRenderItemParams,
} from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { FC } from 'react';
import styles from 'src/styles/components/AppPagination.module.scss';

const AppPagination: FC<PaginationProps> = (props: PaginationProps) => {
  return (
    <Pagination
      {...props}
      variant="outlined"
      shape="rounded"
      {...props}
      renderItem={(params: PaginationRenderItemParams) => (
        <PaginationItem
          {...params}
          classes={{
            page: styles['page'],
            disabled: styles['disabled'],
            ellipsis: styles['ellipsis'],
            selected: styles['selected'],
          }}
        />
      )}
    />
  );
};
export default AppPagination;
