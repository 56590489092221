import { UserInterface } from './user';
import { IdoTimeline, INOTimeline, TimelineInterface } from './timelines';
import { Project, ProjectInterface } from './project';
import { getNetworkConfig, getSolanaNetworkId } from './utils-network';
import { convertWeiToDec, formatTierAsNumber } from './utils-formats';
import config from 'src/config';
import { SolanaProviderType } from 'src/connectors';
import {
  getSolanaProvider,
  isPurchaseWithNativeToken,
  IWinnerResponseType,
} from './utils-pool';

export type Erc20TokenType = {
  symbol: string;
  decimals: number;
  logoUrl?: string;
};

export type JoinConditionsType = {
  kyc: boolean;
  tiers: string[];
};

export type ClaimScheduleType = {
  startTime: number;
  maxClaimablePercentage: number;
};

export type winingTicketType = {
  tier: string;
  allocationSize: string;
  individualMinTicket: number;
  individualMaxTicket: number;
};

export type CurrencyType = {
  id: string;
  network: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  coinGeckoId: string;
};

export type TokenType = {
  network: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoUrl: string;
};

export type IdoDetailsType = {
  token: TokenType;
  maxCap: string;
  price: string;
  ticketAllocation: {
    tokenPerTicket: string;
    winingTickets: winingTicketType[];
  };
  claimSchedules: ClaimScheduleType[];
};

export type PackageType = {
  packageId: string | number;
  name: string;
  logoUrl: string;
  quantity: number;
  price: string;
  sold: number;
};

export type InoDetailsType = {
  maxCap: number;
  packages: PackageType[];
  token: TokenType;
  claimSchedules: ClaimScheduleType[];
  ticketAllocation: {
    boxPerTicket: number;
    winingTickets: winingTicketType[];
  };
  serialContents?: string;
};

export type PoolResponseType = {
  id: string;
  project: ProjectInterface;
  ownerAddress: string;
  name: string;
  type: string;
  network: string;
  currency: CurrencyType;
  currencyPriceUSD: number;
  receiveFundAddress: string;
  contractAddress: string;
  creationTx: string;
  conditionsOfParticipation: {
    isKycRequired: boolean;
    eligibleTiers: string[];
  };
  featureImageUrl: string;
  buyingRules: string;
  whitelistSetting: {
    startTime: number;
    endTime: number;
  };
  saleSetting: {
    startTime: number;
    endTime: number;
  };
  freeBuySetting: {
    endTime: number;
    maxAllocationPerUser: string;
  };
  isKycRequired: boolean;
  isHidden: boolean;
  idoDetails: IdoDetailsType;
  inoDetails: InoDetailsType;
  lbpDetails: any;
  status: string;
};

export class Pool {
  protected id = '';
  protected name = '';
  protected network = '';
  protected contractAddress = '';
  protected joinConditions: JoinConditionsType = {
    kyc: false,
    tiers: [],
  };
  protected collateralCurrency: Erc20TokenType | null = null;
  protected timeline: TimelineInterface | null = null;
  protected project: ProjectInterface | null = null;

  protected freeBuyAllocation = 0;
  protected currencyInUSD = 0;
  protected hasFreeBuyPhase = false;

  protected solanaProvider: SolanaProviderType | null = null;

  // TODO: define pool detail type
  constructor(pool: PoolResponseType) {
    if (!pool) {
      return;
    }
    this.hasFreeBuyPhase = !!pool.freeBuySetting;
    this.freeBuyAllocation =
      Number(pool.freeBuySetting?.maxAllocationPerUser) || 0;
    this.currencyInUSD = pool.currencyPriceUSD;

    this.setId(pool.id);
    this.setName(pool.name);
    this.setContractAddress(pool.contractAddress);
    this.setNetwork(pool.network);

    this.setCollateralCurrency({
      symbol: pool.currency?.symbol || '',
      decimals: Number(pool.currency?.decimals || 18),
    });

    this.setJoinConditions({
      kyc: pool.isKycRequired,
      tiers: pool.conditionsOfParticipation?.eligibleTiers || [],
    });
    const project = new Project(pool.project);
    this.setProject(project);

    const timeline = new IdoTimeline(pool);
    this.setTimeline(timeline);
  }

  setId(id: string) {
    return (this.id = id);
  }

  getId(): string {
    return this.id;
  }

  setName(name: string) {
    return (this.name = name);
  }

  getName(): string {
    return this.name;
  }

  setNetwork(network: string) {
    return (this.network = network);
  }

  getNetwork(): string {
    return this.network;
  }

  setContractAddress(contractAddress: string) {
    return (this.contractAddress = contractAddress);
  }

  getContractAddress(): string {
    return this.contractAddress;
  }

  setCollateralCurrency(collateralCurrency: Erc20TokenType) {
    return (this.collateralCurrency = collateralCurrency);
  }

  getCollateralCurrency(): Erc20TokenType | null {
    return this.collateralCurrency;
  }

  getCollateralCurrencyDecimals(): number {
    return this.collateralCurrency?.decimals || 18;
  }

  getCollateralCurrencySymbol(): string {
    return this.collateralCurrency?.symbol || '';
  }

  getCollateralCurrencyIcon(): string | undefined {
    if (!this.getNetwork()) {
      return '';
    }
    if (
      isPurchaseWithNativeToken(
        this.getNetwork(),
        this.getCollateralCurrencySymbol(),
      )
    ) {
      return this.getNetworkIcon();
    }
    return config.networks[this.getNetwork()].currencies[
      this.getCollateralCurrencySymbol().toLowerCase()
    ].icon;
  }

  getCollateralCurrencyAddress(): string {
    const networkCurrencies = config.networks[this.getNetwork()].currencies;
    if (
      !networkCurrencies ||
      !networkCurrencies[this.getCollateralCurrencySymbol().toLowerCase()]
    ) {
      return '';
    }
    return networkCurrencies[this.getCollateralCurrencySymbol().toLowerCase()]
      .address;
  }

  setJoinConditions(joinConditions: JoinConditionsType) {
    return (this.joinConditions = joinConditions);
  }

  getJoinConditions(): JoinConditionsType {
    return this.joinConditions;
  }

  setTimeline(timeline: TimelineInterface) {
    return (this.timeline = timeline);
  }

  getTimeline(): TimelineInterface | null {
    return this.timeline;
  }

  setProject(project: ProjectInterface) {
    return (this.project = project);
  }

  getProject(): ProjectInterface | null {
    return this.project;
  }

  getFreeBuyAllocation(): number {
    return this.freeBuyAllocation;
  }

  getCurrencyInUSD(): number {
    return this.currencyInUSD;
  }

  getTiers() {
    return this.joinConditions.tiers;
  }

  getNetworkIcon(): string | undefined {
    const networkConfig = getNetworkConfig(this.network);
    return networkConfig?.icon;
  }

  isPoolDeployed(): boolean {
    return !!this.contractAddress;
  }

  doesPoolHasFreeBuyPhase(): boolean {
    return this.hasFreeBuyPhase;
  }

  doesPoolRequireKYC(): boolean {
    return this.joinConditions.kyc;
  }
}

export class IDOPool extends Pool {
  protected claimSchedules: ClaimScheduleType[];
  protected capacity = 0;
  protected swapRate = '0';
  protected imageUrl = '';

  protected swapToken: Erc20TokenType | null = null;

  constructor(pool: PoolResponseType) {
    super(pool);
    this.claimSchedules = pool.idoDetails?.claimSchedules;
    this.setSwapToken({
      symbol: pool.idoDetails?.token?.symbol || '',
      decimals: Number(pool.idoDetails?.token?.decimals || 18),
      logoUrl: pool.idoDetails?.token?.logoUrl,
    });
    this.setCapacity(+pool.idoDetails?.maxCap || 0);
    this.setSwapRate(pool.idoDetails?.price || '0');
    this.imageUrl = pool.featureImageUrl;
  }

  setSwapToken(swapToken: Erc20TokenType) {
    return (this.swapToken = swapToken);
  }

  getSwapToken(): Erc20TokenType | null {
    return this.swapToken;
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getSwapTokenDecimals(): number {
    return this.swapToken?.decimals || 18;
  }

  getSwapTokenSymbol(): string {
    return this.swapToken?.symbol || '';
  }

  getSwapTokenLogoUrl(): string {
    return this.swapToken?.logoUrl || '';
  }

  setCapacity(capacity: number) {
    return (this.capacity = capacity);
  }

  getCapacity(): number {
    return this.capacity;
  }

  setSwapRate(swapRate: string) {
    return (this.swapRate = swapRate);
  }

  getWeiSwapRate(): string {
    return this.swapRate;
  }

  getSwapRate(): string {
    return convertWeiToDec(
      this.getWeiSwapRate(),
      this.getCollateralCurrencyDecimals(),
    );
  }

  getClaimSchedules(): ClaimScheduleType[] {
    return this.claimSchedules;
  }

  isTierEligible(user: UserInterface): boolean {
    const poolTiers = this.getTiers();
    const userTier = user.getTier();
    if (!poolTiers || !userTier) return true;
    return poolTiers.some((tier: string) => tier === userTier);
  }

  isTierUnstaked(
    user: UserInterface,
    winner: IWinnerResponseType | null,
  ): boolean {
    if (!winner) {
      return false;
    }
    return (
      formatTierAsNumber(user.getTier()) < formatTierAsNumber(winner.userTier)
    );
  }

  isDisableTierUnstaked(
    user: UserInterface,
    winner: IWinnerResponseType | null,
  ): boolean {
    return (
      this.isTierUnstaked(user, winner) &&
      !!this.getTimeline()?.isPrivateSwapPhase()
    );
  }

  isKYCValid(user: UserInterface): boolean {
    return this.doesPoolRequireKYC() ? user.isKyced() : true;
  }

  isNetworkSolana(): boolean {
    return this.getNetwork() === config.networks[getSolanaNetworkId()].id;
  }

  isSolanaConnected(user: UserInterface): boolean {
    return this.isNetworkSolana() && !!getSolanaProvider(this, user);
  }

  isNetworkCorrect(user: UserInterface): boolean {
    if (this.isNetworkSolana()) {
      return this.isSolanaConnected(user);
    }
    const networkConfig = getNetworkConfig(this.network);
    return user.getNetwork().toLowerCase() === networkConfig?.id.toLowerCase();
  }

  isEnoughJoinConditions(user: UserInterface): boolean {
    return user && this.isKYCValid(user) && this.isTierEligible(user);
  }

  canJoinWhitelistPhase(user: UserInterface): boolean {
    return (
      user && this.isEnoughJoinConditions(user) && this.isNetworkCorrect(user)
    );
  }

  canJoinTokenSalePhase(user: UserInterface): boolean {
    return user && this.isKYCValid(user) && this.isNetworkCorrect(user);
  }

  canJoinClaimPhase(user: UserInterface): boolean {
    return user && this.isKYCValid(user) && this.isNetworkCorrect(user);
  }

  canPurchaseToken(user: UserInterface): boolean {
    return user && this.isKYCValid(user) && this.isNetworkCorrect(user);
  }

  canClaimToken(user: UserInterface | null): boolean {
    return !!user && this.isKYCValid(user) && this.isNetworkCorrect(user);
  }
}

export class INOPool extends IDOPool {
  protected packages: PackageType[];

  constructor(pool: PoolResponseType) {
    super(pool);
    this.claimSchedules = pool.inoDetails?.claimSchedules;
    this.setSwapToken({
      symbol: pool.inoDetails?.token?.symbol || '',
      decimals: Number(pool.inoDetails?.token?.decimals || 18),
      logoUrl: pool.inoDetails?.token?.logoUrl,
    });
    this.setCapacity(+pool.inoDetails?.maxCap || 0);
    this.imageUrl = pool.featureImageUrl;
    this.packages = pool?.inoDetails?.packages;
    const timeline = new INOTimeline(pool);
    this.setTimeline(timeline);
  }

  setPackages(packages: PackageType[]) {
    this.packages = packages;
  }
  getPackages(): PackageType[] {
    return this.packages;
  }
}
