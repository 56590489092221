import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rf from 'src/requests/RequestFactory';
import { RootState } from 'src/store';
import { getNetworkProvider } from 'src/utils/utils-network';
import {
  ILinkedAccount,
  ILinkedProvider,
  ITierInfo,
  TierEnum,
} from 'src/utils/common';
import { formatKysStatusAsString } from 'src/utils/utils-formats';
import { getUserStakingPool } from 'src/utils/utils-farm';

interface MyAccountState {
  balance: string;
  isLoadingBalance: boolean;
  myTier?: ITierInfo | null;
  userInfo: {
    email?: string;
    kycStatus?: number;
    kycStatusText?: string;
    userAddress?: string;
    tier?: TierEnum | any;
    buniToNextTier?: number;
    stakingTokens?: [
      {
        poolAddress: string;
        stakingTokenAddress: string;
        stakedAmount: string | number;
        rate: number;
        stakingTokenName: string;
      },
    ];
    amountStaked?: string;
    rank?: number;
  };
  stakedInfo: {
    buniStake: string;
    lpStake: string;
  };
  linkedAccounts?: ILinkedAccount[];
  linkedProviders?: Array<ILinkedProvider>;
}

const initialState: MyAccountState = {
  isLoadingBalance: false,
  myTier: null,
  balance: '0',
  userInfo: {
    kycStatusText: '',
    kycStatus: 0,
  },
  stakedInfo: {
    buniStake: '0',
    lpStake: '0',
  },
  linkedAccounts: [],
  linkedProviders: [],
};

export const getAccountBalance = createAsyncThunk(
  'myAccount/getAccountBalance',
  async (params, thunkApi) => {
    const { getState } = thunkApi;

    const {
      authentication: { address: account, connector, network },
    } = getState() as RootState;
    if (!account || !connector) {
      return 0;
    }
    const provider = getNetworkProvider(network);
    const balance = await provider.getBalance(account);
    return balance.toString();
  },
);

export const getMyTier = createAsyncThunk(
  'myAccount/getMyTier',
  async (params: { tier: string }, thunkApi) => {
    const { tier } = params;
    const { getState } = thunkApi;
    const {
      metadata: { tiers },
    } = getState() as RootState;

    const myTier = tiers.find((value) => value.tier === tier);
    return myTier || null;
  },
);

export const getUserStacked = createAsyncThunk(
  'myAccount/getUserStacked',
  async (params, thunkApi) => {
    const {
      authentication: { address: account },
    } = thunkApi.getState() as RootState;

    const res = await rf.getRequest('MetaDataRequest').getStakingPools();
    const metadataStakingPools = res.docs || [];

    if (!account) {
      return {};
    }
    let data = {
      buniStake: 0,
      lpStake: 0,
    };
    for (const pool of metadataStakingPools) {
      const userStakingPool = await getUserStakingPool(
        account,
        pool.poolAddress,
      );
      if (!userStakingPool) {
        continue;
      }
      if (
        userStakingPool.stakedAmount &&
        pool.lpSymbol.toLowerCase() === 'buni'
      ) {
        data = { ...data, buniStake: userStakingPool.stakedAmount };
      }
      if (
        userStakingPool.stakedAmount &&
        pool.lpSymbol.toLowerCase() !== 'buni'
      ) {
        data = { ...data, lpStake: userStakingPool.stakedAmount };
      }
    }
    return data;
  },
);

export const getUser = createAsyncThunk(
  'myAccount/getUser',
  async (params, thunkApi) => {
    const res = await rf.getRequest('UserRequest').getUser();
    const tierResponse = await thunkApi.dispatch(getMyTier({ tier: res.tier }));
    return { ...res, myTier: tierResponse.payload };
  },
);

export const getInformationUser = createAsyncThunk(
  'myAccount/getInformationUser',
  async (params, thunkApi) => {
    thunkApi.dispatch(getUser());
    thunkApi.dispatch(getAccountBalance());
    thunkApi.dispatch(getUserStacked());
  },
);

export const myAccount = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    setMyInfo: (state, action) => {
      return {
        ...state,
        info: {
          ...state.stakedInfo,
          ...action.payload,
        },
      };
    },
    clearAccount: (state) => {
      state.balance = '0';
      state.myTier = null;
      state.userInfo = {
        kycStatusText: '',
        kycStatus: 0,
      };
      state.stakedInfo = {
        buniStake: '0',
        lpStake: '0',
      };
      state.isLoadingBalance = false;
      state.linkedAccounts = [];
      state.linkedProviders = [];
    },
    addLinkedProvider: (state, action) => {
      state.linkedProviders?.push(action.payload);
    },
    disconnectLinkedNetwork: (state, action) => {
      return {
        ...state,
        linkedAccounts: state.linkedAccounts?.filter(
          (item: ILinkedAccount) =>
            item.networkAddress !== action.payload.networkAddress &&
            item.networkFamily !== action.payload.networkFamily,
        ),
        linkedProviders: state.linkedProviders?.filter(
          (item) =>
            item.networkAddress !== action.payload.networkAddress &&
            item.networkFamily !== action.payload.networkFamily,
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserStacked.fulfilled, (state, action) => {
        return {
          ...state,
          stakedInfo: {
            ...state.stakedInfo,
            ...action.payload,
          },
        };
      })
      .addCase(getAccountBalance.pending, (state) => {
        state.isLoadingBalance = true;
      })
      .addCase(getAccountBalance.fulfilled, (state, action) => {
        state.balance = action.payload || '0';
        state.isLoadingBalance = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const { myTier, linkedAccounts, ...rest } = action.payload;
        return {
          ...state,
          myTier,
          linkedAccounts: [...linkedAccounts],
          userInfo: {
            ...rest,
            kycStatusText: formatKysStatusAsString(
              action.payload?.kycStatus || 0,
            ),
          },
        };
      });
  },
});

export const { clearAccount, addLinkedProvider, disconnectLinkedNetwork } =
  myAccount.actions;
export default myAccount.reducer;
