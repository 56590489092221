import { ITokenAuction } from './utils-auction';
import { checkIsTokenBase, TokenType } from './utils-token';

interface LBPTokenType extends TokenType {
  icon?: string | undefined;
  latestPriceRate?: string | number | undefined;
}

interface AuctionDraftInfo {
  amounts: string[];
  endTime: number;
  endWeights: string[];
  startTime: number;
  startWeights: string[];
  swapFee: number;
  tokens: LBPTokenType[];
}

export class Auction {
  protected isDraft = false;
  protected isVerified = false;
  protected draftInfo: AuctionDraftInfo | null = null;
  protected pool = null;
  protected network = '';

  constructor(auction: any) {
    if (!auction) {
      return;
    }
    this.setIsDraft(auction.isDraft);
    this.setIsVerified(auction.isVerified);
    this.setDraftInfo(auction.draftInfo);
    this.setPool(auction.pool);
    this.setNetwork(auction.network);
  }

  setIsDraft(isDraft: boolean): boolean {
    this.isDraft = isDraft;
    return this.isDraft;
  }

  getIsDraft(): boolean {
    return this.isDraft;
  }

  setIsVerified(isVerified: boolean): boolean {
    this.isVerified = isVerified;
    return this.isVerified;
  }

  getIsVerified(): boolean {
    return this.isVerified;
  }

  setDraftInfo(draftInfo: AuctionDraftInfo | null) {
    this.draftInfo = draftInfo;
    return this.draftInfo;
  }

  getDraftInfo(): AuctionDraftInfo | null {
    return this.draftInfo;
  }

  setPool(pool: any | null) {
    this.pool = pool;
    return this.pool;
  }

  getPool(): any | null {
    return this.pool;
  }

  setNetwork(network: string) {
    this.network = network;
    return this.network;
  }

  getNetwork(): string {
    return this.network;
  }

  getStartTime(): number {
    return this.getIsDraft()
      ? this.getDraftInfo()?.startTime
      : this.getPool().startTime;
  }

  getEndTime(): number {
    return this.getIsDraft()
      ? this.getDraftInfo()?.endTime
      : this.getPool().endTime;
  }

  getStartWeights(): string[] {
    return this.getIsDraft()
      ? this.getDraftInfo()?.startWeights
      : this.getPool().startWeights;
  }

  getEndWeights(): string[] {
    return this.getIsDraft()
      ? this.getDraftInfo()?.endWeights
      : this.getPool().endWeights;
  }

  getTokens() {
    const tokens = this.getIsDraft()
      ? this.getDraftInfo()?.tokens
      : this.getPool().tokens;
    return tokens.sort((a: any, b: any) => {
      if (a.address.toLowerCase() < b.address.toLowerCase()) {
        return -1;
      }
      return 1;
    });
  }

  getName() {
    return this.getIsDraft() ? '' : this.getPool().name;
  }

  getSymbol() {
    return this.getIsDraft() ? '' : this.getPool().symbol;
  }

  getPoolId() {
    return this.getIsDraft() ? '' : this.getPool().id;
  }

  getTokenBase(): LBPTokenType {
    return this.getTokens().find((item: ITokenAuction) =>
      checkIsTokenBase(item.address, this.getNetwork()),
    );
  }

  getTokenBaseIndex(): number {
    return this.getTokens().findIndex(
      (item: ITokenAuction) => item.address === this.getTokenBase().address,
    );
  }

  getTokenMain(): LBPTokenType {
    return this.getTokens().find(
      (item: ITokenAuction) => item.address !== this.getTokenBase().address,
    );
  }

  getTokenMainIndex(): number {
    return this.getTokens().findIndex(
      (item: ITokenAuction) => item.address === this.getTokenMain().address,
    );
  }

  getPrice(): number | string {
    return this.getTokenMain()?.latestPriceRate || 0;
  }
}
