import styles from 'src/styles/modals/ModalShowInfoDetail.module.scss';
import { FC } from 'react';
import BaseModal from './BaseModal';

interface ModalShowInfoDetailProps {
  open: boolean;
  onClose: () => void;
  personInfo: {
    type: string;
    info: IPersonInfo;
  };
}

interface IPersonInfo {
  avatar: string;
  fullName: string;
  position?: string;
  intro: string;
}

const ModalShowInfoDetail: FC<ModalShowInfoDetailProps> = ({
  open,
  onClose,
  personInfo,
}) => {
  const { type, info } = personInfo;
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      title={type}
    >
      <div className={styles['main']}>
        <div className={styles['team-item']}>
          <div className={styles['avatar']}>
            <img src={info?.avatar} alt="" />
          </div>

          <div className={styles['name']}>{info?.fullName}</div>
          {info?.position && (
            <div className={styles['position']}>{info?.position}</div>
          )}
          <div className={styles['description']}>{info?.intro}</div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalShowInfoDetail;
