import * as React from 'react';
import { useEffect, useState } from 'react';
import { INOPool } from '../../../utils/pool';
import { ModalListItem } from '../../../modals/ModalBuyINO';
import styles from 'src/styles/pages/INODetail/PurchaseInfo.module.scss';
import clsx from 'clsx';
import {
  formatInoBoxes,
  formatTierAsString,
} from '../../../utils/utils-formats';
import { getNetworkConfig } from '../../../utils/utils-network';
import useAuth from '../../../hooks/useAuth';
import { getBoxesUserPurchased } from '../../../utils/utils-pool';
import { usePhaseInfo } from '../../../hooks/usePhaseInfo';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { NOT_AVAILABLE_TEXT } from '../../../utils/common';
import rf from 'src/requests/RequestFactory';

export const RELOAD_PURCHASED_BOXES_AFTER_BUYING =
  'RELOAD_PURCHASED_BOXES_AFTER_BUYING';

const PurchaseInfo = ({ inoPool }: { inoPool: INOPool }) => {
  const timeline = inoPool.getTimeline();
  const { user } = useAuth();
  const { userAllocation, isShowUserAllocation } = usePhaseInfo(inoPool);

  const totalBoxes = inoPool
    .getPackages()
    .reduce((pre, curr) => pre + Number(curr.quantity), 0);
  const network = getNetworkConfig(inoPool.getNetwork())?.name;
  const totalBoxesSold = inoPool
    .getPackages()
    .reduce((pre, curr) => pre + Number(curr.sold), 0);

  const [boxesPurchased, setBoxesPurchased] = useState<string>('0');

  const formatPrivateInfo = (value: string | number) => {
    if (user && !inoPool.isNetworkCorrect(user)) {
      return NOT_AVAILABLE_TEXT;
    }
    return formatInoBoxes(value);
  };

  const getDeposit = async () => {
    return rf.getRequest('PoolRequest').getUserDepositAmount(inoPool.getId());
  };

  const [deposit, setDeposit] = useState<string>('0');

  useEffect(() => {
    getDeposit()
      .then((res) => setDeposit(res.maxBuyAmount))
      .catch((error) => console.log(error));
  }, [user]);

  const purchaseConfigs = [
    {
      name: 'Eligible Tiers',
      value: inoPool
        .getJoinConditions()
        .tiers.map((item) => formatTierAsString(item))
        .join(', '),
      isShow: true,
    },
    {
      name: 'Total Quantity',
      value: formatInoBoxes(totalBoxes),
      isShow: true,
    },
    {
      name: 'Network',
      value: network,
      isShow: true,
    },
    {
      name: 'Total Boxes Sold',
      value: formatInoBoxes(totalBoxesSold),
      isShow: timeline?.isSwapPhase(),
    },
    {
      name: timeline?.isPublicSwapPhase()
        ? 'Your Max Purchase'
        : 'Your Allocation',
      value: formatPrivateInfo(userAllocation),
      isShow: timeline?.shouldHighlightSwapPhase(),
    },
    {
      name: 'KYC required',
      value: inoPool.getJoinConditions().kyc ? 'Yes' : 'No',
      isShow: true,
    },
    {
      name: `Your Remaining${timeline?.isPublicSwapPhase() ? ' FCFS' : ''}`,
      value: formatPrivateInfo(Number(deposit) - Number(boxesPurchased)),
      isShow: isShowUserAllocation(),
    },
    {
      name: 'Your Purchase',
      value: formatPrivateInfo(boxesPurchased),
      isShow: isShowUserAllocation(),
    },
  ];

  const getPurchasedBoxes = async () => {
    if (!user || !inoPool.getContractAddress()) {
      return;
    }
    const boxes = await getBoxesUserPurchased(
      user?.getAddress(),
      inoPool.getContractAddress(),
      inoPool.getNetwork(),
    );
    setBoxesPurchased(boxes.toString());
  };

  // Get your purchase
  useEffect(() => {
    getPurchasedBoxes();
    AppBroadcast.on(RELOAD_PURCHASED_BOXES_AFTER_BUYING, () =>
      getPurchasedBoxes(),
    );
    return () => {
      AppBroadcast.remove(RELOAD_PURCHASED_BOXES_AFTER_BUYING);
    };
  }, [user?.getAddress(), inoPool.getContractAddress()]);

  return (
    <ul
      className={clsx(
        styles['purchase-info'],
        timeline?.afterWhitelistPhase() && styles['purchase-info-border'],
      )}
    >
      {purchaseConfigs.map((item) => {
        return item.isShow ? (
          <ModalListItem
            key={item.name}
            name={item.name}
            value={item.value}
            secondaryColor
            className={styles['purchase-info__item']}
          />
        ) : null;
      })}
    </ul>
  );
};

export default PurchaseInfo;
