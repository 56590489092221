import config from 'src/config';
import { TokenType } from './utils-token';
const env = process.env.REACT_APP_ENV || 'prod';

const PREFERENCES = `metaversestarter-preferences-${env}`;

const LBP = 'Auction';

interface Authentication {
  network: string;
  connectorId?: string;
  chainId?: string;
  address?: string;
  accessToken?: string;
  signature?: string;
}

const defaultAuthentication: Authentication = {
  network: config.defaultNetwork,
};

type LbpOverview = {
  network: string;
  token: {
    address: string;
    balance: string;
    decimals: number;
    logo: string;
    name: string;
    totalSupply: string;
    symbol: string;
  };
};

interface TokenInterface extends TokenType {
  totalSupply: string;
  logo: string;
}

interface CollateralTokenInterface extends TokenType {
  balance: string;
}

interface MediaInterface {
  website: string;
  telegram: string;
  twitter: string;
  discord: string;
  medium: string;
}

interface AuctionType {
  network: string;
  token: TokenInterface;
  collateralToken: CollateralTokenInterface;
  depositToken: {
    launch: number;
    collateral: number;
  };
  duration: {
    startDate: Date;
    endDate: Date;
  };
  weights: {
    startWeight: number;
    endWeight: number;
  };
  description: string;
  media: MediaInterface;
  countries: string[];
  swapFee: number;
  permissions: {
    pauseTrading: boolean;
    pullLiquidity: boolean;
  };
  step: number;
}

type StorageInterface = {
  chainId?: string;
  network: string;
  connectorId?: string;
  address?: string;
  accessToken?: string;
  signature?: string;
  auction?: AuctionType;
  overview?: LbpOverview;
  poolSetup?: any;
  poolInfo?: any;
}; // A | B | C

function getStorage(type: string): StorageInterface {
  switch (type) {
    case PREFERENCES:
      const preferencesString = localStorage.getItem(PREFERENCES);
      const preferences = JSON.parse(preferencesString || '{}');
      return {
        ...defaultAuthentication,
        ...preferences,
      };
    case LBP:
      const lbpString = localStorage.getItem(LBP);
      return JSON.parse(lbpString || '{}');
    default:
      return defaultAuthentication;
  }
}

function setStorage(type: string, value: StorageInterface) {
  switch (type) {
    case PREFERENCES:
      localStorage.setItem(type, JSON.stringify(value));
      break;
    case LBP:
      localStorage.setItem(type, JSON.stringify(value));
      break;
    default:
      break;
  }
}

class Storage {
  static init() {
    const preferences = getStorage(PREFERENCES);
    setStorage(PREFERENCES, preferences);
  }

  static getNetwork(): string {
    const { network } = getStorage(PREFERENCES);
    return network;
  }

  static getConnectorId(): string | undefined {
    const { connectorId } = getStorage(PREFERENCES);
    return connectorId;
  }

  static getAddress(): string | undefined {
    const { address } = getStorage(PREFERENCES);
    return address;
  }

  static getAccessToken(): string | undefined {
    const { accessToken } = getStorage(PREFERENCES);
    return accessToken;
  }

  static getChainId(): string {
    const { chainId } = getStorage(PREFERENCES);
    return chainId || '';
  }

  static setNetwork(network: string) {
    const preferences = getStorage(PREFERENCES);
    preferences.network = network;
    setStorage(PREFERENCES, preferences);
  }

  static setChainId(chainId: string | number) {
    const preferences = getStorage(PREFERENCES);
    preferences.chainId = `${chainId}`;
    setStorage(PREFERENCES, preferences);
  }

  static setConnectorId(connectorId: string) {
    const preferences = getStorage(PREFERENCES);
    preferences.connectorId = connectorId;
    setStorage(PREFERENCES, preferences);
  }

  static setAddress(address: string) {
    const preferences = getStorage(PREFERENCES);
    preferences.address = address;
    setStorage(PREFERENCES, preferences);
  }

  static setAccessToken(accessToken: string) {
    const preferences = getStorage(PREFERENCES);
    preferences.accessToken = accessToken;
    setStorage(PREFERENCES, preferences);
  }

  static clearNetwork() {
    const preferences = getStorage(PREFERENCES);
    preferences.network = config.defaultNetwork;
    setStorage(PREFERENCES, preferences);
  }

  static clearConnectorId() {
    const preferences = getStorage(PREFERENCES);
    delete preferences.connectorId;
    setStorage(PREFERENCES, preferences);
  }

  static clearAccessToken() {
    const preferences = getStorage(PREFERENCES);
    delete preferences.accessToken;
    setStorage(PREFERENCES, preferences);
  }

  static clearSignature() {
    const preferences = getStorage(PREFERENCES);
    delete preferences.signature;
    setStorage(PREFERENCES, preferences);
  }

  static clearAddress() {
    const preferences = getStorage(PREFERENCES);
    delete preferences.address;
    setStorage(PREFERENCES, preferences);
  }

  static clearAuthentication() {
    Storage.clearAccessToken();
    Storage.clearSignature();
  }

  static clearAuction() {
    const preferences = getStorage(PREFERENCES);
    delete preferences.auction;
    setStorage(PREFERENCES, preferences);
  }

  static logout() {
    Storage.clearConnectorId();
    Storage.clearAccessToken();
    Storage.clearSignature();
    Storage.clearAddress();
    Storage.clearAuction();
  }

  static getAuction(): AuctionType | null {
    const preferences = getStorage(PREFERENCES);
    return preferences.auction || null;
  }

  static setAuction(values: any) {
    const preferences = getStorage(PREFERENCES);
    preferences.auction = { ...preferences.auction, ...values };
    setStorage(PREFERENCES, preferences);
  }
}

export default Storage;
