import { Contract, ethers } from 'ethers';
import abi from 'src/abi';
import { Interface as AbiInterface } from '@ethersproject/abi';
import { BaseProvider } from '@ethersproject/providers';

export function getContract(
  address: string,
  ABI: AbiInterface,
  provider: BaseProvider,
): Contract {
  if (!ethers.utils.isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, provider);
}

export const stakingPoolContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['StakingPool'], provider);
};

export const erc20Contract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['Erc20'], provider);
};

export const preSalePoolContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['PreSalePool'], provider);
};

export const multicallContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['Multicall'], provider);
};

export const LBPContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['LBP'], provider);
};

export const vaultContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['Vault'], provider);
};

export const LBPProxyContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['LBPProxy'], provider);
};

export const inoPoolContract = (
  address: string,
  provider: BaseProvider,
): Contract => {
  return getContract(address, abi['InoPool'], provider);
};
