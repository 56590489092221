import { WalletLinkConnector as WalletLinkProvider } from '@web3-react/walletlink-connector';
import BaseConnector from './BaseConnector';

class WalletLinkConnector extends BaseConnector {
  getName() {
    return 'Coinbase Wallet';
  }

  getId(): string {
    return 'wallet-link-connector';
  }

  logout() {
    if (this.provider.close) this.provider.close();
    else super.logout();
  }

  /**
   * connect wallet
   * @returns provider
   */
  async connect() {
    const connector = new WalletLinkProvider(this.options);
    await connector.activate();
    const provider = await connector.getProvider();
    this.connector = connector;
    this.provider = provider;
    return provider;
  }
}

export default WalletLinkConnector;
