import { Collapse, withWidth } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import {
  formatShortAddress,
  formatTimestamp,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import AppCountdown from 'src/components/AppCountdown';
import AppButton from 'src/components/AppButton';
import { useSelector } from 'react-redux';
import styles from 'src/styles/pages/StakingPools.module.scss';
import {
  SnapshotIcon,
  CongratulationIcon,
  WarningRedIcon,
  ClockIcon,
  FlagIcon,
} from 'src/assets/icons';
import { RootState } from 'src/store';
import { withRouter } from 'react-router';
import StakingCardUser from 'src/components/StakingCardUser';
import { NOT_AVAILABLE_TEXT } from 'src/utils/common';
import { isMobile } from 'react-device-detect';
import useAuth from 'src/hooks/useAuth';
import { toastError, toastSuccess } from 'src/utils/utils-notify';
import { getErrorMessage } from 'src/utils/utils-helpers';
import { getValueStaked } from './PartDiamondUsers';

const GOLD_TIER = 3;
const DIAMOND_TIER = 5;

const PartStakingCompetition = () => {
  const { user } = useAuth();
  const [usersCompetition, setUsersCompetition] = useState<any>([]);
  const [snapshotLatestCompetition, setSnapshotLatestCompetition] =
    useState<any>({});
  const [myUserInfo, setMyUserInfo] = useState<any>({});
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [openSnapshot, setOpenSnapshot] = useState(false);
  const [liveCompetition, setLiveCompetition] = useState<any>(null);

  const { address: connectedAccount, token: accessToken } = useSelector(
    (state: any) => state.authentication,
  );

  const { myTier } = useSelector((state: RootState) => state.myAccount);

  const getLiveCompetition = async () => {
    try {
      const response = await rf
        .getRequest('CompetitionsRequest')
        .getLiveCompetition();
      setLiveCompetition(response);
    } catch (error) {
      setLiveCompetition(null);
    }
  };

  useEffect(() => {
    getLiveCompetition();
  }, []);

  const getSnapshotLatestCompetition = async () => {
    try {
      const params = {
        competitionId: liveCompetition.competitionId,
        page: 1,
        limit: 1,
      };
      const response = await rf
        .getRequest('CompetitionsRequest')
        .getSnapshotCompetition(params);
      setSnapshotLatestCompetition(response?.docs[0]);
      setUsersCompetition(response?.docs[0]?.rankedUsers);
    } catch (error) {
      setSnapshotLatestCompetition({});
    }
  };

  const getSnapshotById = async () => {
    try {
      const response = await rf
        .getRequest('CompetitionsRequest')
        .getSnapshotCompetitionById(liveCompetition?.finalizedSnapshotId);

      setSnapshotLatestCompetition(response);
      setUsersCompetition(response?.rankedUsers);
    } catch (error) {
      setSnapshotLatestCompetition({});
    }
  };

  const getMyLiveCompetition = async () => {
    try {
      const response = await rf
        .getRequest('CompetitionsRequest')
        .getMyCompetition();
      if (!!Object.keys(response).length) {
        setMyUserInfo(response);
        setIsJoined(!!response.registeredAt);
      }
    } catch (error) {
      setMyUserInfo({});
    }
  };

  useEffect(() => {
    if (liveCompetition) {
      if (isFinalizedCompetition) {
        getSnapshotById();
      } else {
        getSnapshotLatestCompetition();
      }
      user?.getAddress() && getMyLiveCompetition();
    }
  }, [liveCompetition, accessToken]);

  const isActiveCompetition = useMemo(() => {
    return (
      liveCompetition &&
      moment(liveCompetition.startTime).valueOf() <= moment().valueOf() &&
      moment().valueOf() <= moment(liveCompetition.endTime).valueOf()
    );
  }, [liveCompetition]);

  const isStarted = useMemo(() => {
    return (
      liveCompetition &&
      moment(liveCompetition.startTime).valueOf() <= moment().valueOf()
    );
  }, [liveCompetition]);

  const isEnded = useMemo(() => {
    return (
      liveCompetition &&
      moment(liveCompetition.endTime).valueOf() < moment().valueOf()
    );
  }, [liveCompetition]);

  const availableJoin = useMemo(() => {
    return !!connectedAccount && myTier?.level === GOLD_TIER;
  }, [myTier, connectedAccount]);

  const renderRank = (rank: any) => {
    const lastDigit = String(rank).slice(-1);
    if (+rank === 11 || +rank === 12 || +rank == 13) {
      return `${rank}th`;
    }

    if (+lastDigit === 1) return `${rank}st`;
    if (+lastDigit === 2) return `${rank}nd`;
    if (+lastDigit === 3) return `${rank}rd`;

    return `${rank}th`;
  };

  const renderCountdown = (duration: any) => {
    return (
      <>
        {Math.floor(duration / (3600 * 24)) > 0 && (
          <>
            <span>{Math.floor(duration / (3600 * 24))}</span> Days{' : '}
          </>
        )}
        <span>{moment.utc(duration * 1000).format('HH')}</span> Hours{' : '}
        <span>{moment.utc(duration * 1000).format('mm')}</span> Minutes{' : '}
        <span>{moment.utc(duration * 1000).format('ss')}</span> Seconds
      </>
    );
  };

  const myStakingAsString = () => {
    if (!myUserInfo.stakingTokens) {
      return '--';
    }

    const stakingInfoBuniToken = myUserInfo.stakingTokens.find(
      (item: any) => item.stakingTokenName.toLowerCase() === 'buni',
    );
    const stakingInfoBPTToken = myUserInfo.stakingTokens.find(
      (item: any) => item.stakingTokenName.toLowerCase() === 'wbnb',
    );

    const amountBuni = stakingInfoBuniToken?.stakedAmount;
    const amountBPT = stakingInfoBPTToken?.stakedAmount;

    if (amountBuni && amountBPT) {
      return (
        <>
          {formatWeiNumber(amountBuni)} BUNI & {formatWeiNumber(amountBPT)} BPT
        </>
      );
    }

    if (amountBuni) {
      return <>{formatWeiNumber(amountBuni)} BUNI</>;
    }

    if (amountBPT) {
      return <>{formatWeiNumber(amountBPT)} BPT</>;
    }
    return '--';
  };

  const renderProgressMobile = () => {
    return (
      <div className={`${styles['competition-info']} row`}>
        <div
          onClick={() => setOpenProgress((openProgress) => !openProgress)}
          className={styles['card-staking-header']}
        >
          <div className={styles['flex-header']}>
            <ClockIcon className={styles['icon']} />
            <span className={styles['title']}>
              {!isEnded ? 'Ending in: ' : 'Ended'}
            </span>
            <span
              className={`${styles['icon-collapse']} ${
                !openProgress ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        {isActiveCompetition && (
          <div className={styles['count-down']}>
            <AppCountdown
              endDate={new Date(liveCompetition?.endTime).getTime()}
              render={renderCountdown}
            />
          </div>
        )}

        <Collapse in={openProgress} timeout="auto" unmountOnExit>
          <div className={styles['card-staking-body']}>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>
                <span className={styles['icon']} />
                <span>Number of Slots Auctioned</span>
              </label>
              <h6 className={styles['value']}>
                {liveCompetition?.slotAuctioned}
              </h6>
            </div>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>
                <span className={styles['icon']} />
                <span>Number of Competitors</span>
              </label>
              <h6 className={styles['value']}>
                {liveCompetition?.numCompetitors}
              </h6>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const renderProgress = () => {
    return (
      <>
        <div>
          <div className={`${styles['competition-info']} row`}>
            <div className="col-12">
              <div className={styles['competition-info-item']}>
                <div className={styles['end-time']}>
                  <ClockIcon />
                  <div className={styles['text']}>
                    <span> {!isEnded ? 'Ending in: ' : 'Ended'} </span>
                    {isActiveCompetition && (
                      <div className={styles['count-down']}>
                        <AppCountdown
                          endDate={new Date(liveCompetition?.endTime).getTime()}
                          render={renderCountdown}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles['competition-info-item']}>
                <div className={styles['label']}>
                  <div className={styles['dot']} />
                  <div className={styles['text']}>
                    Number of Slots Auctioned
                  </div>
                </div>
                <div className={styles['value']}>
                  {liveCompetition?.slotAuctioned}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles['competition-info-item']}>
                <div className={styles['label']}>
                  <div className={styles['dot']} />
                  <div className={styles['text']}>Number of Competitors</div>
                </div>
                <div className={styles['value']}>
                  {liveCompetition?.numCompetitors}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['snapshot']}>
          <div className={`${styles['competition-info']} row`}>
            <div className="col-12">
              <div
                className={styles['competition-info-item']}
                style={{ flexDirection: 'column' }}
              >
                <div className={styles['box-snapshot']}>
                  <div className={styles['snapshot-info']}>
                    <span className={styles['label']}>
                      <SnapshotIcon />
                      {isFinalizedCompetition
                        ? 'Finalized snapshot Time'
                        : 'Snapshot Time'}
                      :
                    </span>
                    <span className={styles['snap-time']}>
                      {formatTimestamp(
                        snapshotLatestCompetition?.snapshotTime,
                        'HH:mm - YYYY/MM/DD',
                      )}
                    </span>
                  </div>
                  <div className={styles['token-rate']}>
                    (Conversion Rate: <span>1BPT = 50,000 BUNI</span>)
                  </div>
                </div>
                <div className={styles['description']}>
                  For reference purpose, snapshot ranking is automatically taken
                  and updated in every 60 mins. Metaverse Starter competition
                  host will determine the final result from the snapshot list.
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles['competition-info-item']}>
                <div className={styles['label']}>
                  <div className={styles['dot']} />
                  <div className={styles['text']}>Your Staking</div>
                </div>
                <div className={styles['value']}>
                  {myUserInfo &&
                  myUserInfo.stakingTokens &&
                  connectedAccount ? (
                    <>{myStakingAsString()}</>
                  ) : (
                    NOT_AVAILABLE_TEXT
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles['competition-info-item']}>
                <div className={styles['label']}>
                  <div className={styles['dot']} />
                  <div className={styles['text']}>Your Competition Ranking</div>
                </div>
                <div className={styles['value']}>
                  {connectedAccount && myUserInfo?.rank
                    ? myUserInfo?.rank
                    : NOT_AVAILABLE_TEXT}
                </div>
              </div>
            </div>
          </div>
        </div>
        {usersCompetition && !!usersCompetition.length && (
          <div className={styles['table']}>
            <div className={`row ${styles['thead']}`}>
              <div className="col-1">
                <div className={styles.name}>Position</div>
              </div>
              <div className="col-2">
                <div className={styles.name}>Wallet Address</div>
              </div>
              <div className="col-2">
                <div className={styles.name}>Total Buni Value</div>
              </div>
              <div className="col-2">
                <div className={styles.name}>BPT Staked</div>
              </div>
              <div className="col-2">
                <div className={styles.name}>BUNI Staked</div>
              </div>
              <div className="col-3">
                <div className={styles.name}>Last Updated</div>
              </div>
            </div>
            {usersCompetition.map((user: any, index: number) => {
              const { stakedBpt, stakedBuni } = getValueStaked(user);
              return (
              <div className={`${styles['item']}`} key={`item-${index}`}>
                <div className={`row`}>
                  <div className="col-1">
                    <div>{index + 1}</div>
                  </div>
                  <div className="col-2">
                    <div>{formatShortAddress(user.userAddress)}</div>
                  </div>
                  <div className="col-2">
                    <div>{formatWeiNumber(user.amountStaked)} BUNI</div>
                  </div>
                  <div className="col-2">
                    <div>
                      {formatWeiNumber(stakedBpt)}{' '}BPT
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      {formatWeiNumber(stakedBuni)}{' '} BUNI
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      {formatTimestamp(
                        user.lastTimeStaked,
                        'HH:mm - YYYY/MM/DD',
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )})
            }
          </div>
        )}
      </>
    );
  };

  const renderSnapshot = () => {
    return (
      <div className={`${styles['competition-info']} row`}>
        <div
          onClick={() => setOpenSnapshot((openSnapshot) => !openSnapshot)}
          className={styles['card-staking-header']}
        >
          <div className={styles['flex-header']}>
            <div className={styles['label']}>
              <SnapshotIcon className={styles['icon']} />
              {isFinalizedCompetition
                ? 'Finalized snapshot Time'
                : 'Snapshot Time'}
              :
            </div>
            <span
              className={`${styles['icon-collapse']} ${
                !openProgress ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['snapshot']}>
          <div className={styles['snap-time']}>
            {formatTimestamp(
              snapshotLatestCompetition?.snapshotTime,
              'HH:mm - YYYY/MM/DD',
            )}
          </div>
          <div className={styles['token-rate']}>
            (Conversion Rate: <span>1BPT = 50,000 BUNI</span>)
          </div>
        </div>

        <Collapse in={openSnapshot} timeout="auto" unmountOnExit>
          <div className={styles['card-staking-body']}>
            <div className={`${styles['card-staking-item']} row`}>
              <label className={`${styles['label']} col-6`}>
                <span className={styles['icon']} />
                <span>Your Staking</span>
              </label>
              <h6 className={`${styles['value']} col-6`}>
                {myUserInfo && myUserInfo.stakingTokens && connectedAccount ? (
                  <>{myStakingAsString()}</>
                ) : (
                  NOT_AVAILABLE_TEXT
                )}
              </h6>
            </div>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>
                <span className={styles['icon']} />
                <span>Your Competiton Ranking</span>
              </label>
              <h6 className={styles['value']}>
                {connectedAccount && myUserInfo?.rank
                  ? myUserInfo?.rank
                  : NOT_AVAILABLE_TEXT}
              </h6>
            </div>
          </div>
          <div className={styles['description-mobile']}>
            For reference purpose, snapshot ranking is automatically taken and
            updated in every 60 mins. Metaverse Starter competition host will
            determine the final result from the snapshot list.
          </div>
        </Collapse>
      </div>
    );
  };

  const renderButtonRegisterCompetition = () => {
    const joinCompetition = async () => {
      if (!availableJoin || !isActiveCompetition) return;
      try {
        await rf
          .getRequest('CompetitionsRequest')
          .registerCompetition(liveCompetition.competitionId);
        toastSuccess({ message: 'Join competition successfully!' });
        await getMyLiveCompetition();
        await getLiveCompetition();
        setIsJoined(true);
      } catch (err: any) {
        toastError({ message: getErrorMessage(err) });
      }
    };

    return (
      <AppButton
        onClick={joinCompetition}
        sizes="medium"
        isDisable={!availableJoin || !isActiveCompetition}
      >
        <span>Join Staking Competition</span>
      </AppButton>
    );
  };

  const renderButtonUnregisterCompetition = () => {
    const unregisterCompetition = async () => {
      try {
        await rf
          .getRequest('CompetitionsRequest')
          .unregisterCompetition(liveCompetition.competitionId);
        toastSuccess({ message: 'Deregister competition successfully!' });
        await getMyLiveCompetition();
        await getLiveCompetition();
        setIsJoined(false);
      } catch (err: any) {
        toastError({ message: getErrorMessage(err) });
      }
    };

    return (
      <AppButton onClick={unregisterCompetition}>
        <span>Deregister Competition</span>
      </AppButton>
    );
  };

  const isTierNotToGold =
    connectedAccount &&
    myTier?.level !== GOLD_TIER &&
    myTier?.level !== DIAMOND_TIER;

  const renderBottom = () => {
    if (isEnded || myTier?.level === DIAMOND_TIER || !connectedAccount) return;
    return isJoined
      ? renderButtonUnregisterCompetition()
      : renderButtonRegisterCompetition();
  };

  const currentTime = moment.utc().valueOf();
  const isBeforeStartLiveCompetition =
    moment.utc(liveCompetition?.startTime).valueOf() > currentTime;
  const isStartLiveCompetition =
    !isBeforeStartLiveCompetition &&
    currentTime <= moment.utc(liveCompetition?.endTime).valueOf();
  const isEndLiveCompetition =
    moment.utc(liveCompetition?.endTime).valueOf() < currentTime;

  const isFinalizedCompetition = !!liveCompetition?.finalizedSnapshotId;

  const shouldShowCurrentPosition =
    isStartLiveCompetition && !isEndLiveCompetition && isJoined;

  const shouldShowRegistrationCondition = !isJoined && !isEndLiveCompetition;

  const formattedStartTime = formatTimestamp(
    liveCompetition?.startTime,
    'HH:mm, YYYY-MM-DD',
  );
  const formattedEndTime = formatTimestamp(
    liveCompetition?.endTime,
    'HH:mm, YYYY-MM-DD',
  );

  const _renderForMobile = () => {
    return (
      <>
        {liveCompetition ? (
          <div className={styles['staking-table-mobile']}>
            <div className={styles['card-staking']}>
              <div className={styles['competition-notification']}>
                <div className={styles['text']}>{_renderNotice()} </div>
              </div>
              <div className={styles['competition-note']}>
                <div className={styles['text']}>
                  {isStartLiveCompetition && (
                    <>
                      {shouldShowRegistrationCondition &&
                        _renderRegistrationConditions()}
                      {shouldShowCurrentPosition &&
                        _renderCurrentUserPosition()}
                    </>
                  )}
                  {isEndLiveCompetition && (
                    <>
                      {isFinalizedCompetition
                        ? _renderResultLiveCompetition()
                        : _renderMessageWaitingResultCompetition()}
                    </>
                  )}
                </div>
                <div className={styles['btn']}>{renderBottom()}</div>
              </div>
            </div>

            {isStarted && (
              <>
                <div className={styles['card-staking']}>
                  {renderProgressMobile()}
                </div>
                <div
                  className={styles['card-staking']}
                  style={{ marginTop: '16px' }}
                >
                  {renderSnapshot()}
                </div>
                <>
                  {usersCompetition &&
                    !!usersCompetition.length &&
                    usersCompetition.map((user: any, index: number) => (
                      <StakingCardUser key={index} index={index} user={user} />
                    ))}
                </>
              </>
            )}
          </div>
        ) : (
          <div className={styles['text-noti']}>
            Stay tuned for more to come.
          </div>
        )}
      </>
    );
  };

  const _renderMessageWaitingResultCompetition = () => {
    if (!isJoined) {
      return;
    }

    return <div>Please wait for the winner list to be announced.</div>;
  };

  const _renderResultLiveCompetition = () => {
    if (!isJoined) {
      return;
    }
    const isDiamondUser = myUserInfo?.rank <= liveCompetition?.slotAuctioned;
    if (isDiamondUser) {
      return (
        <div>
          <CongratulationIcon />
          <span className={`st-uppercase`}>Congratulation!</span>
          {myUserInfo?.rank && (
            <span>
              You are placing at <span>{renderRank(myUserInfo?.rank)}</span>{' '}
              position among <span>{liveCompetition?.numCompetitors}</span>{' '}
              participants and become the winner of the Diamond slot.
            </span>
          )}
        </div>
      );
    }
    return (
      <div>
        <span>
          You placed at <span>{renderRank(myUserInfo?.rank)}</span> position
          among <span>{liveCompetition?.numCompetitors}</span> participants and
          do not become the winner of the Diamond slot.
          <span className={`st-uppercase`}>Good luck next time!</span>
        </span>
      </div>
    );
  };

  const _renderCurrentUserPosition = () => {
    return (
      <div>
        You have registered for the competition.
        {!myUserInfo?.rank ? (
          <span>
            Please wait until the next snapshot time to see your ranking.
          </span>
        ) : (
          <span>
            You are placing at <span>{renderRank(myUserInfo?.rank)}</span>{' '}
            position among <span>{liveCompetition?.numCompetitors}</span>{' '}
            participants.
          </span>
        )}
      </div>
    );
  };

  const _renderRegistrationConditions = () => {
    if (!connectedAccount) {
      return (
        <div className={styles['account-err']}>
          <WarningRedIcon />
          Connect your wallet to join the competition.
        </div>
      );
    }
    if (isTierNotToGold) {
      return (
        <div className={styles['account-err']}>
          <WarningRedIcon />
          You must be at Gold tier to join the competition.
        </div>
      );
    }
    if (availableJoin) {
      return (
        <div className={styles['text-success']}>
          You are qualified to join the competition.
        </div>
      );
    }
    return;
  };

  const _renderNotice = () => {
    return (
      <>
        {isBeforeStartLiveCompetition && (
          <div className={styles['text-warning']}>
            <WarningRedIcon /> Next competition will be hosted from <br />
            {formattedStartTime} to {formattedEndTime}. Prepare to join!
          </div>
        )}
        {isStartLiveCompetition && (
          <div>
            The competition for {liveCompetition?.slotAuctioned} slots is on
            going from
            <br />
            {formattedStartTime} to {formattedEndTime}. Join now!
          </div>
        )}
        {isEndLiveCompetition && (
          <div>
            The competition for {liveCompetition?.slotAuctioned} slots from
            <br />
            {formattedStartTime} to {formattedEndTime} has ended.
          </div>
        )}
      </>
    );
  };

  const _renderIntroForDesktop = () => {
    return (
      <div className={styles['competition-header']}>
        <div className={styles['competition-notification']}>
          <FlagIcon /> <div className={styles['text']}>{_renderNotice()}</div>
        </div>
        <div className={styles['competition-note']}>
          <div className={styles['text']}>
            {isStartLiveCompetition && (
              <>
                {shouldShowRegistrationCondition &&
                  _renderRegistrationConditions()}
                {shouldShowCurrentPosition && _renderCurrentUserPosition()}
              </>
            )}
            {isEndLiveCompetition && (
              <>
                {isFinalizedCompetition
                  ? _renderResultLiveCompetition()
                  : _renderMessageWaitingResultCompetition()}
              </>
            )}
          </div>
          <div className={styles['btn']}>{renderBottom()}</div>
        </div>
      </div>
    );
  };

  const _renderEmptyLiveCompetition = () => {
    return (
      <div className={styles['text-noti']}>Stay tuned for more to come.</div>
    );
  };

  const _renderForDesktop = () => {
    return (
      <>
        <div
          className={`${styles['collapse-tier']} ${
            open ? styles['collapse-tier-show'] : ''
          }`}
        >
          <div
            className={styles['header']}
            onClick={() => setOpen((open) => !open)}
          >
            <div className={styles['title']}>Staking Competition Ranking</div>
            <div>
              <span
                className={`${styles['icon-collapse']} ${
                  !open ? styles['collapsed'] : ''
                }`}
              />
            </div>
          </div>

          <Collapse in={open} timeout="auto" unmountOnExit>
            {!liveCompetition && _renderEmptyLiveCompetition()}
            {liveCompetition && (
              <div className={styles['competition']}>
                {_renderIntroForDesktop()}
                {isStarted && renderProgress()}
              </div>
            )}
          </Collapse>
        </div>
      </>
    );
  };

  return <>{isMobile ? _renderForMobile() : _renderForDesktop()}</>;
};

export default withWidth()(withRouter(PartStakingCompetition));
