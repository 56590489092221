import MetamaskConnector from './MetamaskConnector';
import BscWalletConnector from './BscWalletConnector';
import WalletConnectConnector from './WalletConnectConnector';
import WalletLinkConnector from './WalletLinkConnector';
import TrustWalletConnector from './TrustWalletConnector';
import config from '../config';
import PhantomConnector from './PhantomConnector';
import SolletConnector from './SolletConnector';
import SolflareConnector from './SolflareConnector';

export const METAMASK_WALLET = 'metamask-connector';
export const BSC = 'bsc-connector';
export const WALLET_CONNECT = 'wallet-connect-connector';
export const WALLET_LINK = 'wallet-link-connector';
export const TRUST_WALLET = 'trust-wallet-connector';
export const PHANTOM = 'phantom-connector';
export const SOLLET = 'sollet-connector';
export const SOLFLARE = 'solflare-connector';

export type SolanaProviderType =
  | PhantomConnector
  | SolflareConnector
  | SolletConnector;

const connectorList: { [key: string]: any } = {
  [METAMASK_WALLET]: MetamaskConnector,
  [BSC]: BscWalletConnector,
  [WALLET_CONNECT]: WalletConnectConnector,
  [WALLET_LINK]: WalletLinkConnector,
  [TRUST_WALLET]: TrustWalletConnector,
  [PHANTOM]: PhantomConnector,
  [SOLLET]: SolletConnector,
  [SOLFLARE]: SolflareConnector,
};

export type ConnectorType = typeof connectorList[keyof typeof connectorList];

class ConnectorFactory {
  static instances: {
    [key: string]: any;
  } = {};

  static getConnector(connectorId: string, network: string): ConnectorType {
    const Connector = connectorList[connectorId];
    if (!Connector) {
      throw new Error(`Invalid connector class: ${Connector}`);
    }
    let connectorInstance = this.instances[connectorId];
    if (!connectorInstance) {
      const networkConfig = config.networks[network];
      // @ts-ignore
      const options = networkConfig.connectors[connectorId].options;
      connectorInstance = new Connector(options);
      this.instances[connectorId] = connectorInstance;
    }
    return connectorInstance;
  }
}

export default ConnectorFactory;
