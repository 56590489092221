import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { RocketIcon } from 'src/assets/icons';
import LBPImage from 'src/assets/images/lbp.svg';
import AppButton from 'src/components/AppButton';
import landingStyle from 'src/styles/pages/Homepage.module.scss';

const PartOverViewLBP: FC = () => {
  const history = useHistory();
  return (
    <div className={landingStyle['lbp']}>
      <div className={landingStyle['lbp-info']}>
        <div className={landingStyle['title']}>
          What is Token Launch Auction?
        </div>
        <div className={landingStyle['description']}>
          Token Launch Auction is the most open, transparent, and user-friendly
          way to participate in Balancer Liquidity Bootstrapping Pools.
          <br />
          <br />
          Liquidity and price discovery for an idea can now be easily
          bootstrapped by any community. Metaverse Starter is here to help make
          Token Launch Auctions accessible for everyone.
        </div>
        <div className={landingStyle['btn-group']}>
          <AppButton
            className={landingStyle['lbp-btn']}
            variant="primary"
            sizes="big"
            onClick={() => history.push('/auctions')}
          >
            <RocketIcon /> View Auctions
          </AppButton>
          <a
            href="https://docs.metaversestarter.io/"
            target="_blank"
            rel="noreferrer"
          >
            <AppButton
              className={landingStyle['lbp-btn']}
              variant="secondary"
              sizes="big"
              onClick={() => history.push('')}
            >
              Learn More
            </AppButton>
          </a>
        </div>
      </div>
      <img className={landingStyle['lbp-image']} alt="lbp" src={LBPImage} />
    </div>
  );
};

export default PartOverViewLBP;
