import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import styles from 'src/styles/pages/LBPDetails/LBPDetailPage.module.scss';
import { useParams } from 'react-router-dom';
import rf from 'src/requests/RequestFactory';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import PartLBPOverview from 'src/pages/PageAuctionDetail/parts/PartLBPOverview';
import PartLBPDetail from 'src/pages/PageAuctionDetail/parts/PartLBPDetail';
import PartLBPChart from './parts/PartLBPChart';
import PartLBPSwap from './parts/PartLBPSwap';
import { getToken, getSymbolBaseToken } from 'src/utils/utils-token';
import { convertWeiToDec } from 'src/utils/utils-formats';
import { multicall } from 'src/utils/utils-multicall';
import { appRetry } from 'src/utils/utils-helpers';
import abi from 'src/abi';
import NoResult from 'src/assets/icons/NoResult';
import {
  getCurrentBalances,
  getStatusEnableSwap,
  calcSpotPrice,
  getInfoJoinExitPool,
} from 'src/utils/utils-auction';
import AppAlertWarning from 'src/components/AppAlertWarning';
import ModalWarningLBP from 'src/modals/ModalWarningLBP';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import { Auction } from 'src/utils/auction';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const STATUS_POOL = {
  JOIN: 'JOIN',
  EXIT: 'EXIT',
};

const LBPDetails = () => {
  const { id: auctionId } = useParams() as any;
  const [auction, setAuction] = useState<any>(null);
  const [baseToken, setBaseToken] = useState<any>({});
  const [mainToken, setMainToken] = useState<any>({});
  const [mainTokenIndex, setMainTokenIndex] = useState<number>(0);
  const [baseTokenIndex, setBaseTokenIndex] = useState<number>(1);
  const [balanceStart, setBalanceStart] = useState<string[]>([]);
  const [balanceCurrent, setBalanceCurrent] = useState<string[]>([]);
  const [tokenWeights, setTokenWeights] = useState<string[]>([]);
  const [swapFee, setSwapFee] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);
  const [isEnabledSwap, setIsEnabled] = useState<boolean>(true);
  const [isExit, setIsExit] = useState<boolean>(false);
  const [balanceExit, setBalanceExit] = useState<string[]>([]);
  const [isOpenModalWaringLBP, setIsOpenModalWaringLBP] =
    useState<boolean>(false);
  const { user } = useAuth();
  const reloadAuctionDetailInterval: { current: NodeJS.Timeout | null } =
    useRef(null);

  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);

  const onHandleAuctionDetail = (auction: any) => {
    const lbpAuction = new Auction(auction);
    if (reloadAuctionDetailInterval.current) {
      clearInterval(reloadAuctionDetailInterval.current);
    }

    if (lbpAuction.getIsDraft()) {
      // reload to get auction detail after every 30 seconds
      reloadAuctionDetailInterval.current = setInterval(() => {
        fetchAuctionDetail();
      }, 30000);
    }

    if (!lbpAuction.getIsVerified()) {
      setIsOpenModalWaringLBP(true);
    }

    // set baseToken
    let tokenBaseInPool = lbpAuction.getTokenBase();
    const tokenBaseIndex = lbpAuction.getTokenBaseIndex();
    tokenBaseInPool = getToken(
      lbpAuction.getNetwork(),
      tokenBaseInPool.address,
    );
    setBaseToken(tokenBaseInPool);
    setBaseTokenIndex(tokenBaseIndex);

    // set mainToken
    let tokenMainInPool = lbpAuction.getTokenMain();
    tokenMainInPool = { ...tokenMainInPool, icon: auction.logoUrl };
    const tokenMainIndex = lbpAuction.getTokenMainIndex();
    setMainToken(tokenMainInPool);
    setMainTokenIndex(tokenMainIndex);
  };

  const fetchAuctionDetail = useCallback(async () => {
    setLoading(true);
    try {
      const response = (await rf
        .getRequest('AuctionsRequest')
        .getAuctionDetail(auctionId)) as any;
      if (response) {
        setAuction(response);
        onHandleAuctionDetail(response);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [auctionId]);

  const getBalancesCurrentPool = useCallback(async () => {
    const lbpAuction = new Auction(auction);

    const pool = lbpAuction.getPool();
    const poolNetwork = lbpAuction.getNetwork();
    const tokenBaseInPool = lbpAuction.getTokenBase();
    const tokenBaseIndex = lbpAuction.getTokenBaseIndex();
    const tokenMainInPool = lbpAuction.getTokenMain();
    const tokenMainIndex = lbpAuction.getTokenMainIndex();

    if (!pool) {
      return;
    }

    const balances = await getCurrentBalances(
      pool.onchainId,
      poolNetwork,
      pool.vault.address,
    );

    const balanceBaseToken = convertWeiToDec(
      balances[tokenBaseIndex],
      tokenBaseInPool?.decimals,
    );
    const balanceMainToken = convertWeiToDec(
      balances[tokenMainIndex],
      tokenMainInPool?.decimals,
    );
    const balanceTokens = [];
    balanceTokens[tokenBaseIndex] = balanceBaseToken;
    balanceTokens[tokenMainIndex] = balanceMainToken;
    setBalanceCurrent(balanceTokens);
  }, [auction]);

  const fetchBalancesPool = async () => {
    await getBalancesCurrentPool();
    await getInfoJoinExitHistoryPool();
  };

  const getWeightsAndSwapFeePool = async () => {
    const { pool, network: poolNetwork } = auction;
    if (!pool) {
      return;
    }
    try {
      const calls = [
        {
          address: pool.address,
          name: 'getNormalizedWeights',
          params: [],
        },
        {
          address: pool.address,
          name: 'getSwapFeePercentage',
          params: [],
        },
      ];

      const [normalizedWeights, swapFeePercentage] = await multicall(
        abi['LBP'],
        calls,
        poolNetwork,
      );

      const weights = normalizedWeights[0];
      setTokenWeights(weights);
      setSwapFee(convertWeiToDec(swapFeePercentage));
    } catch (e) {
      console.error(e);
    }
  };

  const getInfoJoinExitHistoryPool = async () => {
    const infoData = await getInfoJoinExitPool(auction);

    if (infoData) {
      setIsExit(infoData.isExit);
      setBalanceExit(infoData.balanceExit);
      setBalanceStart(infoData.balanceStart);
    }
  };

  const getStatusEnabledSwap = async () => {
    const { network, pool } = auction;
    if (!pool) {
      return;
    }
    const { address: poolAddress } = pool;
    const isEnabled = await getStatusEnableSwap(network, poolAddress);
    setIsEnabled(isEnabled);
  };

  const getInfosPool = async () => {
    await getBalancesCurrentPool();
    await getInfoJoinExitHistoryPool();
    await getWeightsAndSwapFeePool();
    await getStatusEnabledSwap();
  };

  useEffect(() => {
    if (!auction) return;
    appRetry(getInfosPool()).then();
    const getInfos = setInterval(() => appRetry(getInfosPool()), 300000);
    return () => {
      clearInterval(getInfos);
    };
  }, [auction]);

  useEffect(() => {
    fetchAuctionDetail();
  }, [auctionId]);

  const isPoolEnd = useMemo(
    () =>
      tokensUsdPrice[getSymbolBaseToken(baseToken?.symbol?.toLowerCase())] *
        Number(balanceCurrent[baseTokenIndex]) <
      1,
    [tokensUsdPrice, balanceCurrent, baseTokenIndex, baseToken],
  );

  useEffect(() => {
    isPoolEnd && setIsEnabled(false);
  }, [isPoolEnd, isEnabledSwap]);

  const mainTokenReleased = useMemo(() => {
    if (isExit) {
      return +balanceStart[mainTokenIndex] - +balanceExit[mainTokenIndex];
    }

    if (!isExit && isPoolEnd) {
      return 0;
    }
    return +balanceStart[mainTokenIndex] - +balanceCurrent[mainTokenIndex];
  }, [balanceCurrent, balanceStart, isExit, balanceExit, isPoolEnd]);

  const baseTokenAccrued = useMemo(() => {
    if (isExit) {
      return +balanceExit[baseTokenIndex] - +balanceStart[baseTokenIndex];
    }

    if (!isExit && isPoolEnd) {
      return 0;
    }
    return +balanceCurrent[baseTokenIndex] - +balanceStart[baseTokenIndex];
  }, [balanceStart, balanceCurrent, isExit, balanceExit, isPoolEnd]);

  const _renderAlertWarning = () => {
    const { network: poolNetwork, pool, draftInfo, isDraft } = auction;
    const startTime = pool ? pool.startTime : draftInfo.startTime;
    const endTime = pool ? pool.endTime : draftInfo.endTime;
    const now = moment().unix();
    const isBeforeStart = now < startTime;
    const isEnded = now > endTime;
    const isDuring = now <= endTime && now >= startTime;

    const getMessage = () => {
      switch (true) {
        case isDraft:
          return 'Auction is not ready yet. Please wait a few seconds.';
        case isBeforeStart:
          return 'The auction has not started yet.';
        case isPoolEnd && isDuring:
          return 'The pool has been closed due to host’s withdrawal.';
        case isEnded:
          return 'The auction has ended.';
        case !isEnabledSwap:
          return `Auction host hasn't enabled swapping yet.`;
      }
    };

    if (isBeforeStart || isDraft || (isPoolEnd && isDuring)) {
      return <AppAlertWarning>{getMessage()}</AppAlertWarning>;
    }

    if (pool && user?.getNetwork() !== poolNetwork) {
      return (
        <AppAlertWarning acceptedNetwork={poolNetwork}>
          Connected wallet does not match target network of {pool.name}
        </AppAlertWarning>
      );
    }

    if (!isEnabledSwap || isEnded) {
      return <AppAlertWarning>{getMessage()}</AppAlertWarning>;
    }

    return;
  };

  const priceTokenMain = useMemo(() => {
    if (isExit) {
      return mainToken.latestPriceRate.toString() || '0';
    }

    return (
      calcSpotPrice(
        balanceCurrent[baseTokenIndex],
        tokenWeights[baseTokenIndex]?.toString(),
        balanceCurrent[mainTokenIndex],
        tokenWeights[mainTokenIndex]?.toString(),
        swapFee,
      ) || '0'
    );
  }, [
    balanceCurrent,
    isExit,
    mainToken,
    tokenWeights,
    swapFee,
    baseTokenIndex,
    mainTokenIndex,
  ]);

  const _renderLBPDetail = () => {
    if (!auction) {
      return <NoResult />;
    }

    return (
      <>
        <div className={styles['lbp-detail']}>
          {_renderAlertWarning()}
          <div className={styles['detail-body']}>
            <PartLBPOverview
              auction={auction}
              mainToken={mainToken}
              baseToken={baseToken}
              baseTokenIndex={baseTokenIndex}
              mainTokenIndex={mainTokenIndex}
              mainTokenReleased={mainTokenReleased}
              baseTokenAccrued={baseTokenAccrued}
              balanceStart={balanceStart}
              balanceCurrent={balanceCurrent}
              priceTokenMain={priceTokenMain}
              isEnabledSwap={isEnabledSwap}
              fetchStatusEnableSwap={getStatusEnabledSwap}
              fetchBalancesPool={fetchBalancesPool}
            />
            <div className={styles['detail-content']}>
              {!isMobile && (
                <div
                  className={`${styles['content-item']} ${styles['after-rounded']}`}
                >
                  <div className={styles['lbp-swap']}>
                    <div
                      className={`${styles['swap-aside']} ${styles['chart']}`}
                    >
                      <PartLBPChart
                        auction={auction}
                        mainToken={mainToken}
                        baseToken={baseToken}
                        baseTokenIndex={baseTokenIndex}
                        mainTokenIndex={mainTokenIndex}
                        balanceStart={balanceStart}
                        balanceCurrent={balanceCurrent}
                        tokenWeights={tokenWeights}
                        swapFee={swapFee}
                      />
                    </div>
                    <div
                      className={`${styles['swap-aside']} ${styles['form']}`}
                    >
                      <PartLBPSwap
                        auction={auction}
                        mainToken={mainToken}
                        baseToken={baseToken}
                        baseTokenIndex={baseTokenIndex}
                        mainTokenIndex={mainTokenIndex}
                        balanceCurrent={balanceCurrent}
                        tokenWeights={tokenWeights}
                        swapFee={swapFee}
                        priceTokenMain={priceTokenMain}
                        isEnabledSwap={isEnabledSwap}
                        fetchData={getInfosPool}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${styles['content-item']} ${styles['group-tab-desktop']}`}
              >
                <PartLBPDetail
                  auction={auction}
                  mainToken={mainToken}
                  baseToken={baseToken}
                  baseTokenIndex={baseTokenIndex}
                  mainTokenIndex={mainTokenIndex}
                  mainTokenReleased={mainTokenReleased}
                  baseTokenAccrued={baseTokenAccrued}
                  balanceStart={balanceStart}
                  balanceCurrent={balanceCurrent}
                  tokenWeights={tokenWeights}
                  swapFee={swapFee}
                  priceTokenMain={priceTokenMain}
                  isEnabledSwap={isEnabledSwap}
                  fetchData={getInfosPool}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles['single-page']}>
      <section className={styles['max-width-content']}>
        {loading ? <LoadingIcon /> : <>{_renderLBPDetail()}</>}
      </section>

      <ModalWarningLBP
        open={isOpenModalWaringLBP}
        onClose={() => setIsOpenModalWaringLBP(false)}
      />
    </div>
  );
};

export default LBPDetails;
