import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class StakingRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.userApi;
  }

  getDiamondUsers() {
    const url = `/public/diamond-tier`;
    return this.get(url);
  }
}
