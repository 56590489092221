import { Switch, SwitchProps } from '@material-ui/core';
import { FC } from 'react';
import styles from 'src/styles/components/AppSwitchButton.module.scss';

interface AppSwitchButtonProps extends SwitchProps {
  size?: 'small' | 'medium';
}

const AppSwitchButton: FC<AppSwitchButtonProps> = ({
  size = 'medium',
  ...props
}) => {
  return (
    <Switch
      size={size}
      {...props}
      classes={{
        root: `${styles['root']} ${styles['root-' + size]}`,
        checked: `${styles['checked']} ${styles['checked-' + size]}`,
        thumb: `${styles['thumb-' + size]}`,
        track: `${styles['track']} ${styles['track-' + size]}`,
        switchBase: `${styles['switch-base-' + size]}`,
        disabled: `${styles['disabled']}`,
      }}
    />
  );
};
export default AppSwitchButton;
