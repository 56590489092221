import React, { useEffect, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import BasePage from '../BasePage';
import INODescription from './part/INODescription';
import INOOverview from './part/INOOverview';
import styles from 'src/styles/pages/INODetail/INODetail.module.scss';
import { useParams } from 'react-router';
import { PoolResponseType } from 'src/utils/pool';
import { AppBroadcast } from 'src/utils/utils-broadcast';

export const RELOAD_INO_DETAILS_AFTER_BUYING =
  'RELOAD_INO_DETAILS_AFTER_BUYING';

type ParamTypes = {
  id: string;
};

const INODetail = () => {
  const { id: poolId } = useParams<ParamTypes>();
  const [pool, setPool] = useState<PoolResponseType>({} as PoolResponseType);

  const fetchPoolDetails = async (id: string) => {
    try {
      const response = (await rf
        .getRequest('PoolRequest')
        .getPoolDetail(id)) as any;
      setPool(response);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoolDetails(poolId);
    AppBroadcast.on(RELOAD_INO_DETAILS_AFTER_BUYING, () =>
      fetchPoolDetails(poolId),
    );
    return () => {
      AppBroadcast.remove(RELOAD_INO_DETAILS_AFTER_BUYING);
    };
  }, [poolId]);

  return (
    <BasePage>
      <div className={styles['container']}>
        <INOOverview pool={pool} />
        {pool?.project && (
          <INODescription
            project={pool?.project}
            inoDetails={pool.inoDetails}
          />
        )}
      </div>
    </BasePage>
  );
};

export default INODetail;
