import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { setNetwork } from 'src/store/authentication';
import { getTokensUsdPrice } from 'src/store/metadata';
import { getAccountBalance, getInformationUser } from 'src/store/myAccount';
import Storage from 'src/utils/storage';
import { AppBroadcast } from 'src/utils/utils-broadcast';

import { useLocation } from 'react-router';
import useMetadata from 'src/hooks/useMetadata';
import PageMyProfile from 'src/pages/PageMyProfile';
import PageStaking from 'src/pages/PageStaking';
import TheFooter from 'src/layout/TheFooter';
import TheHeader from 'src/layout/TheHeader';
import ModalConnectWallet from 'src/modals/ModalConnectWallet';
import ModalSignatureRequired from 'src/modals/ModalSignatureRequired';
import ModalSubmittingTx from 'src/modals/ModalSubmittingTx';
import ModalViewSubmittedTx from 'src/modals/ModalViewSubmittedTx';
import HomePage from 'src/pages/HomePage';
import PageAuctionCreate from 'src/pages/PageAuctionCreate';
import PageAuctionDetail from 'src/pages/PageAuctionDetail';
import PageAuctionList from 'src/pages/PageAuctionList';
import PageIDOPoolDetail from 'src/pages/PageIDOPoolDetail';
import PageINOPoolDetail from 'src/pages/PageINOPoolDetail';
import PagePoolList from 'src/pages/PagePoolList';
import PageProjectDetail from 'src/pages/PageProjectDetail';
import useAuth from './hooks/useAuth';
import PageTermOfUse from './pages/PageTermOfUse';

/**
 * Main App routes.
 */

const Routes: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { initMetadata } = useMetadata();
  const { pathname } = useLocation();
  const { user, disconnectWallet } = useAuth();

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const network = Storage.getNetwork();
  dispatch(setNetwork(network));

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    initMetadata().then(() => {
      setIsLoadingData(false);
    });
    const tokensUsdPriceInterval = setInterval(() => {
      dispatch(getTokensUsdPrice());
    }, 900000); // 15 minutes
    return () => clearInterval(tokensUsdPriceInterval);
  }, []);

  // listen event to action
  useEffect(() => {
    AppBroadcast.on('PROCESS_LOGOUT_USER', () => {
      user && disconnectWallet();
    });

    AppBroadcast.on('LOAD_USER_INFORMATION', () => {
      dispatch(getInformationUser());
    });

    AppBroadcast.on('LOAD_USER_NATIVE_BALANCE', () => {
      dispatch(getAccountBalance());
    });

    return () => {
      AppBroadcast.remove('LOAD_USER_INFORMATION');
      AppBroadcast.remove('LOAD_USER_NATIVE_BALANCE');
    };
  }, []);

  // scroll to top when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Switch>
        <PrivateRoute path={'/account'} component={PageMyProfile} />
        <Route path={'/project/:id'} component={PageProjectDetail} />
        <Route path={'/pools'} component={PagePoolList} />
        <Route path={'/pool-ido/:id'} component={PageIDOPoolDetail} />
        <Route path={'/pool-ino/:id'} component={PageINOPoolDetail} />
        <Route path={'/staking'} component={PageStaking} />
        <Route path={'/auctions'} component={PageAuctionList} />
        <Route path={'/auction/:id'} component={PageAuctionDetail} />
        <PrivateRoute path={'/auction'} component={PageAuctionCreate} />

        <Route path="/term-of-use" component={PageTermOfUse} />
        <Route path={'/'} component={HomePage} />
      </Switch>

      {!isLoadingData && (
        <>
          <ModalSignatureRequired />
          <ModalSubmittingTx />
          <ModalViewSubmittedTx />
          <ModalConnectWallet />
        </>
      )}
    </div>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <ErrorBoundary>
          <TheHeader />
          <RoutesHistory />
          <TheFooter />
        </ErrorBoundary>
      </Router>
    </>
  );
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const accessToken = Storage.getAccessToken();
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !!accessToken || !!user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
