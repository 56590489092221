import { withRouter, useHistory, RouteComponentProps } from 'react-router';
import {
  UnverifiedIcon,
  VerifiedIcon,
  WarningOrangeIcon,
} from 'src/assets/icons';
import { switchNetwork } from 'src/utils/utils-auth';
import styles from 'src/styles/components/CardAccountTierAndStatus.module.scss';
import AppButton from 'src/components/AppButton';
import useAuth from 'src/hooks/useAuth';
import AppBadge from 'src/components/AppBadge';
import { isNetworkEVM } from 'src/utils/utils-network';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { TOGGLE_CONNECT_SOLANA_MODAL } from '..';

interface CardAccountTierAndStatusProps extends RouteComponentProps {
  acceptedNetwork: string;
  isWrongNetwork: boolean;
}

const CardAccountTierAndStatus = (props: CardAccountTierAndStatusProps) => {
  const { acceptedNetwork, isWrongNetwork } = props;
  const { user } = useAuth();

  const history = useHistory();

  const shouldRequireKyc = !user?.isKyced();

  const switchToTargetNetwork = () => {
    if (!user) {
      return;
    }
    if (isNetworkEVM(acceptedNetwork)) {
      switchNetwork(acceptedNetwork, user.getProvider()).then();
    } else {
      AppBroadcast.dispatch(TOGGLE_CONNECT_SOLANA_MODAL);
    }
  };

  const onKycNow = () => history.push('/account');

  const onStake = () => history.push('/staking');

  return (
    <div className={`${styles['card']}`}>
      <div className={styles['body-center']}>
        <div className={styles['left']}>
          <div className={styles['name']}>{user?.getTierAsString()} User</div>
          <AppBadge
            color={shouldRequireKyc ? 'orange' : 'green'}
            className={styles['status']}
          >
            {shouldRequireKyc ? <UnverifiedIcon /> : <VerifiedIcon />}
            <span className={`${styles['text']}`}>
              {shouldRequireKyc ? 'Unverified' : 'Verified'}
            </span>
          </AppBadge>
        </div>
        {isWrongNetwork ? (
          <div className={`${styles['right']} ${styles['error-network']}`}>
            <div className={styles['warning']}>
              <span className={styles['icon']}>
                <WarningOrangeIcon />
              </span>
              <span className={styles['text']}>
                Network does not match target
              </span>
            </div>
            <AppButton
              sizes="small"
              variant="white"
              onClick={switchToTargetNetwork}
            >
              Switch network
            </AppButton>
          </div>
        ) : (
          <div className={styles['right']}>
            <div className={styles['-margin']}>
              {shouldRequireKyc && (
                <AppButton variant="primary" sizes="mini" onClick={onKycNow}>
                  KYC Now
                </AppButton>
              )}
              <AppButton
                variant="secondary"
                className={styles['btn-stake']}
                sizes="mini"
                onClick={onStake}
              >
                Stake
              </AppButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default withRouter(CardAccountTierAndStatus);
