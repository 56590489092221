import { Tooltip } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ClockIcon, VolumeIcon, VolumeMutedIcon } from 'src/assets/icons';
import AppButton from 'src/components/AppButton';
import AppCountdown from 'src/components/AppCountdown';
import 'src/styles/vendors/LandingSlider.scss';
import { IdoTimeline } from 'src/utils/timelines';
import {
  getCountdownTimestamp,
  getPoolDetailLink,
  getTitleCurrentPhase,
} from 'src/utils/utils-pool';

interface IPartBanner {
  pools: any;
}

const PartBanner: React.FC<IPartBanner> = ({ pools }) => {
  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();
  const [currentItem, setCurrentItem] = useState<number>();

  const settingSlideAs: Settings = {
    asNavFor: nav2,
    arrows: false,
    // fade: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 10000,
    easing: 'linear',
    initialSlide: 0,
    focusOnSelect: true,
    swipeToSlide: false,
    swipe: false,
    pauseOnFocus: true,
    pauseOnHover: false,
    beforeChange: (current, next: number) => {
      setCurrentItem(next);
    },
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  const settingSlideFor: Settings = {
    asNavFor: nav1,
    initialSlide: 0,
    infinite: true,
    slidesToShow: pools.length > 4 ? 4 : pools.length,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    swipe: true,
    focusOnSelect: true,
    pauseOnHover: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: pools.length > 3 ? 3 : pools.length,
        },
      },
    ],
  };

  const getSrcImage = (pool: any) => {
    if (pool.project.featuredImagesUrl.length) {
      return pool.project.featuredImagesUrl[0];
    }
    return '';
  };

  const SlickSlideItem = (propsItem: any) => {
    const { pool, currentItemNumber } = propsItem;
    const history = useHistory();

    const timeline = useMemo(() => new IdoTimeline(pool), [propsItem]);
    const [muted, setMuted] = useState(true);
    useEffect(() => {
      setMuted(true);
    }, [currentItemNumber]);

    const goToPoolDetail = () => {
      return history.push(getPoolDetailLink(pool));
    };

    const titleCountdown = getTitleCurrentPhase(timeline);
    const endTimeCountdown = getCountdownTimestamp(timeline);

    return (
      <>
        {pool.project.featuredVideosUrl.length && !isMobile ? (
          <video
            autoPlay
            src={pool.project.featuredVideosUrl[0]}
            loop
            preload="auto"
            muted={muted}
            className="slick-video-item"
            playsInline={false}
          />
        ) : (
          <img className="slick-img-item" src={getSrcImage(pool)} />
        )}
        <div className="slick-content-manage">
          <div className="slick-container">
            <div className="slick-body-pool">
              <Tooltip title={pool?.project?.name} placement="bottom-start">
                <div className="slick-pool-name">{pool?.project?.name}</div>
              </Tooltip>
              <div
                dangerouslySetInnerHTML={{ __html: pool.project.info.overview }}
                className="slick-pool-desc"
              />
              {getTitleCurrentPhase(timeline) && (
                <>
                  <hr className="slick-pool-divider" />
                  <div className="slick-pool-countdown-label">
                    {!!endTimeCountdown && !!titleCountdown && (
                      <>
                        <ClockIcon className="slick-clock-icon" />
                        {titleCountdown}
                      </>
                    )}
                    <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp; {pool.name}</span>
                  </div>
                  {!!endTimeCountdown && (
                    <AppCountdown endDate={endTimeCountdown} />
                  )}
                </>
              )}
              <div className="slick-group-btn">
                <div className="s-g-b-col">
                  <AppButton
                    variant="white"
                    sizes="large"
                    onClick={() => history.push(`/project/${pool.project.id}`)}
                  >
                    Show details
                  </AppButton>
                </div>

                <div className="s-g-b-col">
                  <AppButton sizes="large" onClick={goToPoolDetail}>
                    Join pool now
                  </AppButton>
                </div>
                {pool.project.featuredVideosUrl.length && !isMobile && (
                  <div className="s-g-b-col">
                    <div
                      onClick={() => setMuted((pre) => !pre)}
                      className="slick-volume-video-icon"
                    >
                      {muted ? <VolumeMutedIcon /> : <VolumeIcon />}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="section-slick-landing">
      {pools.length && (
        <>
          <Slider
            {...settingSlideAs}
            ref={(e: Slider) => setNav1(e)}
            className="slick-as"
          >
            {pools.map((pool: any, index: number) => (
              <div key={index} className="slick-as-item">
                <SlickSlideItem pool={pool} currentItemNumber={currentItem} />
              </div>
            ))}
          </Slider>

          <div className="slick-for-manager">
            <div
              className="slick-for-body"
              style={{
                ['--count-slick-item' as any]:
                  pools.length > 4 ? 4 : pools.length,
              }}
            >
              <Slider
                {...settingSlideFor}
                ref={(e: Slider) => setNav2(e)}
                className="slick-for"
              >
                {pools.map((pool: any, index: number) => (
                  <div key={index} className="slick-for-item">
                    <img
                      className="slick-img-item"
                      src={
                        pool.project.featuredImagesUrl.length
                          ? pool.project.featuredImagesUrl[0]
                          : ''
                      }
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </>
      )}
    </section>
  );
};
export default PartBanner;
