import {
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
} from '@material-ui/core';
import { FC, forwardRef, ReactNode, useState } from 'react';
import styles from 'src/styles/components/AppDropdown.module.scss';

export interface AppDropdownProps extends SelectProps {
  onChange: (value: any) => void;
  options: any[];
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
  renderOptions?: (item: any, index: number) => ReactNode;
}

interface DropdownItemProps extends MenuItemProps {
  className?: string;
}

export const DropdownItem = forwardRef(
  ({ className, children, ...props }: DropdownItemProps, ref) => {
    return (
      <MenuItem
        {...props}
        innerRef={ref}
        button={true}
        classes={{
          root: `${styles['root-menu-item']} ${className ? className : ''}`,
          selected: styles['selected-menu-item'],
        }}
      >
        {children}
      </MenuItem>
    );
  },
);

const AppDropdown: FC<AppDropdownProps> = ({
  variant = 'filled',
  size = 'medium',
  options,
  className,
  onChange,
  renderOptions,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Select
      {...otherProps}
      displayEmpty
      disableUnderline
      classes={{
        select: `${styles['select']} ${styles['select-' + variant]} ${
          styles[size]
        } ${className ? className : ''}`,
        disabled: styles[`disabled`],
        icon: styles[`icon`],
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        getContentAnchorEl: null,
        classes: {
          paper: styles[`paper`],
          list: `${styles['list']} ${styles['list-' + size]}`,
        },
      }}
      IconComponent={() => (
        <img
          src="/images/header/icon-arrow-down.svg"
          alt=""
          className={`${styles['icon-down']}`}
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
        />
      )}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(e) => onChange(e.target.value)}
    >
      {renderOptions
        ? options.map((item, index) => renderOptions(item, index))
        : options.map((item: any, index: number) => (
            <DropdownItem value={item.value} key={index}>
              <span className={styles['menu-item']}>{item.label}</span>
            </DropdownItem>
          ))}
    </Select>
  );
};

export default AppDropdown;
