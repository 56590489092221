export const overrides = {
  MuiPaper: {
    root: {
      backgroundColor: 'transparent',
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#1a203f',
      borderRadius: '10px',
      padding: '8px 13px',
      fontSize: '12px',
      color: '#caccd1',
      border: '1px solid #373A4D',
    },
  },
  MuiOutlinedInput: {
    root: {
      width: '100%',
      borderRadius: '12px',
      outline: 'none',
      padding: '8px 16px',
      '&:hover': {
        '& > .MuiOutlinedInput-notchedOutline': {
          borderColor: '#373a4d',
        },
      },
      //   border: 'none'
    },
    input: {
      color: '#CACCD1',
      outline: 'none',
      padding: 0,
      fontSize: '16px',
      '&::placeholder': {
        color: '#696B79',
      },
    },

    notchedOutline: {
      border: '2px solid ',
      borderColor: '#373a4d',
    },
  },
};
