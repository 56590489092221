import { BscConnector as BscWalletProvider } from '@binance-chain/bsc-connector';
import { ethers } from 'ethers';
import BaseConnector from './BaseConnector';

declare global {
  interface Window {
    BinanceChain: any;
  }
}

const isInstalled = () => {
  return !!window.BinanceChain;
};

class BscWalletConnector extends BaseConnector {
  getName() {
    return 'BSC Wallet';
  }

  getId() {
    return 'bsc-connector';
  }

  /**
   * connect wallet
   * @returns provider
   */
  async connect() {
    if (!isInstalled()) {
      return null;
    }
    const connector = new BscWalletProvider(this.options);
    await connector.activate();
    const provider = await connector.getProvider();
    this.connector = connector;
    this.provider = provider;
    return provider;
  }

  handleChainChanged(chainId: string) {
    const chainIdNum = Number(chainId);
    console.debug(
      "Handling 'chainChanged' event with payload",
      chainId,
      isNaN(chainIdNum),
    );
    if (isNaN(chainIdNum)) {
      this.connector.emitError('NaN ChainId');
      return;
    }
    this.connector.handleChainChanged(chainId);
  }

  /**
   * sign a signature and save token into localStorage
   */
  async signMessage(): Promise<any> {
    const MESSAGE_INVESTOR_SIGNATURE = 'Join bunicorn game';
    const rawMessageLength = new Blob([MESSAGE_INVESTOR_SIGNATURE]).size;
    const message = ethers.utils.toUtf8Bytes(
      '\x19Ethereum Signed Message:\n' +
        rawMessageLength +
        MESSAGE_INVESTOR_SIGNATURE,
    );
    const messageHash = ethers.utils.keccak256(message);
    if (this.account && this.connector && this.provider) {
      try {
        await this.provider.sendAsync(
          {
            method: 'personal_sign',
            params: [this.account, messageHash],
          },
          async function (err: Error, response: any) {
            if (err || response.error) {
              const errorMessage =
                err.message || (err as any).error || response.error.message;
              console.log('errorMessage', errorMessage);
              return null;
            } else {
              const signature = response.result;
              return signature;
            }
          },
        );
      } catch (error) {
        console.error(error);
        console.error('Signing message failed!');
        return null;
      }
    }
  }
}

export default BscWalletConnector;
