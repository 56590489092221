import React, { FC } from 'react';
import {
  ToastErrorIcon,
  ToastSuccessIcon,
  ToastWarningIcon,
  ToastInfoIcon,
} from 'src/assets/icons';
import { NOTIFY_TYPE } from 'src/utils/utils-notify';

interface ToastNotifyProps {
  type: NOTIFY_TYPE;
  title?: string;
  message?: string;
}
const AppToast: FC<ToastNotifyProps> = ({
  type = 'success',
  title = '',
  message = '',
}: ToastNotifyProps) => {
  const getIconNotify = () => {
    switch (type) {
      case 'error':
        return <ToastErrorIcon />;
      case 'success':
        return <ToastSuccessIcon />;
      case 'warning':
        return <ToastWarningIcon />;
      case 'info':
        return <ToastInfoIcon />;
      default:
        return <ToastInfoIcon />;
    }
  };

  return (
    <div className={`toast-notify ${type}`}>
      <div>{getIconNotify()}</div>
      <div className="content">
        {title && <div className="title">{title}</div>}
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

export default AppToast;
