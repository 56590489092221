import styles from 'src/styles/pages/PageProjectDetail/PartBanner.module.scss';
import { CopyIcon, VolumeIcon, VolumeMutedIcon } from 'src/assets/icons';
import { formatShortAddress } from 'src/utils/utils-formats';
import SocialLinks from 'src/components/SocialLinks';
import { getLogoNetwork, getNetworkConfig } from 'src/utils/utils-network';
import { copyToClipboard } from 'src/utils/utils-helpers';
import { Tooltip } from '@material-ui/core';
import { BigArrowLeftIcon } from 'src/assets/icons/index';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

const PartBanner = ({ project }: any) => {
  const history = useHistory();
  const [muted, setMuted] = useState(true);

  return (
    <div className={styles['banner-project']}>
      <div className={styles['banner-img']}>
        {project.featuredVideosUrl.length && !isMobile ? (
          <video
            autoPlay
            src={project.featuredVideosUrl[0]}
            loop
            preload="auto"
            muted={muted}
            className="slick-video-item"
            playsInline={false}
          />
        ) : (
          <img
            className="slick-img-item"
            src={
              project.featuredImagesUrl.length
                ? project.featuredImagesUrl[0]
                : ''
            }
          />
        )}
      </div>

      <div className={styles['banner-content']}>
        <div className={styles['icon-redirect']}>
          <BigArrowLeftIcon onClick={() => history.push('/')} />
        </div>

        <div className={styles['social-mobile']}>
          <SocialLinks media={project.media} type="menu" />
        </div>
        <div className={styles['info-wrap']}>
          <div className={styles['name-wrap']}>
            <BigArrowLeftIcon onClick={() => history.push('/')} />
            <Tooltip title={project.name} placement="bottom-start">
              <div className={styles['name']}>{project.name}</div>
            </Tooltip>
          </div>
          <div className={styles['box-info']}>
            <div className={styles['info-project']}>
              <div className={styles['network']}>
                <img
                  src={getLogoNetwork(project.info.keyMetrics.networks[0])}
                  alt=""
                />
                {getNetworkConfig(project.info.keyMetrics.networks[0])?.name}
              </div>

              <div className={styles['token']}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={styles['icon-token']}>
                    <img src={project.tokens[0]?.logoUrl} alt="" />
                  </div>
                  <span>{project.info.keyMetrics.ticker}</span>
                  <span className={styles['icon-token-name']}>
                    {project?.tokens[0]?.name ||
                      project?.info?.keyMetrics?.ticker}
                  </span>
                </div>
                <div className={styles['address']}>
                  {formatShortAddress(project.tokens[0]?.address)}
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(project.tokens[0]?.address || '')
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {project.featuredVideosUrl.length && !isMobile && (
          <div className={styles['icon-volume']}>
            <div onClick={() => setMuted((pre) => !pre)}>
              {muted ? <VolumeMutedIcon /> : <VolumeIcon />}
            </div>
          </div>
        )}
        <div className={styles['info-social']}>
          <SocialLinks media={project.media} />
        </div>
      </div>
    </div>
  );
};

export default PartBanner;
