import { Tooltip } from '@material-ui/core';
import styles from 'src/styles/modals/ModalSettingLBP.module.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DetailOverviewIcon } from 'src/assets/icons';
import AppSwitchButton from 'src/components/AppSwitchButton';
import { useDispatch, useSelector } from 'react-redux';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import config from 'src/config';
import { formatNumber } from '../utils/utils-formats';
import {
  IAuctionResponseType,
  withdrawAuction,
  toggleEnableSwap,
} from '../utils/utils-auction';
import { getNetworkProvider } from '../utils/utils-network';
import { LBPProxyContract } from '../utils/utils-contract';
import BaseModal from './BaseModal';
import AppButton from 'src/components/AppButton';
import { RootState } from 'src/store';
import BigNumber from 'bignumber.js';
import moment from 'moment';

interface ModalSettingLBPProps {
  open: boolean;
  onClose: () => void;
  auction: IAuctionResponseType;
  baseToken: any;
  baseTokenIndex: number;
  mainTokenIndex: number;
  baseTokenAccrued: number;
  balanceCurrent: string[];
  isEnabledSwap?: boolean;
  fetchStatusEnableSwap?: () => void;
  fetchBalancesPool: () => void;
  type?: string;
}

const ModalSettingLBP: FC<ModalSettingLBPProps> = ({
  open,
  onClose,
  auction,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  balanceCurrent,
  baseTokenAccrued,
  isEnabledSwap,
  fetchStatusEnableSwap,
  fetchBalancesPool,
  type = 'setting',
}) => {
  const { pool, network } = auction;
  const { address: poolAddress, endTime } = pool;
  const [feePercent, setFeePercent] = useState<number>(0);
  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleEnable = useCallback(async () => {
    onClose();
    const params = await toggleEnableSwap(poolAddress, !isEnabledSwap, network);
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    fetchStatusEnableSwap && fetchStatusEnableSwap();
  }, [isEnabledSwap, poolAddress]);

  const isCorrectNetwork = useMemo(
    () => user?.getNetwork() === network,
    [user, network],
  );

  const isPoolEnd = useMemo(
    () =>
      tokensUsdPrice[baseToken.symbol.toLowerCase()] *
        Number(balanceCurrent[baseTokenIndex]) <
      1,
    [tokensUsdPrice, balanceCurrent, baseTokenIndex, baseToken],
  );

  const getFeeSwap = async () => {
    const provider = getNetworkProvider(network);
    try {
      const contract = LBPProxyContract(
        config.networks[network].addresses.auctionProxy,
        provider,
      );
      const feeBPS = await contract._feeBPS();
      setFeePercent(+feeBPS.toString() / 100);
    } catch (e) {
      setFeePercent(0);
    }
  };

  const withdrawToken = async () => {
    onClose();
    const params = withdrawAuction(poolAddress, network);

    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    await fetchBalancesPool();
  };

  const feeApproximation = useMemo(() => {
    if (+baseTokenAccrued < 0) {
      return 0;
    }

    return new BigNumber(+baseTokenAccrued * feePercent).dividedBy(100);
  }, [baseTokenAccrued, feePercent]);

  const balanceBaseAfterMinusFee = useMemo(() => {
    const balance = new BigNumber(+balanceCurrent[baseTokenIndex]).minus(
      feeApproximation,
    );

    if (+balance < 0) {
      return 0;
    }

    return balance;
  }, [balanceCurrent, baseTokenIndex, feeApproximation]);

  useEffect(() => {
    getFeeSwap();
  }, []);

  const _renderToggleSwapping = () => {
    return (
      <div className={styles['wapper-field']}>
        <div className={styles['title']}>Swapping</div>
        <div className={styles['flex-swap']}>
          <div className={styles['label']}>
            {isEnabledSwap ? 'Enabled' : 'Disabled'}
          </div>
          <div className={styles['switch-ctrl']}>
            <AppSwitchButton
              onChange={handleEnable}
              checked={isEnabledSwap}
              disabled={
                isPoolEnd || !isCorrectNetwork || moment().unix() > endTime
              }
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-setting-lbp-title"
      aria-describedby="alert-dialog-setting-lbp-description"
      title={type === 'withdraw' ? 'Withdraw' : 'Settings'}
    >
      {type === 'setting' && _renderToggleSwapping()}

      <div className={styles['wapper-field']}>
        <div className={styles['title']}>Auction Balances</div>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={styles['shrink']}>
              <span className={styles['adornment']} />
              <span className={styles['label']}>Available balance</span>
            </div>
            <div className={`${styles['grow']}`}>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(balanceCurrent[mainTokenIndex])}
                  <span className={styles['adornment-end']}>
                    <img src={auction.logoUrl} alt="" />
                  </span>
                </div>
              </div>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(balanceCurrent[baseTokenIndex])}
                  <span className={styles['adornment-end']}>
                    <img src={baseToken.icon} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['view-paper']}>
            <div className={styles['shrink']}>
              <span className={styles['adornment']}></span>
              <span className={styles['label']}>Base Tokens Accrued</span>
            </div>
            <div className={`${styles['grow']}`}>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(baseTokenAccrued)}
                  <span className={styles['adornment-end']}>
                    <img src={baseToken.icon} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['wapper-field']}>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={`${styles['grow']} ${styles['grow-left']}`}>
              <div className={styles['label-info']}>
                <span className={styles['text']}>
                  {feePercent}% Platform Access Fee Approximation
                </span>
                <span className={styles['adornment-end']}>
                  <Tooltip
                    title="Platform access fee approximation is calculated in real time upon withdrawal transaction using the base tokens accrued."
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <DetailOverviewIcon />
                  </Tooltip>
                </span>
              </div>
              <div className={styles['value-info']}>
                <span className={styles['text']}>
                  {formatNumber(feeApproximation)}
                </span>
                <span className={styles['adornment-end']}>
                  <img src={baseToken.icon} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['wapper-field']}>
        <div className={styles['title']}>
          Balances After Platform Access Fee
        </div>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={styles['balance-block']}>
              <div className={styles['row-balance']}>
                <div className={styles['col-value']}>
                  <div className={styles['value']}>
                    {formatNumber(+balanceCurrent[mainTokenIndex])}
                  </div>
                  <div className={styles['adornment']}>
                    <img src={auction.logoUrl} alt="" />
                  </div>
                </div>
                <div className={styles['col-value']}>
                  <div className={styles['value']}>
                    {formatNumber(balanceBaseAfterMinusFee)}
                  </div>
                  <div className={styles['adornment']}>
                    <img src={baseToken.icon} alt="" />
                  </div>
                </div>
              </div>
              <AppButton
                sizes="small"
                isDisable={isPoolEnd || !isCorrectNetwork}
                className={styles['btn-withraw']}
                onClick={withdrawToken}
              >
                Withdraw All
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSettingLBP;
