import { useLocation } from 'react-router-dom';
import styles from 'src/styles/layout/Main.module.scss';
import { ReactNode, FC } from 'react';

interface IBasePage {
  children?: ReactNode;
  isPrivate?: boolean;
}

const BasePage: FC<IBasePage> = ({ children }) => {
  const location = useLocation();

  return (
    <div
      className={`${styles['default-layout']} ${
        location.pathname === '/'
          ? styles['background-landing']
          : styles['page-layout']
      }`}
    >
      <div className={styles['bg-body']}>
        <div className={styles['main-layout']}>{children}</div>
      </div>
    </div>
  );
};

export default BasePage;
