export const typography = {
  color: '#000000',
  h1: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '70px',
  },
  h2: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '50px',
    lineHeight: '60px',
  },
  h3: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '48px',
  },
  h4: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '38px',
  },
  h5: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '30px',
  },
  h6: {
    fontFamily: 'R-Flex-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
  },

  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '28px',
  },
  body2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
  },
  subtitle1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  caption: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
  overline: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
};
