import ProjectRequest from './ProjectRequest';
import UserRequest from './UserRequest';
import PoolRequest from './PoolRequest';
import GameRequest from './GameRequest';
import CoingeckoRequest from './CoingeckoRequest';
import TransactionRequest from './TransactionRequest';
import TransactionSolanaRequest from './TransactionSolanaRequest';
import CompetitionsRequest from './CompetitionsRequest';
import StakingRequest from './StakingRequest';
import MetaDataRequest from './MetaDataRequest';
import AuctionsRequest from './AuctionRequest';

const requestMap = {
  ProjectRequest,
  UserRequest,
  PoolRequest,
  GameRequest,
  CoingeckoRequest,
  TransactionRequest,
  TransactionSolanaRequest,
  CompetitionsRequest,
  StakingRequest,
  MetaDataRequest,
  AuctionsRequest,
};

const instances = {};

export default class RequestFactory {
  static getRequest(classname: string) {
    // @ts-ignore
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    // @ts-ignore
    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      // @ts-ignore
      instances[classname] = requestInstance;
    }

    return requestInstance;
  }
}
