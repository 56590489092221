import AppToast from 'src/components/AppToast';
import { toast } from 'react-toastify';

export type NOTIFY_TYPE = 'success' | 'warning' | 'error' | 'info';

const addToast = (type: NOTIFY_TYPE, content: ToastContentType) => {
  toast(
    AppToast({
      type,
      title: content?.title,
      message: content?.message,
    }),
  );
};
type ToastContentType = {
  title?: string;
  message: string;
};
export const toastError = (content: ToastContentType) => {
  addToast('error', content);
};

export const toastSuccess = (content: ToastContentType) => {
  addToast('success', content);
};

export const toastWarning = (content: ToastContentType) => {
  addToast('warning', content);
};

export const toastInfo = (content: ToastContentType) => {
  addToast('info', content);
};
