import { TokenType } from './utils-token';
import BigNumber from 'bignumber.js';
import Decimal from 'decimal.js';
import moment from 'moment';
import abi from 'src/abi';
import config from 'src/config';
import { LBPContract, vaultContract } from './utils-contract';
import { convertDecToWei } from './utils-formats';
import { getNetworkProvider } from './utils-network';
import rf from 'src/requests/RequestFactory';
import { Auction } from './auction';
import { STATUS_POOL } from 'src/pages/PageAuctionDetail';

export interface AuctionClient {
  tokenName: string;
  status: number;
  id: string;
  network: string;
  verified: boolean;
  logoUrl: string;
  liquidity: number;
  price: string | number;
  startTime: number;
  endTime: number;
  baseToken: ITokenAuction;
  swapEnabled: boolean;
}

export interface ISearchValue {
  status?: string | number;
  network?: string;
  searchKey?: string;
  page?: number;
  limit?: number;
  isDraft?: boolean;
  sortBy?: string;
  sortType?: string;
}

export interface ITokenDraft {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

export interface ITokenAuction {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  priceRate: string;
  balance: string;
  weight: string;
  latestPriceRate: string;
}

export interface ITokenMain {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  priceRate: string;
  balance: string;
  weight: string;
  icon: string;
}

export interface ITokenBase {
  coingeckoId: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  icon: string;
}

export interface SwapListResponseType {
  id: string;
  network: string;
  onchainId: string;
  caller: {
    id: string;
    network: string;
    address: string;
  };
  tokenIn: {
    id: string;
    network: string;
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    totalBalanceUSD: string;
    totalBalanceNotional: string;
    totalVolumeUSD: string;
    totalVolumeNotional: string;
    totalSwapCount: number;
  };
  tokenOut: {
    id: string;
    network: string;
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    totalBalanceUSD: string;
    totalBalanceNotional: string;
    totalVolumeUSD: string;
    totalVolumeNotional: string;
    totalSwapCount: number;
  };
  tokenAmountIn: string;
  tokenAmountOut: string;
  txid: string;
  timestamp: number;
  price?: string;
}

export interface IPoolAuctionResponseType {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  factory: string;
  symbol: string;
  name: string;
  swapEnabled: boolean;
  swapFee: string;
  owner: string;
  vault: {
    id: string;
    network: string;
    address: string;
    poolCount: number;
    totalSwapCount: number;
    totalLiquidity: string;
    totalSwapVolume: string;
    totalSwapFee: string;
  };
  txid: string;
  tokens: ITokenAuction[];
  tokensList: string[];
  createTime: number;
  totalLiquidity: string;
  totalSwapFee: string;
  totalSwapVolume: string;
  totalWeight: string;
  totalShares: string;
  holdersCount: number;
  swapsCount: number;
  startTime: number;
  endTime: number;
  startWeights: string[];
  endWeights: string[];
}

export interface IAuctionResponseType {
  id: string;
  network: string;
  creationTx: string;
  ownerAddress: string;
  logoUrl: string;
  description: string;
  draftInfo: {
    tokens: ITokenDraft[];
    amounts: number[];
    startTime: number;
    endTime: number;
    startWeights: number[];
    endWeights: number[];
    swapFee: number;
  };
  pool: IPoolAuctionResponseType;
  socialLinks: {
    website?: string;
    telegram?: string;
    twitter?: string;
    medium?: string;
    discord?: string;
  };
  isDraft: boolean;
  isVerified: boolean;
  status?: number;
}

export interface IAuctionSnapshotResponseType {
  avgPrice: string;
  timestamp: number;
}

export interface TokenInterface extends TokenType {
  totalSupply: string;
  logo: string;
}

export interface MediaInterface {
  website: string;
  telegram: string;
  twitter: string;
  discord: string;
  medium: string;
}

export interface AuctionType {
  network: string;
  token: TokenInterface;
  collateralToken: TokenType;
  depositToken: {
    launch: number | string;
    collateral: number | string;
  };
  duration: {
    startDate: Date;
    endDate: Date;
  };
  weights: {
    startWeight: number;
    endWeight: number;
  };
  description: string;
  media: MediaInterface;
  countries: string[];
  swapFee: number;
  permissions: {
    pauseTrading: boolean;
    pullLiquidity: boolean;
  };
  step: number;
}

export interface RefStep {
  validate?: () => void;
  auction?: AuctionType;
}

export interface CollateralToken {
  name: string;
  icon: string;
  currency: string;
  address: string;
  decimals: number;
}

export interface AuctionPriceChartData {
  time: number | string;
  value: number | string;
}

export const DEFAULT_CURRENCY = 'usdc';
export const DEFAULT_SWAP_FEE = 0.15 / 100;
export const TIME_FORMAT = 'HH:mm:ss MMM D, YYYY';

const BONE = new BigNumber(1);

export const getCurrentAuctionTime = (): moment.Moment =>
  moment().startOf('minute');

export const calcInGivenOut = (
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountOut: string,
  swapFee: string | number,
) => {
  const weightRatio = new BigNumber(tokenWeightOut).div(tokenWeightIn);
  const diff = new BigNumber(tokenBalanceOut).minus(tokenAmountOut);
  const y = new BigNumber(tokenBalanceOut).div(diff);
  const foo = Decimal.pow(y.toString(), weightRatio.toString()).toString();
  const bar = new BigNumber(foo).minus(BONE);
  const tokenAmountIn = new BigNumber(tokenBalanceIn)
    .multipliedBy(bar)
    .dividedBy(BONE.minus(swapFee));
  return !tokenAmountIn.isNaN() ? tokenAmountIn.toString() : '0';
};

export const calcOutGivenIn = (
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountIn: string,
  swapFee: string | number,
) => {
  const weightRatio = new BigNumber(tokenWeightIn)
    .div(tokenWeightOut)
    .toFixed(8, BigNumber.ROUND_DOWN);
  let adjustedIn = BONE.minus(swapFee);
  adjustedIn = new BigNumber(tokenAmountIn).multipliedBy(adjustedIn);
  const y = new BigNumber(tokenBalanceIn)
    .dividedBy(new BigNumber(tokenBalanceIn).plus(adjustedIn))
    .toFixed(8, BigNumber.ROUND_UP);
  const foo = Decimal.pow(y.toString(), weightRatio.toString())
    .toFixed(8, Decimal.ROUND_UP)
    .toString();
  const bar = BONE.minus(foo);
  const tokenAmountOut = new BigNumber(tokenBalanceOut)
    .multipliedBy(bar)
    .toFixed(8, BigNumber.ROUND_DOWN);
  return !new BigNumber(tokenAmountOut).isNaN()
    ? tokenAmountOut.toString()
    : '0';
};

export const calcAuctionChartData = (
  collateralBalance: number | string,
  tokenBalance: number | string,
  startTokenWeight: number,
  endTokenWeight: number,
  startDate: Date,
  endDate: Date,
  swapFee = DEFAULT_SWAP_FEE,
): AuctionPriceChartData[] => {
  const duration = moment(endDate).diff(moment(startDate), 'hours');
  if (duration <= 0) {
    return [];
  }
  const chartData = [];
  for (let step = 0; step <= duration; step++) {
    const tokenWeightOut = new BigNumber(
      startTokenWeight -
        (step / duration) * Math.abs(startTokenWeight - endTokenWeight),
    );
    const tokenWeightIn = new BigNumber(1).minus(tokenWeightOut);

    const inAmount = calcSpotPrice(
      collateralBalance,
      tokenWeightIn.toString(),
      tokenBalance,
      tokenWeightOut.toString(),
      swapFee,
    );
    const price = new BigNumber(inAmount).toFixed(8, BigNumber.ROUND_HALF_UP);

    chartData.push({
      time: moment(startDate).add(step, 'hours').valueOf(),
      value: price,
    });
  }

  return chartData;
};

export const withdrawAuction = (poolAddress: string, network: string) => {
  const addressContract = config.networks[network].addresses.auctionProxy;
  const paramsSend = [poolAddress, [0, 0], 0];
  return [abi['LBPProxy'], addressContract, 'exitPool', paramsSend, {}];
};

export const getCurrentBalances = async (
  poolId: string,
  network: string,
  vaultAddress: string,
) => {
  const provider = getNetworkProvider(network);
  try {
    const contract = vaultContract(vaultAddress, provider);
    const { balances } = await contract.getPoolTokens(poolId);
    return balances.map((item: string) => item.toString());
  } catch (e) {
    return [];
  }
};

export const getStatusEnableSwap = async (
  network: string,
  poolAddress: string,
) => {
  const provider = getNetworkProvider(network);
  try {
    const contract = LBPContract(poolAddress, provider);
    return await contract.getSwapEnabled();
  } catch (e) {
    return false;
  }
};

export const toggleEnableSwap = async (
  poolAddress: string,
  isEnabledSwap: boolean,
  network: string,
) => {
  const paramsSend = [poolAddress, isEnabledSwap];
  const addressContract = config.networks[network].addresses.auctionProxy;
  return [abi['LBPProxy'], addressContract, 'setSwapEnabled', paramsSend, {}];
};

export const calcSpotPrice = (
  tokenBalanceIn: string | number,
  tokenWeightIn: string | number,
  tokenBalanceOut: string | number,
  tokenWeightOut: string | number,
  swapFee: string | number,
) => {
  const weightRatio = new BigNumber(tokenWeightOut).div(tokenWeightIn);
  const inAmount = new BigNumber(tokenBalanceIn)
    .dividedBy(tokenBalanceOut)
    .multipliedBy(weightRatio)
    .toString();

  return new BigNumber(inAmount).multipliedBy(BONE.plus(swapFee)).toString();
};

function calculateWeightChangeProgress(
  timestamp: number | string,
  startTime: number | string,
  endTime: number | string,
): string {
  if (Number(timestamp) > Number(endTime)) {
    return convertDecToWei('1');
  } else if (Number(timestamp) < Number(startTime)) {
    return '0';
  }
  // No need for SafeMath as it was checked right above: endTime >= currentTime >= startTime
  const totalSeconds = Number(endTime) - Number(startTime);
  const secondsElapsed = Number(timestamp) - Number(startTime);
  return totalSeconds == 0
    ? convertDecToWei('1')
    : new BigNumber(secondsElapsed)
        .dividedBy(totalSeconds)
        .multipliedBy(convertDecToWei('1'))
        .decimalPlaces(0)
        .toString();
}

export function getNormalizedWeight(
  timestamp: number | string,
  poolState: {
    startTime: number;
    endTime: number;
    startWeight: string;
    endWeight: string;
  },
): number | string {
  const pctProgress = calculateWeightChangeProgress(
    timestamp,
    poolState.startTime,
    poolState.endTime,
  );
  if (
    new BigNumber(pctProgress).isZero() ||
    new BigNumber(poolState.startWeight).comparedTo(poolState.endWeight) === 0
  ) {
    return poolState.startWeight;
  }
  if (new BigNumber(pctProgress).comparedTo(convertDecToWei('1')) >= 0) {
    return poolState.endWeight;
  }
  if (
    new BigNumber(poolState.startWeight).comparedTo(poolState.endWeight) >= 0
  ) {
    const weightDelta = new BigNumber(pctProgress)
      .multipliedBy(
        new BigNumber(poolState.startWeight).minus(poolState.endWeight),
      )
      .dividedBy(convertDecToWei('1'))
      .decimalPlaces(0);

    return new BigNumber(poolState.startWeight).minus(weightDelta).toString();
  }
  const weightDelta = new BigNumber(pctProgress)
    .multipliedBy(
      new BigNumber(poolState.endWeight).minus(poolState.startWeight),
    )
    .dividedBy(convertDecToWei('1'))
    .decimalPlaces(0);
  return new BigNumber(poolState.startWeight).plus(weightDelta).toString();
}

export const getInfoJoinExitPool = async (auction: IAuctionResponseType) => {
  const lbpAuction = new Auction(auction);

  let balanceStart = [];
  let isExit = false;
  let balanceExit = [];

  try {
    const response = (await rf
      .getRequest('AuctionsRequest')
      .getInfoJoinExitHistoryPool(lbpAuction.getPoolId())) as any;
    if (response && response.docs) {
      const balancePoolStart = response.docs.find(
        (item: any) => item.type === STATUS_POOL.JOIN,
      );

      balanceStart = balancePoolStart.amounts;

      const balancePoolExit = response.docs.find(
        (item: any) => item.type === STATUS_POOL.EXIT,
      );

      if (balancePoolExit && !!Object.values(balancePoolExit).length) {
        isExit = true;
        balanceExit = balancePoolExit.amounts;
      }

      return {
        balanceStart,
        isExit,
        balanceExit,
      };
    }
  } catch (error: any) {
    return {
      balanceStart,
      isExit,
      balanceExit,
    };
  }
};
