import { useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import styles from 'src/styles/components/TokenomicsChart.module.scss';

Chart.register(...registerables);

interface DonutChartData {
  title: string;
  value: number;
}

export interface DonutChartProps {
  id: string;
  tokenData: any[];
}

const defaultProps = {
  tokenData: [],
};

const countSizeBorderRadius = (data: any[], isMobile: boolean) => {
  if (data.length > 1) {
    if (isMobile) return 5;
    else return 10;
  } else return 0;
};

const AppChartDonut: React.FC<DonutChartProps> = (props: DonutChartProps) => {
  const { id, tokenData } = props;

  const [data, setData] = useState<DonutChartData[]>([]);

  const CHART_TYPE = 'doughnut';
  const CUTOUT = 55;
  const MOBILE_WIDTH = 768;
  const legendContainerId = 'donut-chart-legend-container' + id;
  const DATASET_COLORS = [
    'rgba(0, 120, 255, 1)',
    'rgba(55, 222, 254, 1)',
    'rgba(64, 195, 109, 1)',
    'rgba(181, 62, 250, 1)',
    'rgba(240, 116, 22, 1)',
    'rgba(253, 218, 2, 1)',
    'rgba(245, 66, 114, 1)',
    'rgba(245, 239, 66, 1)',
    'rgba(245, 66, 96, 1)',
    'rgba(66, 245, 117, 1)',
    'rgba(245, 236, 66, 1)',
    'rgba(72, 245, 66, 1)',
    'rgba(245, 242, 66, 1)',
    'rgba(66, 245, 150, 1)',
    'rgba(66, 164, 245, 1)',
  ];

  useEffect(() => {
    const onResize = () => {
      if (data.length > 0) onDrawChart();
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [data]);

  useEffect(() => {
    if (tokenData.length > 0) {
      onGenerateChartData(tokenData);
    }
  }, [tokenData]);

  useEffect(() => {
    if (data.length > 0) {
      onDrawChart();
    }
  }, [data]);

  const onGenerateChartData = (tokensData: any[]) => {
    const newData = tokensData.map((tokenContent) => ({
      title: tokenContent.title,
      value: tokenContent.totalAllocation,
    }));
    setData(newData);
  };

  const onGenerateGradientBackgroundColor = () => {
    const pieChart = document.getElementById(id);
    // @ts-ignore
    const chartContext = pieChart.getContext('2d');
    const hoveredArcBackgroundColor = chartContext.createLinearGradient(
      0,
      0,
      225,
      225,
    );
    hoveredArcBackgroundColor.addColorStop(0, 'rgba(255, 166, 133, 1)');
    hoveredArcBackgroundColor.addColorStop(0.0772, 'rgba(255, 73, 6, 1)');
    hoveredArcBackgroundColor.addColorStop(0.5089, 'rgba(255, 139, 96, 1)');
    hoveredArcBackgroundColor.addColorStop(0.9146, 'rgba(255, 166, 133, 1)');
    return hoveredArcBackgroundColor;
  };

  const onGenerateChartOptions = (isMobile: boolean) => {
    const hoverBackgroundColor = onGenerateGradientBackgroundColor();
    return {
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          fontColor: '#fff',
        },
      },
      elements: {
        arc: {
          borderRadius: countSizeBorderRadius(data, isMobile),
          borderWidth: 0,
          offset: countSizeBorderRadius(data, isMobile),
          hoverBackgroundColor,
        },
      },
    };
  };

  const getOrCreateLegendList = () => {
    const legendContainer = document.getElementById(legendContainerId);
    // @ts-ignore
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.className = styles['container__chart__legend__list'];
      // @ts-ignore
      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: legendContainerId,
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList();
      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item: any) => {
        const li = document.createElement('li');
        li.className = styles['container__chart__legend__list__item'];
        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            // chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex),
            );
          }
          chart.update();
        };
        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.className = styles['container__chart__legend__list__color-box'];
        boxSpan.style.background = item.fillStyle;

        // Text
        const textContainer = document.createElement('p');
        textContainer.className =
          styles['container__chart__legend__list__text'];
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const onDrawChart = () => {
    const pieChart = document.getElementById(id);
    const chart = Chart.getChart(id);
    if (chart) chart.destroy();
    const isMobile = window.innerWidth <= MOBILE_WIDTH;
    const options = onGenerateChartOptions(isMobile);
    const dataLabels = data.map((item) => item.title);
    const dataValues = data.map((item) => item.value);
    // @ts-ignore
    return new Chart(pieChart, {
      type: CHART_TYPE,
      data: {
        labels: dataLabels,
        datasets: [
          {
            data: dataValues,
            cutout: `${CUTOUT}%`,
            backgroundColor: DATASET_COLORS,
          },
        ],
      },
      options,
      plugins: [htmlLegendPlugin],
    });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['container__chart']}>
        {data.length > 0 && (
          <div className={styles['container__chart__inner-circle']} />
        )}
        <div className={styles['container__chart__wrapper']}>
          <canvas id={id} className={styles['container__chart__element']} />
        </div>
      </div>
      <div
        id={legendContainerId}
        className={styles['container__chart__legend']}
      />
    </div>
  );
};

AppChartDonut.defaultProps = defaultProps;
export default AppChartDonut;
