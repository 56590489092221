import { useEffect, useMemo, useState } from 'react';
import AppBadge from 'src/components/AppBadge';
import AppCountdown from 'src/components/AppCountdown';
import styles from 'src/styles/components/PoolCard.module.scss';
import SocialLinks from 'src/components/SocialLinks';
import {
  getAllocationTicketByUSD,
  getCountdownTimestamp,
  getPoolDetailLink,
  getPublicRaiseByUSD,
} from 'src/utils/utils-pool';
import { Link } from 'react-router-dom';
import { VisibilityGridPoolsEnum } from 'src/constants';
import { IdoTimeline } from 'src/utils/timelines';
import {
  convertWeiToDec,
  formatNumber,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import moment from 'moment';
import { IDOPool, PoolResponseType } from 'src/utils/pool';
import { getLogoNetwork } from 'src/utils/utils-network';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { PoolType } from 'src/utils/common';

interface IPoolCard {
  pool: PoolResponseType;
  type: string;
}

const PoolCard = (props: IPoolCard) => {
  const { pool, type = VisibilityGridPoolsEnum.ROW } = props;
  const poolIdo = new IDOPool(pool);
  const [project, setProject] = useState<any>(null);
  const timeline = useMemo(() => new IdoTimeline(pool), [pool]);

  useEffect(() => {
    setProject(poolIdo.getProject());
  }, [poolIdo?.getId()]);

  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);

  const getTitleCountdown = () => {
    if (timeline.beforeWhitelistPhase()) {
      return 'Countdown to Whitelist';
    }
    if (timeline.isWhitelistPhase()) {
      return 'Whitelist ends in';
    }
    if (timeline.beforeSwapPhase()) {
      return 'Countdown to Swap time';
    }
    if (timeline.isPrivateSwapPhase()) {
      return 'Exclusive sale ends in';
    }
    if (timeline.isPublicSwapPhase()) {
      return 'FCFS ends in:';
    }
    return '';
  };

  const getTotalRaisedUsd = () => {
    const { idoDetails, currency, currencyPriceUSD, type, inoDetails } = pool;
    if (type === PoolType.IDO) {
      const publicRaise = getPublicRaiseByUSD(
        idoDetails?.maxCap,
        idoDetails?.price,
        idoDetails?.token.decimals,
        currency?.decimals,
        currencyPriceUSD,
      );

      const totalPublicRaise =
        +convertWeiToDec(idoDetails?.maxCap, idoDetails?.token.decimals) *
        +convertWeiToDec(idoDetails?.price, currency?.decimals);

      return _renderTotalRaised(
        formatNumber(totalPublicRaise.toString()),
        publicRaise,
        currency?.symbol,
      );
    }

    if (type === PoolType.INO) {
      const totalRaised = inoDetails.packages.reduce(
        (a: number, b) =>
          a + +convertWeiToDec(b.price, currency.decimals) * b.quantity,
        0,
      );

      return _renderTotalRaised(
        formatNumber(totalRaised.toFixed(2)),
        formatNumber((totalRaised * currencyPriceUSD).toFixed(2)),
        currency?.symbol,
      );
    }
  };

  const getPrice = () => {
    const { type, inoDetails, currency } = pool;

    if (type === PoolType.IDO) {
      return `1 ${pool?.idoDetails?.token.symbol} = ${formatWeiNumber(
        pool?.idoDetails?.price,
        pool?.currency.decimals,
      )} ${pool?.currency.symbol}`;
    }

    if (type === PoolType.INO) {
      const minPrice = _.minBy(inoDetails.packages, (item: any) => +item.price);
      const maxPrice = _.maxBy(inoDetails.packages, (item: any) => +item.price);
      if (minPrice?.price === maxPrice?.price) {
        return `${formatWeiNumber(minPrice?.price || '0', currency.decimals)} ${
          currency.symbol
        }`;
      }

      return `${formatWeiNumber(
        minPrice?.price || '0',
        currency.decimals,
      )} - ${formatWeiNumber(maxPrice?.price || '0', currency.decimals)} ${
        currency.symbol
      }`;
    }
  };

  const getAllocationPerTicket = () => {
    const { idoDetails, currency, type, inoDetails, currencyPriceUSD } = pool;

    if (type === PoolType.IDO) {
      const allocationPerTicket = getAllocationTicketByUSD(
        idoDetails?.ticketAllocation.tokenPerTicket,
        idoDetails?.price,
        idoDetails?.token.decimals,
        currency?.decimals,
        currencyPriceUSD,
      );
      return `$${allocationPerTicket}`;
    }

    if (type === PoolType.INO) {
      return `${formatNumber(
        inoDetails?.ticketAllocation?.boxPerTicket,
      )} BOXES`;
    }
  };

  const getTokenUrl = () => {
    const { type, idoDetails, inoDetails } = pool;

    if (type === PoolType.IDO) {
      return idoDetails?.token.logoUrl;
    }

    if (type === PoolType.INO) {
      return inoDetails?.token.logoUrl;
    }
  };

  const getTokenSymbol = () => {
    const { type, idoDetails, inoDetails } = pool;

    if (type === PoolType.IDO) {
      return idoDetails?.token.symbol;
    }

    if (type === PoolType.INO) {
      return inoDetails?.token.symbol;
    }
  };

  const renderCountdown = (duration: number) => {
    return (
      <>
        {Math.floor(duration / (3600 * 24)) > 0 && (
          <>
            <span>{Math.floor(duration / (3600 * 24))}</span>d{' : '}
          </>
        )}
        <span>{moment.utc(duration * 1000).format('HH')}</span>h{' : '}
        <span>{moment.utc(duration * 1000).format('mm')}</span>m{' : '}
        <span>{moment.utc(duration * 1000).format('ss')}</span>s
      </>
    );
  };

  const getLinkDetail = () => {
    return getPoolDetailLink(pool);
  };

  const _renderTotalRaised = (
    totalRaised: string,
    totalRaisedUsd: string,
    symbol: string,
  ) => {
    return (
      <div>
        {totalRaised} {symbol}
        <span className={styles['price']}>~${totalRaisedUsd}</span>
      </div>
    );
  };

  const _renderPoolCardRow = () => {
    const countdownTitle = getTitleCountdown();
    const countdownTimeStamp = getCountdownTimestamp(timeline);
    return (
      <div className={styles['pool-card-row']}>
        <Link to={getLinkDetail()}>
          <div className={styles['thumb']}>
            <div className={styles['thumb-img']}>
              <img src={pool?.featureImageUrl} alt="" />
            </div>
            <div className={styles['box-logo-network']}>
              <div className={styles['logo-network']}>
                <img src={getLogoNetwork(pool?.network)} alt="" />
              </div>
            </div>
          </div>
        </Link>
        <div className={styles['info']}>
          <div className={styles['badge']}>
            <div>
              <AppBadge color="pink" variant="outline">
                {pool?.type}
              </AppBadge>
              <AppBadge color="green" variant="outline">
                {pool?.status === 'TOKEN_SALE' ? 'SWAP' : pool?.status}
              </AppBadge>
            </div>
            <div className={styles['info-social']}>
              {project && <SocialLinks media={project.getMedia()} />}
            </div>
          </div>

          <div className={styles['name']}>
            <Link to={getLinkDetail()}>
              <Tooltip title={pool?.name} placement="bottom-start">
                <div className={styles['title']}>{pool?.name}</div>
              </Tooltip>
            </Link>

            <div className={styles['token']}>
              <img src={getTokenUrl()} alt="" />
              <span>{getTokenSymbol()}</span>
            </div>
          </div>

          <div className={styles['info-detail']}>
            <div>
              <div className={styles['info-item']}>
                <span className={styles['label']}>Public Raise</span>
                <span className={styles['value']}>{getTotalRaisedUsd()}</span>
              </div>
              <div className={styles['info-item']}>
                <span className={styles['label']}>Price</span>
                <span className={styles['value']}>{getPrice()}</span>
              </div>
            </div>

            <div>
              <div className={styles['info-item']}>
                <span className={styles['label']}>Allocation per User</span>
                <span className={styles['value']}>
                  {getAllocationPerTicket()}
                </span>
              </div>

              {!!countdownTitle && !!countdownTimeStamp && (
                <div
                  className={`${styles['info-item']} ${styles['countdown']}`}
                >
                  <span className={styles['label']}>{countdownTitle}</span>
                  <span className={styles['value']}>
                    {!!countdownTimeStamp && (
                      <AppCountdown
                        endDate={countdownTimeStamp}
                        render={renderCountdown}
                      />
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderPoolCardColumn = () => {
    const countdownTitle = getTitleCountdown();
    const countdownTimeStamp = getCountdownTimestamp(timeline);
    return (
      <div
        className={`${styles['pool-card-column']} col-sm-12 col-md-4 mb-4 mt-2`}
      >
        <div className={styles['content']}>
          <div className={styles['thumb']}>
            <div className={styles['thumb-img']}>
              <Link to={getLinkDetail()}>
                <img src={pool?.featureImageUrl} alt="" />
              </Link>
            </div>

            <div className={styles['badges']}>
              <AppBadge color="pink" variant="outline">
                {pool?.type}
              </AppBadge>
              <AppBadge color="green" variant="outline">
                {pool?.status === 'TOKEN_SALE' ? 'SWAP' : pool?.status}
              </AppBadge>
            </div>

            <div className={styles['box-logo-network']}>
              <div className={styles['logo-network']}>
                <img src={getLogoNetwork(pool?.network)} alt="" />
              </div>
            </div>
          </div>

          <div className={styles['detail']}>
            <div className={styles['name-info']}>
              <div>
                <Link to={getLinkDetail()}>
                  <Tooltip title={pool?.name} placement="bottom-start">
                    <div className={styles['name']}>{pool?.name}</div>
                  </Tooltip>
                </Link>
                <div className={styles['project-logo']}>
                  <img src={getTokenUrl()} alt="" />
                  <span>{getTokenSymbol()}</span>
                </div>
              </div>
            </div>

            <div className={styles['info']}>
              <div className={styles['info-item']}>
                <div className={styles['label']}>Public Raise</div>
                <div className={styles['value']}>{getTotalRaisedUsd()}</div>
              </div>
              <div className={styles['info-item']}>
                <div className={styles['label']}>Price</div>
                <div className={styles['value']}>{getPrice()}</div>
              </div>
              <div className={styles['info-item']}>
                <div className={styles['label']}>Allocation per Ticket</div>
                <div className={styles['value']}>
                  {getAllocationPerTicket()}
                </div>
              </div>
              {!!countdownTitle && !!countdownTimeStamp && (
                <div className={styles['info-item']}>
                  <span className={styles['label']}>{countdownTitle}</span>
                  <div className={styles['value']}>
                    {!!countdownTimeStamp && (
                      <AppCountdown
                        endDate={countdownTimeStamp}
                        render={renderCountdown}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className={styles['social']}>
              {project && <SocialLinks media={project.getMedia()} />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {type === VisibilityGridPoolsEnum.ROW
        ? _renderPoolCardRow()
        : _renderPoolCardColumn()}
    </>
  );
};
export default PoolCard;
