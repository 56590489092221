import config from 'src/config';
import BaseRequest from './BaseRequest';

type BuyINOTransactionParams = {
  poolId: string;
  userAddress: string;
  inAmount: string;
  packageId: number | string;
};
export default class TransactionRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.swapApi;
  }

  getBuyTransaction(data: any) {
    const url = `/transactions/buy`;
    return this.post(url, data);
  }

  getClaimTransaction(data: any) {
    const url = `/transactions/claim`;
    return this.post(url, data);
  }

  appointWithdraw(params: any) {
    const url = `/transactions/appoint-withdraw`;
    return this.post(url, params);
  }

  getBuyInoTransaction(params: BuyINOTransactionParams) {
    const url = `/transactions/ino/buy`;
    return this.post(url, params);
  }
}
