import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import AppButton from 'src/components/AppButton';
import StakingTable from 'src/components/StakingTable';
import landingStyle from 'src/styles/pages/Homepage.module.scss';

const PartStaking: FC = () => {
  const history = useHistory();
  return (
    <div className={landingStyle['staking']}>
      <div className={landingStyle['title']}>Staking</div>
      <div className={landingStyle['description']}>
        Stake BUNI to our pool to earn passive rewards and increase your tier in
        joining projects on Metaverse Starter{' '}
      </div>
      <div
        className={`${isMobile ? 'container' : landingStyle['staking-table']}`}
      >
        <StakingTable isBorderTable={true} />
      </div>
      <AppButton
        className={landingStyle['start-staking-btn']}
        variant="primary"
        sizes="medium"
        onClick={() => history.push('/staking')}
      >
        Start Staking
      </AppButton>
    </div>
  );
};

export default PartStaking;
