import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/styles/layout/_layout.scss';
import 'src/styles/components/_toast-notify.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'src/styles/main.scss';
import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 50 });
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
