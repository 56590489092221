import { FC, useState } from 'react';
import bs58 from 'bs58';
import { SolanaIcon } from 'src/assets/icons';
import CopyableWalletAddress from 'src/components/CopyableWalletAddress';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/modals/ModalLinkAccount.module.scss';
import config, { Connector } from 'src/config';
import { map } from 'lodash';
import ConnectorFactory from 'src/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NetworkLink } from '../utils/project';
import clsx from 'clsx';
import { getUser } from 'src/store/myAccount';
import BaseModal from './BaseModal';
import { toastError } from 'src/utils/utils-notify';

interface ModalLinkAccountProps {
  open: boolean;
  networkLink: NetworkLink | null;
  onSuccess?: () => void;
  onClose?: () => void;
}

const ModalLinkAccount: FC<ModalLinkAccountProps> = (props) => {
  const { open, networkLink, onSuccess, onClose } = props;

  const connectorsByNetwork =
    networkLink && config.networks[networkLink?.network]?.connectors;

  const { network, address } = useSelector(
    (state: RootState) => state.authentication,
  );
  const dispatch = useDispatch();

  const [chooseWallet, setChooseWallet] = useState<boolean>(false);
  const onConnectWallet = async (wallet: Connector) => {
    if (!networkLink) return;
    try {
      const connector = ConnectorFactory.getConnector(
        wallet.id,
        networkLink.network,
      );
      await connector.connect();

      const linkedAddress = await connector.getAccount();
      const data = {
        domain: networkLink.domain,
        network: networkLink.network,
        address: linkedAddress,
      };
      const signature = await connector.signMessage(data);
      const signatureEncode = bs58.encode(signature);
      await rf
        .getRequest('UserRequest')
        .linkAccount(
          { ...data, signature: signatureEncode },
          networkLink.networkFamily,
        );
      dispatch(getUser());
      onSuccess && onSuccess();
      onCloseModal();
    } catch (error: any) {
      console.log('error', error);
      toastError({ message: error.toString() });
    }
  };

  const onConfirm = () => {
    setChooseWallet(true);
  };

  const onCloseModal = () => {
    setChooseWallet(false);
    onClose && onClose();
  };

  return (
    <BaseModal
      open={open}
      title={'Connect wallet'}
      onClose={onCloseModal}
      onActionLeft={!chooseWallet ? onCloseModal : undefined}
      textActionLeft={'No'}
      onActionRight={!chooseWallet ? onConfirm : undefined}
      textActionRight={'Yes'}
    >
      <>
        <div className={styles['wallet-connector']}>
          <div className={styles['icon-brand']}>
            <SolanaIcon />
          </div>
          <div className={styles['wallet-address']}>
            <span className={styles['copy-text']}>
              {network.toLowerCase()}:
            </span>
            <CopyableWalletAddress walletAddress={address} bold />
          </div>
          <p className={styles['text-desc']}>
            {!chooseWallet ? (
              <>
                To join{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {networkLink?.networkFamily}{' '}
                </span>
                network required pools, you need to connect your current account
                with Terra network. Are you sure want to continue?
              </>
            ) : (
              <>
                You are connecting this account with{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {networkLink?.networkFamily}{' '}
                </span>
                network.
              </>
            )}
          </p>
          {chooseWallet && (
            <div className={styles['choose-wallet']}>
              <p className={styles['choose-wallet-title']}>Choose a Wallet</p>
              <div className={styles['group-btn-wallet']}>
                {map(connectorsByNetwork, (wallet: Connector) => (
                  <ButtonConnectWallet
                    wallet={wallet}
                    onConnectWallet={() => {
                      onConnectWallet(wallet);
                    }}
                    key={wallet.id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    </BaseModal>
  );
};

export default ModalLinkAccount;

interface ButtonConnectWalletProps {
  wallet: Connector;
  className?: string;
  onConnectWallet?: () => void;
}
export const ButtonConnectWallet: FC<ButtonConnectWalletProps> = ({
  wallet,
  className,
  onConnectWallet,
}) => {
  return (
    <button
      className={clsx(styles['wallet-item'], className)}
      onClick={onConnectWallet}
    >
      <img src={wallet.icon} alt={wallet.name} className={styles['icon']} />
      <div className={styles['name']}>{wallet.name}</div>
    </button>
  );
};
