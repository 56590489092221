import { FC, ReactNode } from 'react';
import styles from 'src/styles/components/AppBadge.module.scss';

type BADGE_TYPE = 'primary' | 'outline';
type BADGE_COLOR = 'pink' | 'green' | 'orange' | 'skyblue' | 'red' | 'blue';

interface BadgeProps {
  children: ReactNode;
  onClick?: () => void;
  color: BADGE_COLOR;
  className?: string;
  variant?: BADGE_TYPE;
}
const AppBadge: FC<BadgeProps> = ({
  children,
  onClick = () => {
    return null;
  },
  className,
  color = 'green',
  variant = 'primary',
}: BadgeProps) => {
  return (
    <div
      className={`${styles.badge} ${className} ${styles[variant]} ${styles[color]}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default AppBadge;
