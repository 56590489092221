import {
  useState,
  useEffect,
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import { Select, MenuItem } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'src/styles/pages/LPB/steps/StepPoolSetup.module.scss';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import useAuth from 'src/hooks/useAuth';
import AppButton from 'src/components/AppButton';
import AuctionPriceChart from '../parts/PartAuctionPriceChart';
import {
  convertWeiToDec,
  formatToPercent,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import {
  AuctionType,
  RefStep,
  CollateralToken,
  DEFAULT_CURRENCY,
  calcAuctionChartData,
  AuctionPriceChartData,
} from 'src/utils/utils-auction';
import {
  getTokenBalance,
  getTokenDecimals,
  getSymbolBaseToken,
} from 'src/utils/utils-token';
import config from 'src/config';
import { useForceRender } from 'src/hooks/useForceRender';
import { createValidator } from 'src/utils/utils-validator';
import AppInput from 'src/components/AppInput';
import AppDatePicker from 'src/components/AppDatePicker';
import AppInputRange from 'src/components/AppInputRange';
import { FooterSetupPool } from './StepPoolInfo';
import { PlusIcon } from 'src/assets/icons';
import { BeatLoader } from 'react-spinners';
import { getNetworkProvider } from 'src/utils/utils-network';
import { roundNumber } from 'src/utils/utils-helpers';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

interface PoolSetupProps {
  auction: AuctionType;
  onClickNext: () => void;
  onClickBack: () => void;
}

const ADDRESS_TOKEN_NATIVE = '0x00';
const TOTAL_MINUTES_IN_7_DAYS = 10080;
const MIN_VALUE_COLLATERAL_TOKEN = config.minBaseTokenInAuction;

const StepPoolSetup = forwardRef((props: PoolSetupProps, ref: Ref<RefStep>) => {
  const { auction, onClickNext, onClickBack } = props;
  const { user } = useAuth();

  const [internalAuction, setInternalAuction] = useState(auction);
  const [launchTokenBalance, setLaunchTokenBalance] = useState<number>(0);
  const [collateralTokenBalance, setCollateralTokenBalance] =
    useState<number>(0);
  const [isLoadingCollateralTokenBalance, setIsLoadingCollateralTokenBalance] =
    useState(false);
  const [isDisableNextStep, setIsDisableNextStep] = useState(true);
  const [listCollateralToken, setListCollateralToken] = useState<
    CollateralToken[]
  >([]);
  const [chartData, setChartData] = useState<AuctionPriceChartData[]>([]);

  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);

  const { network, token, depositToken, collateralToken, duration, weights } =
    internalAuction;

  const isNetworkCorrect = network === user?.getNetwork();

  const forceRender = useForceRender();

  const validator = useRef(
    createValidator({
      element: (message: string) => (
        <div className={styles['error']}>{message}</div>
      ),
    }),
  );

  const validate = async () => {
    if (isDisableNextStep) throw new Error('Invalid validation');
  };

  useImperativeHandle(ref, () => ({
    validate,
    auction: internalAuction,
  }));

  useEffect(() => {
    setInternalAuction(auction);
  }, [auction]);

  useEffect(() => {
    const isDisabled =
      isLoadingCollateralTokenBalance ||
      !validator.current.allValid() ||
      duration.startDate >= duration.endDate ||
      duration.startDate < nowDate ||
      durationTime > TOTAL_MINUTES_IN_7_DAYS ||
      !isNetworkCorrect;
    setIsDisableNextStep(isDisabled);
  }, [
    depositToken,
    duration,
    launchTokenBalance,
    collateralTokenBalance,
    isLoadingCollateralTokenBalance,
    isNetworkCorrect,
  ]);

  useEffect(() => {
    const currencies = config.networks[auction.network].currencies;
    const data = Object.keys(currencies)
      .map((currency) => ({
        ...currencies[currency],
        currency,
      }))
      .filter((currency) => {
        return currency.address !== '0x00';
      });
    setListCollateralToken(data);
  }, [network]);

  const fetchLaunchTokenBalance = (
    tokenAddress: string,
    userAddress: string | undefined,
  ) => {
    if (!tokenAddress || !userAddress) {
      return;
    }
    getTokenBalance(network, tokenAddress, userAddress)
      .then((balance) => setLaunchTokenBalance(balance))
      .catch(() => setLaunchTokenBalance(0));
  };

  useEffect(() => {
    fetchLaunchTokenBalance(token.address, user?.getAddress());
  }, [token.address, user?.getAddress()]);

  const fetchCollateralTokenBalance = async (
    collateralSymbol: string,
    auctionNetwork: string,
    userAddress: string | undefined,
  ) => {
    if (!collateralSymbol || !auctionNetwork || !userAddress) return;
    setIsLoadingCollateralTokenBalance(true);

    const tokenByCurrency = listCollateralToken.find(
      (item) => item.name.toLowerCase() === collateralSymbol.toLowerCase(),
    );

    if (!tokenByCurrency) {
      setIsLoadingCollateralTokenBalance(false);
      return;
    }

    if (tokenByCurrency.address === ADDRESS_TOKEN_NATIVE) {
      const provider = getNetworkProvider(network);
      const balance = await provider.getBalance(userAddress);
      setCollateralTokenBalance(+balance.toString());
      setIsLoadingCollateralTokenBalance(false);
      return;
    }

    getTokenBalance(auctionNetwork, tokenByCurrency.address, userAddress)
      .then((balance) => {
        setCollateralTokenBalance(balance);
        setIsLoadingCollateralTokenBalance(false);
        setInternalAuction((prevState) => ({
          ...prevState,
          collateralToken: {
            ...prevState.collateralToken,
            address: tokenByCurrency.address,
            name: tokenByCurrency.name,
            decimals: tokenByCurrency.decimals,
          },
        }));
      })
      .finally(() => {
        setIsLoadingCollateralTokenBalance(false);
      });
  };

  useEffect(() => {
    fetchCollateralTokenBalance(
      collateralToken.symbol,
      network,
      user?.getAddress(),
    );
  }, [
    collateralToken.symbol,
    network,
    user?.getAddress(),
    listCollateralToken,
  ]);

  useEffect(() => {
    if (
      Number(depositToken.collateral) <= 0 ||
      Number(depositToken.launch) <= 0
    ) {
      setChartData([]);
      return;
    }

    const newChartData = calcAuctionChartData(
      depositToken.collateral || 0,
      depositToken.launch || 0,
      weights.startWeight,
      weights.endWeight,
      duration.startDate,
      duration.endDate,
    );
    setChartData(newChartData);
  }, [depositToken, duration, weights]);

  const onClickMaxLaunchToken = () => {
    const balance = convertWeiToDec(
      launchTokenBalance.toString(),
      token.decimals,
    );
    onChangeDepositToken({
      ...depositToken,
      launch: +new BigNumber(balance.toString()).toFixed(
        token.decimals > 8 ? 8 : token.decimals,
      ),
    });
  };

  const onClickMaxCollateralToken = () => {
    const balance = convertWeiToDec(
      collateralTokenBalance.toString(),
      collateralToken.decimals,
    );
    onChangeDepositToken({
      ...depositToken,
      collateral: +new BigNumber(balance.toString()).toFixed(
        decimalCollateralToken > 8 ? 8 : decimalCollateralToken,
      ),
    });
  };

  const onHandleNext = async () => {
    if (isDisableNextStep) return;
    onClickNext();
  };

  const onBlurInput = (inputName: string) => {
    if (!validator.current.fieldValid(inputName)) {
      validator.current.showMessageFor(inputName);
    }
    forceRender();
  };

  const onChangeDepositToken = (newDepositToken: {
    launch: number | string;
    collateral: number | string;
  }) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      depositToken: newDepositToken,
    }));
  };

  const onChangeCollateralTokenSymbol = (symbol: string) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      collateralToken: { ...prevState.collateralToken, symbol },
    }));
  };

  const getDecimalPlaces = (decimals: number) => {
    return decimals > 8 ? 8 : decimals;
  };

  const minCollateralToken = useMemo(() => {
    const value =
      MIN_VALUE_COLLATERAL_TOKEN /
      tokensUsdPrice[getSymbolBaseToken(collateralToken.symbol.toLowerCase())];
    return roundNumber(value, BigNumber.ROUND_DOWN, 6);
  }, [tokensUsdPrice, collateralToken]);

  const decimalCollateralToken = useMemo(
    () => getTokenDecimals(network, collateralToken.symbol, DEFAULT_CURRENCY),
    [network, collateralToken],
  );

  const onChangeDuration = (newDuration: {
    startDate: Date;
    endDate: Date;
  }) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      duration: newDuration,
    }));
  };

  const onChangeWeights = (newWeights: {
    startWeight: number;
    endWeight: number;
  }) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      weights: newWeights,
    }));
  };

  validator.current.purgeFields();

  useEffect(() => {
    if (weights.startWeight < weights.endWeight) {
      onChangeWeights({
        ...weights,
        endWeight: weights.startWeight,
      });
    }
  }, [weights.startWeight]);

  useEffect(() => {
    if (weights.startWeight < weights.endWeight) {
      onChangeWeights({
        ...weights,
        startWeight: weights.endWeight,
      });
    }
  }, [weights.endWeight]);

  const handleChangeWeight = (
    weightAttribute: 'startWeight' | 'endWeight',
    type: 'minus' | 'plus',
    value: number,
  ) => {
    if (!isNetworkCorrect) return;
    const valueAfterMinus =
      Number(new BigNumber(value).minus(0.01)) < 0.01
        ? 0.01
        : Number(new BigNumber(value).minus(0.01));
    const valueAfterPlus =
      Number(new BigNumber(value).plus(0.01)) > 0.99
        ? 0.99
        : Number(new BigNumber(value).plus(0.01));

    if (weightAttribute === 'startWeight') {
      onChangeWeights({
        ...weights,
        startWeight: type === 'minus' ? valueAfterMinus : valueAfterPlus,
      });
    }

    if (weightAttribute === 'endWeight') {
      onChangeWeights({
        ...weights,
        endWeight: type === 'minus' ? valueAfterMinus : valueAfterPlus,
      });
      return;
    }
  };

  const _renderWeightSlider = (
    weightAttribute: 'startWeight' | 'endWeight',
  ) => (
    <AppInputRange
      handleChange={(_, value) => {
        onChangeWeights({
          ...weights,
          [weightAttribute]: new BigNumber(+value).dividedBy(100).toString(),
        });
      }}
      value={new BigNumber(weights[weightAttribute])
        .multipliedBy(100)
        .toNumber()}
      valueLabelDisplay="auto"
      step={1}
      max={99}
      min={1}
      disabled={!isNetworkCorrect}
    />
  );

  const getMaxValueLaunchToken = () => {
    return +convertWeiToDec(launchTokenBalance.toString(), token.decimals);
  };

  const getMaxValueCollateralToken = () => {
    return +convertWeiToDec(
      collateralTokenBalance.toString(),
      decimalCollateralToken,
    );
  };

  const durationTime = useMemo(
    () => moment(duration.endDate).diff(moment(duration.startDate), 'minutes'),
    [duration],
  );

  const nowDate = moment(moment.utc().format('yyyy-MM-DD HH:mm:ss')).toDate();

  const _renderMessageErrorEndDate = () => {
    if (duration.startDate >= duration.endDate) {
      return (
        <div className={styles['error']}>
          The end time must be after the start time.
        </div>
      );
    }

    if (durationTime > TOTAL_MINUTES_IN_7_DAYS) {
      return (
        <div className={styles['error']}>
          Auction must be less than 7-day duration. Please choose another time.
        </div>
      );
    }

    return <> </>;
  };

  return (
    <>
      <div className={styles['wrap-item']}>
        <div className={`${styles['card']}`}>
          <div className={`${styles['card-body']}`}>
            <div className={styles['title-body']}>Pool Setup</div>
            <div className={styles['section-content']}>
              <div className={styles['sub-title-body']}>1. Deposit Token</div>
              <div className={styles['row']}>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>
                      Main token <span className={styles['require']}>*</span>
                    </label>
                    <AppInput
                      type="number"
                      value={depositToken.launch}
                      validate={{
                        name: 'depositMainToken',
                        validator: validator.current,
                        rule: [
                          'required',
                          'numeric',
                          'isPositive',
                          `maxDigits:${getDecimalPlaces(token.decimals)}`,
                          {
                            maxValue: roundNumber(
                              getMaxValueLaunchToken(),
                              BigNumber.ROUND_DOWN,
                              token.decimals,
                            ),
                          },
                        ],
                      }}
                      readOnly={!isNetworkCorrect}
                      handleChange={(value: string) =>
                        onChangeDepositToken({
                          ...depositToken,
                          launch: +value,
                        })
                      }
                      endAdornment={
                        <div className={styles['adornment-end']}>
                          <div className={styles['slot']}>
                            <div className={styles['brand']}>
                              {token.logo && (
                                <img
                                  className={styles['logo-brand']}
                                  src={token.logo}
                                  alt="logo"
                                />
                              )}
                              <span className={styles['symbol']}>
                                {token.symbol}
                              </span>
                            </div>
                          </div>
                          <div className={styles['slot']}>
                            <AppButton
                              sizes={'small'}
                              onClick={onClickMaxLaunchToken}
                              isDisable={!isNetworkCorrect}
                            >
                              MAX
                            </AppButton>
                          </div>
                        </div>
                      }
                    />
                    <span className={styles['sub-label']}>
                      Balance:{' '}
                      {formatWeiNumber(
                        launchTokenBalance.toString(),
                        token.decimals,
                      )}{' '}
                      {token.symbol.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>
                      Base Token <span className={styles['require']}>*</span>
                    </label>
                    <AppInput
                      type="number"
                      value={depositToken.collateral}
                      validate={{
                        name: 'depositBaseToken',
                        validator: validator.current,
                        rule: [
                          'required',
                          'numeric',
                          'isPositive',
                          `minValue:${minCollateralToken}`,
                          `maxDigits:${getDecimalPlaces(
                            decimalCollateralToken,
                          )}`,
                          {
                            maxValue: roundNumber(
                              getMaxValueCollateralToken(),
                              BigNumber.ROUND_DOWN,
                              decimalCollateralToken,
                            ),
                          },
                        ],
                      }}
                      readOnly={!isNetworkCorrect}
                      handleChange={(value: string) =>
                        onChangeDepositToken({
                          ...depositToken,
                          collateral: +value,
                        })
                      }
                      endAdornment={
                        <div className={styles['adornment-end']}>
                          <div className={styles['slot']}>
                            <div className={styles['brand']}>
                              <Select
                                value={collateralToken.symbol}
                                onChange={(e) =>
                                  onChangeCollateralTokenSymbol(
                                    String(e.target.value),
                                  )
                                }
                                disableUnderline
                                classes={{
                                  icon: styles['icon-collateral'],
                                }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                  },
                                  getContentAnchorEl: null,
                                  classes: {
                                    paper: styles['paper-collateral'],
                                    list: styles['list-collateral'],
                                  },
                                }}
                              >
                                {listCollateralToken.map(
                                  (item: CollateralToken) => (
                                    <MenuItem
                                      classes={{
                                        root: styles['root-item'],
                                        selected: styles['selected-item'],
                                      }}
                                      key={item.currency}
                                      value={item.currency}
                                    >
                                      <img
                                        className={styles['logo-brand']}
                                        src={item?.icon}
                                        alt="icon"
                                      />
                                      <span className={styles['symbol']}>
                                        {item.name}
                                      </span>
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </div>
                          </div>
                          <div className={styles['slot']}>
                            <AppButton
                              sizes={'small'}
                              onClick={onClickMaxCollateralToken}
                              isDisable={!isNetworkCorrect}
                            >
                              MAX
                            </AppButton>
                          </div>
                        </div>
                      }
                    />
                    <span className={styles['sub-label']}>
                      Balance:{' '}
                      {isLoadingCollateralTokenBalance ? (
                        <span style={{ paddingTop: '15px' }}>
                          <BeatLoader color="white" size={8} />
                        </span>
                      ) : (
                        `${formatWeiNumber(
                          collateralTokenBalance.toString(),
                          collateralToken.decimals,
                        )} ${collateralToken.symbol.toUpperCase()}`
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* sub step 2 */}
            <div className={styles['section-content']}>
              <div className={styles['sub-title-body']}>2. Duration</div>
              <div className={styles['row']}>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>
                      Start Date (UTC Time){' '}
                      <span className={styles['require']}>*</span>
                    </label>
                    <AppDatePicker
                      onChange={(value: Date) => {
                        onChangeDuration({
                          ...duration,
                          startDate: value,
                        });
                        onBlurInput('startDate');
                      }}
                      selected={duration.startDate}
                      showTimeSelect
                      minDate={nowDate}
                      timeIntervals={5}
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy hh:mm:ss aa"
                      placeholder="dd/MM/yyyy"
                      className={styles['date-picker']}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        e.preventDefault();
                      }}
                      readOnly={!isNetworkCorrect}
                    />
                    <div>
                      {duration.startDate < nowDate && (
                        <div className={styles['error']}>
                          The start time must be after current time.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>
                      End Date (UTC Time){' '}
                      <span className={styles['require']}>*</span>
                    </label>
                    <AppDatePicker
                      onChange={(value: Date) => {
                        onChangeDuration({ ...duration, endDate: value });
                        onBlurInput('endDate');
                      }}
                      className={styles['date-picker']}
                      selected={duration.endDate}
                      showTimeSelect
                      minDate={duration.startDate}
                      maxDate={moment(duration.startDate)
                        .add(7, 'days')
                        .toDate()}
                      timeIntervals={5}
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy hh:mm:ss aa"
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        e.preventDefault();
                      }}
                      readOnly={!isNetworkCorrect}
                    />
                    <div>{_renderMessageErrorEndDate()}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* sub step 3 */}
            <div className={styles['section-content']}>
              <div className={styles['sub-title-body']}>3. Weights</div>
              <div className={styles['row']}>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field-slider']}>
                    <label className={styles['label']}>
                      Start weight <span className={styles['require']}>*</span>
                    </label>
                    <div className={styles['control']}>
                      <div
                        className={styles['minus']}
                        onClick={() =>
                          handleChangeWeight(
                            'startWeight',
                            'minus',
                            weights.startWeight,
                          )
                        }
                      >
                        <div className={styles['icon-change']} />
                      </div>
                      {_renderWeightSlider('startWeight')}
                      <div
                        className={styles['plus']}
                        onClick={() =>
                          handleChangeWeight(
                            'startWeight',
                            'plus',
                            weights.startWeight,
                          )
                        }
                      >
                        <PlusIcon />
                      </div>
                    </div>
                    <div className={styles['value']}>
                      <div className={styles['item']}>
                        <span className={styles['name']}>{token.symbol}</span>
                        <span className={styles['percent']}>
                          {formatToPercent(weights.startWeight)}
                        </span>
                      </div>
                      <div className={styles['item']}>
                        <span className={styles['name']}>
                          {collateralToken.symbol}
                        </span>
                        <span className={styles['percent']}>
                          {formatToPercent(
                            new BigNumber(1).minus(weights.startWeight),
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field-slider']}>
                    <label className={styles['label']}>
                      End weight <span className={styles['require']}>*</span>
                    </label>
                    <div className={styles['control']}>
                      <div
                        className={styles['minus']}
                        onClick={() =>
                          handleChangeWeight(
                            'endWeight',
                            'minus',
                            weights.endWeight,
                          )
                        }
                      >
                        <div className={styles['icon-change']} />
                      </div>
                      {_renderWeightSlider('endWeight')}
                      <div
                        className={styles['plus']}
                        onClick={() =>
                          handleChangeWeight(
                            'endWeight',
                            'plus',
                            weights.endWeight,
                          )
                        }
                      >
                        <PlusIcon />
                      </div>
                    </div>
                    <div className={styles['value']}>
                      <div className={styles['item']}>
                        <span className={styles['name']}>{token.symbol}</span>
                        <span className={styles['percent']}>
                          {formatToPercent(weights.endWeight)}
                        </span>
                      </div>
                      <div className={styles['item']}>
                        <span className={styles['name']}>
                          {collateralToken.symbol}
                        </span>
                        <span className={styles['percent']}>
                          {formatToPercent(
                            new BigNumber(1).minus(weights.endWeight),
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['wrap-item']}>
        <div className={`${styles['card']}`}>
          {/* card 2 */}
          <div className={styles['card-body']}>
            <div className={styles['title-body']}>Auction Preview</div>
            <div className={styles['section-content']}>
              <div className={styles['chart-auction']}>
                <AuctionPriceChart
                  id="lbp-auction-chart"
                  priceData={chartData}
                  customChartClassname={styles['lbp-auction-chart']}
                  isPreview
                  startTime={duration.startDate}
                  endTime={duration.endDate}
                  symbol={{
                    base: collateralToken.symbol,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSetupPool
        onHandleBack={onClickBack}
        onHandleNext={onHandleNext}
        isDisabledNextStep={isDisableNextStep}
      />
    </>
  );
});

export default StepPoolSetup;
