import BaseRequest from './BaseRequest';
import config from '../config';

export default class ProjectRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.poolApi;
  }

  getProjects(params: any) {
    const url = '/public/projects';
    return this.get(url, params);
  }

  getProjectDetail(id: any) {
    const url = `/public/projects/${id}`;
    return this.get(url);
  }

  getPoolsInProject(id: any, params?: any) {
    const url = `/public/projects/${id}/pools`;
    return this.get(url, params);
  }
}
