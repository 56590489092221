import { useSelector } from 'react-redux';
import { ErrorSignIcon } from 'src/assets/icons';
import styles from 'src/styles/modals/ModalSignatureRequired.module.scss';
import AppButton from 'src/components/AppButton';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import BaseModal from './BaseModal';

const ModalSignatureRequired = () => {
  const { openSignatureRequiredModal, connector } = useSelector(
    (state: any) => state.authentication,
  );
  const { createAccessToken } = useAuth();
  const [renderDiffComponent, setRenderDiffComponent] = useState(false);
  useEffect(() => {
    if (
      openSignatureRequiredModal !== null &&
      openSignatureRequiredModal !== undefined &&
      connector
    ) {
      setRenderDiffComponent(openSignatureRequiredModal);
    }
  }, [openSignatureRequiredModal, connector]);

  return renderDiffComponent ? (
    <BaseModal
      open={openSignatureRequiredModal}
      isCloseIcon={false}
      aria-labelledby="modal-signature-title"
      aria-describedby="modal-signature-description"
      title={'Signature Required'}
    >
      <div className={styles['modal-signature']}>
        <div className={styles['thumb-signature']}>
          <ErrorSignIcon />
        </div>
        <div className={styles['modal-text']}>
          Please sign on your wallet to confirm.
        </div>
        <AppButton
          variant="white"
          sizes="big"
          onClick={() => createAccessToken(connector)}
        >
          Sign in the wallet
        </AppButton>
      </div>
    </BaseModal>
  ) : null;
};

export default ModalSignatureRequired;
