import { SolanaConnector } from './BaseConnector';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

class PhantomConnector extends SolanaConnector {
  network = {
    chainId: 'phantom-chainId', // temporarily chainId
  };

  getName() {
    return 'Phantom';
  }

  /**
   * connect wallet
   * @returns provider
   */
  async connect() {
    const adapter = new PhantomWalletAdapter();
    this.provider = adapter;
    return adapter.connect();
  }
}

export default PhantomConnector;
