import { Program, Provider, web3, BN } from '@project-serum/anchor';
import { Idl } from '@project-serum/anchor/src/idl';
import { ASSOCIATED_PROGRAM_ID } from '@project-serum/anchor/dist/cjs/utils/token';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { PublicKey, TokenAmount, Transaction } from '@solana/web3.js';

export default class PreSalePool {
  private readonly connection: web3.Connection;
  private readonly provider: Provider;

  public static PRESALE_POOL_SEED = Buffer.from('presale-pool');
  public static USER_SEED = Buffer.from('user');

  constructor(public readonly program: Program<Idl>) {
    this.connection = this.program.provider.connection;
    this.provider = this.program.provider;
  }

  public async getPresalePoolAccount(id: web3.PublicKey) {
    return web3.PublicKey.findProgramAddress(
      [PreSalePool.PRESALE_POOL_SEED, id.toBuffer()],
      this.program.programId,
    );
  }

  public async getPresalePoolTokenAccount(
    presalePoolAccount: web3.PublicKey,
    token: web3.PublicKey,
  ): Promise<web3.PublicKey> {
    return Token.getAssociatedTokenAddress(
      ASSOCIATED_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      token,
      presalePoolAccount,
      true,
    );
  }

  public async getUserAccount(
    presalePoolAccount: web3.PublicKey,
    user: web3.PublicKey,
  ) {
    return web3.PublicKey.findProgramAddress(
      [PreSalePool.USER_SEED, presalePoolAccount.toBuffer(), user.toBuffer()],
      this.program.programId,
    );
  }

  public async initPresalePool(params: {
    id: web3.PublicKey;
    token: web3.PublicKey;
    signer: web3.PublicKey;
  }) {
    const [presalePoolAccount] = await this.getPresalePoolAccount(params.id);
    const presalePoolTokenAccount = await this.getPresalePoolTokenAccount(
      presalePoolAccount,
      params.token,
    );

    return this.program.rpc.initPresalePool(params.id, params.signer, {
      accounts: {
        sender: this.provider.wallet.publicKey,
        presalePoolAccount,
        systemProgram: web3.SystemProgram.programId,
      },
      postInstructions: [
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          params.token,
          presalePoolTokenAccount,
          presalePoolAccount,
          this.provider.wallet.publicKey,
        ),
      ],
    });
  }

  public async getBuyTokenByTokenWithPermissionTransaction(params: {
    presalePoolAccount: web3.PublicKey;
    fundingWallet: web3.PublicKey;
    inToken: web3.PublicKey;
    inAmount: BN;
    outToken: web3.PublicKey;
    outAmount: BN;
    maxAmount: BN;
    minAmount: BN;
    maxCap: BN;
    signer: web3.Keypair;
    candidate: web3.PublicKey;
  }) {
    const senderTokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      params.inToken,
      params.candidate,
    );
    const [senderUserAccount] = await this.getUserAccount(
      params.presalePoolAccount,
      params.candidate,
    );

    const fundingWalletTokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      params.inToken,
      params.fundingWallet,
    );

    const preInstructions = [];
    if (!(await this.connection.getAccountInfo(senderUserAccount))) {
      preInstructions.push(
        this.program.instruction.initUser(params.candidate, {
          accounts: {
            sender: params.candidate,
            presalePoolAccount: params.presalePoolAccount,
            userAccount: senderUserAccount,
            systemProgram: web3.SystemProgram.programId,
          },
        }),
      );
    }
    if (!(await this.connection.getAccountInfo(fundingWalletTokenAccount))) {
      preInstructions.push(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          params.inToken,
          fundingWalletTokenAccount,
          params.fundingWallet,
          params.candidate,
        ),
      );
    }

    const transaction = this.program.transaction.buyTokenByTokenWithPersmission(
      params.fundingWallet,
      params.inToken,
      params.inAmount,
      params.outToken,
      params.outAmount,
      params.maxAmount,
      params.minAmount,
      params.maxCap,
      {
        accounts: {
          sender: params.candidate,
          senderTokenAccount,
          senderUserAccount,
          signer: params.signer.publicKey,
          presalePoolAccount: params.presalePoolAccount,
          fundingWalletTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
        },
        preInstructions,
      },
    );
    transaction.recentBlockhash = (
      await this.connection.getRecentBlockhash()
    ).blockhash;
    transaction.feePayer = params.candidate;
    const recoverTx = Transaction.from(
      transaction.serialize({ requireAllSignatures: false }),
    );
    recoverTx.partialSign(params.signer);

    return recoverTx;
  }

  public async getBuyTokenBySolWithPermissionTransaction(params: {
    presalePoolAccount: web3.PublicKey;
    fundingWallet: web3.PublicKey;
    inAmount: BN;
    outToken: web3.PublicKey;
    outAmount: BN;
    maxAmount: BN;
    minAmount: BN;
    maxCap: BN;
    signer: web3.Keypair;
    candidate: web3.PublicKey;
  }) {
    const [senderUserAccount] = await this.getUserAccount(
      params.presalePoolAccount,
      params.candidate,
    );

    const preInstructions = [];
    if (!(await this.connection.getAccountInfo(senderUserAccount))) {
      preInstructions.push(
        this.program.instruction.initUser(params.candidate, {
          accounts: {
            sender: params.candidate,
            presalePoolAccount: params.presalePoolAccount,
            userAccount: senderUserAccount,
            systemProgram: web3.SystemProgram.programId,
          },
        }),
      );
    }

    const inToken = PublicKey.default;
    const transaction = this.program.transaction.buyTokenBySolWithPersmission(
      params.fundingWallet,
      inToken,
      params.inAmount,
      params.outToken,
      params.outAmount,
      params.maxAmount,
      params.minAmount,
      params.maxCap,
      {
        accounts: {
          sender: params.candidate,
          senderUserAccount,
          signer: params.signer.publicKey,
          presalePoolAccount: params.presalePoolAccount,
          fundingWallet: params.fundingWallet,
          systemProgram: web3.SystemProgram.programId,
        },
        preInstructions,
      },
    );
    transaction.recentBlockhash = (
      await this.connection.getRecentBlockhash()
    ).blockhash;
    transaction.feePayer = params.candidate;
    const recoverTx = Transaction.from(
      transaction.serialize({ requireAllSignatures: false }),
    );
    recoverTx.partialSign(params.signer);

    return recoverTx;
  }

  public async getClaimTokensTransaction(params: {
    presalePoolAccount: web3.PublicKey;
    token: web3.PublicKey;
    amount: BN;
    signer: web3.Keypair;
    candidate: web3.PublicKey;
  }) {
    const senderTokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      params.token,
      params.candidate,
    );
    const [senderUserAccount] = await this.getUserAccount(
      params.presalePoolAccount,
      params.candidate,
    );
    const presalePoolTokenAccount = await this.getPresalePoolTokenAccount(
      params.presalePoolAccount,
      params.token,
    );

    const preInstructions = [];
    if (!(await this.connection.getAccountInfo(senderTokenAccount))) {
      preInstructions.push(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          params.token,
          senderTokenAccount,
          params.candidate,
          params.candidate,
        ),
      );
    }

    const transaction = this.program.transaction.claimTokens(
      params.token,
      params.amount,
      {
        accounts: {
          sender: params.candidate,
          senderUserAccount,
          senderTokenAccount,
          signer: params.signer.publicKey,
          presalePoolAccount: params.presalePoolAccount,
          presalePoolTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
        },
        preInstructions,
      },
    );
    transaction.recentBlockhash = (
      await this.connection.getRecentBlockhash()
    ).blockhash;
    transaction.feePayer = params.candidate;
    const recoverTx = Transaction.from(
      transaction.serialize({ requireAllSignatures: false }),
    );
    recoverTx.partialSign(params.signer);

    return recoverTx;
  }

  public async setNewSigner(params: {
    presalePoolAccount: web3.PublicKey;
    newSigner: web3.PublicKey;
  }) {
    return this.program.rpc.setNewSigner(params.newSigner, {
      accounts: {
        sender: this.provider.wallet.publicKey,
        presalePoolAccount: params.presalePoolAccount,
      },
    });
  }

  public async emergencyWithdraw(params: {
    presalePoolAccount: web3.PublicKey;
    token: web3.PublicKey;
    wallet: web3.PublicKey;
    amount: BN;
  }) {
    this._onlyEOA(params.wallet);

    const presalePoolTokenAccount = await this.getPresalePoolTokenAccount(
      params.presalePoolAccount,
      params.token,
    );
    const walletTokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      params.token,
      params.wallet,
    );

    const preInstructions = [];
    if (!(await this.connection.getAccountInfo(walletTokenAccount))) {
      preInstructions.push(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          params.token,
          walletTokenAccount,
          params.wallet,
          this.provider.wallet.publicKey,
        ),
      );
    }

    const transaction = this.program.transaction.emergencyWithdraw(
      params.token,
      params.wallet,
      params.amount,
      {
        accounts: {
          sender: this.provider.wallet.publicKey,
          walletTokenAccount,
          presalePoolAccount: params.presalePoolAccount,
          presalePoolTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
        preInstructions,
      },
    );
    transaction.recentBlockhash = (
      await this.connection.getRecentBlockhash()
    ).blockhash;
    transaction.feePayer = this.provider.wallet.publicKey;
    transaction.sign((this.provider.wallet as any).payer);

    return this.connection.sendRawTransaction(transaction.serialize());
  }

  private _onlyEOA(address: web3.PublicKey) {
    if (!web3.PublicKey.isOnCurve(address.toBuffer())) {
      throw new Error('only EOA address');
    }
  }

  async getAssociatedTokenAccount(mint: web3.PublicKey, owner: web3.PublicKey) {
    return Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      owner,
    );
  }

  async getTokenBalance(
    tokenId: web3.PublicKey,
    address: web3.PublicKey,
  ): Promise<TokenAmount> {
    try {
      const tokenAccount = await this.getAssociatedTokenAccount(
        tokenId,
        address,
      );
      const accountInfo = await this.connection.getAccountInfo(tokenAccount);
      if (!accountInfo) {
        return {
          amount: '0',
          decimals: 0,
          uiAmount: 0,
          uiAmountString: '0',
        };
      }
      const balanceInfo = await this.connection.getTokenAccountBalance(
        tokenAccount,
      );
      return balanceInfo.value;
    } catch (error) {
      return {
        amount: '0',
        decimals: 0,
        uiAmount: 0,
        uiAmountString: '0',
      };
    }
  }
}
