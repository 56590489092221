import { Tooltip, withWidth } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import AppAlertWarning from 'src/components/AppAlertWarning';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import PartProjectContentView from 'src/components/ProjectContentView';
import useAuth from 'src/hooks/useAuth';
import BasePage from 'src/pages/BasePage';
import PartBanner from 'src/pages/PageIDOPoolDetail/parts/PartBanner';
import PoolContents from 'src/pages/PageIDOPoolDetail/parts/PoolContents';
import rf from 'src/requests/RequestFactory';
import { RootState } from 'src/store';
import styles from 'src/styles/pages/PoolDetail.module.scss';
import { IDOPool, PoolResponseType } from 'src/utils/pool';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import {
  getClaimedTokenUserInfo,
  IWinnerResponseType,
} from 'src/utils/utils-pool';
import { getErrorMessage } from '../../utils/utils-helpers';
import ModalConnectSolana from 'src/modals/ModalConnectSolana';

export const RELOAD_POOL_DETAILS = 'RELOAD_POOL_DETAILS';
export const RELOAD_WHITE_LIST_PARTICIPANTS = 'RELOAD_WHITE_LIST_PARTICIPANTS';
export const RELOAD_WINNER_LIST = 'RELOAD_WINNER_LIST';
export const RELOAD_USER_CLAIM_INFO = 'RELOAD_USER_CLAIM_INFO';
export const TOGGLE_PURCHASE_TOKEN_MODAL = 'TOGGLE_PURCHASE_TOKEN_MODAL';
export const TOGGLE_CONNECT_SOLANA_MODAL = 'TOGGLE_CONNECT_SOLANA_MODAL';

export interface UserClaimInfoType {
  purchasedToken: string;
  claimedToken: string;
  remainingToken: string;
}

type ParamTypes = {
  id: string;
};

const PagePoolDetail = () => {
  const { id: poolId } = useParams<ParamTypes>();
  const { user } = useAuth();

  const { token: jwtToken } = useSelector(
    (state: RootState) => state.authentication,
  );
  const [pool, setPool] = useState<any>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [isRegisteredWhitelist, setIsRegisteredWhitelist] = useState(false);
  const [winner, setWinner] = useState<IWinnerResponseType | null>(null);

  const [claimInfo, setClaimInfo] = useState<UserClaimInfoType>({
    claimedToken: '0',
    purchasedToken: '0',
    remainingToken: '0',
  });

  const [openPurchaseTokenModal, setOpenPurchaseTokenModal] =
    useState<boolean>(false);
  const [openConnectSolanaModal, setOpenConenctSolanaModal] =
    useState<boolean>(false);

  const idoPool = useMemo(() => new IDOPool(pool), [pool]);

  useEffect(() => {
    AppBroadcast.on(TOGGLE_PURCHASE_TOKEN_MODAL, (isOpen: boolean) =>
      setOpenPurchaseTokenModal(isOpen),
    );
    AppBroadcast.on(TOGGLE_CONNECT_SOLANA_MODAL, () =>
      onToggleConnectSolanaModal(),
    );
    return () => {
      AppBroadcast.remove(TOGGLE_PURCHASE_TOKEN_MODAL);
    };
  }, []);

  useEffect(() => {
    AppBroadcast.on(RELOAD_POOL_DETAILS, () => fetchPoolDetails(poolId));
    AppBroadcast.on(RELOAD_WHITE_LIST_PARTICIPANTS, () =>
      fetchWhiteListParticipants(),
    );
    AppBroadcast.on(RELOAD_WINNER_LIST, () => fetchWinners());
    AppBroadcast.on(RELOAD_USER_CLAIM_INFO, () => fetchUserClaimInfo());
    return () => {
      AppBroadcast.remove(RELOAD_POOL_DETAILS);
      AppBroadcast.remove(RELOAD_WHITE_LIST_PARTICIPANTS);
      AppBroadcast.remove(RELOAD_WINNER_LIST);
      AppBroadcast.remove(RELOAD_USER_CLAIM_INFO);
    };
  }, [poolId, user?.getAddress(), user?.getLinkedProviders()]);

  useEffect(() => {
    fetchPoolDetails(poolId);
  }, [poolId]);

  useEffect(() => {
    if (!user || !jwtToken) return;
    fetchPoolDetailData();
  }, [
    poolId,
    user?.getAddress(),
    jwtToken,
    idoPool.getNetwork(),
    user?.getLinkedProviders(),
  ]);

  const fetchPoolDetails = async (poolId: string) => {
    try {
      setIsLoadingData(true);
      const response = (await rf
        .getRequest('PoolRequest')
        .getPoolDetail(poolId)) as PoolResponseType;
      setPool(response);
    } catch (error: any) {
      console.error(
        `[FetchPool] with poolId ${poolId} throw error: ${getErrorMessage(
          error,
        )}`,
        error,
      );
    }
    setIsLoadingData(false);
  };

  const fetchWhiteListParticipants = async () => {
    if (!pool || !user) return;
    const response = (await rf
      .getRequest('PoolRequest')
      .getWhiteListParticipants(poolId)) as any;
    setIsRegisteredWhitelist(
      response.docs.some((item: any) => item.userAddress === user.getAddress()),
    );
  };

  const fetchWinners = async () => {
    if (!pool || !user) return;
    const response = (await rf
      .getRequest('PoolRequest')
      .getWinners(poolId)) as any;
    const winnerUser = response.docs.find(
      (item: IWinnerResponseType) => item.userAddress === user.getAddress(),
    );
    setWinner(winnerUser);
  };

  const fetchUserClaimInfo = async () => {
    if (!user || !idoPool.getNetwork() || !pool?.contractAddress) return;
    const { claimedToken, purchasedToken, remainingToken } =
      await getClaimedTokenUserInfo(idoPool, user);
    setClaimInfo({ claimedToken, purchasedToken, remainingToken });
  };

  const fetchPoolDetailData = () => {
    Promise.all([
      fetchWhiteListParticipants(),
      fetchWinners(),
      fetchUserClaimInfo(),
    ]);
  };

  const onToggleConnectSolanaModal = () => {
    setOpenConenctSolanaModal((prevState) => !prevState);
  };

  const onOpenConnectNetworkModal = () => {
    if (idoPool.isNetworkSolana()) {
      onToggleConnectSolanaModal();
    }
  };

  const _renderNetworkAlert = () => {
    if (!user || idoPool.isNetworkCorrect(user)) {
      return null;
    }
    return (
      <AppAlertWarning
        acceptedNetwork={idoPool.getNetwork()}
        onHandleChangeNetwork={onOpenConnectNetworkModal}
      >
        <div className={styles['message-alert']}>
          The connected wallet does not match the target network of{' '}
          <Tooltip title={idoPool.getName()} placement="top-start">
            <span>{idoPool.getName()}</span>
          </Tooltip>
        </div>
      </AppAlertWarning>
    );
  };

  const _renderProjectMetricsAndCharts = () => {
    const project = idoPool.getProject();
    const swapToken = idoPool.getSwapToken();
    return (
      project &&
      swapToken && (
        <PartProjectContentView
          project={project}
          currency={swapToken}
          hiddenProjectInfos
        />
      )
    );
  };

  const _renderProjectContents = () => {
    const project = idoPool.getProject();
    return project ? (
      <PartProjectContentView project={project} hiddenProjectContent />
    ) : null;
  };

  const _renderShowLoadingIcon = () => {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  };

  return (
    <BasePage>
      <div className={`${styles['pool-detail']}`}>
        {isLoadingData && _renderShowLoadingIcon()}
        {!isLoadingData && pool && (
          <div>
            {_renderNetworkAlert()}
            <PartBanner
              idoPool={idoPool}
              claimInfo={claimInfo}
              isRegisteredWhitelist={isRegisteredWhitelist}
              winner={winner}
            />
            <div className={styles['time-line']}>
              <PoolContents
                idoPool={idoPool}
                claimInfo={claimInfo}
                isRegisteredWhitelist={isRegisteredWhitelist}
                winner={winner}
                openPurchaseTokenModal={openPurchaseTokenModal}
              />
            </div>
            {_renderProjectMetricsAndCharts()}
            {_renderProjectContents()}
          </div>
        )}
        <ModalConnectSolana
          open={openConnectSolanaModal}
          network={idoPool.getNetwork()}
          onClose={onToggleConnectSolanaModal}
        />
      </div>
    </BasePage>
  );
};

export default withWidth()(withRouter(PagePoolDetail));
