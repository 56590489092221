import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class CoingeckoRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiCoingecko;
  }

  getTokenPrice(params: any) {
    const url = '/coins/markets';
    return this.get(url, params);
  }
}
