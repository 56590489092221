import clsx from 'clsx';
import { FC, HTMLProps, ReactNode, BaseSyntheticEvent } from 'react';
import styles from 'src/styles/components/AppButton.module.scss';

type BUTTON_TYPE =
  | 'primary' // $color-brand-0 (blue)
  | 'secondary' // $bg-accent-8 (violet)
  | 'tertiary' //
  | 'quaternary' // $bg-100 (light-gray)
  | 'white' // $bg-brand-4 (white)
  | 'outline' // background transparent
  | 'warning'; // $bg-accent-6 (red)
type BUTTON_SIZE = 'big' | 'large' | 'medium' | 'small' | 'mini';

interface AppButtonProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
  onClick?: (e: BaseSyntheticEvent) => void;
  variant?: BUTTON_TYPE;
  isDisable?: boolean;
  className?: string;
  sizes?: BUTTON_SIZE;
}
const AppButton: FC<AppButtonProps> = ({
  children,
  onClick = () => {
    return null;
  },
  variant = 'primary',
  isDisable,
  className,
  sizes = 'large',
}: AppButtonProps) => {
  const onClickButton = (e: BaseSyntheticEvent) => {
    onClick(e);
  };

  return (
    <button
      className={`${styles['button']} ${styles[variant]} ${styles[sizes]} ${
        className ? className : ''
      }`}
      onClick={onClickButton}
      disabled={isDisable}
    >
      {children}
    </button>
  );
};

export default AppButton;
