import { useDispatch } from 'react-redux';
import Storage from 'src/utils/storage';
import { init } from 'src/store/metadata';

type ReturnType = {
  initMetadata: () => Promise<void>;
};

const useMetadata = (): ReturnType => {
  const dispatch = useDispatch();
  const initMetadata = async (): Promise<void> => {
    Storage.init();
    await dispatch(init());
  };

  return {
    initMetadata,
  };
};

export default useMetadata;
