import styles from 'src/styles/pages/PageMyProfile/PartMyLBP.module.scss';
import rf from 'src/requests/RequestFactory';
import {
  LinkIcon,
  PlusIcon,
  UnverifiedIcon,
  VerifiedIcon,
} from 'src/assets/icons';
import { isMobile } from 'react-device-detect';
import AppDataTable from 'src/components/AppDataTable';
import AppButton from 'src/components/AppButton';
import { useHistory } from 'react-router';
import {
  getCurrentBalances,
  getInfoJoinExitPool,
  getStatusEnableSwap,
  IAuctionResponseType,
  toggleEnableSwap,
} from 'src/utils/utils-auction';
import { Link } from 'react-router-dom';
import { getToken, getSymbolBaseToken } from 'src/utils/utils-token';
import { convertWeiToDec, formatNumber } from 'src/utils/utils-formats';
import { getNetworkConfigs } from 'src/utils/utils-network';
import AppSwitchButton from 'src/components/AppSwitchButton';
import React, { useEffect, useMemo, useState, FC } from 'react';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from '@material-ui/core';
import { StatusLBP } from 'src/pages/PageAuctionList';
import { switchNetwork } from 'src/utils/utils-auth';
import { Auction } from 'src/utils/auction';
import { RootState } from 'src/store';
import moment from 'moment';
import ModalSettingLBP from 'src/modals/ModalSettingLBP';
import BigNumber from 'bignumber.js';

interface ILBP {
  lbp: IAuctionResponseType;
}

const LBP: FC<ILBP> = ({ lbp }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const lbpAuction = useMemo(() => new Auction(lbp), [lbp]);

  const startTime = lbpAuction.getStartTime();
  const endTime = lbpAuction.getEndTime();
  const poolNetwork = lbpAuction.getNetwork();
  const pool = lbpAuction.getPool();

  const [balanceCurrent, setBalanceCurrent] = useState<string[]>([]);
  const [isEnabledSwap, setIsEnabledSwap] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openModalWithdraw, setOpenModalWithdraw] = useState<boolean>(false);
  const [balanceStart, setBalanceStart] = useState<string[]>([]);
  const [balanceExit, setBalanceExit] = useState<string[]>([]);
  const [isExitPool, setIsExitPool] = useState<boolean>(false);

  const { tokensUsdPrice } = useSelector((state: RootState) => state.metadata);

  let tokenBase = lbpAuction.getTokenBase();

  tokenBase = getToken(poolNetwork, tokenBase?.address);

  const indexTokenBase = lbpAuction.getTokenBaseIndex();

  const tokenMain = lbpAuction.getTokenMain();

  const indexTokenMain = lbpAuction.getTokenMainIndex();

  const networks = getNetworkConfigs();
  const network = networks.find((item) => item.id === poolNetwork);

  const getBalancesCurrentPool = async () => {
    const balances = await getCurrentBalances(
      pool.onchainId,
      poolNetwork,
      pool.vault.address,
    );

    const balanceBaseToken = convertWeiToDec(
      balances[indexTokenBase],
      tokenBase?.decimals,
    );
    const balanceMainToken = convertWeiToDec(
      balances[indexTokenMain],
      tokenMain?.decimals,
    );

    const balanceTokens = [];
    balanceTokens[indexTokenBase] = balanceBaseToken;
    balanceTokens[indexTokenMain] = balanceMainToken;
    setBalanceCurrent(balanceTokens);
  };

  const getEnableSwap = async () => {
    const isEnabled = await getStatusEnableSwap(poolNetwork, pool.address);
    setIsEnabledSwap(isEnabled);
  };

  const isPoolEnd = useMemo(
    () =>
      tokensUsdPrice[getSymbolBaseToken(tokenBase.symbol.toLowerCase())] *
        Number(balanceCurrent[indexTokenBase]) <
      1,
    [tokensUsdPrice, balanceCurrent, indexTokenBase, tokenBase],
  );

  useEffect(() => {
    isPoolEnd && setIsEnabledSwap(false);
  }, [isPoolEnd, isEnabledSwap]);

  const handleEnable = async () => {
    const params = await toggleEnableSwap(
      pool.address,
      !isEnabledSwap,
      poolNetwork,
    );
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    getEnableSwap();
  };

  const isNotCorrectNetwork = user?.getNetwork() !== poolNetwork;

  const getInfoExitPool = async () => {
    const dataExitPool = await getInfoJoinExitPool(lbp);
    if (dataExitPool) {
      setBalanceStart(dataExitPool.balanceStart);
      setIsExitPool(dataExitPool.isExit);
      setBalanceExit(dataExitPool.balanceExit);
    }
  };

  const baseTokenAccrued = useMemo(() => {
    if (isExitPool) {
      return +balanceExit[indexTokenBase] - +balanceStart[indexTokenBase];
    }

    if (!isExitPool && isPoolEnd) {
      return 0;
    }

    return +balanceCurrent[indexTokenBase] - +balanceStart[indexTokenBase];
  }, [balanceStart, balanceCurrent, isExitPool, balanceExit, isPoolEnd]);

  const mainTokenReleased = useMemo(() => {
    if (isExitPool) {
      return +balanceStart[indexTokenMain] - +balanceExit[indexTokenMain];
    }

    if (!isExitPool && isPoolEnd) {
      return 0;
    }
    return +balanceStart[indexTokenMain] - +balanceCurrent[indexTokenMain];
  }, [balanceCurrent, balanceStart, isExitPool, balanceExit, isPoolEnd]);

  const percentMainTokensReleased = useMemo(() => {
    if (+mainTokenReleased < 0) {
      return 0;
    }

    return new BigNumber(+mainTokenReleased)
      .dividedBy(+balanceStart[indexTokenMain])
      .multipliedBy(100)
      .toFixed(2);
  }, [mainTokenReleased, balanceStart, indexTokenMain]);

  useEffect(() => {
    getBalancesCurrentPool();
    getEnableSwap();
    getInfoExitPool();
  }, []);

  const _renderButtonWithdraw = () => {
    if (isNotCorrectNetwork) {
      return (
        <AppButton
          sizes="mini"
          onClick={() => switchNetwork(poolNetwork, user?.getProvider())}
          variant="white"
        >
          Switch Network
        </AppButton>
      );
    }
    return (
      <AppButton
        sizes="small"
        onClick={() => setOpenModalWithdraw(true)}
        isDisable={isPoolEnd}
      >
        Withdraw All
      </AppButton>
    );
  };

  const _renderLBPMobile = () => {
    return (
      <div className={styles['card-lbp']}>
        <div
          onClick={() => setOpenCollapse((prevState) => !prevState)}
          className={styles['card-lbp-header']}
        >
          <div className={styles['flex-header']}>
            <Link to={`/auction/${lbp.id}`} className={styles['link-pool']}>
              <span className={styles['icon']}>
                <img className={styles['img-cell']} src={lbp.logoUrl} />
              </span>
              <div className={styles['title']}>
                {tokenMain.symbol} - {tokenBase.symbol}
              </div>
              {lbp.isVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
            </Link>
            <span
              className={`${styles['icon-collapse']} ${
                !openCollapse ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['card-lbp-subheader']}>
          <label className={styles['label']}>Status</label>
          <div className={styles['text']}>
            <StatusLBP
              startTime={startTime}
              endTime={endTime}
              swapEnabled={pool.swapEnabled}
              status={lbp.status}
            />
          </div>
        </div>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <div className={styles['card-lbp-body']}>
            <div className={styles['card-lbp-item']}>
              <label className={styles['label']}>Last Price</label>
              <div className={styles['text']}>
                {formatNumber(tokenMain.latestPriceRate || '0')}{' '}
                {tokenMain.latestPriceRate && +tokenMain.latestPriceRate > 0
                  ? tokenBase.symbol
                  : ''}
              </div>
            </div>
            <div className={styles['card-lbp-item']}>
              <label className={styles['label']}>Main token released</label>
              <div className={styles['text']}>
                {formatNumber(percentMainTokensReleased)}%
              </div>
            </div>
            <div className={styles['card-lbp-item']}>
              <label className={styles['label']}>Base token accrued</label>
              <div className={styles['text']}>
                <img
                  className={styles['img-token']}
                  src={tokenBase.icon}
                  alt=""
                />
                <span>{formatNumber(baseTokenAccrued)}</span>
              </div>
            </div>
            <div className={styles['card-lbp-item']}>
              <label className={styles['label']}>Network</label>
              <div className={styles['text']}>
                <span>{network?.name}</span>
              </div>
            </div>
            <div className={styles['withdraw']}>{_renderButtonWithdraw()}</div>
          </div>
        </Collapse>
      </div>
    );
  };

  const _renderLBPDesktop = () => {
    return (
      <div className={`${styles['table-row']}`}>
        <div className={`${styles['table-cell']} ${styles['name']}`}>
          <img className={styles['img-cell']} src={lbp.logoUrl} />
          <span className={styles['token-name']}>
            {tokenMain.symbol} - {tokenBase.symbol}
          </span>
          {lbp.isVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
        </div>

        <div className={`${styles['table-cell']} ${styles['network']}`}>
          <img className={styles['img-cell']} src={network?.icon} />
          <span>{network?.name}</span>
        </div>

        <div className={`${styles['table-cell']} ${styles['text-right']}`}>
          {formatNumber(tokenMain.latestPriceRate || '0')}{' '}
          {tokenMain.latestPriceRate && +tokenMain.latestPriceRate > 0
            ? tokenBase.symbol
            : ''}
        </div>

        <div
          className={`${styles['table-cell']} ${styles['token']} ${styles['text-right']}`}
        >
          {formatNumber(percentMainTokensReleased)}%
        </div>

        <div
          className={`${styles['table-cell']} ${styles['token']} ${styles['text-right']}`}
        >
          <span>{formatNumber(baseTokenAccrued)}</span>
          <img className={styles['img-token']} src={tokenBase.icon} alt="" />
        </div>

        <div className={`${styles['table-cell']} ${styles['action']}`}>
          {_renderButtonWithdraw()}
        </div>
        <div className={`${styles['table-cell']} ${styles['action']}`}>
          <span className={styles['status']}>Swapping</span>
          <AppSwitchButton
            checked={isEnabledSwap}
            onChange={handleEnable}
            disabled={
              isNotCorrectNetwork || isPoolEnd || moment().unix() > endTime
            }
          />
        </div>

        <div className={styles['link-detail']}>
          <Link to={`/auction/${lbp.id}`}>
            <LinkIcon />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? _renderLBPMobile() : _renderLBPDesktop()}

      <ModalSettingLBP
        open={openModalWithdraw}
        onClose={() => setOpenModalWithdraw(false)}
        baseToken={tokenBase}
        auction={lbp}
        baseTokenIndex={indexTokenBase}
        mainTokenIndex={indexTokenMain}
        balanceCurrent={balanceCurrent}
        fetchBalancesPool={getBalancesCurrentPool}
        type="withdraw"
        baseTokenAccrued={baseTokenAccrued}
      />
    </>
  );
};

const PartMyLBP = () => {
  const _renderForMobile = () => {
    const history = useHistory();
    return (
      <div className={styles['btn-add-lbp']}>
        <AppButton onClick={() => history.push('/auction')} sizes="large">
          <PlusIcon />
          <span>Create Auction</span>
        </AppButton>
      </div>
    );
  };

  const _renderForDesktop = () => {
    const history = useHistory();
    return (
      <>
        <div className={styles['header']}>
          <div className={styles['title']}>My Auction</div>

          <AppButton onClick={() => history.push('/auction')} sizes="large">
            <PlusIcon />
            <span>Create Auction</span>
          </AppButton>
        </div>
      </>
    );
  };
  return (
    <section className={`${styles[isMobile ? 'my-lbp-mobile' : 'my-lbp']}`}>
      {!isMobile && _renderForDesktop()}
      <AppDataTable
        wrapperClassName={styles['lbp-list']}
        limit={10}
        fetchData={(payload) =>
          rf
            .getRequest('AuctionsRequest')
            .getMyLBPs({ ...payload, isDraft: false })
        }
        renderHeader={() => {
          if (isMobile) {
            return null;
          }
          return (
            <div className={`${styles['header-lbp-list']}`}>
              <div className={styles['table-row']}>
                <div className={`${styles['table-cell']} ${styles['name']}`}>
                  Token
                </div>
                <div
                  className={`${styles['table-cell']} ${styles['network']} `}
                >
                  Network
                </div>
                <div
                  className={`${styles['table-cell']} ${styles['text-right']}`}
                >
                  Last Price
                </div>
                <div
                  className={`${styles['table-cell']} ${styles['token']} ${styles['text-right']}`}
                >
                  Main token released
                </div>
                <div
                  className={`${styles['table-cell']} ${styles['token']} ${styles['text-right']}`}
                >
                  Base token accrued
                </div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
                <div className={styles['link-detail']}></div>
              </div>
            </div>
          );
        }}
        renderBody={(lbps: IAuctionResponseType[]) =>
          lbps.map((lbp: IAuctionResponseType) => {
            return <LBP lbp={lbp} key={lbp.id} />;
          })
        }
      />

      {isMobile && _renderForMobile()}
    </section>
  );
};

export default PartMyLBP;
