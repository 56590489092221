import BigNumber from 'bignumber.js';
import abi from '../abi';
import rf from '../requests/RequestFactory';
import { convertDecToWei } from '../utils/utils-formats';
import { approveToken, isPurchaseWithNativeToken } from '../utils/utils-pool';
import { processTransaction } from '../store/transactions';
import { useDispatch } from 'react-redux';
import useAuth from './useAuth';
import { INOPool } from '../utils/pool';
import { toastError } from 'src/utils/utils-notify';

const useINOPool = (inoPool: INOPool) => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const makePurchaseINOParams = (
    poolAddress: string,
    methodParams: Pick<any, string>,
    abiName: string,
    value?: BigNumber,
  ) => {
    methodParams = Object.values(methodParams) || [];
    return [abi['InoPool'], poolAddress, abiName, methodParams, { value }];
  };

  const onBuyNFT = async (
    amount: string,
    packageId: string | number,
    onSuccess?: () => void,
  ) => {
    if (!inoPool?.getId() || !user?.getAddress() || !packageId) return;
    try {
      await approveToken(inoPool, user, dispatch);
      const res = await rf
        .getRequest('TransactionRequest')
        .getBuyInoTransaction({
          poolId: inoPool.getId(),
          userAddress: user.getAddress(),
          inAmount: convertDecToWei(
            amount,
            inoPool.getCollateralCurrencyDecimals(),
          ),
          packageId: packageId,
        });

      const {
        candidate,
        fundingWallet,
        inToken,
        inAmount,
        outToken,
        outAmount,
        outPackageId,
        outPackageQuantity,
        maxAmount,
        minAmount,
        totalSupply,
        deadline,
        signature,
      } = res;

      const methodParams = [
        [
          candidate,
          fundingWallet,
          inToken,
          inAmount,
          outToken,
          outAmount,
          outPackageId.toString(),
          outPackageQuantity,
          maxAmount,
          minAmount,
          totalSupply,
          deadline,
        ],
        signature,
      ];

      const isNativeToken = isPurchaseWithNativeToken(
        inoPool.getNetwork(),
        inoPool.getCollateralCurrencySymbol(),
      );

      const params = makePurchaseINOParams(
        inoPool?.getContractAddress(),
        methodParams,
        isNativeToken
          ? 'buyNftByEtherWithPermission'
          : 'buyNftByTokenWithPermission',
        isNativeToken && inAmount,
      );
      await dispatch(
        processTransaction({
          provider: user?.getProvider(),
          params,
          title: 'Purchase NFT',
        }),
      );
      onSuccess && onSuccess();
    } catch (error: any) {
      console.log(error);
      toastError({ message: error.toString() });
    }
  };

  return { onBuyNFT };
};

export default useINOPool;
