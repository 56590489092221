import { FC, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import styles from 'src/styles/components/TokenReleaseChart.module.scss';

Chart.register(...registerables);

interface LineData {
  label: string;
  dataLabel: string[];
  data: any[];
}

interface LineChartProps {
  id: string;
  tokenData: Array<any>;
}

const AppChartLine: FC<LineChartProps> = (props: LineChartProps) => {
  const { id, tokenData } = props;

  const [data, setData] = useState<LineData[]>([]);

  const CHART_TYPE = 'line';
  const DATASET_COLORS = [
    'rgba(0, 120, 255, 1)',
    'rgba(55, 222, 254, 1)',
    'rgba(64, 195, 109, 1)',
    'rgba(181, 62, 250, 1)',
    'rgba(240, 116, 22, 1)',
    'rgba(253, 218, 2, 1)',
    'rgba(245, 66, 114, 1)',
    'rgba(245, 239, 66, 1)',
    'rgba(245, 66, 96, 1)',
    'rgba(66, 245, 117, 1)',
    'rgba(245, 236, 66, 1)',
    'rgba(72, 245, 66, 1)',
    'rgba(245, 242, 66, 1)',
    'rgba(66, 245, 150, 1)',
    'rgba(66, 164, 245, 1)',
  ];
  const DAYS_IN_MONTH = 30;
  const MONTH_LABEL = 'Month';
  const BORDER_DASH = [2, 2]; // number pixels of line and number pixels of space
  const legendContainerId = 'line-chart-legend-container' + id;

  useEffect(() => {
    if (tokenData.length > 0) {
      onGenerateChartData(tokenData);
    }
  }, [tokenData]);

  useEffect(() => {
    if (data.length > 0) {
      onDrawChart();
    }
  }, [data]);

  const isEndOfMonth = (day: number | string) => {
    return Number(day) % DAYS_IN_MONTH === 0;
  };

  const onGenerateChartData = (tokensData: any[]) => {
    const newData = tokensData.map((tokenInfos: any) => {
      if (!tokenInfos.schedules)
        return {
          label: tokenInfos.title,
          dataLabel: [],
          data: [],
        };
      const dataTitle = Object.keys(tokenInfos.schedules).filter(
        (titleToken) => !isNaN(Number(titleToken)),
      );
      return {
        label: tokenInfos.title,
        dataLabel: dataTitle,
        data: dataTitle.map((title) => tokenInfos.schedules[title]),
      };
    });
    setData(newData);
  };

  const onGenerateCalculatedLabels = (
    dataLabels: Array<any>,
  ): Array<string> => {
    const labels = dataLabels[0].dataLabel; // get the first data label (equal in all data)
    const finalLabels: Array<string> = [];
    labels.forEach((item: string, _index: number) => {
      if (isEndOfMonth(item)) {
        finalLabels.push(
          Number(item) > 0
            ? `${MONTH_LABEL} ${Number(item) / DAYS_IN_MONTH}`
            : '',
        ); // Month 0 is hidden
      }
    });
    return finalLabels;
  };

  const onGenerateCalculatedData = (dataCalculate: any): Array<number> => {
    const labels = dataCalculate.dataLabel;
    const finalData: Array<number> = [];
    labels.forEach((item: string, index: number) => {
      if (isEndOfMonth(item)) {
        finalData.push(dataCalculate.data[index]);
      }
    });
    return finalData;
  };

  const onGenerateData = () => {
    const labels = onGenerateCalculatedLabels(data);
    const datasets = data.map((item, index) => ({
      label: item.label,
      data: onGenerateCalculatedData(item),
      backgroundColor: DATASET_COLORS[index],
      fill: true,
    }));
    return { labels, datasets };
  };

  const onGenerateOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      pointRadius: 0,
      backgroundColor: 'rgba(21, 27, 56, 1)',
      plugins: {
        title: {
          display: false,
        },
        htmlLengend: {
          // ID of the container to put the legend in
          containerID: legendContainerId,
        },
        legend: {
          display: false, // use custom legend
        },
      },
      scales: {
        x: {
          grid: {
            color: '#252D55',
            borderDash: BORDER_DASH,
          },
        },
        y: {
          stacked: true,
          grid: {
            color: '#252D55',
            borderDash: BORDER_DASH,
          },
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
    };
  };

  const getOrCreateLegendList = () => {
    const legendContainer = document.getElementById(legendContainerId);
    if (!legendContainer) {
      return;
    }
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.className = styles['container__legend__list'];
      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: legendContainerId,
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList();
      if (!ul) {
        return;
      }
      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item: any) => {
        const li = document.createElement('li');
        li.className = styles['container__legend__list__item'];
        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex),
            );
          }
          chart.update();
        };
        // Checkbox
        const icon = document.createElement('img');
        icon.src = `/images/${
          !item.hidden ? 'checked-icon.svg' : 'checkbox-icon.svg'
        }`;
        icon.className = styles['container__legend__list__icon-checkbox'];

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.className = styles['container__legend__list__color-box'];
        boxSpan.style.background = item.fillStyle;

        // Text
        const textContainer = document.createElement('p');
        textContainer.className = styles['container__legend__list__text'];
        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(icon);
        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const onDrawChart = () => {
    const areaChart = document.getElementById(id);
    const chart = Chart.getChart(id);
    if (chart) chart.destroy();
    const dataChart = onGenerateData();
    const options = onGenerateOptions();
    if (!areaChart) {
      return null;
    }
    // @ts-ignore
    return new Chart(areaChart, {
      type: CHART_TYPE,
      data: dataChart,
      options,
      plugins: [htmlLegendPlugin],
    });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['container__chart']}>
        <canvas id={id} />
      </div>
      <div id={legendContainerId} className={styles['container__legend']} />
    </div>
  );
};

export default AppChartLine;
