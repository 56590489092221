import config from 'src/config';
import { ILinkedAccount, IUserInfo } from 'src/utils/common';
import BaseRequest from './BaseRequest';

export default class UserRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.userApi;
  }

  getUser(): Partial<IUserInfo> {
    const url = `/my/users`;
    return this.get(url) as any;
  }

  linkAccount(data: {
    domain: string;
    signature: string;
    address: string;
    network: string;
  }): ILinkedAccount[] {
    const url = '/my/users/link-solana';
    return this.post(url, data) as any;
  }

  unlinkAccount() {
    const url = '/my/users/unlink-solana';
    return this.post(url, {});
  }
}
