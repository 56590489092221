import React, { FC } from 'react';
import styles from 'src/styles/components/WalletAddress.module.scss';
import { formatShortAddress } from 'src/utils/utils-formats';
import { CopyIcon } from 'src/assets/icons';
import clsx from 'clsx';
import { copyToClipboard } from 'src/utils/utils-helpers';

interface WalletAddressProps {
  network?: string;
  walletAddress: string;
  bold?: boolean;
  className?: string;
}

const CopyableWalletAddress: FC<WalletAddressProps> = ({
  walletAddress,
  bold = false,
  className,
}) => {
  return (
    <div className={clsx(styles['wallet-address'], className)}>
      <p
        className={clsx(styles['wallet-address__text'], bold && styles['bold'])}
      >
        {formatShortAddress(walletAddress)}
      </p>
      <CopyIcon
        onClick={() => copyToClipboard(walletAddress)}
        className={styles['wallet-address__icon']}
      />
    </div>
  );
};

export default CopyableWalletAddress;
