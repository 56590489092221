import { RouteComponentProps, withRouter } from 'react-router';
import BigNumber from 'bignumber.js';
import useAuth from 'src/hooks/useAuth';
import styles from 'src/styles/components/CardInfoToken.module.scss';
import { formatTierAsString, formatWeiNumber } from 'src/utils/utils-formats';
import { getPublicRaiseByUSD } from 'src/utils/utils-pool';
import { IDOPool } from 'src/utils/pool';
import { UserClaimInfoType } from '..';

interface CardInfoTokenProps extends RouteComponentProps {
  idoPool: IDOPool;
  userAllocation: number;
  claimInfo: UserClaimInfoType;
  purchasedTokens: number;
  maxTokenCanBuy: string;
  isRegisteredWhitelist: boolean;
  isModal: boolean;
}

const CardInfoToken = (props: CardInfoTokenProps) => {
  const {
    idoPool,
    userAllocation,
    claimInfo,
    purchasedTokens,
    maxTokenCanBuy,
    isRegisteredWhitelist,
    isModal = false,
  } = props;
  const { user } = useAuth();

  const timeline = idoPool.getTimeline();

  const getAllocation = (): string => {
    if (!user || !isRegisteredWhitelist) return '';
    return userAllocation.toString();
  };

  const getTokenReceived = (): string => {
    return `${formatWeiNumber(
      claimInfo.claimedToken,
      idoPool.getSwapTokenDecimals(),
    )}/${formatWeiNumber(
      claimInfo.purchasedToken,
      idoPool.getSwapTokenDecimals(),
    )}`;
  };

  const getYourPurchase = (): string => {
    const isWaitingPickWinner =
      !idoPool.isPoolDeployed() || timeline?.beforeSwapPhase();
    if (isWaitingPickWinner || !user) {
      return '0';
    }
    return purchasedTokens.toString();
  };

  const getEligibleTiers = (): string => {
    const poolTiers = idoPool.getTiers();
    if (!poolTiers) return '';
    return poolTiers.map((tier: string) => formatTierAsString(tier)).join(', ');
  };

  const getRemainingToken = (): string => {
    if (!user || !isRegisteredWhitelist) return '';
    return new BigNumber(maxTokenCanBuy)
      .minus(new BigNumber(purchasedTokens))
      .toString();
  };

  const _renderDataTable = (data: any[]) => (
    <>
      {data.map((col, colIndex) => (
        <div
          className={`${styles['col']} ${styles[`col--${data.length}`]}`}
          key={colIndex}
        >
          {col.map((row: any, rowIndex: number) => (
            <div
              className={`${styles['row']} ${
                (isModal && row.title === 'Eligible Tiers') ||
                (isModal && row.title === 'KYC required')
                  ? styles['hidden-in-modal']
                  : ''
              }`}
              key={rowIndex}
            >
              <div className={styles['left']}>
                <i className={styles['icon']} />
                <span className={styles['label']}>{row.title}</span>
              </div>
              <div className={styles['right']}>
                <span className={styles['value']}>{row.value}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );

  const _renderCardInfoToken = () => {
    const ticker = idoPool.getSwapTokenSymbol();
    const swapAmount = idoPool.getCapacity();
    const tokenPrice = idoPool.getWeiSwapRate();
    const yourPurchase = getYourPurchase();
    const publicRaiseByUSD = getPublicRaiseByUSD(
      swapAmount.toString(),
      tokenPrice,
      idoPool.getSwapTokenDecimals(),
      idoPool.getCollateralCurrencyDecimals(),
      idoPool.getCurrencyInUSD(),
    );
    const eligibleTiers = getEligibleTiers();

    // default is upcoming and whitelist
    const tableData = [
      [
        { title: 'Ticker', value: ticker },
        {
          title: 'Swap Amount',
          value: `${formatWeiNumber(
            swapAmount.toString(),
            idoPool.getSwapTokenDecimals(),
          )} ${ticker}`,
        },
        { title: 'Eligible Tiers', value: eligibleTiers },
      ],
      [
        {
          title: 'Token Price',
          value: `${formatWeiNumber(
            tokenPrice,
            idoPool.getCollateralCurrencyDecimals(),
          )} ${idoPool.getCollateralCurrencySymbol()}`,
        },
        { title: 'Public Raise', value: `$${publicRaiseByUSD}` },
        {
          title: 'KYC required',
          value: idoPool.doesPoolRequireKYC() ? 'Yes' : 'No',
        },
      ],
    ];
    if (timeline?.shouldHighlightSwapPhase()) {
      const allocation = getAllocation();
      const remainingToken = getRemainingToken();
      tableData.push([
        {
          title: timeline.isPublicSwapPhase()
            ? 'Your Max Purchase for FCFS Phase'
            : 'Your Allocation',
          value: `${formatWeiNumber(
            allocation,
            idoPool.getSwapTokenDecimals(),
          )} ${ticker}`,
        },
        {
          title: 'Your Purchase',
          value: `${formatWeiNumber(
            yourPurchase,
            idoPool.getSwapTokenDecimals(),
          )} ${ticker}`,
        },
        {
          title: `Remaining${timeline.isPublicSwapPhase() ? ' FCFS' : ''}`,
          value: `${formatWeiNumber(
            remainingToken,
            idoPool.getSwapTokenDecimals(),
          )} ${ticker}`,
        },
      ]);
    } else if (timeline?.shouldHighlightClaimPhase()) {
      const tokenReceived = getTokenReceived();
      tableData.push([
        {
          title: 'Your Purchase',
          value: `${formatWeiNumber(
            yourPurchase,
            idoPool.getSwapTokenDecimals(),
          )} ${ticker}`,
        },
        { title: 'Token Received', value: tokenReceived },
      ]);
    }
    return _renderDataTable(tableData);
  };

  return (
    <div className={`${styles['card']} ${isModal && styles['card-in-modal']}`}>
      {_renderCardInfoToken()}
    </div>
  );
};

export default withRouter(CardInfoToken);
