import React from 'react';
import clsx from 'clsx';
import { InfoCircleIcon, WarningRedIcon } from 'src/assets/icons';
import SocialLinks from 'src/components/SocialLinks';
import styles from 'src/styles/pages/INODetail/ImagePackageDetail.module.scss';
import { MediaType } from 'src/utils/project';

interface PhaseNoticeProps {
  iconType: 'info' | 'warning';
  message: React.ReactNode;
}

interface ImagePackageDetailProps {
  media: MediaType;
  imageUrl: string;
  phaseNotice: PhaseNoticeProps;
  loading?: boolean;
}

const ImagePackageDetail = ({
  media,
  imageUrl,
  phaseNotice,
  loading,
}: ImagePackageDetailProps) => {
  return (
    <div className={styles['image-container']}>
      <div className={clsx(styles['hide-desktop'], styles['socials'])}>
        <SocialLinks media={media || {}} iconColor="#CACCD1" type="menu" />
      </div>
      <img src={imageUrl} alt="" />
      <div className={styles['image-detail']}>
        {loading ? 'Loading...' : <PhaseNotice {...phaseNotice} />}
      </div>
    </div>
  );
};

const PhaseNotice = ({ iconType, message }: PhaseNoticeProps) => {
  return (
    <div className={styles['phase-notice']}>
      {iconType === 'info' ? <InfoCircleIcon /> : <WarningRedIcon />}
      <p>{message}</p>
    </div>
  );
};

export default ImagePackageDetail;
