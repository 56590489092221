import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class MetaDataRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.userApi;
  }

  getStakingTiers() {
    const url = `/public/tiers/staked-tiers`;
    return this.get(url);
  }

  getStakingPools() {
    const url = `/public/staking-pool`;
    return this.get(url);
  }
}
