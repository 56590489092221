import { Collapse } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from 'src/styles/pages/PoolDetail.module.scss';
import CardAccountTierAndStatus from './CardAccountTierAndStatus';
import CardInfoToken from './CardInfoToken';
import CardPurchaseToken from './CardPurchaseToken';
import CardTimeLine from './CardTimeLine';
import CardClaimProgress from './CardClaimProgress';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { PoolStatus } from 'src/constants';
import {
  TOGGLE_PURCHASE_TOKEN_MODAL,
  UserClaimInfoType,
} from 'src/pages/PageIDOPoolDetail';
import useAuth from 'src/hooks/useAuth';
import rf from 'src/requests/RequestFactory';
import { isMobile } from 'react-device-detect';
import {
  getSoldTokens,
  getUserPurchased,
  IWinnerResponseType,
} from 'src/utils/utils-pool';
import BaseModal from 'src/modals/BaseModal';
import { IDOPool } from 'src/utils/pool';
import { RootState } from 'src/store';

export const RELOAD_PURCHASED_TOKENS = 'RELOAD_PURCHASED_TOKENS';
export const RELOAD_SOLD_TOKENS = 'RELOAD_SOLD_TOKENS';

interface PoolContentsProps extends RouteComponentProps {
  idoPool: IDOPool;
  winner: IWinnerResponseType | null;
  claimInfo: UserClaimInfoType;
  isRegisteredWhitelist: boolean;
  openPurchaseTokenModal: boolean;
}

const PoolContents = (props: PoolContentsProps) => {
  const {
    idoPool,
    winner,
    claimInfo,
    isRegisteredWhitelist,
    openPurchaseTokenModal,
  } = props;
  const timeline = idoPool.getTimeline();

  const { user } = useAuth();
  const { token: jwtToken } = useSelector(
    (state: RootState) => state.authentication,
  );

  const [purchasedTokens, setPurchasedTokens] = useState(0);
  const [soldTokens, setSoldTokens] = useState(0);
  const [maxTokenCanBuy, setMaxTokenCanBuy] = useState<string>('0');
  const [openTimeLineCollapse, setOpenTimelineCollapse] =
    useState<boolean>(false);

  const isClaimablePhase = timeline?.shouldHighlightClaimPhase();

  const getUserAllocation = () => {
    let allocation = 0;
    if (winner) {
      allocation = Number(winner?.allocationSize) || 0;
    }
    if (timeline?.isPublicSwapPhase()) {
      allocation = idoPool.getFreeBuyAllocation();
    }
    return allocation;
  };

  const userAllocation = getUserAllocation();

  const fetchUserPurchase = async () => {
    if (!user || !idoPool.getContractAddress()) {
      return;
    }
    const userPurchased = await getUserPurchased(idoPool, user);
    setPurchasedTokens(userPurchased);
  };

  const fetchSoldTokens = async () => {
    if (!user || !idoPool.getContractAddress()) {
      return;
    }
    const tokens = await getSoldTokens(idoPool, user);
    setSoldTokens(tokens);
  };

  const fetchMaxTokenCanBuy = async (myAddress: string, poolId: string) => {
    if (!myAddress || !idoPool || !idoPool.getContractAddress() || !jwtToken)
      return;
    const response = await rf
      .getRequest('PoolRequest')
      .getUserDepositAmount(poolId);
    setMaxTokenCanBuy(response.maxBuyAmount);
  };

  useEffect(() => {
    if (!idoPool.getContractAddress()) return;
    const soldTokensInterval = setInterval(() => fetchSoldTokens(), 9000); // TODO: define reload time after 3 blocks are created (3s/block)
    return () => {
      clearInterval(soldTokensInterval);
    };
  }, [idoPool?.getId()]);

  useEffect(() => {
    AppBroadcast.on(RELOAD_PURCHASED_TOKENS, () => fetchUserPurchase());
    AppBroadcast.on(RELOAD_SOLD_TOKENS, () => fetchSoldTokens());
    return () => {
      AppBroadcast.remove(RELOAD_PURCHASED_TOKENS);
      AppBroadcast.remove(RELOAD_SOLD_TOKENS);
    };
  }, [user?.getAddress(), idoPool?.getId(), user?.getLinkedProviders()]);

  useEffect(() => {
    user && fetchMaxTokenCanBuy(user.getAddress(), idoPool.getId());
  }, [
    user?.getAddress(),
    idoPool?.getId(),
    user?.getLinkedProviders(),
    jwtToken,
  ]);

  useEffect(() => {
    if (user && idoPool) {
      fetchUserPurchase();
      fetchSoldTokens();
    }
  }, [user?.getAddress(), idoPool?.getId(), user?.getLinkedProviders()]);

  const getPhaseNameByTime = (): string => {
    if (!timeline) {
      return '';
    }
    if (timeline.beforeWhitelistPhase()) {
      return PoolStatus.Upcoming;
    }
    if (timeline.isWhitelistPhase()) {
      return PoolStatus.Whitelist;
    }
    if (timeline.shouldHighlightSwapPhase()) {
      return PoolStatus.Swap;
    }
    if (timeline.shouldHighlightClaimPhase()) {
      return PoolStatus.Claim;
    }
    return '';
  };

  const onClosePurchaseTokenModal = () => {
    AppBroadcast.dispatch(TOGGLE_PURCHASE_TOKEN_MODAL, false); // close Purchase Token modal
  };

  const onToggleTimelineCollapse = () =>
    setOpenTimelineCollapse((prevState) => !prevState);

  const _renderTimeline = () => <CardTimeLine timeline={timeline} />;

  const _renderAccountTierAndStatus = () => (
    <CardAccountTierAndStatus
      isWrongNetwork={!user || !idoPool.isNetworkCorrect(user)}
      acceptedNetwork={idoPool.getNetwork()}
    />
  );

  const _renderPoolPurchaseInformation = (isModal = false) => (
    <CardInfoToken
      isModal={isModal}
      idoPool={idoPool}
      purchasedTokens={purchasedTokens}
      claimInfo={claimInfo}
      userAllocation={userAllocation}
      isRegisteredWhitelist={isRegisteredWhitelist}
      maxTokenCanBuy={maxTokenCanBuy}
    />
  );

  const _renderPurchaseTokenForm = (
    isModal = false,
    onCloseModal?: () => void,
  ) => (
    <CardPurchaseToken
      isModal={isModal}
      handleCloseModal={onCloseModal}
      idoPool={idoPool}
      purchasedTokens={purchasedTokens}
      soldTokens={soldTokens}
      isRegisteredWhitelist={isRegisteredWhitelist}
      maxTokenCanBuy={maxTokenCanBuy}
      winner={winner}
    />
  );

  const _renderClaimProgress = () => (
    <CardClaimProgress
      idoPool={idoPool}
      purchasedTokens={purchasedTokens}
      claimInfo={claimInfo}
    />
  );

  const _renderDesktopView = () => (
    <div className={styles['contain']}>
      <div className={styles['item-card']}>{_renderTimeline()}</div>
      <div className={styles['item-card']}>{_renderAccountTierAndStatus()}</div>
      <div className={styles['item-card']}>
        {_renderPoolPurchaseInformation()}
      </div>
      {timeline?.isSwapPhase() && (
        <div className={styles['item-card']}>{_renderPurchaseTokenForm()}</div>
      )}
      {isClaimablePhase && (
        <div className={styles['item-card']}>{_renderClaimProgress()}</div>
      )}
    </div>
  );

  const _renderMobileView = () => (
    <div className={styles['contain']}>
      <div className={styles['item-card']}>
        <div className={styles['card-header']}>
          <div
            className={styles['header-collapse']}
            onClick={onToggleTimelineCollapse}
          >
            <div className={styles['left']}>
              <span className={styles['check-radio']} />
              <span className={styles['title']}>{getPhaseNameByTime()}</span>
            </div>
            <div className={styles['right']}>
              <span
                className={`${styles['toggle-collapse']} ${
                  !openTimeLineCollapse ? styles['collapsed'] : ''
                }`}
              />
            </div>
          </div>
        </div>
        {_renderAccountTierAndStatus()}
        <Collapse in={openTimeLineCollapse} timeout="auto" unmountOnExit>
          {_renderTimeline()}
        </Collapse>
      </div>
      <div className={styles['item-card']}>
        {_renderPoolPurchaseInformation()}
      </div>
      {timeline?.isSwapPhase() && (
        <>
          <div className={styles['item-card']}>
            {_renderPurchaseTokenForm()}
          </div>

          <BaseModal
            open={openPurchaseTokenModal}
            onClose={onClosePurchaseTokenModal}
            fullScreenMobile
            title={'Purchase Token'}
          >
            <>
              {_renderPoolPurchaseInformation(true)}
              {_renderPurchaseTokenForm(true, onClosePurchaseTokenModal)}
            </>
          </BaseModal>
        </>
      )}
      {isClaimablePhase && (
        <div className={styles['item-card']}>{_renderClaimProgress()}</div>
      )}
    </div>
  );

  return isMobile ? _renderMobileView() : _renderDesktopView();
};

export default withRouter(PoolContents);
