import { FC, useEffect, useState } from 'react';
import { convertWeiToDec, formatWeiNumber } from 'src/utils/utils-formats';
import { NFTS_DATA } from 'src/constants';
import { ethers } from 'ethers';
import { getNFTCard, makeHarvestParams } from 'src/utils/utils-farm';
import { processTransaction } from '../store/transactions';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { LongArrowIcon } from 'src/assets/icons';
import styles from 'src/styles/modals/ModalClaimFarm.module.scss';
import BaseModal, { BaseModalProps } from './BaseModal';

interface ModalClaimFarmProps extends BaseModalProps {
  poolAddress?: string;
  pendingReward?: any;
  onClose?: () => void;
}

const ModalClaimFarm: FC<ModalClaimFarmProps> = ({
  poolAddress,
  pendingReward,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [currentLevel, setCurrentLevel] = useState(NFTS_DATA[0]);

  const claim = async () => {
    try {
      if (!poolAddress || !ethers.utils.isAddress(poolAddress)) {
        return;
      }
      const params = makeHarvestParams(poolAddress);
      dispatch(processTransaction({ provider: user?.getProvider(), params }));
      AppBroadcast.dispatch('LOAD_USER_INFORMATION');
      onClose && onClose();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setCurrentLevel(getNFTCard(pendingReward));
  }, [pendingReward]);

  const hasHighestLevelNFT = () => {
    return Number(currentLevel.id) === 5;
  };

  const _renderWhenHasHighestLevelNFT = () => {
    return (
      <div className={styles['nfts']}>
        <div style={{ textAlign: 'center' }}>
          <img
            alt={currentLevel.name}
            className={styles.nftImage}
            src={currentLevel.smallImgUrl}
          />

          <div className={styles['text']}>
            You have <br /> accumulated {currentLevel.name}
          </div>
        </div>
      </div>
    );
  };

  const _renderWhenHasNormalLevelNFT = () => {
    return (
      <div>
        <div className={styles['nfts']}>
          <div>
            <img alt={currentLevel.name} src={currentLevel.smallImgUrl} />
          </div>
          <div>
            <div className={styles['next-milestone']}>
              <div>
                Earned{' '}
                <span className={styles['value-convert']}>
                  {Number(
                    NFTS_DATA[currentLevel.id]?.lowestBuni -
                      Number(convertWeiToDec(pendingReward)) || 0,
                  ).toFixed(1)}{' '}
                  BUNI
                </span>
                <br /> to receive
              </div>
              <LongArrowIcon />
            </div>
          </div>
          <div>
            <img
              alt={NFTS_DATA[currentLevel.id].name}
              className={styles.nftImage}
              src={NFTS_DATA[currentLevel.id].smallImgUrl}
            />
          </div>
        </div>
        <div className={styles['descriptions']}>
          <div className={styles['text']}>
            You have <br /> accumulated {currentLevel.name}
          </div>
          <div className={styles['text']}>
            Keep farming to level up <br /> your NFT to{' '}
            {NFTS_DATA[currentLevel.id].name}
          </div>
        </div>
      </div>
    );
  };

  const ItemInfo = ({ name = '', value = '' }) => {
    return (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span className={styles['adornment']}></span>
          <div className={styles['name']}>{name}</div>
        </div>
        <div className={styles['value']}>{value}</div>
      </div>
    );
  };

  return (
    <BaseModal
      title={'Harvest NFT'}
      onClose={onClose}
      onActionLeft={onClose}
      onActionRight={claim}
      textActionRight="Harvest"
      fullScreenMobile
      {...props}
    >
      <>
        <div className={styles['list-info']}>
          <ItemInfo
            name="Current BUNI Earned"
            value={formatWeiNumber(pendingReward)}
          />
          <ItemInfo
            name="Next milestone"
            value={`${NFTS_DATA[currentLevel.id]?.lowestBuni}`}
          />
        </div>
        <div className={styles['box-nft']}>
          {hasHighestLevelNFT()
            ? _renderWhenHasHighestLevelNFT()
            : _renderWhenHasNormalLevelNFT()}
        </div>
      </>
    </BaseModal>
  );
};

export default ModalClaimFarm;
