import { SolanaProviderType } from 'src/connectors';

export interface IResponseDocs<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: any;
  nextPage?: any;
}

export enum TierEnum {
  NONE = 'NONE',
  TIER0 = 'TIER0',
  TIER1 = 'TIER1',
  TIER2 = 'TIER1',
  TIER3 = 'TIER3',
  TIER_GAMER = 'TIER_GAMER',
  TIER_TOP = 'TIER_TOP',
}

export interface ITierInfo {
  id: string;
  configId: number;
  tier: TierEnum;
  stakeValue: string;
  level: number;
  guaranteedAllow: string;
  lotteryTicket: string;
  text: string;
  active: true;
  icon: string;
  requirement: string;
  method: string;
  maxAllocation: string;
  withdrawDelay: string;
  exclusivePools: boolean;
}

export interface IStakingTokenProfile {
  poolAddress: string;
  stakingTokenAddress: string;
  stakedAmount: string;
  rate: number;
  stakingTokenName: string;
}

export interface IUserInfo {
  userAddress: string;
  tier: TierEnum;
  buniToNextTier: number;
  stakingTokens: IStakingTokenProfile[];
  amountStaked: string;
  rank: number;
  email: string;
  kycStatus: string;
}

export interface IDiamonUser {
  userAddress: string;
  amountStaked: string;
  stakingTokens: IStakingTokenProfile[];
  rank: number;
  competitionId: number;
}

export interface IStakingPool {
  id: string;
  poolAddress: string;
  networkAvailable: string;
  poolId: number;
  title: string;
  stakingType: string;
  stakingToken: string;
  website: string;
  logo: string;
  isDisplay: boolean;
  withdrawDelay: string;
}

export enum PoolStatusEnum {
  TOKEN_SALE = 1,
  WHITELIST = 2,
  UPCOMING = 3,
  CLAIMABLE = 4,
  ENDED = 5,
}

export enum PoolType {
  ALL_TYPE = '',
  IDO = 'IDO',
  INO = 'INO',
  LBP = 'LBP',
}

export enum AuctionStatusEnum {
  ACTIVE = 1,
  PENDING = 2,
  INACTIVE = 3,
}

export const NOT_AVAILABLE_TEXT = '--';

export interface ILinkedAccount {
  userAddress: string;
  networkFamily: string;
  networkAddress: string;
}

export type LinkedProviderType = SolanaProviderType; // add more provider types later with |

export interface ILinkedProvider {
  userAddress: string;
  networkAddress: string;
  networkFamily: string;
  provider: LinkedProviderType;
}
