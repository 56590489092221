import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Contract } from 'ethers';
import { getErrorMessage } from 'src/utils/utils-helpers';
import { toastError, toastSuccess } from '../utils/utils-notify';

interface TransactionsState {
  openModalTransactionSubmitting: boolean;
  openModalTransaction: boolean;
  transaction: any;
}

const initialState: TransactionsState = {
  openModalTransactionSubmitting: false,
  openModalTransaction: false,
  transaction: {},
};

const GAS_LIMIT_BUFFER = 0.1;

const sendTransaction = async (
  provider: any,
  [abiName, contractAddress, action, params, overrides]: any,
) => {
  const contractWithSigner = new Contract(
    contractAddress,
    abiName,
    provider.getSigner(),
  );
  try {
    // Gas estimation
    const gasLimitNumber = await contractWithSigner.estimateGas[action](
      ...params,
      overrides,
    );
    const gasLimit = gasLimitNumber.toNumber();
    overrides.gasLimit = Math.floor(gasLimit * (1 + GAS_LIMIT_BUFFER));
    return await contractWithSigner[action](...params, overrides);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const processTransaction = createAsyncThunk(
  'transaction/processTransaction',
  async (
    params: {
      provider: any;
      params: any;
      confirmation?: number;
      title?: string;
    },
    thunkApi,
  ) => {
    const { dispatch } = thunkApi;
    const { provider, params: data, title, confirmation = 5 } = params;

    try {
      const transaction = await sendTransaction(provider, data);
      console.log('transaction', transaction);

      await dispatch(
        handleTransaction({ transaction, provider, confirmation, title }),
      );
      return transaction;
    } catch (err: any) {
      toastError({ message: getErrorMessage(err) });
      console.log(err);
      throw new Error(err.message);
    }
  },
);

export const handleTransaction = createAsyncThunk(
  'transaction/handleTransaction',
  async (
    params: {
      transaction: any;
      provider: any;
      confirmation?: number;
      title?: string;
    },
    thunkApi,
  ) => {
    const { dispatch } = thunkApi;
    const { transaction, provider, confirmation } = params;
    try {
      if (transaction.hash) {
        dispatch(toggleTransactionSubmittingModal(true));
        const transactionReceipt = await provider.waitForTransaction(
          transaction.hash,
          confirmation,
        );

        dispatch(toggleTransactionSubmittingModal(false));
        if (transactionReceipt.status === 1) {
          dispatch(setTransaction(transaction));
          dispatch(toggleTransactionModal(true));
          toastSuccess({ message: 'Successfully!' });
        } else {
          toastError({ message: 'Oops. Something went wrong!' });
        }
      }
    } catch (err: any) {
      toastError({ message: getErrorMessage(err) });
      dispatch(toggleTransactionSubmittingModal(false));
      throw new Error(err.message);
    }
  },
);

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    toggleTransactionSubmittingModal: (state, action) => {
      state.openModalTransactionSubmitting = action.payload;
    },
    toggleTransactionModal: (state, action) => {
      state.openModalTransaction = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
  },
});

export const {
  toggleTransactionSubmittingModal,
  setTransaction,
  toggleTransactionModal,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
