import createRoutes from './routes';
import { Provider } from 'react-redux';
import configureStore from 'src/store';
import {
  ThemeProvider,
  createTheme,
  StylesProvider,
} from '@material-ui/core/styles';
import { typography } from './styles/vendors/material/typography';
import { overrides } from './styles/vendors/material/overrides';

const defaultTheme = createTheme({
  typography: typography,
  overrides: overrides,
  ink: {
    lighter: '#72777A',
    light: '#6C7072',
    base: '#404446',
    dark: '#303437',
    darker: '#202325',
    darkest: '#090A0A',
  },
  sky: {
    lightest: '#F7F9FA',
    lighter: '#F2F4F5',
    light: '#E3E5E5',
    base: '#CDCFD0',
    dark: '#979C9E',
  },
  red: {
    lightest: '#FFE5E5',
    lighter: '#FF9898',
    light: '#FF6D6D',
    base: '#FF5247',
    dark: '#D3180C',
  },

  green: {
    lightest: '#ECFCE5',
    lighter: '#7DDE86',
    light: '#4CD471',
    base: '#23C16B',
    dark: '#198155',
  },
  yellow: {
    lightest: '#FFEFD7',
    lighter: '#FFD188',
    light: '#FFC462',
    base: '#FFB323',
    dark: '#A05E03',
  },

  // palette: palette,
});

const App = () => {
  const { store } = configureStore();
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>{createRoutes()}</ThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

export default App;
