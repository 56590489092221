import { Dialog, DialogContent } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  ArrowNextIcon,
  CircleReadIcon,
  CloseModalIcon,
} from 'src/assets/icons';
import styles from 'src/styles/pages/LPB/PartLeftSide.module.scss';
import { STEPS } from '../';

interface LeftSideProps {
  activeStep: typeof STEPS[keyof typeof STEPS];
  isSuccess: boolean;
}

const PartLeftSide = (props: LeftSideProps) => {
  const { activeStep, isSuccess } = props;

  const sideRef = useRef<HTMLDivElement>(null);

  const [openOverview, setOpenOverview] = useState<boolean>(false);
  const [stickyStep, setStickyStep] = useState<boolean>(window.scrollY >= 84);
  const [isShowFull, setIsShowFull] = useState<boolean>(false);

  useEffect(() => {
    const onScrollPage = () => {
      if (sideRef.current?.offsetTop) {
        setStickyStep(window.scrollY > sideRef.current?.offsetTop);
      }
    };
    document.addEventListener('scroll', onScrollPage);
    return () => {
      document.removeEventListener('scroll', onScrollPage);
    };
  }, []);

  const ModalOverview = () => {
    return (
      <Dialog
        className={styles['modal-overview']}
        fullScreen
        open={openOverview}
        onClose={() => setOpenOverview(false)}
      >
        <DialogContent classes={{ root: styles['root'] }}>
          <div className={styles['head-title']}>
            <span
              className={styles['icon-back']}
              onClick={() => setOpenOverview(false)}
            >
              <ArrowNextIcon />
            </span>
            <div className={styles['title-text']}>Overview</div>
            <CloseModalIcon onClick={() => setOpenOverview(false)} />
          </div>
          <div className={styles['content']}>
            <p className={styles['caption']}>
              Creating your own auction would require three steps:
            </p>
            <p className={styles['desc']}>
              Let’s begin with “Step 1” by filling the form. Once your input is
              validated, the information will be loaded. In “Step 2”, please
              input the amount of token that you want as long as it meets your
              wallet balance. All pool details are optional but highly
              recommended. After you agree to all terms and approve the
              transactions in “Step 3”, you’re finishing up the auction
              creation.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const _renderReadmore = () => {
    if (isMobile) return;
    return (
      <div className={styles['bottom']}>
        <div className={styles['icon']}>
          <CircleReadIcon />
        </div>
        <div className={styles['desc']}>
          <span
            className={`${styles['text']} ${
              styles[isShowFull ? '' : 'summary']
            }`}
          >
            Let’s begin with “Step 1” by filling the form. Once your input is
            validated, the information will be loaded. In “Step 2”, please input
            the amount of token that you want as long as it meets your wallet
            balance. All pool details are optional but highly recommended. After
            you agree to all terms and approve the transactions in “Step 3”,
            you’re finishing up the auction creation.
          </span>
          <span
            className={styles['read-more']}
            onClick={() => setIsShowFull(!isShowFull)}
          >
            {isShowFull ? 'Read less' : 'Read more'}
            <ArrowNextIcon />
          </span>
        </div>
      </div>
    );
  };

  const getStyleByStep = (step: typeof STEPS[keyof typeof STEPS]): string => {
    if (activeStep < step) return '';
    return activeStep === step ? styles['pendding'] : styles['done'];
  };

  const isStickStepMobile = isMobile && stickyStep;

  const getTitleForMobile = useMemo(() => {
    if (activeStep === STEPS.CONFIG_OVERVIEW) {
      return 'Select a valid Token';
    }

    if (activeStep === STEPS.CREATE_LBP) {
      return 'Sign transaction to confirm all of your inputs';
    }

    return 'Provide information of the auction';
  }, [activeStep]);

  return (
    <>
      <div className={styles['side']}>
        <div className={styles['top']}>
          <div className={styles['title']}>
            {!isMobile ? 'Overview' : getTitleForMobile}
          </div>
          <div className={styles['desc']}>
            {isMobile && (
              <span
                className={styles['icon']}
                onClick={() => setOpenOverview(true)}
              >
                <CircleReadIcon />
              </span>
            )}
            <span className={styles['text']}>
              Creating your own auction would require three steps:
            </span>
            {isMobile && (
              <span
                className={styles['icon-next-detail']}
                onClick={() => setOpenOverview(true)}
              >
                <ArrowNextIcon />
              </span>
            )}
          </div>
        </div>
        <div
          className={`${isStickStepMobile ? styles['pseudo-stickey'] : ''}`}
          ref={sideRef}
        >
          <div
            className={`${styles['position-static']} ${
              isStickStepMobile ? styles['position-sticky'] : ''
            }`}
          >
            <div className={styles['step-line']}>
              <div
                className={`
                  ${styles['item']}
                  ${
                    activeStep > STEPS.CONFIG_OVERVIEW
                      ? styles['done']
                      : styles['pendding']
                  }`}
              >
                <div className={styles['intro']}>
                  <div className={styles['index']}>1</div>
                  <div className={styles['title']}>Select a valid Token</div>
                </div>
                <div className={styles['detail']}>
                  <span className={styles['line']}></span>
                </div>
              </div>
              <div className={styles['divider']}></div>
              <div
                className={`
                  ${styles['item']}
                  ${activeStep >= STEPS.POOL_SETUP && styles['pendding']}
                  ${activeStep >= STEPS.CREATE_LBP && styles['done']}`}
              >
                <div className={styles['intro']}>
                  <div className={styles['index']}>2</div>
                  <div className={styles['title']}>
                    Provide information of the auction
                  </div>
                </div>
                <div className={styles['detail']}>
                  <span className={styles['line']}></span>
                  <div className={styles['list-step']}>
                    <div
                      className={`
                        ${styles['view']}
                        ${getStyleByStep(STEPS.POOL_SETUP)}
                      `}
                    >
                      Pool Setup
                    </div>
                    <div
                      className={`
                        ${styles['view']}
                        ${getStyleByStep(STEPS.POOL_INFO)}
                      `}
                    >
                      Pool Information
                    </div>
                    <div
                      className={`
                        ${styles['view']}
                        ${getStyleByStep(STEPS.POOL_PREVIEW)}
                      `}
                    >
                      Auction Preview
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['divider']}></div>
              <div
                className={`
                  ${styles['item']}
                  ${activeStep >= STEPS.CREATE_LBP && styles['pendding']}
                  ${isSuccess && styles['done']}`}
              >
                <div className={styles['intro']}>
                  <div className={styles['index']}>3</div>
                  <div className={styles['title']}>
                    Sign transaction to confirm all of your inputs
                  </div>
                </div>
                <div className={styles['detail']}>
                  <span className={styles['line']}></span>
                  <div className={styles['list-step']}>
                    <div
                      className={`
                        ${styles['view']}
                        ${getStyleByStep(STEPS.CREATE_LBP)}
                      `}
                    >
                      Created Auction
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {_renderReadmore()}
      </div>
      <>{ModalOverview()}</>
    </>
  );
};

export default PartLeftSide;
