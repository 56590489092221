import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { RootState } from 'src/store';
import BasePage from 'src/pages/BasePage';
import { withWidth, Tabs, Tab } from '@material-ui/core';
import styles from 'src/styles/pages/StakingPools.module.scss';
import StakingTable from 'src/components/StakingTable';
import { isMobile } from 'react-device-detect';
import PartStakingListPool from './parts/PartStakingPoolList';
import PartDiamondUsers from './parts/PartDiamondUsers';
import PartStakingCompetition from './parts/PartStakingCompetition';
import PartStakingTier from './parts/PartStakingTier';
import PartOverview from './parts/PartOverview';
import PartStakingPool from './parts/PartStakingPool';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import AppAlertWarning from 'src/components/AppAlertWarning';
import config from 'src/config';
import { ArrowNextIcon } from 'src/assets/icons';

const TAB_STAKING = {
  poolList: 0,
  stakingTable: 1,
  diamondTier: 2,
  competition: 3,
};

const TABS_MOBILE = [
  'Pool List',
  'Staking Tier',
  'Diamond Tier Ranking',
  'Staking Competition',
];

const Staking = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedPool, setSelectedPool] = useState<any>({});

  const { network, address: account } = useSelector(
    (state: RootState) => state.authentication,
  );

  useEffect(() => {
    if (account) {
      setSelectedPool({});
    }
  }, [account]);

  useEffect(() => {
    AppBroadcast.on('OPEN_DETAIL_STAKING_POOL', (payload: any) => {
      setSelectedPool(payload);
    });
    AppBroadcast.on('CLOSE_DETAIL_STAKING_POOL', () => {
      setSelectedPool({});
    });

    return () => {
      AppBroadcast.remove('OPEN_DETAIL_STAKING_POOL');
      AppBroadcast.remove('CLOSE_DETAIL_STAKING_POOL');
    };
  }, []);

  const wrongChain = useMemo(() => {
    return network !== config.supportStakingNetwork;
  }, [network]);

  const _renderWrongNetwork = () => {
    return (
      <AppAlertWarning acceptedNetwork={config.supportStakingNetwork}>
        Please switch network to the Binance Smart Chain to join these staking
        pools
      </AppAlertWarning>
    );
  };

  const isActiveTab = (tab: number) => {
    return activeTab === tab;
  };

  const handleChangeTab = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };

  const _renderTabForMobile = () => {
    return (
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="on"
        aria-label="scrollable auto tabs example"
        classes={{
          root: styles['root-tabs'],
          indicator: styles['indicator'],
        }}
        ScrollButtonComponent={(props) => {
          if (props.direction === 'left' && !props.disabled) {
            return (
              <div
                {...props}
                className={`${styles['btn-navigation']} ${styles['btn-left']}`}
              >
                <ArrowNextIcon />
              </div>
            );
          } else if (props.direction === 'right' && !props.disabled) {
            return (
              <div
                {...props}
                className={`${styles['btn-navigation']} ${styles['btn-right']}`}
              >
                <ArrowNextIcon />
              </div>
            );
          } else {
            return null;
          }
        }}
      >
        {TABS_MOBILE.map((item: string, index: number) => {
          return (
            <Tab
              key={index}
              wrapped={true}
              label={item}
              classes={{
                selected: styles['selected-tab'],
                wrapped: styles['wrapped-tab'],
              }}
            />
          );
        })}
      </Tabs>
    );
  };

  const _renderForDesktop = () => {
    return (
      <div className="row">
        <div
          className={
            selectedPool && !!Object.keys(selectedPool).length
              ? styles['content-left'] + ' col-9'
              : ' col-12'
          }
        >
          {_renderWrongNetwork()}
          <PartOverview />
          <PartStakingListPool />
          <div>
            <PartStakingTier />
            <PartDiamondUsers />
            <PartStakingCompetition />
          </div>
        </div>

        {selectedPool && !!Object.keys(selectedPool).length && (
          <div className={styles['content-right'] + ' col-3'}>
            <div className={`${styles['pool-detail']}`}>
              <PartStakingPool pool={selectedPool} wrongChain={wrongChain} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const _renderForMobile = () => {
    return (
      <>
        <div
          className={styles['']}
          style={{
            visibility:
              selectedPool && Object.keys(selectedPool).length
                ? 'hidden'
                : 'inherit',
          }}
        >
          {_renderWrongNetwork()}

          <div className={styles['tab-staking-mobile']}>
            {_renderTabForMobile()}
          </div>

          <div
            style={{
              display: isActiveTab(TAB_STAKING.poolList) ? 'block' : 'none',
            }}
          >
            <PartStakingListPool />
          </div>
          <div
            style={{
              display: isActiveTab(TAB_STAKING.stakingTable) ? 'block' : 'none',
            }}
          >
            <StakingTable />
          </div>
          <div
            style={{
              display: isActiveTab(TAB_STAKING.diamondTier) ? 'block' : 'none',
            }}
          >
            <PartDiamondUsers />
          </div>
          <div
            style={{
              display: isActiveTab(TAB_STAKING.competition) ? 'block' : 'none',
            }}
          >
            <PartStakingCompetition />
          </div>
        </div>
        <div
          className={`${styles['pool-detail-mobile']} ${
            selectedPool && Object.keys(selectedPool).length
              ? styles['show']
              : ''
          }`}
        >
          {Object.keys(selectedPool).length && (
            <PartStakingPool pool={selectedPool} wrongChain={wrongChain} />
          )}
        </div>
      </>
    );
  };

  return (
    <BasePage>
      <div className={styles['staking-page']}>
        {isMobile ? <>{_renderForMobile()}</> : <>{_renderForDesktop()}</>}
      </div>
    </BasePage>
  );
};

export default withWidth()(withRouter(Staking));
