import styles from 'src/styles/components/NetworkWarningAlert.module.scss';
import AppButton from 'src/components/AppButton';
import {
  WarningOrangeIcon,
  BubbleIcon,
  CloseModalIcon,
} from 'src/assets/icons';
import { switchNetwork } from 'src/utils/utils-auth';
import { ReactNode, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { isNetworkEVM } from 'src/utils/utils-network';

type PropsType = {
  acceptedNetwork: string;
  type?: 'fullWidth' | 'shortcut';
  children?: ReactNode;
  onHandleChangeNetwork?: (network?: string) => void;
};

const AlertSwitchNetwork = (props: PropsType) => {
  const { acceptedNetwork, type = 'fullWidth', onHandleChangeNetwork } = props;
  const { user } = useAuth();

  const isCorrectNetwork = user?.getNetwork() === acceptedNetwork;

  const onSwitchToAcceptedNetwork = () => {
    if (!user) {
      return;
    }
    if (isNetworkEVM(acceptedNetwork)) {
      switchNetwork(acceptedNetwork, user?.getProvider());
    } else {
      onHandleChangeNetwork && onHandleChangeNetwork(acceptedNetwork);
    }
  };

  if (!user || isCorrectNetwork) {
    return null;
  }

  const defaultMessage =
    'The connected wallet does not match the target network';

  return (
    <div
      className={`${
        type === 'shortcut'
          ? styles['network-warning-block']
          : styles['network-warning']
      }`}
    >
      <WarningOrangeIcon className={styles['icon-outer']} />
      <div className={styles['text']}>
        <span className={styles['icon-warning']}>
          <WarningOrangeIcon />
        </span>
        {props?.children ? props?.children : defaultMessage}
        <span className={styles['icon-bubble']}>
          <BubbleIcon style={{ marginLeft: '8px' }} />
        </span>
      </div>
      <AppButton
        variant="white"
        sizes="small"
        onClick={onSwitchToAcceptedNetwork}
      >
        Switch network
      </AppButton>
    </div>
  );
};

export const AlertWaring = (props: any) => {
  const [isShowAlert, setIsShowAlert] = useState<boolean>(true);

  if (!isShowAlert) {
    return <></>;
  }

  return (
    <div className={styles['alert-warning']}>
      <WarningOrangeIcon className={styles['icon-outer']} />
      <div className={styles['text']}>
        {props.children}
        <span className={styles['icon-bubble']}>
          <BubbleIcon style={{ marginLeft: '8px' }} />
        </span>
      </div>
      <div className={styles['btn-close']}>
        <CloseModalIcon onClick={() => setIsShowAlert(false)} />
      </div>
    </div>
  );
};

export const AppAlertWarning = (props: any) => {
  if (props.acceptedNetwork) {
    return (
      <AlertSwitchNetwork
        acceptedNetwork={props.acceptedNetwork}
        type={props.type}
        onHandleChangeNetwork={props.onHandleChangeNetwork}
      >
        {props.children}
      </AlertSwitchNetwork>
    );
  }

  return <AlertWaring>{props.children}</AlertWaring>;
};

export default AppAlertWarning;
