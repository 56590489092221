import { useEffect, useState } from 'react';
import { Chart, registerables, ScriptableLineSegmentContext } from 'chart.js';
import styles from 'src/styles/pages/LPB/AuctionPriceChart.module.scss';
import moment from 'moment';
import {
  AuctionPriceChartData,
  getCurrentAuctionTime,
} from 'src/utils/utils-auction';

const TAB_CHART = {
  STANDARD: 0,
  LOGARITHMIC: 1,
};

Chart.register(...registerables);

interface AuctionDrawData {
  labels: string[];
  data: number[];
}

interface AuctionPriceChartProps {
  id: string;
  priceData: AuctionPriceChartData[];
  customChartClassname?: string;
  isPreview?: boolean;
  symbol?: {
    main?: string;
    base?: string;
  };
  startTime?: Date;
  endTime?: Date;
}

const PartAuctionPriceChart: React.FC<AuctionPriceChartProps> = (
  props: AuctionPriceChartProps,
) => {
  const {
    id,
    customChartClassname = '',
    priceData,
    isPreview = false,
    symbol,
    startTime = moment().toDate(),
    endTime = moment().add(3, 'days').toDate(),
  } = props;

  const CHART_TYPE = 'line';
  const TIME_FORMAT = 'DD MMM';
  const DATASET_COLORS = ['rgba(181, 62, 250, 1)', 'rgba(255, 152, 229, 1)'];
  const POINT_RADIUS = 4;

  const [activeTab, setActiveTab] = useState<number>(TAB_CHART.STANDARD);

  const current = getCurrentAuctionTime();

  useEffect(() => {
    const data = getAuctionDrawData(priceData);
    onDrawChart(data);
  }, [priceData, activeTab]);

  const getAuctionDrawData = (
    data: AuctionPriceChartData[],
  ): AuctionDrawData => {
    let day = 0;
    return {
      labels: data.map((item) => {
        if (!item.time) {
          return '';
        }
        if (moment(item.time).date() !== day) {
          day = moment(item.time).date();
          return moment(item.time).format(TIME_FORMAT);
        }
        return '';
      }),
      data: data.map((item) => +item.value),
    };
  };

  const getChartOptions = () => ({
    maintainAspectRatio: false,
    fill: false,
    lineTension: 0.4, // make curve for line
    borderJoinStyle: 'round',
    borderWidth: 2,
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        offset: true,
        ticks: {
          autoSkip: false, // show all labels
          // maxRotation: 0,
        },
      },
      y: {
        grid: {
          color: 'rgba(31, 38, 70, 1)',
        },
        ticks: {
          callback: (label: string) => `${(+label).toFixed(6)}`,
          maxTicksLimit: 11,
        },
        type: activeTab === TAB_CHART.LOGARITHMIC ? 'logarithmic' : undefined,
      },
    },
    interaction: {
      intersect: false,
      axis: 'x',
      mode: 'nearest',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        caretPadding: 10,
        callbacks: {
          title: (tooltipItem: any[]) => {
            return priceData[tooltipItem[0].dataIndex]
              ? moment(priceData[tooltipItem[0].dataIndex].time).format(
                  'MMM DD, YYYY, HH:mm a',
                )
              : '';
          },
          label: function (tooltipItem: any) {
            return priceData[tooltipItem.dataIndex]
              ? `${Number(priceData[tooltipItem.dataIndex].value).toFixed(8)}${
                  symbol?.base ? ` ${symbol.base.toUpperCase()}` : ''
                }`
              : '';
          },
        },
      },
    },
  });

  const isPredictedPrice = (time: number | string, isPreviewMode: boolean) => {
    if (isPreviewMode) {
      return false;
    }
    return moment(time).isSameOrAfter(current);
  };

  const getChartData = (
    drawData: AuctionDrawData,
    priceDataProps: AuctionPriceChartData[],
    isPreviewMode: boolean,
  ) => {
    let findFirstPredictedPrice = false;
    const pointRadius = priceDataProps.map((item) => {
      if (isPredictedPrice(item.time, isPreviewMode)) {
        const radius = findFirstPredictedPrice ? 0 : POINT_RADIUS;
        findFirstPredictedPrice = true;
        return radius;
      }
      return 0;
    });

    const pointBackgroundColor = priceDataProps.map((item) => {
      if (!isPredictedPrice(item.time, isPreviewMode)) {
        return DATASET_COLORS[0];
      }
      return '#D9D9D9';
    });
    return {
      labels: drawData.labels,
      datasets: [
        {
          data: drawData.data,
          borderColor: DATASET_COLORS[0],
          pointRadius: pointRadius,
          pointHoverRadius: 4,
          pointBackgroundColor: pointBackgroundColor,
          pointHoverBackgroundColor: pointBackgroundColor,
          segment: {
            borderColor: (ctx: ScriptableLineSegmentContext) => {
              return isPredictedPrice(
                priceDataProps[ctx.p0DataIndex].time,
                isPreviewMode,
              )
                ? DATASET_COLORS[1]
                : DATASET_COLORS[0];
            },
          },
        },
      ],
    };
  };

  const onDrawChart = (drawData: AuctionDrawData) => {
    const areaChart = document.getElementById(id);
    let chart = Chart.getChart(id);
    if (chart) chart.destroy();
    const options = getChartOptions();
    const data = getChartData(drawData, priceData, isPreview);
    // @ts-ignore
    chart = new Chart(areaChart, {
      type: CHART_TYPE,
      data,
      options,
    });
  };

  const _renderTabChart = () => {
    if (isPreview) return null;

    const isActiveTab = (tab: number) => {
      return activeTab === tab;
    };

    return (
      <div className={styles['tabs-chart']}>
        <div
          className={`
            ${styles['tabs-item']} 
            ${styles[isActiveTab(TAB_CHART.STANDARD) ? 'active' : '']}
          `}
          onClick={() => setActiveTab(TAB_CHART.STANDARD)}
        >
          Standard
        </div>
        <div
          className={`
            ${styles['tabs-item']} 
            ${styles[isActiveTab(TAB_CHART.LOGARITHMIC) ? 'active' : '']}
          `}
          onClick={() => setActiveTab(TAB_CHART.LOGARITHMIC)}
        >
          Logarithmic
        </div>
      </div>
    );
  };

  const _renderLegends = () => {
    if (isPreview) return null;
    return (
      <div className={styles['legends']}>
        <ul className={styles['list']}>
          <li className={styles['item']}>
            <span className={styles['color-box']}></span>
            <span className={styles['text']}>
              {symbol?.main?.toUpperCase()} price
            </span>
          </li>
          <li className={styles['item']}>
            <span
              className={`${styles['color-box']} ${styles['color-box-predicted']}`}
            ></span>
            <span className={styles['text']}>
              {symbol?.main?.toUpperCase()} predicted price
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const _renderChartInfo = () => {
    if (!isPreview) return null;
    const firstData = !priceData.length
      ? { time: 0, value: 1 / 0 } // 1/0 => show Infinity
      : priceData[0];
    const lastData = !priceData.length // 1/0 => show Infinity
      ? { time: 0, value: 1 / 0 }
      : priceData[priceData.length - 1];
    const durationOfTime = !priceData.length
      ? moment(endTime).diff(moment(startTime))
      : moment(lastData.time).diff(moment(firstData.time));
    const dayDuration = moment.duration(durationOfTime).days();
    const hourDuration = moment.duration(durationOfTime).hours();

    return (
      <div className={styles['information']}>
        <div className={styles['text']}>
          <span className={styles['title']}>Duration: </span>
          <span
            className={styles['value']}
          >{`${dayDuration} days ${hourDuration} hours`}</span>
        </div>
        <div className={styles['text']}>
          <span className={styles['title']}>Price range: </span>
          <span className={styles['value']}>
            {`$${firstData.value} - $${lastData.value}`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles[isPreview ? 'container' : 'container-detail']}>
      <div className={styles['header']}>
        {_renderTabChart()}
        {_renderLegends()}
      </div>
      <div className={`${styles['chart']} ${customChartClassname}`}>
        <canvas id={id} />
      </div>
      {_renderChartInfo()}
    </div>
  );
};

export default PartAuctionPriceChart;
