import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class CompetitionsRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.userApi;
  }

  getLiveCompetition() {
    const url = '/public/competitions/available-competition';
    return this.get(url);
  }

  registerCompetition(competitionId: any) {
    const url = `/my/competitions/${competitionId}/register`;
    return this.post(url, null);
  }

  unregisterCompetition(competitionId: any) {
    const url = `/my/competitions/${competitionId}/unregister`;
    return this.post(url, null);
  }

  getSnapshotCompetition(params: any) {
    const url = `/public/snapshots`;
    return this.get(url, params);
  }

  getSnapshotCompetitionById(snapshotId: number) {
    const url = `/public/snapshots/${snapshotId}`;
    return this.get(url);
  }

  getMyCompetition() {
    const url = `/my/competitions/available-competition`;
    return this.get(url, {});
  }
}
